import React, { useState } from "react";
import VerificationPopUp from "./VerificationPopUp";

const EmailVerify = () => {
  const [email, setEmail] = useState(null);
  const [validemail, setvalidemail] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const validateEmail = (email) => {
    const regex = /^[0-9]{8}@lums\.edu\.pk$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail); // Always update the email state
    if (validateEmail(newEmail)) {
      setError(null);
      setvalidemail(true);
    } else {
      setError("Invalid email format. Please enter a valid LUMS email address.");
      setvalidemail(false);
    }
  };
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/check-email/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        },config
      );

      if (!response.ok) {
        setLoading(false);
        throw new Error("Network response was not ok");
        
      }

      const data = await response.json();
      setResponse(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <div class="h-screen">
      <div class="darki:bg-slate-900 bg-gray-100 flex h-full items-center py-16">
        <main className="w-full max-w-md mx-auto p-6">
          <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm darki:bg-gray-800 darki:border-gray-700">
            <div className="p-4 sm:p-7">
              <div className="text-center">
                <svg
                  className="w-[6rem] mb-6"
                  viewBox="0 0 361 361"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M197.099 35.684l5.991 9.717c6.577 10.668 20.145 14.652 31.446 9.234L244.83 49.7c13.087-6.276 28.198 3.436 27.927 17.947l-.213 11.413c-.235 12.531 9.026 23.218 21.462 24.77l11.328 1.413c14.402 1.797 21.864 18.136 13.79 30.197l-6.35 9.486c-6.971 10.415-4.959 24.412 4.665 32.441l8.765 7.313c11.144 9.298 8.588 27.077-4.725 32.859l-10.47 4.547c-11.496 4.993-17.37 17.856-13.615 29.813l3.42 10.891c4.349 13.847-7.414 27.422-21.739 25.088l-11.267-1.835c-12.37-2.015-24.266 5.63-27.572 17.719l-3.011 11.011c-3.828 14-21.062 19.061-31.852 9.353l-8.486-7.635c-9.316-8.383-23.458-8.383-32.774 0l-8.486 7.635c-10.79 9.708-28.024 4.647-31.852-9.353l-3.011-11.011c-3.306-12.089-15.202-19.734-27.572-17.719l-11.266 1.835c-14.326 2.334-26.089-11.241-21.74-25.088l3.42-10.891c3.755-11.957-2.12-24.82-13.615-29.813l-10.47-4.547c-13.313-5.782-15.87-23.561-4.725-32.859l8.765-7.313c9.624-8.029 11.636-22.026 4.665-32.441l-6.35-9.486c-8.074-12.061-.612-28.4 13.79-30.197l11.328-1.413c12.436-1.552 21.697-12.239 21.463-24.77l-.214-11.413c-.271-14.511 14.84-24.223 27.927-17.948l10.294 4.936c11.301 5.418 24.869 1.434 31.446-9.234l5.991-9.717c7.618-12.354 25.58-12.354 33.198 0z"
                    fill="url(#paint0_radial_747_146)"
                    stroke="#5908A8"
                    strokeWidth={5}
                  />
                  <g filter="url(#filter0_d_747_146)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M246.96 129.04c6.053 6.053 6.053 15.867 0 21.92l-72.257 72.258c-8.396 8.396-22.01 8.396-30.406 0L114.04 192.96c-6.053-6.053-6.053-15.867 0-21.92 6.053-6.053 15.867-6.053 21.92 0l23.54 23.54 65.54-65.54c6.053-6.053 15.867-6.053 21.92 0z"
                      fill="url(#paint1_linear_747_146)"
                      shapeRendering="crispEdges"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_747_146"
                      x={101}
                      y={120}
                      width={159}
                      height={122.015}
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy={4} />
                      <feGaussianBlur stdDeviation={4.25} />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix values="0 0 0 0 0.190104 0 0 0 0 0 0 0 0 0 0.375 0 0 0 1 0" />
                      <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_747_146"
                      />
                      <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_747_146"
                        result="shape"
                      />
                    </filter>
                    <radialGradient
                      id="paint0_radial_747_146"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="rotate(90 0 180.5) scale(176.5)"
                    >
                      <stop stopColor="#510C95" />
                      <stop offset={1} stopColor="#6B12C2" />
                    </radialGradient>
                    <linearGradient
                      id="paint1_linear_747_146"
                      x1={240.5}
                      y1={125}
                      x2={154.5}
                      y2={230}
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0.9} />
                    </linearGradient>
                  </defs>
                </svg>

                <h1 className="block text-2xl font-bold text-gray-800 darki:text-white">
                  Enrollment Status Check
                </h1>

                <p className="mt-2 text-sm text-gray-600 darki:text-gray-400">
                  Just pop in your email below, and we'll take it from there
                </p>
              </div>
              <div className="mt-5">
                {/* Form */}
                <form>
                  <div className="grid gap-y-4">
                    {/* Form Group */}
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm mb-2 darki:text-white"
                      >
                        Email address
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="input input-bordered w-full"
                          placeholder="eg: 12345678@lums.edu.pk"
                          required
                          value={email}
                          onChange={handleEmailChange}
                          aria-describedby="email-error"
                        />
                      </div>
                    </div>
                    {/* End Form Group */}
                    <div className="tooltip" data-tip={error}>



{loading ? (
    <button
  type="button"
  className="btn btn-primary w-full btn-block"
  disabled
>
  Checking...
</button>
      ) : (

    <button
  type="button"
  className="btn btn-primary w-full btn-block"
  onClick={handleSubmit}
  disabled={!validemail || email === ""}
>
  Verify Enrollment
</button>
      )}
</div>
                    
                  </div>
                </form>
                {/* End Form */}
              </div>
            </div>
          </div>
        </main>

        {response && (
          <p>
            {response.exists ? (
              <VerificationPopUp text="Great news! We've found your email in our system 🙌" success={true} desc="Our team is currently reviewing applications, so hang tight. We'll be in touch soon! If you have any questions, feel free to reach out." />
            ) : (
              <VerificationPopUp text="Hmmm, it seems that email doesn't exist in our records. 😕" success={false} desc="No worries though! You can easily start the enrollment process by clicking the button below" />
            )}
          </p>
        )}
      </div>{" "}
    </div>
  );
};

export default EmailVerify;
