import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

import axios from "axios";
import CryptoJS from "crypto-js";

const Home = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  if (!user) {
    // navigate('/login')
    window.location.replace("/login");
  }

  if (user["role"] === "Student") {
    window.location.replace(`/pathway`);
  }

  if (user["role"] === "Network Admin") {
    window.location.replace(`/network`);
  }

  return (
    <div className="flex items-center justify-center space-x-2 h-screen">
      <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
      <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
      <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
    </div>
  );
};
export default Home;
