// ImageSlider.js
import React, { useEffect, useState } from "react";

const ImageSlider = ({ images, onImageIndexChange }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // This effect will run after the component renders
    setIsLoading(false); // Update the loading state after rendering
  }, [currentImageIndex]); // Only re-run the effect when currentImageIndex changes

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % images.length;
      if (onImageIndexChange) {
        onImageIndexChange(newIndex);
      }
      setIsLoading(true);
      return newIndex;
    });
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + images.length) % images.length;
      if (onImageIndexChange) {
        onImageIndexChange(newIndex);
      }
      setIsLoading(true);
      return newIndex;
    });
  };

  return (
    <div className="image-slider">
      <p className="text-center bg-gray-100 p-2 rounded-t-xl">
        {" "}
        Slide <b>{currentImageIndex+1}</b> of <b>{images.length}</b>{" "}
      </p>

      <div className="controls flex flex-row justify-end gap-4 mb-4 items-center">
        <div className="prev-button-container">
          <button
            className="btn btn-circle bg-primary"
            onClick={goToPreviousImage}
            disabled={currentImageIndex === 0 || isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          </button>
        </div>

        <div className="image-container">
        {isLoading && <div className="text-center h-screen">Loading...</div>}
        <img
          src={images[currentImageIndex]}
          alt={`Slide ${currentImageIndex}`}
          onLoad={handleImageLoad}
          className="w-10/12 mx-auto block"
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>

        <div className="next-button-container">
          <button
            className="btn btn-circle bg-primary"
            onClick={goToNextImage}
            disabled={currentImageIndex === images.length - 1 || isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
