// src/components/Card.js
import React, { useState } from 'react';
import './Card.css';

const CardFlip = ({ frontContent, backContent }) => {
  const [isFlipped, setFlipped] = useState(false);

  const handleClick = () => {
    setFlipped(!isFlipped);
  };

  return (
    <div
      className={`card gap-6 cardflip border my-8 justify-center items-center ${isFlipped ? 'flipped' : ''}`}
      onClick={handleClick}
    >
      <div className="frontflipped">
        <p className='text-2xl font-bold'>{frontContent} <br/>  <small className='text-gray-400 font-normal text-base'>Click to explore more <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
</svg>
</small></p>
      </div>
      <div className="backflipped p-8 text-start ">
        <p className='block'>{backContent}</p>
      </div>





    </div>
  );
};

export default CardFlip;
