import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import SliderWeek2 from "./SliderWeek2";
import { toast, Toaster } from 'react-hot-toast';
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Confetti from 'react-confetti';
import "./new-certi.css";


const Week2 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 3;
  const week_name = "week_2";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);

  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem('week2Images');

    if (!storedImages) {
      // If not in localStorage, fetch the images
      const slideImages = [
        'https://res.cloudinary.com/ahbranding/image/upload/v1700069414/AHbranding/Slide_16_9_-_8_vjyhxy.png',
        'https://res.cloudinary.com/ahbranding/image/upload/v1700070293/AHbranding/Slide_16_9_-_9_hcpcxq.png',
        'https://res.cloudinary.com/ahbranding/image/upload/v1700070293/AHbranding/Slide_16_9_-_10_popdfd.png',
        'https://res.cloudinary.com/ahbranding/image/upload/v1700070291/AHbranding/Slide_16_9_-_11_tkedhc.png',
        'https://res.cloudinary.com/ahbranding/image/upload/v1700070292/AHbranding/Slide_16_9_-_12_xfbyei.png',
        'https://res.cloudinary.com/ahbranding/image/upload/v1700070291/AHbranding/Slide_16_9_-_13_dar2ju.png'
        // Add other image URLs as needed
      ];

      // Store the images in localStorage
      localStorage.setItem('week2Images', JSON.stringify(slideImages));
    }
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const [peerid, setPeerId] = useState(0);
  const [peerFeedback, setpeerFeedback] = useState("");
  const [disablebtn,setDisablebtn] = useState(false)

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    result: "",
  });
  const [assignedReview, setAssigned] = useState({
    result: "",
  });
  const [emotional_regulation_practice, setemotional_regulation_practice] = useState({
    emotion_situation: "",
    emotion_resp: "",
    emotion_handle: "",
  });

  const [assigned_emotional_regulation_practice, assigned_setemotional_regulation_practice] = useState({
    emotion_situation: "",
    emotion_resp: "",
    emotion_handle: "",
  });
  const [checkBox,setCheckBox] = useState(false)

  const [emotional_regulation_practice_feedback, setemotional_regulation_practice_feedback] = useState("");
  const [swotFeedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);

  const areEmotionSituationValid = countWords(emotional_regulation_practice?.emotion_situation) >= 50;
  const areEmotionalRespValid = countWords(emotional_regulation_practice?.emotion_resp) >= 50;
  const areEmotionalHandleValid = countWords(emotional_regulation_practice?.emotion_handle) >= 50;

  const isAnySectionInvalid =
      !areEmotionSituationValid ||
      !areEmotionalRespValid ||
      !areEmotionalHandleValid

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        setDisablebtn(true)
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });

        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          // console.log('Week2 EM: ',response)

          // assigned_setemotional_regulation_practice({
          //   emotion_situation: data.emotion_situation,
          //   emotion_resp: data.emotion_resp,
          //   emotion_handle: data.emotion_handle,
          // });
          // assigned_setemotional_regulation_practice(response.data.emotional_regulation_practice_feedback);
        }
      });
  };

  const fetchAssignedReview2 = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });

        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          console.log('Week2 EM: ',response)

          setFeedback(response.data.feedback);

          assigned_setemotional_regulation_practice({
            emotion_situation: data.emotion_situation,
            emotion_resp: data.emotion_resp,
            emotion_handle: data.emotion_handle,
          });
          // assigned_setemotional_regulation_practice(response.data.emotional_regulation_practice_feedback);

          
        }
      });
  };

  function countWords(text) {
    const trimmedText = text?.trim();
    const words = trimmedText?.split(/\s+/);
    return words?.length;
  }

  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          
          setemotional_regulation_practice({
            emotion_situation: data.emotion_situation,
            emotion_resp: data.emotion_resp,
            emotion_handle: data.emotion_handle,
          });
        }
      });
  };


  console.log("Week2 FB: "+peerFeedback)

  const fetchStepData2 = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });
        if (response.status === 200) {
          console.log("Data for peer2: ",response.data.content)
          let data = JSON.parse(response.data.content);
          

          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);
          setemotional_regulation_practice({
            emotion_situation: data.emotion_situation,
            emotion_resp: data.emotion_resp,
            emotion_handle: data.emotion_handle,
          });
        

        }
      });
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,config);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchStepData2(profileID, week_id, week_name, 5, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
      fetchAssignedReview2(profileID, week_id, week_name,5);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_3",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log("Cont",assigned_emotional_regulation_practice);
  
  console.log("FB"+emotional_regulation_practice_feedback);

  // const handleStepSubmission = async () => {
  //   console.log("this step", currentStep);
  
  //   try {
  //     if (status === "Active") {
  //       if (currentStep === 6) {
  //         await axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
  //           user_id: user.user_id,
  //           week_name: week_name,
  //           week_id: week_id,
  //           section: 6,
  //           content: emotional_regulation_practice,
  //           pathway: 1,
  //           feedback: emotional_regulation_practice_feedback,
  //         })
  //         .then(() => {
  //           toast.success('Good Job! Feedback Submitted', {
  //             icon: '👏',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         })
  //         .catch((error) => {
  //           console.error('Error submitting feedback:', error);
  
  //           toast.error('Oops! Something went wrong. Please try again later.', {
  //             icon: '❌',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         });
  //       } else if (currentStep === 5) {
  //         await axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
  //           user_id: user.user_id,
  //           week_name: week_name,
  //           week_id: week_id,
  //           section: currentStep,
  //           content: emotional_regulation_practice,
  //           pathway: 1,
  //           status: "Reviewing",
  //         })
  //         .then(() => {
  //           toast.success('Good Job! Activity Submitted', {
  //             icon: '👏',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         })
  //         .catch((error) => {
  //           console.error('Error submitting activity:', error);
  
  //           toast.error('Oops! Something went wrong. Please try again later.', {
  //             icon: '❌',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         });
  //       } else if (currentStep === 4) {
  //         await axios.post(${process.env.REACT_APP_API_KEY}/api/peer_submission/, {
  //           user_id: user.user_id,
  //           week_name: week_name,
  //           week_id: week_id,
  //           section: currentStep,
  //           content: swotSubmission,
  //           pathway: 1,
  //           status: "Reviewing",
  //         })
  //         .then(() => {
  //           toast.success('Good Job! Submission Submitted', {
  //             icon: '👏',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         })
  //         .catch((error) => {
  //           console.error('Error submitting submission:', error);
  
  //           toast.error('Oops! Something went wrong. Please try again later.', {
  //             icon: '❌',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error submitting data:', error);
  
  //     toast.error('Oops! Something went wrong. Please try again later.', {
  //       icon: '❌',
  //       style: {
  //         borderRadius: '55px',
  //         marginBottom: '15px',
  //       },
  //     });
  //   }
  // };
  const [subFeedback, setSubFeedback] = useState("");

  const handleSubFeedback = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`, {
        peerid: peerid,
        subFeedback: subFeedback,
        flag: true,
      },config)
      .then((res) => {
        // toast.success("Good Job! Feedback Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
      });
  };

  const [submit, setsubmit] = useState(false);

  const handleStepSubmission = () => {
    setsubmit(true);
    console.log("this steo", currentStep);
    if (status === "Active") {
      axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
        user_id: user.user_id,
        week_name: week_name,
        week_id: week_id,
        section: currentStep,
        content: emotional_regulation_practice,
        pathway: 1,
        status: "Reviewing",
        rating: 0,
        pr_enabled: true,
        activity_name: "Emotional Regulation Practice",
      },config)
          .then(response => {
            toast.success('Good Job! Activity Submitted', {
              icon: '👏',
              style: {
                borderRadius: '55px',
                marginBottom: '15px',
              },
            });
          })
          .catch(error => {
            console.error("Error submitting activity:", error);
            // Handle the error, you can show an error message using toast or any other method.
          });
    }

  };
  const [isShown, setIsShown] = useState(false);
  const handleRating = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_rating/`, {
        peerid: peerid,
        rating: rating,
      },config)
      .then((res) => {
        // toast.success("Good Job! Rating Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
      });
  };
  const handleClick = event => {
    setIsShown(current => !current);
  };
  const totalSteps = 5; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const accordionData = [
    { title: 'Utilize', content: 'How does Bilal utilize Emotional Intelligence in his role as a student leader?' },
    { title: 'Lesson', content: "What can we learn from Bilal's journey that can enhance our leadership experience?"},
  ];


  const accordionData3 = [
    {
      title: 'Emotional Intelligence',
      content: "This week, we deepened our understanding of emotional intelligence and its fundamental role in effective leadership. We explored the power of emotional self-awareness, self-regulation, empathy, and emotional agility through Bilal's continued leadership journey."
    },
    {
      title: 'Aspects',
      content: 'We explored the dimensions of emotional intelligence, including understanding our emotions, managing emotions effectively, recognizing others\' emotions, and managing relationships. These aspects are critical for building emotional intelligence.'
    },
    // {
    //   title: 'Quiz',
    //   content: 'You took an Emotional Intelligence Quiz to assess your emotional awareness, understanding, self-regulation, and relationship management. This activity helped you identify your strengths and areas of growth in emotional intelligence.'
    // },
    {
      title: 'Regulation Practice',
      content: 'You reflected on an emotional experience from your day, documenting the situation, your emotional response, and how you handled it. This practice was designed to enhance your emotional self-awareness and regulation skills.'
    },
    // {
    //   title: 'Peer Review',
    //   content: 'You provided valuable feedback to your peers on their emotional intelligence quiz responses and emotional regulation practices. Your thoughtful insights and constructive feedback have contributed to a supportive learning environment and have supported their journey in emotional intelligence development.'
    // },
  ];


  const resources = [
    {
      title: 'Resource 1',
      content: 'Explore "Emotional Intelligence 2.0" by Travis Bradberry to delve deeper into the principles and practices of emotional intelligence.'
    },
    {
      title: 'Resource 2',
      content: 'Read Chapter 6 – "The Emotional Machine" in "Thinking, Fast and Slow" by Daniel Kahneman for insights into the interplay between emotions and decision-making.'
    },
    {
      title: 'Resource 3',
      content: 'Discover "The Power of Self-Awareness" by Dr. Tasha Eurich, a resource that provides valuable perspectives on enhancing self-awareness for effective leadership.'
    },
    {
      title: 'Resource 4',
      content: 'Explore "Why Aren’t We More Compassionate?" by Daniel Goleman to gain insights into the factors influencing compassion and empathy in leadership.'
    },

  ];
  
  

  
  const accordionData2 = [
    {
      title: 'Self-awareness',
      sub: 'Illuminating Your Emotional Landscape',
      content: 'Self-awareness, the foundation of EI, helps us recognize and understand our own emotions. It allows us to perceive how our emotions influence our actions, decisions, and interactions with others.\n\n' +
        '\nPoint of Action:\n\n• Reflect on your emotions and how they influence your actions\n' +
        '• Identify the triggers that elicit strong emotional responses in you.\n' +
        '• Examine the relationship between your emotions and your values.',
    },
    {
      title: 'Self-regulation',
      sub: 'Mastering the Art of Emotional Balance',
      content: 'Self-regulation is about managing and controlling our emotions effectively, which is crucial for maintaining emotional balance and resilience.\n\n' +
        '\nPoint of Action:\n\n• Develop strategies that help you manage your emotions and maintain equilibrium, especially in stressful situations\n' +
        '• Reflect on instances where self-regulation has made a difference in your leadership journey.',
    },
    {
      title: 'Motivation',
      sub: 'Fuelling Your Leadership Journey',
      content: 'Motivation, a key aspect of EI, is about harnessing our emotions to drive us towards our goals, enhancing our leadership effectiveness.\n\n' +
        '\nPoint of Action:\n\n• Identify your core motivations and how they align with your leadership goals.\n' +
        '• Reflect on how your emotions can fuel your motivation and influence your actions.',
    },
    {
      title: 'Empathy',
      sub: 'Building Bridges of Understanding',
      content: 'Empathy allows us to understand and share the feelings of others. It\'s a powerful tool for building strong and authentic relationships.\n\n' +
        '\nPoint of Action:\n\n• Practice active listening to understand others\' perspectives and emotions.\n' +
        '• Reflect on how empathy can enhance your leadership style and relationships within your team.',
    },
    {
      title: 'Social Skills',
      sub: 'Crafting Connections with Emotional Intelligence',
      content: 'Social skills involve effectively managing relationships and inspiring others. They enable us to connect with our team members and the wider community.\n\n' +
        '\nPoint of Action:\n\n• Consider how you use EI in your interactions and communications with your team.\n' +
        '• Reflect on situations where EI has helped you navigate conflicts or challenges.',
    },
  ];

  const [nextBtnCondtion, setNextBtnCondtion] = useState(false);

  const [RecheckBox,setRecheckBox] = useState(false)
  const [RecheckBox2,setRecheckBox2] = useState(false)


  useEffect(() => {
    if (currentStep === 0 || currentStep === 1 || currentStep === 3  ) {
      setNextBtnCondtion(true); // Enable button at step 0
    } else if (currentStep === 2 && currentImageIndex < 4) {
      setNextBtnCondtion(false);
    } else if (currentStep === 2 && currentImageIndex > 4) {
      setNextBtnCondtion(true);
    } 

    else if (currentStep === 4 && status==='Active') {
      if(submit){
        setNextBtnCondtion(true);
      } 
      else{
        setNextBtnCondtion(false);
      }
    } 


    else if (currentStep === 4 && status==='Completed') {
      setNextBtnCondtion(true);
    } 

    // else if (status==='Completed' && Object.values(swotSubmission).every(value => value !== '')){
    //   setNextBtnCondtion(true);
    // }
    
    // else if (currentStep === 5 && (disableBtnTwo || (status==='Completed' && Object.values(swotSubmission2).some(value => value !== '')))) {
    //   setNextBtnCondtion(true);
    // } 
    
    
    else {
      setNextBtnCondtion(false);
    }
  }, [currentStep, currentImageIndex, status, submit]);
  
  
  
  

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to{" "}
        <b>Week 2: Emotional Intelligence</b>
      </h2>
      <p className="mt-4 text-gray-600">
        In Week 2, we delve into the heart of Emotional Intelligence (EI), an
        indispensable part of effective leadership. Learn about the five
        components of EI: Self-awareness, Self-regulation, Motivation, Empathy,
        and Social Skills. Understand how these aspects affect your
        interactions, decision-making, and leadership styles. Participate in
        interactive activities, introspective reflections, and enriching
        discussions with your peers. This week is dedicated to nurturing your
        emotional intelligence, enabling you to lead with empathy, resilience,
        and authenticity.
      </p>

      <div className="w-full flex justify-start items-center pt-6">
             {/* <span className="text-lg">- Team Ascent</span> */}
             </div>
      {/* <div className="mt-6 flex justify-center w-full h-full">
        <img
          className="mx-auto w-full hover:shadow-2xl hover:shadow-indigo-500/50"
          src="https://res.cloudinary.com/ahbranding/image/upload/v1702304815/AHbranding/Group_34784420_c14xig.png"
        />
      </div> */}


      <Quote content={"Emotional intelligence is not the opposite of intelligence, it's not the triumph of heart over head - it's the unique intersection of both."} person={"David Caruso"}/>


    </section>,
    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1700068840/AHbranding/Group_34784417_wvbhc4.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>This Week’s Objectives</b>
            </h2>
            <p className="mt-4 text-gray-600">
              As emerging leaders keen to make a difference in our society, it
              is essential to harness Emotional Intelligence (EI) as a key
              factor in effective leadership. In the context of this week's
              theme, Understanding and Harnessing Emotional Intelligence, we
              dive into the captivating narrative of Bilal, a student from
              Lahore University of Management Sciences (LUMS), who has recently
              taken up the mantle of the General Secretary for the LUMS
              Dramatics Society (Dramaline). <br/> <br/>Bilal's journey uncovers the
              importance and application of EI in leading a diverse group of
              individuals with varying interests and skills. His story offers
              profound insights into the dynamic world of EI in student
              leadership and its significance in fostering strong connections,
              empathy, and inspiring others. <br/> <br/>Over this week, we encourage you to ponder:
            </p>




            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                <Collapse accordionData={accordionData}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
          <strong>Note: </strong>Through Bilal's narrative, we aim to unveil the power of Emotional
          Intelligence in cultivating strong leadership. By comprehending and
          effectively managing our emotions and those of others, we can steer
          through challenges, build authentic relationships, and motivate our
          teams to reach their potential.
        </p>
      </div>
    </section>,
    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek2 className="w-full" onImageIndexChange={handleImageIndexChange}  />
      </div>
    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Embarking on the Journey of Emotional Intelligence</b>
            </h2>
            <p className="mt-4 text-gray-600">
              Exploring Emotional Intelligence (EI) is essential for personal
              and leadership development. Like Bilal, we can deepen our
              understanding of emotions, enhancing our ability to manage them
              effectively. EI is crucial for perceiving, understanding, and
              handling emotions—both ours and others'. Cultivating EI leads to
              empathetic and resilient leadership, as seen in Bilal's journey as
              the General Secretary for Dramaline. As student leaders, enhancing
              our EI unlocks our potential for positive impact within and beyond
              the university, providing a fundamental pillar for personal growth
              and effective leadership.<br/> <br/> Explore the following facets of
              Emotional Intelligence:
            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                  <Collapse accordionData={accordionData2}  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Emotional Intelligence Quiz</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">
    //     This activity aims to help you gain a deeper understanding of your
    //     Emotional Intelligence (EI) and its five components: self-awareness,
    //     self-regulation, motivation, empathy and social skills. By taking this
    //     quiz, you will identify areas of strength and opportunities for growth,
    //     enabling you to enhance your EI and your effectiveness as a leader.
    //   </p>

    //   <ul
    //     className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    //         />
    //       </svg>
    //       Instructions{" "}
    //     </p>
    //     <li className="m-6">
    //       Reflect on your experiences and behaviours: Take some time to think
    //       about your past experiences, both in leadership roles and in other
    //       aspects of your life. Consider how you handle your emotions and those
    //       of others.
    //     </li>
    //     <li className="m-6">
    //       Rate your skills: For each statement, rate yourself on a scale of 1
    //       (never) to 5 (always). Be honest and base your responses on what is
    //       generally true for you.
    //     </li>
    //     <li className="m-6">
    //       Identify areas of strength and growth: Once you've rated yourself,
    //       identify the areas where you excel and those that may need
    //       improvement. Consider strategies to enhance your EI in areas needing
    //       growth.
    //     </li>

    //     <li className="m-6">
    //       Before submitting your quiz, please acknowledge that by doing so, you
    //       agree to share your responses with a peer for the purpose of receiving
    //       constructive feedback. This feedback is intended to support your
    //       growth as a leader and enhance your understanding of your Emotional
    //       Intelligence.
    //     </li>
    //   </ul>

    //   <ul className="mt-4 bg-blue-50 p-2 rounded-xl border-2 border-blue-100 text-sm">
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-blue-400 text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    //         />
    //       </svg>
    //       Quiz Link{" "}
    //     </p>

    //     <li className="m-6">
    //       <Link
    //         to="https://www.mindtools.com/axbwm3m/how-emotionally-intelligent-are-you"
    //         target="_blank"
    //         className="btn btn-primary"
    //       >
    //         {" "}
    //         Go to Quiz{" "}
    //       </Link>
    //     </li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Quiz Result</span>
    //       </label>
    //       <textarea
    //         value={swotSubmission.result}
    //         onChange={(e) =>
    //           setSubmission((prev) => ({ ...prev, result: e.target.value }))
    //         }
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button
    //     className="btn btn-primary mt-8 bg-neutral-50"
    //     onClick={handleStepSubmission}
    //   >
    //     Submit
    //   </button>
    // </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Emotional Regulation Practice</b>
      </h2>
      <p className="mt-4 text-gray-600">
        This activity aims to help you understand and manage your emotions
        effectively. By documenting an emotional experience and evaluating how
        you handled it, you can gain insights into your emotional patterns and
        develop better emotional regulation strategies.
      </p>

      <ul
  className="mt-4 bg-amber-50 px-3 pt-4 pb-8 rounded-xl border-2 border-amber-100 text-sm "
  style={{ listStyle: "circle" }}
>
  <p className="mx-6 mt-4 font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    Instructions{" "}
  </p>
  <h5 className='m-2 text-lg'>Situation</h5>
  <li className="mx-6" >
    <b>Identify and Document the Event:</b> Recall a recent event where you experienced significant emotions. Describe the situation in detail, including the context, the people involved, and the events that unfolded.
  </li>

  <h5 className='m-2 text-lg'>Emotional Response</h5>
  <li className="mx-6" ><b>Reflect on Your Emotions:</b> Describe the emotions you felt during this situation. Were they intense, mixed, or surprising? Note how your emotions changed throughout the event.
  </li>
  <li className="mx-6" ><b>Analyse Emotional Triggers:</b> Identify what aspects of the situation triggered these emotions. Consider both the obvious and subtle triggers.
  </li>

  <h5 className='m-2 text-lg'>Handling Method</h5>
  <li className="mx-6" ><b>Assess Your Handling of the Emotions:</b> Evaluate how you managed or expressed your emotions during the situation. Did you respond immediately, take time to process, or suppress your emotions?
  </li>
  <li className="mx-6" ><b>Strategize Better Handling Methods:</b> Based on your assessment, propose strategies for managing similar emotions in the future more effectively. This could include mindfulness techniques, seeking support, or other emotional regulation methods.
  </li>
</ul>


{status === "Completed" || submit ? (
              <div className="mt-4">
                <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">
                <ul>
                    <li><b>Situation:</b><br/> {emotional_regulation_practice?.emotion_situation}<br/><br/></li>
                    <li><b>Emotional Response:</b><br/> {emotional_regulation_practice?.emotion_resp}<br/><br/></li>
                    <li><b>Handling Method:</b><br/> {emotional_regulation_practice?.emotion_handle}<br/><br/></li>
                  </ul>
                </p>
              </div>
            ) : (
<div>
<div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text font-bold">Situation</span>
          </label>
          <textarea
            value={emotional_regulation_practice.emotion_situation }
            onChange={(e) =>
              setemotional_regulation_practice((prev) => ({
                ...prev,
                emotion_situation: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(emotional_regulation_practice?.emotion_situation)} / 50 words</small>

        </div>
      </div>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text font-bold">Emotional Response</span>
          </label>
          <textarea
            value={emotional_regulation_practice.emotion_resp }
            onChange={(e) =>
              setemotional_regulation_practice((prev) => ({
                ...prev,
                emotion_resp: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(
              emotional_regulation_practice?.emotion_resp)} / 50 words</small>

        </div>
      </div>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text font-bold">Handling Method</span>
          </label>
          <textarea
            value={emotional_regulation_practice.emotion_handle }
            onChange={(e) =>
              setemotional_regulation_practice((prev) => ({
                ...prev,
                emotion_handle: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(emotional_regulation_practice?.emotion_handle)} / 50 words</small>

        </div>


      </div>



      <div className="flex items-center gap-2">
        <input
            type="checkbox"
            checked={checkBox}
            className="checkbox checkbox-warning"
            onChange={() => {
              setCheckBox(!checkBox); // Toggle the checkbox state
            }}
        />
        <span className="text-black-400 ">Do you agree to share your response with a peer for the purpose of receiving constructive feedback</span>
      </div>


      <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={RecheckBox} 
  onChange={() => {
    setRecheckBox(!RecheckBox); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Read your submission carefully before submitting</span>
      </div>

      <button
        className="btn btn-primary mt-8 bg-neutral-50"
        onClick={handleStepSubmission}
        disabled={status === "Completed" || !RecheckBox || isAnySectionInvalid || !checkBox || disablebtn }
      >
        Submit
      </button>
</div>
              
            )}
    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
<Confetti numberOfPieces={100} />

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8  lg:gap-16">

          <div className="">
            <div>
              <h2 className="text-xl font-medium sm:text-xl">
                <b> A quick recap </b>
              </h2>
              <p className="mt-4 text-gray-600">
                Congratulations on completing Week 2: Understanding and
                Harnessing Emotional Intelligence! You have made meaningful
                strides on your leadership growth journey. Here's a quick recap
                of this week's key.{" "}
              </p>

              <div className="mt-6">
                <div className="container flex flex-col justify-center mx-auto px-0 ">
                  <div className="grid grid-cols-1 gap-4">
                  <Collapse accordionData={accordionData3}  />
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="divider"></div>
            <div>

              <p className="mt-4 text-gray-600">
                Fantastic work on completing Week 2! Brace yourself for Week 3, where we will explore the intricacies of communication in leadership. Keep up the excellent work and continue your journey towards becoming an extraordinary leader.
              </p>



              {/*<div className="mt-6">*/}
              {/*  <div className="container flex flex-col justify-center mx-auto px-0 ">*/}
              {/*    <div className="grid grid-cols-1 gap-4">*/}
              {/*    <Collapse accordionData={resources}  />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <ul className="flex flex-col" style={{listStyle:"disc"}}>
                <li className="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
                  Group Dynamics and Team Interventions by Timothy M. Franz, Chapter 3 – The Johari Window: A Model for Self-Awareness, Personal Development, Group Development and Understanding Relationship
                </li>
                <li className="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
                  Emotional Intelligence 2.0 by Travis Bradberry, Chapter 6 Understanding Others’ Emotions
                </li>
                <li className="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
                  Personal Development and Self-Reflection by Dr. Tasha Eurich, Chapter 1 – The Importance of Self-Knowledge
                </li>
                <li className="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
                  Thinking, Fast and Slow by Daniel Kahneman, Chapter 6 – The Emotional Machine
                </li>
                <li className="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
                  <a  target="_blank" href="https://www.ted.com/talks/tasha_eurich_increase_your_self_awareness_with_one_simple_fix" className="link link-primary">The power of self-awareness by Dr. Tasha Eurich</a>
                </li>
                <li className="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
                  <a  target="_blank" href="https://www.ted.com/talks/daniel_goleman_why_aren_t_we_more_compassionate" className="link link-primary">Why Aren’t We More Compassionate? by Daniel Goleman</a>
                </li>
              </ul>

  
            </div>
            <div className="w-full flex justify-end items-center pt-6">
             <span className="text-lg">- Team Ascent</span>
             </div>
          </div>
        </div>
      </div>
    </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "Bilal's journey";

      case 3:
        return "Embarking on the Journey ";

      case 4:
        return "Emotional Regulation Practice";

      case 5:
        return "Recap";

      case 6:
        return "Peer Review | Emotional Handling";

      case 7:
        return "Review your feedback";

      case 8:
          return "Resources";

      default:
        return "Page";
    }
  };



  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl ">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto ">
                <div className="w-full bg-white rounded-full ">
                  {steps[currentStep]}    
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex flex-row gap-2">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                        //       disabled={
                        //           currentStep === 2 && (currentImageIndex<5) ||
                        //           isAnySectionInvalid &&  currentStep === 4
                        
                        // }
                        disabled={!nextBtnCondtion}

                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Week2;
