import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import DueDate from "../components/DueDate";
import { Link } from "react-router-dom";

function PeerReviewCount() {
  
  const [peerData, setPeerData] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_KEY + "/api/get_peer_sub/")
      .then((res) => {
        const data = res.data;
        setPeerData(data);
        setloading(false)
        console.log("Peer review submission successful", data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);



  const [sortOrder, setSortOrder] = useState(null);


  const handleSort = (field) => {
    const sortedData = [...peerData].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setPeerData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };


    // Calculate the count of week_id entries
    const weekIdCount = peerData.reduce((acc, item) => {
      const weekId = item.week_id;
      acc[weekId] = (acc[weekId] || 0) + 1;
      return acc;
    }, {});


  return (
    <>
    {(() => {
        if (loading) {
          return (
            <div>Loading....</div>
          )
        }else {
          return (
            <ul>
        {Object.keys(weekIdCount).map((weekId) => (
<>
          {/* <li key={weekId}><strong>Week {weekId-1}: </strong> {weekIdCount[weekId]}</li> */}
          {(() => {
        if (weekId-1 === 1) {
          return (
            <li key={weekId}><strong>Week {weekId-1}: </strong> {(weekIdCount[weekId])/2}</li>
          )
        } else {
          return (
            <li key={weekId}><strong>Week {weekId-1}: </strong> {(weekIdCount[weekId])}</li>
          )
        }
      })()}
      </>
        ))}
      </ul>
          )
        }
      })()}
    
    </>
  );
}

export default PeerReviewCount;