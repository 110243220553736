// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek7 = ({ onImageIndexChange }) => {
  const slideImages = [
    'https://res.cloudinary.com/ahbranding/image/upload/v1702901435/AHbranding/Slide_16_9_-_39_f5xlzs.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702901434/AHbranding/Slide_16_9_-_41_eyznhh.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1716733568/AHbranding/Slide_16_9_-_40_1_jpzjc7.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1716733566/AHbranding/Slide_16_9_-_42_p5we0v.png',
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek7;
