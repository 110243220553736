import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import Confetti from 'react-confetti';
import SliderWeek8_2 from "./SliderWeek8_2";

const Week8 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 9;
  const week_name = "week_8";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_9",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        },config);
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: true,
          activity_name: "BrightTech Electronics' Struggle with Market Competition",
        },config);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };

  const totalSteps = 9; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };


  const questionsData = [
    {
      question: "Data always speaks for itself and requires no interpretation.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      
    },
    {
      question: "Using a variety of data sources can help ensure a well-rounded and accurate analysis.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
      
    },
    {
      question: "In data analysis, complex models always provide better insights than simpler ones.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      
    },
    {
      question: "Data analysis is only useful for making financial or quantitative decisions.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      
    },
    {
      question: "Effective data analysis requires considering both the quantitative and qualitative aspects of data.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
      
    },
  ];
  



  const accordionData = [
    {
      title: 'Demonstration',
      content: 'Ayesha demonstrates self-awareness in her leadership role by consistently reflecting on her actions, decisions, and their impact on others. She is open to feedback and actively seeks to understand how her emotions influence her leadership style and decision-making process.'
    },
    {
      title: 'Lesson',
      content: 'A key lesson we can draw from Ayesha\'s journey is the importance of self-awareness in leadership. Like Ayesha, we should regularly reflect on our experiences, seek feedback, and understand how our emotions shape our leadership approach. This self-awareness allows us to make informed decisions, adapt to challenges, and foster a positive and collaborative leadership environment.'
    },
  ];


  const accordionData2 = [
    {
      title: 'Focus on the Principles',
      content: 'Consider the broader impact of your actions by framing your decisions around ethical principles rather than short-term gains. Research indicates that leaders who prioritize ethical standards over immediate results cultivate a more loyal, trusting, and high-performing team.'
    },
    {
      title: 'Distinguishing Ethics from Morals',
      content: 'Ground your leadership approach in a clear understanding of both ethics and morals. While morals often stem from individual beliefs and cultural backgrounds, ethics typically refer to the accepted standards of behavior in professional and public domains. Appreciating this distinction can enhance your ability to navigate complex situations with clarity and confidence, creating an environment where ethical considerations guide the way to sustainable success.'
    },
  ];
  

  const accordionData3 = [
    {
      title: 'Vision for the Future',
      content: 'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.'
    },
    {
      title: 'Ethical Foundations',
      content: 'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.'
    },
    {
      title: 'Environmental Stewardship',
      content: 'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.'
    },
    {
      title: 'Community Enrichment',
      content: 'Sustainable leadership involves a commitment to the well-being of the community. Leaders invest in social initiatives, support educational programs, and engage in partnerships that address social issues. Their legacy is marked by the positive impact on the community’s health, wealth, and social fabric.'
    },
  ];


  const accordionData82 = [
    {
      title: 'Habits to Challenge - Vision for the Future',
      content: '- Narrow Analysis: Avoid limiting your view to the most obvious aspects of the problem.\n' +
        '- Overlooking Diversity: Make a conscious effort to include diverse perspectives in your analysis.\n' +
        '- Conventional Solutions: Encourage creativity and out-of-the-box thinking in your approach.\n' +
        '- Immediate Results Focus: Balance the need for quick results with the importance of sustainable solutions.'
    },
    {
      title: 'Practical Solution Implementation (Are your solutions viable and effective within your context?)',
      content: '- Impractical Solutions: Assess the feasibility of your proposed solutions.\n' +
        '- Avoiding Responsibility: Promote accountability in the implementation of solutions.\n' +
        '- Preference for Familiarity: Challenge the norm and encourage new methods.\n' +
        '- Isolated Problem-Solving: Understand the interconnectedness of various issues and solutions.'
    },
    {
      title: 'Reflective Problem-Solving Practices (Are you learning from each problem-solving experience?)',
      content: '- Justifying Decisions Post-Facto: Be open to re-evaluating decisions based on their outcomes.\n' +
        '- Blaming External Factors: Focus on the role of your decisions and actions in the outcomes.\n' +
        '- Ignoring Past Experiences: Use previous problem-solving instances as learning opportunities.\n' +
        '- Avoiding Difficult Discussions: Foster open dialogues about the challenges and successes of your solutions.'
    },
    {
      title: 'Cultivating Effective Problem-Solving Habits',
      content: '- Regular Review and Reflection: Make it a habit to evaluate the impact and effectiveness of your solutions.\n' +
        '- Seeking Varied Inputs: Actively seek different perspectives before making decisions.\n' +
        '- Long-Term Oriented Solutions: Consider the broader, long-term impacts of your solutions.\n' +
        '- Transparent Decision-Making: Maintain clarity in your problem-solving process to build trust and encourage participation.'
    },
  ];
  
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];



  const accordionData7 = [
    {
      title: 'Additional Resources for Further Learning',
      content:
        '- Data-ism by Steve Lohr, Chapter 4 – The Rise of Big Data\n' +
        '- Qualitative Research from Start to Finish by Robert K. Yin, Chapter 4 – Data Analysis, Interpretation, and Presentation\n' +
        '- The Art of Thinking Clearly by Rolf Dobelli, Chapter 8 – The Confirmation Bias\n' +
        '- Case Study Research by Robert K. Yin, Chapter 5 – Analyzing Case Study Evidence\n\n',
    },
  ];
  
  

  
const cardData = [
    {
      id: 1,
      frontContent: 'Questioning Data Sources and Methods',
      backContent: (
        <div>
          <p>
            Critical thinking in data analysis starts with questioning the sources and methods.
          </p>
          <ul style={{ listStyle: 'disc' }}>
            <li>How critically have I evaluated the sources of my data?</li>
            <li>
              In what ways have I ensured the methods used for data collection and analysis are robust and unbiased?
            </li>
            <li>
              What steps can I take to further scrutinize and validate my data sources and methods?
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Assessing Assumptions and Biases in Data',
      backContent: (
        <div>
          <p>
            Assessing assumptions and biases is key to sound data analysis.
          </p>
          <ul style={{ listStyle: 'disc' }}>
            <li>How do I identify and challenge the assumptions underlying my data?</li>
            <li>
              Where might biases be influencing my data interpretation?
            </li>
            <li>
              How can I adopt practices to minimize these biases and base conclusions on objective analysis?
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Encouraging Diverse Perspectives in Data Interpretation',
      backContent: (
        <div>
          <p>
            Diverse perspectives enrich data interpretation, offering a more holistic view.
          </p>
          <ul style={{ listStyle: 'disc' }}>
            <li>Have I sought out and valued different viewpoints in interpreting data?</li>
            <li>
              In what ways can I facilitate a culture where multiple interpretations are considered and debated?
            </li>
            <li>
              How can I encourage team members to bring their unique insights into our data analysis processes?
            </li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  


  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Week 8: Analytical Skills</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 8 of the "Ascent" leadership pathway: Analytical Skills - The Backbone of Effective Decision-Making. In a rapidly evolving world, where information is abundant and complexities abound, analytical skills are not just advantageous but essential for emerging leaders. This week, we focus on empowering you with the tools to dissect information, think critically, solve problems, and make informed decisions. From understanding the nuances of data analysis to mastering the art of research and financial assessment, we will explore how these skills form the core of strategic leadership. Engage with us in this transformative journey as we equip you with the abilities to not only navigate but excel in the multifaceted landscape of leadership. Let's cultivate these skills to not only act with confidence but to inspire change.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
      {/* <Quote content={"Ethics is knowing the difference between what you have a right to do and what id right to do."} person={"Potter Stewart"}/> */}

      <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div>
      </div>
    </section>,
    // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //   

    //   <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //     <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
    //       <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    //         <img className="w-full" src="https://res.cloudinary.com/ahbranding/image/upload/v1692603965/AHbranding/Group_34784410_q9qk6r.png" />
    //       </div>

    //       <div className="">
    //         <h2 className="text-xl font-medium sm:text-xl">
    //           <b>This Week’s Objectives</b>
    //         </h2>
    //         <p className="mt-4 text-gray-600">
    //           As students on a transformative journey of leadership and
    //           self-awareness, it is essential to cultivate the qualities that
    //           will empower us to make a positive impact. In this week's theme of
    //           Introduction to Leadership and Self-Awareness, we delve into the
    //           inspiring story of Ayesha, a student leader who has been entrusted
    //           with the role of President of the Student Council. Ayesha's
    //           journey reflects the joys and challenges of leadership within the
    //           vibrant university community. By exploring Ayesha's experiences,
    //           we gain valuable insights into the transformative power of
    //           self-awareness and its role in effective leadership. During this
    //           week, let us reflect on the following:
    //         </p>

    //         <div className="mt-6">
    //           <div className="container flex flex-col justify-center mx-auto px-0 ">
    //             <div className="space-y-4">
    //                 <Collapse accordionData={accordionData}/>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
    //       {" "}
    //       Through Ayesha's story, we will uncover the significance of
    //       self-awareness as a foundation for leadership excellence. By
    //       understanding ourselves deeply, we can navigate complexities, build
    //       meaningful connections, and inspire others to reach their full
    //       potential.
    //     </p>
    //   </div>
    // </section>,
    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek8 className='w-full' onImageIndexChange={handleImageIndexChange} />

      </div>
    </section>,




<section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b></b>
      </h2>
      <p className="mt-4 text-gray-600">
      </p>



 <div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
      {currentQuestion < questionsData.length ? (
        <div>
          <h2 className="text-xl text-center font-semibold mb-8">{questionsData[currentQuestion].question}</h2>
          <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
            {questionsData[currentQuestion].options.map((option, index) => (
              <div
                key={index}
                className={`${
                  (showExplanation && option === questionsData[currentQuestion].correctAnswer) ? 'bg-green-500 text-white' :
                  (selectedOption === option ? 'bg-purple-200 ' : 'bg-white')
                } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer `}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
          {selectedOption && (
            <div className="flex justify-center">
              <button
                className="btn btn-outline-primary mt-4"
                onClick={handleNextQuestion}
              >
                Show Answer
              </button>
            </div>
          )}
          {showExplanation && (
            <div className="mt-4">
              <p className="font-semibold">Correct Answer:</p>
              <p className="text-green-500">{questionsData[currentQuestion].correctAnswer}</p>
              {/* <p className="font-semibold mt-2">Explanation:</p>
              <p>{questionsData[currentQuestion].explanation}</p> */}
              <div className="flex justify-center mt-4">
                <button
                  className="btn btn-outline-primary"
                  onClick={handleNextQuestionOrFinish}
                >
                  {/* {currentQuestion + 1 < questionsData.length ? 'Next Question' : 'Finish'} */}

                  {currentQuestion + 1 < questionsData.length ? 'Next Question' : ''}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
        </div>
      )}
    </div>


    </section>,



<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b> Fostering Critical Thinking in Data Analysis
 </b>
        </h2>
        <p className="mt-4 text-gray-600">
        Having explored the frameworks and tools for effective data analysis, we now shift our focus to a crucial component that underpins it all - Critical Thinking. Let's examine how critical thinking elevates our understanding and use of data, ensuring our analyses are not just comprehensive but also deeply insightful."


        </p>
<div className="grid grid-cols-1 ">
{cardData.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}

</div>
</section>,


<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8  lg:gap-16">
    {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
      <img
        className="w-full"
        src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
      />
    </div> */}

    <div className="">
      <div>
        <h2 className="text-xl font-medium sm:text-xl">
          <b> Enhancing Critical Thinking in Problem-Solving </b>
        </h2>
        <p className="mt-4 text-gray-600">
        In any leadership role, especially within university settings like student-run societies, the ability to critically analyse problems and develop effective solutions is key. This involves questioning conventional approaches, considering diverse viewpoints, and looking beyond short-term fixes. Are you considering the broader implications of your solutions? Do they cater to the diverse needs of your members? Incorporating critical thinking into your problem-solving process leads to more innovative and impactful outcomes.
        </p>

        <div className="mt-6">
          <div className="container flex flex-col justify-center mx-auto px-0 ">
            <div className="">
              

            <Collapse accordionData={accordionData82}/>

            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div>




      


      </div>
    </div>
  </div>
</div>
</section>,





<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8  lg:gap-16">
    {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
      <img
        className="w-full"
        src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
      />
    </div> */}

    <div className="">
      <div>
        <h2 className="text-xl font-medium sm:text-xl">
          <b> Sharpening Your Research Skills: A Core Component of Analytical Leadership </b>
        </h2>
        <p className="mt-4 text-gray-600">
        Research skills are a fundamental aspect of analytical thinking and leadership. Just as regular check-ups help maintain your physical health, a periodic assessment of your research habits ensures you're gathering and using information effectively. This process is crucial for making well-informed decisions, understanding complex issues, and staying ahead in your academic and professional pursuits.

        </p>

        <div className="mt-6">
          <div className="container flex flex-col justify-center mx-auto px-0 ">
            <div className="">
              


            <Collapse accordionData={accordionData4}/>



            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div>




      


      </div>
    </div>
  </div>
</div>
</section>,









<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8  lg:gap-16">
    {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
      <img
        className="w-full"
        src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
      />
    </div> */}

    <div className="">
      <div>
        <h2 className="text-xl font-medium sm:text-xl">
          <b> Mastering Financial Analysis </b>
        </h2>
        <p className="mt-4 text-gray-600">
        As we shift from research skills, we now focus on a crucial component for student leaders and society members: Financial Analysis. Financial analysis is an indispensable skill for student leaders. It involves not just managing and tracking budgets but also understanding the broader financial implications of decisions made within the context of student-run initiatives. Whether it's allocating funds for an event, assessing the cost-effectiveness of a project, or planning for long-term financial sustainability, these skills are key to leading responsibly and effectively.
        </p>

        <div className="mt-6">
          <div className="container flex flex-col justify-center mx-auto px-0 ">
            <div className="">
              

            <Collapse accordionData={accordionData5}/>


            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div>




      


      </div>
    </div>
  </div>
</div>
</section>,


<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
<b>Examples of Data Analytics Tools</b>
<p>Now that you have developed your critical thinking, research, and financial analysis skills, let’s explore some examples of data analytics tools.. This section will list popular data analytics tools you can learn in your own time, their function, significance and usage frequency.</p>
<div className="mt-6 flex justify-start w-full">
  <SliderWeek8_2 className='w-full' onImageIndexChange={handleImageIndexChange} />

</div>
</section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Case Study: BrightTech Electronics' Struggle with Market Competition
</b>
      </h2>
      <p className="mt-4 text-gray-600">
      BrightTech Electronics, once a leader in the personal audio devices market, is confronting a significant downturn in sales. This case study explores the challenges faced by BrightTech over the past three years, particularly focusing on the impact of new market entrants and evolving consumer preferences.
      BrightTech Electronics has been a reputable name in personal audio devices for over a decade, known for its reliable, high-quality products. However, the company has maintained a consistent product line without significant updates or innovations in recent years.
      </p>

      <h5>The Challenge</h5>

      <p className="mt-4 text-gray-600">

      Over the last three years, BrightTech has observed a steady decline in sales and market share. This period coincides with the entry of a new competitor, NovaAudio, which has quickly captured a significant portion of the market.
<br/><br/>
<strong>Sales Data:</strong><br/><br/>
Year 1: $50 million <br/>
Year 2: $45 million <br/>
Year 3: $30 million <br/>
<br/><br/>
<strong>Market Analysis:</strong><br/>
- Year 2, Quarter 3: NovaAudio enters the market with a product line featuring advanced technology and innovative design, directly competing with BrightTech.
<br/><br/>- Year 3: Industry reports indicate that NovaAudio has secured a 20% market share within just a year of its launch, primarily at the expense of BrightTech’s customer base.
<br/><br/>
<strong>Customer Feedback and Industry Trends:</strong><br/>
Customer surveys and feedback have highlighted a growing demand for more innovative and feature-rich audio devices. BrightTech's existing customers have indicated a desire for upgrades and new features, which are seen in NovaAudio’s offerings.
<br/><br/>
<strong>The Task:</strong><br/>
As a business analyst or a member of BrightTech's strategic planning team, your task is to answer the following discussion questions:
<br/><br/>
-	How significant is the threat posed by NovaAudio to BrightTech’s market position?<br/>
-	What could BrightTech have done differently to mitigate the impact of NovaAudio’s entry?<br/>
-	What strategies should BrightTech adopt to regain market share and stay competitive?<br/>
-	Given the customer feedback, how critical is product innovation for BrightTech’s future?<br/><br/>

Develop a Strategic Response Plan:
Propose a detailed plan for BrightTech to address its current challenges, including potential product innovations, marketing strategies, and customer engagement approaches.

      </p>

      <ul
        className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          Instructions{" "}
        </p>

        <li className="m-6">
        Carefully review the provided information about BrightTech Electronics, including the sales data, market context, and customer feedback.
        </li>
        <li className="m-6">
        Your response should not exceed 10 lines.
        </li>
        <li className="m-6">
        Ensure your response is clear, concise, and directly addresses the case study’s challenges.
        </li>

      </ul>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Response</span>
          </label>
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                response: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} disabled={status === 'Completed'}>
        Submit
      </button>
    </section>,

<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}
<Confetti numberOfPieces={100} />
<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Week 8 Conclusion: Consolidating Learning </b>
      </h2>
      <p class="mt-4 text-gray-600">
  Congratulations on completing Week 8: Mastering Analytical Skills! This week has been a crucial chapter in enhancing your leadership capabilities, particularly in contexts where strategic thinking and informed decision-making are key.
  <br/><br/>
  Exploring Analytical Skills: We've journeyed through the realm of analytical skills, essential for dissecting complex scenarios and data. This knowledge is crucial in making well-informed decisions, be it in academic projects or while contributing to student-run societies.
  <br/><br/>
  Proficiency in Financial Analysis: Your newfound understanding of financial analysis is invaluable. It equips you with the skills to interpret financial data accurately, an essential aspect of managing budgets and resources effectively, especially in leadership roles within student societies.
  <br/><br/>
  Critical Thinking in Problem-Solving: You've embraced the art of critical thinking, learning to approach problems with a balanced and comprehensive perspective. This skill is vital for crafting effective solutions in various situations, including those encountered in student-run organizations.
  <br/><br/>
  Advancing Research Skills: Enhancing your research abilities has prepared you to undertake thorough, unbiased inquiries, forming a strong foundation for your academic and extracurricular endeavors.
  <br/><br/>
  Case Study Analysis Application: The case study analysis has allowed you to put your analytical skills into practice, demonstrating how these competencies are applicable in real-world situations, including those you might face in student-led initiatives.
  <br/><br/>
  Reflecting on Practical Implications: Finally, reflecting on how these skills apply to real-life scenarios, including those within student societies, has prepared you to tackle challenges with a sharp, analytical approach.
</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Data Analysis Framework";

      case 2:
        return "Knowledge Check";

      case 3:
        return "Critical Thinking";

      case 4:
        return "Problem Solving";

      case 5:
        return "Analytical Leadership";

        case 6:
        return "Financial Leadership";

        case 7:
        return "Analytical Tools";

        case 8:
          return "Case Study";

        case 9:
          return "Conclusion";

      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week8;
