import React, { useContext, useEffect, useRef, useState } from "react";
import Confetti from 'react-confetti';
import "./new-certi.css";
import AuthContext from "../context/AuthContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Certificate = () => {
  let { user } = useContext(AuthContext);
  let profileID = user["user_id"];
  const targetRef = useRef();
  const [showConfetti, setShowConfetti] = useState(true);
  const [isShown, setIsShown] = useState(true);
  const [profile, setProfile] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,config);
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  useEffect(() => {
    const formatDate = () => {
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
      };

      const today = new Date();
      const formattedDate = today.toLocaleDateString("en-US", options);

      setCurrentDate(formattedDate);
    };

    formatDate();
  }, []);

  const generatePDF = async () => {
    // setShowConfetti(false);
    const element = targetRef.current;
  
    try {
      // Use html2canvas to render the content onto the canvas
      const canvas = await html2canvas(element, { scale: 2 });
  
      // Create a PDF using jsPDF and the canvas content
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
        precision: 16
      });
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );
  
      // Save or download the PDF
      pdf.save("certificate.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  

  return (
    <>
    <div className="w-2/4 flex justify-center m-auto">
        {showConfetti && <Confetti numberOfPieces={100} />}
      <div ref={targetRef} className="firstLayer">
        <div className="secondLayer">
          <div className="thirdLayer" >
            <div className="flex flex-col items-center relative">
              <h1 className="text-6xl uppercase mb-0">Certificate</h1>
              <h2 className="text-3xl mt-1 pb-1 inline-block border-b-2 border-purple-600">
                of Completion
              </h2>
              <h3 className="text-2xl mb-0 mt-10">
              This Certificate Is Proudly Presented To
            </h3>
            <p className="text-2xl mt-1">
              {profile?.firstname} {profile?.lastname}
            </p>
            <h3 className="text-2xl mb-0">Has Completed</h3>
            <p className="text-2xl">Ascent Leadership Program</p>
            <h3 className="text-2xl mb-0 mt-5">On</h3>
            <p className="text-2xl">{currentDate}</p>

            <small>{profile?.special_id} </small>
            </div>
          </div>
         
        </div>
      </div>
     
    </div>

    {isShown && (
      <button
      onClick={generatePDF}
        type="button"
        className="print-hide bg-blue-500 hover:bg-blue-700 w-5/12 mx-auto text-white font-bold py-2 px-4 rounded-full absolute bottom-6 right-0 left-0"
      >
        Download Certificate
      </button>
    )}
    </>
    
  );
};

export default Certificate;