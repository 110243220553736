import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import AdminStats from "./AdminStats";
import ProgressTable from "./ProgressTable";
import EnrollTable from "./EnrollTable";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Select from "react-select";
import { axiosInstance } from "../Axios";

const EditPeer = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { id } = useParams();
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  const [OriginalStudentData, setOriginalStudentData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [select, setSelect] = useState("");
  const [changeData, setChangeData] = useState({
    feeedback: "",
    subFeedback: "",
    assigned_to: "",
    content: "",
  });
  useEffect(() => {
    axiosInstance
      .get("get_all_profiles/")
      .then((res) => {
        const data = res?.data;
        console.log("PROFILES: ", data);
        setProfiles(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSelect = (e) => {
    setSelect(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangeData({
      ...changeData,
      [name]: value,
    });
  };
  const [textareaContent, setTextareaContent] = useState("");

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_KEY +
          "/api/get_single_peer_submission/" +
          id +
          "/",config)
      .then((res) => {
        const data = res.data;
        console.log("DATA : ", data);
        setStudentData(data);
        let cleanData = data.content
          .replace(/^"|"$/g, "")
          .replace(/\\n/g, "\n");
        setTextareaContent(cleanData);
        setSelect(data.assigned_to);
        setChangeData({
          feedback: data.feedback,
          subFeedback: data.per_sub_feedback,
          assigned_to: data.assigned_to,
          content: cleanData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleSubmit = (id) => {
    const data_to_send = {
      feedback: changeData.feedback,
      subFeedback: changeData.subFeedback,
      content: changeData.content,
      assigned_to: select,
    };
    axios
      .put(
        process.env.REACT_APP_API_KEY + "/api/edit_peer/" + id + "/",
        data_to_send
      ,config)
      .then((res) => {
        console.log("Edit Successfully");
        toast.success("Peer Updated!", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((err) => {
        toast.error("Error in updating peer!", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  return (
    <div>
      <div>
        <>
          <div class="hidden md:block absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          {/* <Sidebar /> */}

          {/* {isSidebarVisible && <SideInnerSM />} */}

          {/* <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl"> */}
          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
            {/* Navbar */}
            <Navbar toggleSidebar={toggleSidebar} />

            {/* end Navbar */}
            {/* cards */}
            <div className="w-full px-6 py-6 mx-auto">
              <AdminStats />

              <div className="max-w-[85rem]    px-4 py-4 sm:px-6 lg:px-4 lg:py-4 lg:py-14 mx-auto">
                {/* Card */}
                <div className="flex flex-col">
                  <div className="-m-1.5 overflow-x-auto p-2 bg-white shadow-sm rounded-xl">
                    <div className="p-1.5 min-w-full inline-block align-middle ">
                      Student Name:{" "}
                      <span>
                        <input
                          type="text"
                          placeholder="Type here"
                          className="input input-bordered w-full max-w-xs"
                          value={studentData.user_name}
                          disabled
                        />
                      </span>
                    </div>

                    <div className="p-1.5 min-w-full inline-block align-middle ">
                      Peer Name:{" "}
                      <span>
                        <input
                          type="text"
                          placeholder="Type here"
                          className="input input-bordered w-full max-w-xs"
                          value={studentData.assigned_name}
                          disabled
                        />
                      </span>
                    </div>

                    <div className="p-1.5 min-w-full inline-block align-middle ">
                      Peer Feedback:{" "}
                      <span>
                        <textarea
                          className="input input-bordered w-90 h-32 p-2"
                          name="feedback"
                          value={changeData.feedback}
                          onChange={handleChange}
                        ></textarea>
                      </span>
                    </div>

                    <div className="p-1.5 min-w-full inline-block align-middle ">
                      Sub Feedback:{" "}
                      <span>
                        <textarea
                          className="input input-bordered w-90 h-32 p-2"
                          name="subFeedback"
                          value={changeData.subFeedback}
                          onChange={handleChange}
                        ></textarea>
                      </span>
                    </div>

                    <div className="p-1.5 min-w-full inline-block align-middle ">
                      Content:{" "}
                      <span>
                        <textarea
                          className="input input-bordered w-90 h-32 p-2"
                          name="content"
                          value={changeData.content}
                          onChange={handleChange}
                        ></textarea>
                      </span>
                    </div>

                    <div className="p-1.5 min-w-full inline-block align-middle ">
                      Assigned to:{" "}
                      <span>
                        {/* <select
                          className="select select-bordered w-full max-w-xs"
                          value={select}
                          onChange={handleSelect}
                        >
                          <option>{studentData.assigned_name}</option>
                          {profiles.map((data) => (
                            <option
                              key={data.user}
                              value={data.user}
                              className="text-black-600"
                              style={{ color: "black" }} // Apply the desired text color here
                            >
                              {data.name}
                            </option>
                          ))}
                        </select> */}

                        <Select
                          className="w-full max-w-xs"
                          value={{
                            value: select,
                            label: studentData.assigned_name,
                          }}
                          onChange={(selectedOption) =>
                            handleSelect(selectedOption.value)
                          }
                          options={profiles.map((data) => ({
                            value: data.user,
                            label: data.name + "" + data.email,
                          }))}
                          placeholder="Select a User"
                        />

                        {/* <option value="">Select a User</option>
                        {data.map((userData) => (
                          <option
                            key={userData.username}
                            value={userData.username}
                          >
                            {userData.username}
                          </option> */}
                      </span>
                    </div>
                  </div>
                  <br />
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmit(studentData.id)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
      <div style={{ zIndex: 999999999999999 }}>
        <Toaster position="bottom-center" />
      </div>
      {/* <FeedbackButton/> */}
    </div>
  );
};

export default EditPeer;
