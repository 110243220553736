import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import CardFlip from "./CardFlip";
import SliderWeek7 from "./SliderWeek7";
import Collapse from "../components/Collapse";
import Confetti from 'react-confetti';
const Week7 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 8;
  const week_name = "week_7";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [checkBox,setCheckBox] = useState(false);
  const [RecheckBox,setRecheckBox] = useState(false)
  const [disableBtn,setDisableBtn] = useState(false)
  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    response: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            response: data.response,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            response: data.response,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
          ,config);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 6, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 6);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_8",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" ) {
      axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
        user_id: user.user_id,
        week_name: week_name,
        week_id: week_id,
        section: currentStep,
        content: swotSubmission,
        pathway: 1,
        rating: 0,
        status: "Reviewing",
        pr_enabled: true,
        activity_name: "Argument Analysis in Student-Run Organizations",
      },config);
      setDisableBtn(true)
      toast.success('Good Job! Activity Submitted', {
        icon: '👏',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    
    }
  };

  const totalSteps = 7; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };





  const questionsData = [
    {
      title: "Ethical Dimensions of Critical Thinking ",
      question: "Considering multiple perspectives in decision-making is unnecessary for critical thinking in student societies.",
      options: [
        "Truth",
        "Myth",
      ],
      correctAnswer: "Myth",
      
    },

    {   title: "Ethical Dimensions of Critical Thinking ",
        question: "In critical thinking, ethical considerations are as important as logical reasoning in making decision",
        options: [
          "Truth",
          "Myth",
        ],
        correctAnswer: "Truth",
        
      },


      { title: "Ethical Dimensions of Critical Thinking ",
        question: "- It is acceptable to ignore ethical implications if the decision is logically sound and benefits the majority in a student society.",
        options: [
          "Truth",
          "Myth",
        ],
        correctAnswer: "Myth",
        
      },

      { title: "Ethical Dimensions of Critical Thinking ",
        question: "In critical thinking, questioning the ethical impact of a decision can lead to better and more inclusive outcomes.",
        options: [
          "Truth",
          "Myth",
        ],
        correctAnswer: "Truth",
        
      },


      {title: "Ethical Dimensions of Critical Thinking ",
        question: "Ethical dilemmas are irrelevant to the process of critical thinking in leadership roles within student societies.",
        options: [
          "Truth",
          "Myth",
        ],
        correctAnswer: "Myth",
        
      },


      {
        "title": "The Conference Challenge",
        "question": "How do you schedule each speaker on each day, adhering to their constraints?",
        "options": [
          "Friday: Speaker A (Economist), Saturday: Speaker B (Social Scientist), Sunday: Speaker C (Environmental Studies)",
          "Friday: Speaker B (Social Scientist), Saturday: Speaker A (Economist), Sunday: Speaker C (Environmental Studies)",
          "Friday: Speaker C (Environmental Studies), Saturday: Speaker A (Economist), Sunday: Speaker B (Social Scientist)",
          "Friday: Speaker C (Environmental Studies), Saturday: Speaker B (Social Scientist), Sunday: Speaker A (Economist)"
        ],
        "correctAnswer": "Friday: Speaker B (Social Scientist), Saturday: Speaker A (Economist), Sunday: Speaker C (Environmental Studies)",
      },

      {
        "title": "The Fundraiser Allocation",
        "question": "How do you allocate the funds among the four initiatives, respecting these conditions? (Let's assume the total funds raised are 120 units)",
        "options": [
            "Educational Campaign: 20 units, Community Service Project: 30 units, Cultural Event: 40 units, Operational Costs: 30 units",
            "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
            "Educational Campaign: 25 units, Community Service Project: 20 units, Cultural Event: 35 units, Operational Costs: 40 units",
            "Educational Campaign: 30 units, Community Service Project: 25 units, Cultural Event: 25 units, Operational Costs: 40 units"
        ],
        "correctAnswer": "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
      },


      {
        "title": "The Fundraiser Allocation",
        "question": "How do you allocate the funds among the four initiatives, respecting these conditions? (Let's assume the total funds raised are 120 units)",
        "options": [
            "Educational Campaign: 20 units, Community Service Project: 30 units, Cultural Event: 40 units, Operational Costs: 30 units",
            "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
            "Educational Campaign: 25 units, Community Service Project: 20 units, Cultural Event: 35 units, Operational Costs: 40 units",
            "Educational Campaign: 30 units, Community Service Project: 25 units, Cultural Event: 25 units, Operational Costs: 40 units"
        ],
        "correctAnswer": "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
  "explanation": "If the educational campaign gets twice as much as operational costs, and the cultural event gets a maximum of one-third of the total (which is 40 units), the only way to satisfy all conditions is to give the educational campaign 40 units (2 times the operational costs). The community service project gets half of the cultural event, which is 15 units. This leaves 20 units for the operational costs (half of the educational campaign). The total allocation adds up to 120 units, satisfying all conditions and constraints."
      },


      {"title": "The Age Puzzle",
        "question": "In a room sit three people: a father, a son, and a grandfather. The total number of years of their ages equals 140 years. The grandfather is twice as old as the father, and the father is twice as old as the son. How old is each person?",
        "options": [
          "The son is 25 years old, the father is 50, and the grandfather is 65.",
          "The son is 18 years old, the father is 36, and the grandfather is 72.",
          "The son is 20 years old, the father is 40, and the grandfather is 80.",
          "The son is 15 years old, the father is 30, and the grandfather is 60."
        ],
        "correctAnswer": "The son is 20 years old, the father is 40, and the grandfather is 80.",
      },

      {
        "title": "Survivor Puzzle",
        "question": "If a plane crashes on the border between Pakistan and India, where do they bury the survivors?",
        "options": [
          "On the Pakistani side.",
          "On the Indian side.",
          "Nowhere, because you don't bury survivors.",
          "In a neutral third country."
        ],
        "correctAnswer": "Nowhere, because you don't bury survivors.",
      },

      {"title": "The Scrambled Word Puzzle",
        "question": "What five-letter word becomes shorter when you add two letters to it?",
        "options": [
          "Length",
          "Short",
          "Width",
          "Bread"
        ],
        "correctAnswer": "Short",
      },


      {"title": "The Farmer's Dilemma",
        "question": "A farmer has 17 sheep, and all but 9 die. How many are left?",
        "options": [
          "0 sheep are left.",
          "8 sheep are left.",
          "9 sheep are left.",
          "17 sheep are left."
        ],
        "correctAnswer": "9 sheep are left.",
        "explanation": "The phrase 'all but 9 die' means every sheep except for 9 died, so 9 sheep remain."
      }
      
      
      
      
  ];



  const accordionData = [
    {
      title: 'Fostering Inclusive Debate',
      content:
        <div>
          <strong>Problem:</strong> Malaika notices varying opinions within LUMUN regarding debate topics and strategies.<br />
          <strong>Malaika’s Approach:</strong> She encourages open and critical discussions, allowing members to analyze and challenge each argument. This approach promotes intellectual diversity and strengthens the society's analytical capabilities.
        </div>
    },
    {
      title: 'Navigating Unforeseen Changes',
      content:
        <div>
          <strong>Problem:</strong> A sudden change in a major event's schedule puts Malaika and her team in a challenging position.<br />
          <strong>Malaika’s Approach:</strong> She leverages this as an opportunity to demonstrate adaptability and swift, critical decision-making. Together with her team, she re-evaluates their strategy and develops a revised plan, showcasing the power of critical thinking in crisis management.
        </div>
    },
    {
      title: 'Utilizing Team Potential',
      content:
        <div>
          <strong>Problem:</strong> The LUMUN team comprises debaters with varying strengths and skill sets.<br />
          <strong>Malaika’s Approach:</strong> Understanding and leveraging these diverse talents, Malaika assigns roles that maximize each member's potential, displaying strategic critical thinking in team management.
        </div>
    },
    {
      title: 'Balancing Rationality and Sensitivity',
      content:
        <div>
          <strong>Problem:</strong> High-stress debates can lead to emotionally intense situations.<br />
          <strong>Malaika’s Approach:</strong> Malaika learns to blend rational argumentation with emotional intelligence, ensuring debates remain both intellectually rigorous and empathetically conducted.
        </div>
    },
  ];
  


  const accordionData3 = [
  {
    title: 'Observation and Analysis',
    content:
      'Cultivating a Keen Eye for Detail and Logical Analysis\n\n',
  },
  {
    title: 'Interpretation and Evaluation',
    content:
      'Deciphering Meaning and Assessing Validity\n\n' +
      'Interpreting information accurately and evaluating its validity are critical in making sound decisions. Malaika\'s ability to interpret arguments in debates and evaluate their strength plays a key role in her leadership at LUMUN.\n\n' +
      'Think about how you interpret information and its implications. Are you drawing conclusions based on solid evidence?\n' +
      'Evaluate the validity and relevance of information you encounter. How does this evaluation impact your decision-making process?\n' +
      'Reflect on how your interpretation and evaluation skills contribute to your effectiveness as a leader and a team member.\n\n',
  },
  {
    title: 'Inference and Explanation',
    content:
      'Drawing Conclusions and Articulating Reasoning\n\n' +
      'Making inferences and explaining reasoning are vital aspects of critical thinking. Malaika\'s skill in drawing logical conclusions from debates and clearly explaining her reasoning is essential for leading her team.\n\n' +
      'Consider how you draw inferences from information and situations. Are your conclusions based on logical reasoning?\n' +
      'Reflect on your ability to explain your reasoning to others. How does this clarity of thought and expression aid your leadership?\n' +
      'Explore how developing stronger inference and explanation skills can enhance your leadership and problem-solving abilities.\n\n',
  },
  {
    title: 'Problem-Solving and Decision-Making',
    content:
      'Navigating Complex Challenges with Reason and Insight\n\n' +
      'Effective problem-solving and decision-making are the hallmarks of a critical thinker. Malaika\'s approach to tackling issues within LUMUN showcases her ability to apply critical thinking for practical solutions.\n\n' +
      'Analyse your approach to problem-solving. How do you use critical thinking to find innovative solutions?\n' +
      'Reflect on your decision-making process. Are you considering all aspects and potential consequences logically and thoroughly?\n' +
      'Consider how enhancing your problem-solving and decision-making skills through critical thinking can lead to better outcomes for your team and your leadership journey.\n\n',
  },
];


// const accordionData2 = [
//   {
//     title: 'Observation and Analysis',
//     sub: 'Cultivating a Keen Eye for Detail and Logical Analysis',
//     content:
//       'Interpretation and Evaluation\n' +
//       'Deciphering Meaning and Assessing Validity\n\n' +
//       'Interpreting information accurately and evaluating its validity are critical in making sound decisions. Malaika\'s ability to interpret arguments in debates and evaluate their strength plays a key role in her leadership at LUMUN.\n\n' +
//       'Think about how you interpret information and its implications. Are you drawing conclusions based on solid evidence?\n' +
//       'Evaluate the validity and relevance of information you encounter. How does this evaluation impact your decision-making process?\n' +
//       'Reflect on how your interpretation and evaluation skills contribute to your effectiveness as a leader and a team member.\n\n',
//   },
//   {
//     title: 'Inference and Explanation',
//     sub: 'Drawing Conclusions and Articulating Reasoning',
//     content:
//       'Inference and Explanation\n' +
//       'Drawing Conclusions and Articulating Reasoning\n\n' +
//       'Making inferences and explaining reasoning are vital aspects of critical thinking. Malaika\'s skill in drawing logical conclusions from debates and clearly explaining her reasoning is essential for leading her team.\n\n' +
//       'Consider how you draw inferences from information and situations. Are your conclusions based on logical reasoning?\n' +
//       'Reflect on your ability to explain your reasoning to others. How does this clarity of thought and expression aid your leadership?\n' +
//       'Explore how developing stronger inference and explanation skills can enhance your leadership and problem-solving abilities.\n\n',
//   },
//   {
//     title: 'Problem-Solving and Decision-Making',
//     sub: 'Navigating Complex Challenges with Reason and Insight',
//     content:
//       'Problem-Solving and Decision-Making\n' +
//       'Navigating Complex Challenges with Reason and Insight\n\n' +
//       'Effective problem-solving and decision-making are the hallmarks of a critical thinker. Malaika\'s approach to tackling issues within LUMUN showcases her ability to apply critical thinking for practical solutions.\n\n' +
//       'Analyse your approach to problem-solving. How do you use critical thinking to find innovative solutions?\n' +
//       'Reflect on your decision-making process. Are you considering all aspects and potential consequences logically and thoroughly?\n' +
//       'Consider how enhancing your problem-solving and decision-making skills through critical thinking can lead to better outcomes for your team and your leadership journey.\n\n',
//   },
// ];



const accordionData2 = [
  {
    title: 'Observation and Analysis',
    sub: 'Cultivating a Keen Eye for Detail and Logical Analysis',
    content:
      'Explore the following elements of critical thinking:\n' +

      'Observing details and analysing information critically forms the core of critical thinking. By cultivating\n' +
      'these skills, Malaika learns to discern crucial details and patterns in debates and team discussions,\n' +
      'enhancing her leadership effectiveness.\n' +
      '- Reflect on how you observe and interpret information. Are you considering all relevant details and\n' +
      'perspectives?\n' +
      '- Analyse situations and information critically. How do these insights inform your leadership decisions\n' +
      'and strategies?\n' +
      '- Consider how your observation and analysis skills can be applied to understand complex issues and\n' +
      'lead your team more effectively.\n\n',
  },
  {
    title: 'Interpretation and Evaluation',
    sub: 'Deciphering Meaning and Assessing Validity',
    content:

      'Interpreting information accurately and evaluating its validity are critical in making sound decisions.\n' +
      'Malaika\'s ability to interpret arguments in debates and evaluate their strength plays a key role in her\n' +
      'leadership at LUMUN.\n' +
      '- Think about how you interpret information and its implications. Are you drawing conclusions based\n' +
      'on solid evidence?\n' +
      '- Evaluate the validity and relevance of information you encounter. How does this evaluation impact\n' +
      'your decision-making process?\n' +
      '- Reflect on how your interpretation and evaluation skills contribute to your effectiveness as a leader\n' +
      'and a team member.\n\n',
  },
  {
    title: 'Inference and Explanation',
    sub: 'Drawing Conclusions and Articulating Reasoning',
    content:

      'Making inferences and explaining reasoning are vital aspects of critical thinking. Malaika\'s skill in\n' +
      'drawing logical conclusions from debates and clearly explaining her reasoning is essential for leading\n' +
      'her team.\n' +
      '- Consider how you draw inferences from information and situations. Are your conclusions based on\n' +
      'logical reasoning?\n' +
      '- Reflect on your ability to explain your reasoning to others. How does this clarity of thought and\n' +
      'expression aid your leadership?\n' +
      '- Explore how developing stronger inference and explanation skills can enhance your leadership and\n' +
      'problem-solving abilities.\n\n',
  },
  {
    title: 'Problem-Solving and Decision-Making',
    sub: 'Navigating Complex Challenges with Reason and Insight',
    content:

      'Effective problem-solving and decision-making are the hallmarks of a critical thinker. Malaika\'s\n' +
      'approach to tackling issues within LUMUN showcases her ability to apply critical thinking for practical\n' +
      'solutions.\n' +
      '- Analyse your approach to problem-solving. How do you use critical thinking to find innovative\n' +
      'solutions?\n' +
      '- Reflect on your decision-making process. Are you considering all aspects and potential consequences\n' +
      'logically and thoroughly?\n' +
      '- Consider how enhancing your problem-solving and decision-making skills through critical thinking\n' +
      'can lead to better outcomes for your team and your leadership journey.\n\n',
  },
];


  
const accordionData4 = [
  {
    title: 'Effective Communication',
    content:
      'This week, we delved into the essence of effective communication and its pivotal role in impactful leadership. We studied how clarity, empathy, active listening, and non-verbal cues combine to create powerful communication channels, and we learned this through Preetam\'s continued leadership journey.\n\n' +
      'Drivers of Effective Communication\n' +
      'We explored the elements of effective communication, including the importance of giving and receiving meaningful messages, the power of non-verbal communication, and the need for adaptable communication styles. Recognizing and understanding these elements are vital for building strong communication skills.\n\n' +
      'Importance of Meaning in Communication\n' +
      'We dove into why it\'s crucial to infuse our communications with meaning and purpose. We explored this through a narrative that showed how Preetam learned to craft meaningful messages that resonated with his audience and made him a more effective leader.\n\n' +
      'Virtual Public Speaking Exercise\n' +
      'You prepared speeches on provided topics, recorded them, and submitted your videos for peer review. This exercise not only gave you a platform to practice your public speaking skills but also provided you with constructive feedback from your peers.\n\n',
  },
  {
    title: 'Fostering Inclusive Debate',
    content:
      'Problem: Malaika notices varying opinions within LUMUN regarding debate topics and strategies.\n' +
      'Malaika’s Approach: She encourages open and critical discussions, allowing members to analyze and challenge each argument. This approach promotes intellectual diversity and strengthens the society\'s analytical capabilities.\n\n',
  },
  {
    title: 'Navigating Unforeseen Changes',
    content:
      'Problem: A sudden change in a major event\'s schedule puts Malaika and her team in a challenging position.\n' +
      'Malaika’s Approach: She leverages this as an opportunity to demonstrate adaptability and swift, critical decision-making. Together with her team, she re-evaluates their strategy and develops a revised plan, showcasing the power of critical thinking in crisis management.\n\n',
  },
  {
    title: 'Utilizing Team Potential',
    content:
      'Problem: The LUMUN team comprises debaters with varying strengths and skill sets.\n' +
      'Malaika’s Approach: Understanding and leveraging these diverse talents, Malaika assigns roles that maximize each member\'s potential, displaying strategic critical thinking in team management.\n\n',
  },
  {
    title: 'Balancing Rationality and Sensitivity',
    content:
      'Problem: High-stress debates can lead to emotionally intense situations.\n' +
      'Malaika’s Approach: Malaika learns to blend rational argumentation with emotional intelligence, ensuring debates remain both intellectually rigorous and empathetically conducted.\n\n',
  },
];


  const cardData = [
    {
      id: 1,
      frontContent: 'Promote an Environment of Open Inquiry',
      backContent: (
        <div>
          <p className="font-semibold">
          Creating a culture of open inquiry is essential for nurturing critical thinking within a group. 
          </p>

          <p className="my-2"> Reflect on your recent group interactions: </p>
          <ul style={{listStyle:'disc'}}> 
            <li>How have I encouraged open and critical discussions among team members?</li>
            <li>
            In what ways have I facilitated an environment where every opinion is valued and challenged
constructively?

            </li>
            <li>
            In what ways have I facilitated an environment where every opinion is valued and challenged
constructively?

            </li>
          </ul>
        </div>
      ),
    },




    {
        id: 2,
        frontContent: 'Analyse Group Decision-Making Processes',
        backContent: (
          <div>
            <p className="font-semibold">
            Critical thinking is not just an individual effort but a collective one.
            </p>
            <p className="my-2"> Examine your group's decision making: </p>
            <ul style={{listStyle:'disc'}}>
              <li>How do we approach problem-solving and decision-making as a team? Is it thorough and logical?
 </li>
              <li>
              Where can we improve in ensuring that our decisions are based on sound reasoning and not just
majority opinion?

              </li>
              <li>
              How can I lead the team to consider various perspectives and evidence before reaching conclusions?
              </li>
            </ul>
          </div>
        ),
      },



      {
        id: 3,
        frontContent: 'Encourage Diversity of Thought and Counteract Groupthink',
        backContent: (
          <div>
            <p className="font-semibold">
            Diversity of thought is the antidote to groupthink.
            </p>
            <p className="my-2"> Assess your role in promoting diverse perspectives: </p>
            <ul style={{listStyle:'disc'}}>
              <li>Have I been proactive in seeking and respecting different viewpoints in the group?
 </li>
              <li>
              How can I better identify and mitigate the risks of groupthink in our team discussions and decisions?
              </li>
              <li>In what ways can I empower each member to contribute their unique insights and challenge the
status quo?              </li>
            </ul>
          </div>
        ),
      },
    // Add more card data as needed
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  
  const handleNextQuestion = () => {
    setShowExplanation(true);
  };
  
  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption('');
      setShowExplanation(false);
    }
  };





  
const [nextBtnCondtion, setNextBtnCondtion] = useState(false)

const [allFieldsHaveMinimumWords, setallFieldsHaveMinimumWords] = useState(false)

useEffect(() => {
  // const wordCounts = Object.values(swotSubmission).map(value => value.split(/\s+/).filter(word => word !== '').length);
  const wordCounts = Object.values(swotSubmission).map(value => value.split(/\s+/).filter(word => word !== '').length);

  if(wordCounts.every(count => count >= 50)){
    console.warn("W6 words OK: "+wordCounts)
    setallFieldsHaveMinimumWords(true)
  }

  else{
    console.warn("W6 words ERROR: "+wordCounts)
    setallFieldsHaveMinimumWords(false)
  }
  
  // Check if all fields have at least 50 words each
  // const allFieldsHaveMinimumWords = wordCounts.every(count => count >= 50);

  // alert("WORDS: "+allFieldsHaveMinimumWords);
 
  if (currentStep === 0 || currentStep === 1  || currentStep === 2  || currentStep === 3  || currentStep === 4  || currentStep === 5  || currentStep === 7 ) {
    setNextBtnCondtion(true);
  } 

  else if (currentStep === 6 && status==='Completed') {
    setNextBtnCondtion(true);
  } 

  else if (currentStep === 6 && status === 'Active' && allFieldsHaveMinimumWords && disableBtn) {
    setNextBtnCondtion(true);
  }
  
  else {
    setNextBtnCondtion(false);
  }
}, [currentStep, swotSubmission, allFieldsHaveMinimumWords, status, disableBtn]);


  


  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
      Welcome to Week 7: Critical Thinking

      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 7 of your leadership development journey! This week, we dive into the essential skill of
"Critical Thinking." Prepare to challenge your mind and sharpen your analytical skills as we explore the realms of
rational thought, logical reasoning, and sound decision-making. Discover how critical thinking empowers leaders
to cut through complexity, make informed decisions, and solve problems creatively. Through a series of engaging
activities, thought-provoking discussions, and real-world scenarios, you will learn to question assumptions,
evaluate arguments, and think beyond the obvious. This week is about strengthening your ability to think clearly
and critically, equipping you with the mental tools to lead effectively in an increasingly complex and ambiguous
world. Get ready to transform the way you perceive challenges and opportunities, developing a mindset that
embraces critical analysis and thoughtful deliberation.
      </p>

      <ul style={{listStyle:'disc'}} className="p-6 ">
                <li>As aspiring leaders at LUMS, poised to make informed decisions in an ever-changing world, the art of
Critical Thinking is crucial. This week, in "Critical Thinking" we focus on the story of Malaika, a fellow
LUMS student who has recently taken up the role of President of LUMUN.</li>

<li>Malaika's journey is a testament to the importance of critical thinking in leading a diverse and
intellectually vibrant team. Her experiences offer a glimpse into how critical thinking influences
decision-making, problem-solving, and leadership effectiveness within the context of student-led
organizations.
</li>


<li>Throughout this week, consider:
How does Malaika apply Critical Thinking in her leadership role?
What can we learn from Malaika's approach to enhance our leadership skills?
Malaika's narrative is set to unfold the significance of Critical Thinking in leadership. It highlights how
logical analysis, questioning norms, and evaluating diverse viewpoints can lead to more effective and
innovative solutions.</li>
            </ul>
      <div className="mt-6 flex justify-center w-full h-full">
        {/* <img
          className="mx-auto w-full hover:shadow-2xl hover:shadow-indigo-500/50"
          src="https://res.cloudinary.com/ahbranding/image/upload/v1701935410/AHbranding/Group_34784414_y5gfpi.png"
        /> */}
      </div>
    </section>,




    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img className="w-full" src="https://res.cloudinary.com/ahbranding/image/upload/v1692603965/AHbranding/Group_34784410_q9qk6r.png" />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Embarking on Malaika's Journey</b>
            </h2>
            <p className="mt-4 text-gray-600">
            As the President of LUMUN, Malaika faces a myriad of leadership challenges. She quickly realizes that
her role extends beyond organizing debates – it involves nurturing a culture of analytical thinking and
reasoned discourse.
            </p>



            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                  

                  <Collapse accordionData={accordionData} />



                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
          Through her experience, Malaika develops a profound understanding of critical thinking. She navigates
complex issues, fosters a culture of thoughtful debate, and leads her team towards success with
reasoned and strategic guidance. Her story illustrates that critical thinking goes beyond mere problem
analysis; it's about applying logic and reasoning to lead effectively and inclusively. Malaika's journey at
LUMUN serves as an inspiring example for all LUMS students, emphasizing critical thinking as an
indispensable tool in their leadership toolkit.
        </p>
      </div>
    </section>,






<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Starting your Journey of Critical Thinking 
</b>
      </h2>
      <p className="mt-4 text-gray-600">
      As we progress through our leadership development, this week brings us to the crucial aspect of
critical thinking. Just like Malaika, the President of LUMUN, we have the opportunity to enhance our
ability to analyse, reason, and make sound decisions.
Critical thinking is the backbone of effective leadership. It involves the skilful analysis of information,
questioning assumptions, and making reasoned judgments. By mastering critical thinking, we can
approach complex problems with clarity and precision, leading to well-informed decisions and
innovative solutions.
As Malaika navigates the intricacies of her role at LUMUN, she learns to employ critical thinking in
every aspect of her leadership. From strategizing debates to resolving conflicts, her journey invites us
to reflect on our own approach to critical thinking. As we develop this skill, we become more adept at
addressing the challenges we face as student leaders, ultimately making a significant impact within
our university community.
In our pursuit of becoming more effective leaders, understanding and applying the elements of critical
thinking is essential. By doing so, we can approach leadership challenges with a clear, rational, and
innovative mindset.
      </p>



      <div className="mt-6">
        <div className="container flex flex-col justify-center mx-auto px-0 ">
          <div className="space-y-4">
            
            

          <Collapse accordionData={accordionData2} />


          </div>
        </div>
      </div>
    </div>
  </div>


</div>
</section>,


<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b>Fostering Critical Thinking in Group Dynamics
 </b>
        </h2>
        <p className="mt-4 text-gray-600">
        Just as an orchestra harmonizes different instruments to create a symphony, effective group
leadership requires harmonizing diverse thoughts and opinions. Critical thinking in group settings is
about orchestrating this diversity to achieve collective goals. It's a vital part of your leadership toolkit,
ensuring that decisions are not just a reflection of consensus but of reasoned and balanced judgment.


        </p>

        <strong>Three Steps to Enhance Critical Thinking in Group Settings </strong>
<div className="grid grid-cols-1 ">
{cardData.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}

</div>
</section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek7 className='w-full' onImageIndexChange={handleImageIndexChange} />

      </div>
    </section>,





<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  {/* <b>Transitioning into Practical Application: Activity 13 - Analytical Riddles and Critical Challenges */}
<b>Transitioning into Practical Application: Activity 13 - Analytical Riddles and Critical Challenges


</b>
</h2>
{/* <p className="mt-4 text-gray-600">
we now pivot to a more hands-on approach with Activity 13: Analytical Riddles and Critical Challenges.
This activity is designed not just to test but to cultivate your critical thinking skills in a dynamic and
engaging way. Here, you'll encounter a variety of puzzles and brainteasers that challenge your logic,
encourage creative problem-solving, and sharpen your analytical abilities. Each puzzle is a miniadventure in thinking, offering a unique opportunity to apply the concepts we've discussed in a
practical setting. Expect to be challenged, intrigued, and inspired as you navigate through these
exercises. Remember, the goal is not just to find the right answer, but to understand the reasoning
behind it, enhancing your critical thinking skills in a manner that is both educational and enjoyable. So,
let's dive into this interactive experience and see how you can bring the principles of critical thinking
to life!

</p> */}


<div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
{currentQuestion < questionsData.length ? (
  <div>
  <span class="flex text-center justify-center mb-6 items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">{questionsData[currentQuestion].title}</span>

    <h2 className="text-xl text-center font-semibold mb-8">{questionsData[currentQuestion].question}</h2>
    <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
      {questionsData[currentQuestion].options.map((option, index) => (
        <div
          key={index}
          className={`${
            (showExplanation && option === questionsData[currentQuestion].correctAnswer) ? 'bg-green-500 text-white' :
            (selectedOption === option ? 'bg-purple-200' : 'bg-white')
          } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer `}
          onClick={() => handleOptionSelect(option)}
        >
          {option}
        </div>
      ))}
    </div>
    {selectedOption && (
      <div className="flex justify-center">
        <button
          className="btn btn-outline-primary mt-4"
          onClick={handleNextQuestion}
        >
          Show Answer
        </button>
      </div>
    )}
    {showExplanation && (
      <div className="mt-4">
        <p className="font-semibold">Correct Answer:</p>
        <p className="text-green-500">{questionsData[currentQuestion].correctAnswer}</p>
        {/* <p className="font-semibold mt-2">Explanation:</p>
        <p>{questionsData[currentQuestion].explanation}</p> */}
        <div className="flex justify-center mt-4">
          <button
            className="btn btn-outline-primary"
            onClick={handleNextQuestionOrFinish}
          >
            {/* {currentQuestion + 1 < questionsData.length ? 'Next Question' : 'Finish'} */}

            {currentQuestion + 1 < questionsData.length ? 'Next Question' : ''}
          </button>
        </div>
      </div>
    )}
  </div>
) : (
  <div>
    <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
  </div>
)}
</div>


</section>,




    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Introduction to Activity 14: Argument Analysis in Student-Run Organizations

</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Activity 14, "Argument Analysis," is crafted to engage you in a critical examination of a key dilemma
faced by student-run organizations. This exercise provides an interactive forum for you to articulate,
debate, and critically assess diverse viewpoints on issues that strike at the heart of organizational
management. The objective is to enhance skills in formulating and evaluating arguments, fostering a
deeper understanding of complex, real-world issues. This week’s exploration centres around a
particularly relevant question for such organizations: "Should student-run societies prioritize profit generating activities or focus more on community impact?"

      </p>





      <ul
        className="mt-4 bg-purple-50 p-2 rounded-xl border-2 border-purple-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4 flex-col  font-medium flex flex-row gap-2 items-start bg-purple-400 text-white w-min py-1 px-3 rounded-full">
       Question 
        </p>

        <b  className="m-6">Should Student-Run Organizations Prioritize Profit-Generating Activities or Focus More on Community Impact?</b>

        <li className="m-6">
        This topic prompts you to consider the balance between generating profits, which may support the society’s activities and growth, and pursuing goals that primarily benefit the community. </li>
        <li className="m-6">
        You should weigh factors such as financial sustainability, the mission of the society, ethical considerations in profit-making, and the long-term impact on the university and its community. 
        </li>

      </ul>

      <ul
        className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          Instructions{" "}
        </p>

        <li className="m-6">
        Approach the discussion with an understanding that there are multiple valid perspectives.        </li>
        <li className="m-6">
Craft arguments that are logically sound, fact-based, and reflective of the challenges faced by
student societies.        </li>
        <li className="m-6">
        In providing feedback to your peers, focus on enhancing understanding and encouraging a
diversity of viewpoints.
        </li>

      </ul>


       
      
{status === "Completed" || disableBtn ? (
              <div className="my-4">
                <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">

                  <ul>
                    <li><b>Principles:</b><br/> {swotSubmission?.response}<br/><br/></li>
                  </ul>

                </p>
              </div>
            ) : (

<div>


<div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Response
</span>
          </label>
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({ ...prev, response: e.target.value }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>


      <div className="flex items-center gap-2 mt-6">
        <input
            type="checkbox"
            checked={checkBox}
            className="checkbox checkbox-warning"
            onChange={() => {
              setCheckBox(!checkBox); // Toggle the checkbox state
            }}
        />
        <span className="text-black-400 ">Do you agree to share your response with a peer for the purpose of receiving constructive feedback. </span>
      </div>
      <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={RecheckBox} 
  onChange={() => {
    setRecheckBox(!RecheckBox); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Read your submission carefully before submitting </span>
      </div>

      <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} disabled={status === 'Completed' || (!RecheckBox || !checkBox || !allFieldsHaveMinimumWords)}>
        Submit
      </button>
</div>   
            )}



    </section>,

// <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
// {/* Step 1 content */}

// <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
//   <div className="grid grid-cols-1 gap-8 lg:gap-16">


//     <div className="">
//       <h2 className="text-xl font-medium sm:text-xl">
//         <b>Week 7 Conclusion: Integrating Critical Thinking Skills
// </b>
//       </h2>
//       <p className="mt-4 text-gray-600">

//       Congratulations on completing Week 7: Critical Thinking! This week has been a pivotal part of your
// leadership journey, enriching your skillset and expanding your analytical horizons:
// Mastering Critical Analysis: Throughout this week, you've engaged with the core aspects of critical
// thinking, learning to dissect arguments, challenge assumptions, and evaluate evidence. Through
// various exercises and discussions, you've honed your ability to think logically and critically.
// Engaging in Thoughtful Debate: You've experienced the power of well-reasoned debate,
// understanding how critical thinking enhances discussions and decision-making processes. The
// exercises have equipped you to approach complex issues with a balanced and analytical mindset.
// Problem-Solving and Decision-Making: We delved into the realms of advanced problem-solving,
// practicing how to apply critical thinking to make informed and effective decisions. You've tackled
// intricate puzzles and scenarios, enhancing your capacity to navigate challenges with insight and
// intelligence.
// Expanding Perspectives: This week also focused on broadening your perspectives, encouraging you to
// consider diverse viewpoints and understand the importance of a comprehensive approach in
// leadership and decision-making.
// Applying Critical Thinking in Real Situations: Finally, you've reflected on real-life situations pertinent to
// student societies at LUMS, applying critical thinking skills to analyze and respond to these challenges
// effectively.
// Well done on surmounting the challenges of Week 7! As you step into Week 8, we will shift our focus
// towards developing analytical skills that complement and deepen your critical thinking abilities. Keep
// applying the critical thinking tools you've acquired, stay engaged, and continue to foster your growth
// as a thoughtful and discerning leader. Onward to the next stage of your leadership development!

      
//       </p>


//       <div className="mt-6 grid grid-cols-1 gap-6">
// <div className="space-y-4">


// <Collapse accordionData={accordionData4} />

// </div>




// </div>

// <p className="mt-4 text-gray-600">
// Great job on completing Week 3! Prepare yourself for Week 4, where we will delve into Conflict
// Resolution and its role in leadership. Keep up the outstanding work and continue your journey towards
// becoming an extraordinary leader.
// Feel free to explore the following resources to help you understand more about yourself.
      
//                 </p>



//                 <ul  className="block p-4" style={{listStyle:'circle'}}>

//   </ul>
 
//     </div>
//   </div>
// </div>
// </section>



<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
  {/* Step 1 content */}
  <Confetti numberOfPieces={100} />
  <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-1 gap-8 lg:gap-16">
      <div className="">
        <h2 className="text-xl font-medium sm:text-xl">
          <b>Week 7 Conclusion: Integrating Critical Thinking Skills</b>
        </h2>
        <p className="mt-4 text-gray-600">
          Congratulations on completing Week 7: Critical Thinking! This week has been a pivotal part of your leadership journey, enriching your skillset and expanding your analytical horizons:
          <br/><br/>  <strong>Mastering Critical Analysis:</strong> Throughout this week, you've engaged with the core aspects of critical thinking, learning to dissect arguments, challenge assumptions, and evaluate evidence. Through various exercises and discussions, you've honed your ability to think logically and critically.
          <br/><br/> <strong>Engaging in Thoughtful Debate:</strong> You've experienced the power of well-reasoned debate, understanding how critical thinking enhances discussions and decision-making processes. The exercises have equipped you to approach complex issues with a balanced and analytical mindset.
          <br/><br/> <strong>Problem-Solving and Decision-Making:</strong> We delved into the realms of advanced problem-solving, practicing how to apply critical thinking to make informed and effective decisions. You've tackled intricate puzzles and scenarios, enhancing your capacity to navigate challenges with insight and intelligence.
          <br/><br/>  <strong>Expanding Perspectives:</strong> This week also focused on broadening your perspectives, encouraging you to consider diverse viewpoints and understand the importance of a comprehensive approach in leadership and decision-making.
          <br/><br/>  <strong>Applying Critical Thinking in Real Situations:</strong> Finally, you've reflected on real-life situations pertinent to student societies at LUMS, applying critical thinking skills to analyze and respond to these challenges effectively.
         <br/><br/> Well done on surmounting the challenges of Week 7! As you step into Week 8, we will shift our focus towards developing analytical skills that complement and deepen your critical thinking abilities. Keep applying the critical thinking tools you've acquired, stay engaged, and continue to foster your growth as a thoughtful and discerning leader. Onward to the next stage of your leadership development!
        </p>
        <p className="mt-4 text-gray-600">
          <strong>Additional Resources for Further Learning:</strong>
        </p>
        <ul className="flex flex-col" style={{ listStyle: "disc" }}>
  <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    Thinking, Fast and Slow by Daniel Kahneman, Chapter 23 – The Illusion of Understanding
  </li>
  <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    The Power of Critical Thinking by Lewis Vaughn, Chapter 1 – The Power of Critical Thinking
  </li>
  <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    Outliers: The Story of Success by Malcolm Gladwell, Chapter 7 – The Ethnic Theory of Plane Crashes
  </li>
  <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    Critical Thinking Skills by Stella Cottrell, Chapter 2 – How to Think Critically
  </li>
</ul>
      </div>
    </div>
  </div>
</section>



    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "Starting your Journey ";

      case 3:
        return "Toolkit";

      case 4:
        return "Ethics in Critical thinking";

      case 5:
        return "Analytical Riddles & Critical Challenges";

        case 6:
        return "Activity – Argument Analysis";

        case 7:
        return "Conclusion";
        
      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }

                      disabled={!nextBtnCondtion}
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week7;
