import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import Collapse from "../components/Collapse";
import Quote from "../components/Quote";
import Confetti from 'react-confetti';
import "./new-certi.css";


const Week1 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 2;
  const week_name = "week_1";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  console.warn(server_progress,"server_progress")
  // const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [currentStep, setCurrentStep] = useState(0);
  const [checkBox,setCheckBox] = useState(false)
  const [rating, setRating] = useState(0);
  const [peerid, setPeerId] = useState(0);
  const [flag, setFlag] = useState(false);
  const [subFeedback, setSubFeedback] = useState("");

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }


  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };


  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem('week1Images');

    if (!storedImages) {
      // If not in localStorage, fetch the images
      const slideImages = [
    'https://res.cloudinary.com/ahbranding/image/upload/v1700214915/AHbranding/Slide_16_9_-_1_yyzdiq.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1700214751/AHbranding/Slide_16_9_-_2_xsrzjy.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1700214764/AHbranding/Slide_16_9_-_3_y6wqcd.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1700214778/AHbranding/Slide_16_9_-_4_kjkxar.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1700214886/AHbranding/Slide_16_9_-_6_pq0mer.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1700214873/AHbranding/Slide_16_9_-_5_v1z44x.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1707927089/AHbranding/cSlide_16_9_-_7_loiggq.png',
        // Add other image URLs as needed
      ];

      // Store the images in localStorage
      localStorage.setItem('week1Images', JSON.stringify(slideImages));
    }





  }, []);
  
  

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const storedSwotData = localStorage.getItem('weekOneSwotData');
  const localSwotData = JSON.parse(storedSwotData);
  // const [swotSubmission, setSubmission] = useState({
  //   strengths: localSwotData?.strengths ?? "",
  //   weaknesses: localSwotData?.weaknesses ?? "",
  //   opportunities: localSwotData?.opportunities ?? "",
  //   threats: localSwotData?.threats ?? "",
  //   // peer_feedback: localSwotData?.peer_feedback ?? "",
  // });
  const [swotSubmission, setSubmission] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
    // peer_feedback: "",
  });

  const [nextBtnCondtion, setNextBtnCondtion] = useState(false)

  const [swotSubmission2, setSubmission2] = useState({
    response: "",
  });
  const [disableBtn,setDisableBtn] = useState(false)
  const [disableBtnTwo,setDisableBtnTwo] = useState(false)


  

  const [assignedReview, setAssigned] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            strengths: data.strengths,
            weaknesses: data.weaknesses,
            opportunities: data.opportunities,
            threats: data.threats,
          });
          setFeedback(response.data.feedback);
        }
      });
  };

  // function countWords(text) {
  //   const trimmedText = text?.trim();
  //   const words = trimmedText?.split(/\s+/);
  //   return words?.length;
  // }

  function countWords(text) {
    if (typeof text !== 'string') {
      return 0; // Return 0 if text is not a string
    }
    const trimmedText = text.trim();
    const words = trimmedText.split(/\s+/);
    return words.length;
  }
  

  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
         
          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);

          setSubmission({
            strengths: data.strengths,
            weaknesses: data.weaknesses,
            opportunities: data.opportunities,
            threats: data.threats,
            peer_feedback: data.feedback,
          });

          // Store setSubmission in localStorage
          

          // setSubmission2({
          //   response: data.response,
          // });
        }
      });
  };


  
  const fetchStepData2 = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
         
          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);

          // setSubmission({
          //   strengths: data.strengths,
          //   weaknesses: data.weaknesses,
          //   opportunities: data.opportunities,
          //   threats: data.threats,
          //   peer_feedback: data.feedback,
          // });

          // Store setSubmission in localStorage
          

          setSubmission2({
            response: data.response,
          });
        }
      },config);
  };
  const [RecheckBox,setRecheckBox] = useState(false)
  const [RecheckBox2,setRecheckBox2] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchStepData2(profileID, week_id, week_name, 5, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_2",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const [peerFeedback, setpeerFeedback] = useState("");
  const handleSubFeedback = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`, {
        peerid: peerid,
        subFeedback: subFeedback,
        flag: true,
      },config)
      .then((res) => {
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };
  const areStrengthsValid = countWords(swotSubmission?.strengths) >= 50;
  const areResponseValid = countWords(swotSubmission2?.response) >= 300;
  const areWeaknessesValid = countWords(swotSubmission?.weaknesses) >= 50;
  const areOpportunitiesValid = countWords(swotSubmission?.opportunities) >= 50;
  const areThreatsValid = countWords(swotSubmission?.threats) >= 50;
  
  const isAnySectionInvalid = 
  !areStrengthsValid || 
  !areWeaknessesValid || 
  !areOpportunitiesValid || 
  !areThreatsValid;
  const handleRating = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_rating/`, {
        peerid: peerid,
        rating: rating,
      },config)
      .then((res) => {
        toast.success("Good Job! Rating Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);

      if (status === "Active" && currentStep===4) {

        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: rating,
          pr_enabled: true,
          activity_name: "SWOT Analysis",
        },config)
          .then(response => {
            // Handle success
            setDisableBtn(true)
            toast.success("Good Job! Activity Submitted", {
              icon: "👏",
              style: {
                borderRadius: "55px",
                marginBottom: "15px",
              },
            });
            // Additional handling if needed
          })
          .catch(error => {
            // Handle error
            toast.error("Error submitting activity:", error);
            // Additional error handling if needed
          });
      } else if (status === "Active" && currentStep===5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission2,
          pathway: 1,
          status: "Reviewing",
          rating: rating,
          pr_enabled: true,
          activity_name: "SWOT Analysis22",
        },config)
          .then(response => {
            // Handle success
            setDisableBtn(true)
            toast.success("Good Job! Activity Submitted", {
              icon: "👏",
              style: {
                borderRadius: "55px",
                marginBottom: "15px",
              },
            });
            // Additional handling if needed
          })
          .catch(error => {
            // Handle error
            toast.error("Error submitting activity:", error);
            // Additional error handling if needed
          });
      }
      
    
  };



  const handleStepSubmission2 = () => {
    console.log("this steo", currentStep);
    if (status === "Active") {
      axios
          .post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: swotSubmission2,
            pathway: 1,
            status: "Reviewing",
            rating: rating,
            pr_enabled: false,
            activity_name: "Self Reflection Journaling",
          },config)
          .then(response => {
            // Handle success
            setDisableBtnTwo(true);
            toast.success("Good Job! Activity Submitted", {
              icon: "👏",
              style: {
                borderRadius: "55px",
                marginBottom: "15px",
              },
            });
          })
          .catch(error => {
            // Handle error
            console.error("Error submitting activity:", error);
            toast.error("Error submitting activity. Please try again later.", {
              icon: "❌",
              style: {
                borderRadius: "55px",
                marginBottom: "15px",
              },
            });
          });
    }

  };

  if (currentStep === 4 && !areResponseValid && isAnySectionInvalid ) {
    console.warn("Condition is true LINE 439");
  } else {
    console.warn("Condition is false LINE 441");
  }

  

  const totalSteps = 6; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  useEffect(() => {
    // Check if swotSubmission is defined and not null
    if (swotSubmission !== undefined && swotSubmission !== null) {
      const storedData = localStorage.getItem('weekOneSwotData');

      // Check if storedData exists in localStorage
      if (!storedData) {
        const data  = {
          strengths: swotSubmission.strengths || [],
          opportunities: swotSubmission.opportunities || [],
          threats: swotSubmission.threats || [],
          peer_feedback: swotSubmission.peer_feedback || [], // Adjust according to your data structure
        };

        // Log data before saving to localStorage
        console.log('Data to be stored:', data);

        localStorage.setItem('weekOneSwotData', JSON.stringify(data));
      } else {
        // Log a message if data already exists in localStorage
        console.log('Data already exists in localStorage:', storedData);
      }
    }
  }, [swotSubmission]);

  // useEffect(() => {

  //   if (currentStep === 0 ) {
  //     setNextBtnCondtion(false);
  //   } else {
  //     setNextBtnCondtion(true);
  //   } if(currentStep === 2 && currentImageIndex < 6) {
  //     setNextBtnCondtion(true);
  //   } else {
  //     setNextBtnCondtion(false);
  //   } if ((currentStep === 4 && !Object.values(swotSubmission).some(value => value !== '')) || !disableBtn) {
  //     setNextBtnCondtion(true);
  //   } else {
  //     setNextBtnCondtion(false);
  //   }
  // }, [currentStep, currentImageIndex, areResponseValid, isAnySectionInvalid,disableBtn, disableBtnTwo]);

  useEffect(() => {
    if (currentStep === 0 || currentStep === 1 || currentStep === 3) {
      setNextBtnCondtion(true); // Enable button at step 0
    } else if (currentStep === 2 && currentImageIndex < 6) {
      setNextBtnCondtion(false);
    } else if (currentStep === 2 && currentImageIndex > 5) {
      setNextBtnCondtion(true);
    } 
    
    // else if (currentStep === 4 && (disableBtn || Object.values(swotSubmission).every(value => value !== ''))) {
    //   setNextBtnCondtion(true);
    //   // alert(currentStep + disableBtn + Object.values(swotSubmission).some(value => value !== ''))
    // } 


    else if (currentStep === 4 && status==='Active') {
      if(disableBtn){
        setNextBtnCondtion(true);
      } 
      else{
        setNextBtnCondtion(false);
      }
    } 


    else if (currentStep === 4 && status==='Completed') {
      setNextBtnCondtion(true);
    } 

    else if (status==='Completed' && Object.values(swotSubmission).every(value => value !== '')){
      setNextBtnCondtion(true);
    }
    
    else if (currentStep === 5 && (disableBtnTwo || (status==='Completed' && Object.values(swotSubmission2).some(value => value !== '')))) {
      setNextBtnCondtion(true);
    } 
    
    
    else {
      setNextBtnCondtion(false);
    }
  }, [currentStep, currentImageIndex, swotSubmission, swotSubmission2, areResponseValid, isAnySectionInvalid,disableBtn, disableBtnTwo]);
  
  
  const accordionData = [
    { title: 'Demonstration', content: 'How does Ayesha demonstrate self-awareness in her leadership role?' },
    { title: 'Lesson', content: 'What lessons can we draw from Ayesha\'s journey that resonate with our own experiences as student leaders?'},
  ];

  const accordionData2 = [
    {
      title: 'Understanding your values and beliefs',
      sub: 'Discovering Your North Star for Authentic Leadership',
      content: 'Discovering what truly matters to you forms the foundation of self-awareness. By aligning your actions with your core values, you establish a clear sense of purpose and integrity, guiding your leadership journey.\n\n' +
        '• Take the time to reflect on your core values and beliefs. What principles guide your actions and decisions?\n' +
        '• Identify the values that resonate most deeply with you. How can you align your leadership approach with these values?\n' +
        '• Consider how your values influence your interactions with others and the impact they have on your leadership effectiveness.'
    },
    {
      title: 'Recognizing your strengths and areas for growth',
      sub: 'Embracing a Growth Mindset for Continuous Improvement',
      content: 'Embracing a growth mindset, we acknowledge our strengths and areas where we can further develop. By leveraging our strengths and actively working on areas for improvement, we become more effective and adaptable leaders.\n\n' +
        '• Reflect on your strengths and areas where you excel as a leader. How can you leverage these strengths to make a positive impact?\n' +
        '• Identify areas where you can further develop and grow. What steps can you take to enhance your skills and expand your capabilities?\n' +
        '• Embrace a growth mindset that embraces challenges and views setbacks as opportunities for learning and improvement.'
    },
    {
      title: 'Awareness of emotions and their impact',
      sub: 'Navigating Emotions for Effective Leadership',
      content: 'Emotional intelligence plays a crucial role in self-awareness. Understanding and managing our emotions, as well as recognizing how they influence our interactions with others, enables us to lead with empathy, authenticity, and resilience.\n\n' +
        '• Develop awareness of your own emotions and how they influence your thoughts and actions. How do your emotions impact your decision-making and interactions with others?\n' +
        '• Practice empathy by seeking to understand and connect with the emotions of those around you. How can you foster a supportive and emotionally intelligent environment?\n' +
        '• Enhance your emotional self-regulation skills to manage stress, remain composed in challenging situations, and lead with empathy and resilience.'
    },
    {
      title: 'Reflecting on your leadership style',
      sub: 'Harnessing Introspection for Personal Growth',
      content: 'Regular self-reflection allows us to gain insights into our leadership style, identifying both our successes and areas for refinement. By seeking feedback and engaging in introspection, we continuously evolve as leaders.\n\n' +
        '• Regularly engage in self-reflection to gain insights into your leadership style. What are your strengths as a leader, and how can you amplify them?\n' +
        '• Seek feedback from mentors, colleagues, and team members to gain different perspectives on your leadership approach. What areas can you refine or improve upon?\n' +
        '• Embrace a mindset of continuous growth and improvement, using self-reflection as a tool for personal and professional development.'
    },
  ];
  
  

  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
  };

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Week 1: Self-Awareness</b>
      </h2>
      <p className="mt-4 text-gray-600">
        In Week 1, we explore the importance of self-awareness in your growth as leaders. Gain insights into the
        multifaceted nature of leadership and its significance for students.
        Develop self-awareness to lead with authenticity and make a positive
        impact. Engage in discussions, reflect on your experiences, and connect
        with fellow learners. Get ready to unlock your leadership potential and
        embark on a transformative journey.
      </p>
      {/* <div className="mt-6 flex justify-center w-full h-full">
        <img
          className="mx-auto w-full hover:shadow-2xl hover:shadow-indigo-500/50"
          src="https://res.cloudinary.com/ahbranding/image/upload/v1702304816/AHbranding/Group_34784419_mfmt7s.png"
        />
      </div> */}




<Quote content={"Leadership is not about a title or a designation. It's about impact, influence, and inspiration"} person={"Robin S. Sharma"}/>








    </section>,
    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1692603965/AHbranding/Group_34784410_q9qk6r.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>This Week’s Objectives</b>
            </h2>
            <p className="mt-4 text-gray-600">
              As students on a transformative journey of leadership and
              self-awareness, it is essential to cultivate the qualities that
              will empower us to make a positive impact. In this week’s theme of Self-awareness, we delve into the
              inspiring story of Ayesha, a student leader who has been entrusted
              with the role of President of the Student Council.<br/><br/>Ayesha's
              journey reflects the joys and challenges of leadership within the
              vibrant university community. By exploring Ayesha's experiences,
              we gain valuable insights into the transformative power of
              self-awareness and its role in effective leadership. <br/><br/><strong>During this
              week, let's reflect on the following:</strong>
            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                <Collapse accordionData={accordionData}/>

                  {/* <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Demonstration
                    </summary>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      How does Ayesha demonstrate self-awareness in her
                      leadership role?
                    </p>
                  </details>

                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Lesson
                    </summary>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      What lessons can we draw from Ayesha's journey that
                      resonate with our own experiences as student leaders?
                    </p>
                  </details> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
          <strong>Note: </strong>Through Ayesha's story, we will uncover the significance of
          self-awareness as a foundation for leadership excellence. By
          understanding ourselves deeply, we can navigate complexities, build
          meaningful connections, and inspire others to reach their full
          potential.
        </p>
      </div>
    </section>,
    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
     <h2 className="text-xl font-medium sm:text-xl">
              <b>Ayesha’s journey</b>
            </h2>
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek1
          className="w-full"
          onImageIndexChange={handleImageIndexChange}
        />
      </div>
    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1692614385/AHbranding/GP%20Slides/W1/Group_34784415_eaao9d.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Embarking on the Journey of Self-Awareness</b>
            </h2>
            <p className="mt-4 text-gray-600">
              As we continue our exploration, let us now delve into the crucial aspect of self-awareness. Just like Ayesha, we have the opportunity to deepen our understanding of ourselves, our strengths, and our areas for growth.
              <br/><br/>Self-awareness is the cornerstone of personal and leadership development. It is the ability to introspect, recognize our emotions, thoughts, and values, and understand how they influence our actions and interactions with others. By cultivating self-awareness, we gain invaluable insights that enable us to lead with authenticity and make conscious choices aligned with our values.
              <br/><br/>As Ayesha navigates the challenges and triumphs of her position as President of the Student Council, she learns to embrace self-reflection, seek feedback, and continuously strive for personal growth. Through Ayesha's experiences, we are invited to reflect upon our own self-awareness journey. As we deepen our self-awareness, we unlock our true potential as student leaders and create a positive impact within our university community.
              <br/><br/>In our pursuit of personal and leadership growth, self-awareness stands as a fundamental pillar. By cultivating self-awareness, we gain valuable insights into our thoughts, emotions, and behaviours, allowing us to make conscious choices and navigate the complexities of our roles with authenticity and clarity.
            </p>

            <h2 className="text-xl font-medium sm:text-xl">Elements of self-awareness:</h2>

            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                <Collapse accordionData={accordionData2}/>
                  {/* <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Understanding your values and beliefs
                    </summary>
                    <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
                      Discovering Your North Star for Authentic Leadership{" "}
                    </h3>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Discovering what truly matters to you forms the foundation
                      of self-awareness. By aligning your actions with your core
                      values, you establish a clear sense of purpose and
                      integrity, guiding your leadership journey.
                    </p>
                    <ul
                      className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
                      style={{ listStyle: "circle" }}
                    >
                      <li>
                        Take the time to reflect on your core values and
                        beliefs. What principles guide your actions and
                        decisions?
                      </li>
                      <li>
                        Identify the values that resonate most deeply with you.
                        How can you align your leadership approach with these
                        values?
                      </li>
                      <li>
                        Consider how your values influence your interactions
                        with others and the impact they have on your leadership
                        effectiveness.
                      </li>
                    </ul>
                  </details>

                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Recognizing your strengths and areas for growth
                    </summary>
                    <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
                      Embracing a Growth Mindset for Continuous Improvement
                    </h3>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Embracing a growth mindset, we acknowledge our strengths
                      and areas where we can further develop. By leveraging our
                      strengths and actively working on areas for improvement,
                      we become more effective and adaptable leaders.
                    </p>
                    <ul
                      className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
                      style={{ listStyle: "circle" }}
                    >
                      <li>
                        Reflect on your strengths and areas where you excel as a
                        leader. How can you leverage these strengths to make a
                        positive impact?
                      </li>
                      <li>
                        Identify areas where you can further develop and grow.
                        What steps can you take to enhance your skills and
                        expand your capabilities?
                      </li>
                      <li>
                        Embrace a growth mindset that embraces challenges and
                        views setbacks as opportunities for learning and
                        improvement.
                      </li>
                    </ul>
                  </details>

                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Awareness of emotions and their impact
                    </summary>
                    <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
                      Navigating Emotions for Effective Leadership
                    </h3>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Emotional intelligence plays a crucial role in
                      self-awareness. Understanding and managing our emotions,
                      as well as recognizing how they influence our interactions
                      with others, enables us to lead with empathy,
                      authenticity, and resilience.
                    </p>
                    <ul
                      className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
                      style={{ listStyle: "circle" }}
                    >
                      <li>
                        Develop awareness of your own emotions and how they
                        influence your thoughts and actions. How do your
                        emotions impact your decision-making and interactions
                        with others?
                      </li>
                      <li>
                        Practice empathy by seeking to understand and connect
                        with the emotions of those around you. How can you
                        foster a supportive and emotionally intelligent
                        environment?
                      </li>
                      <li>
                        Enhance your emotional self-regulation skills to manage
                        stress, remain composed in challenging situations, and
                        lead with empathy and resilience.{" "}
                      </li>
                    </ul>
                  </details>

                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Reflecting on your leadership style
                    </summary>
                    <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
                      Harnessing Introspection for Personal Growth
                    </h3>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Regular self-reflection allows us to gain insights into
                      our leadership style, identifying both our successes and
                      areas for refinement. By seeking feedback and engaging in
                      introspection, we continuously evolve as leaders.
                    </p>
                    <ul
                      className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
                      style={{ listStyle: "circle" }}
                    >
                      <li>
                        Regularly engage in self-reflection to gain insights
                        into your leadership style. What are your strengths as a
                        leader, and how can you amplify them?
                      </li>
                      <li>
                        Seek feedback from mentors, colleagues, and team members
                        to gain different perspectives on your leadership
                        approach. What areas can you refine or improve upon?
                      </li>
                      <li>
                        Embrace a mindset of continuous growth and improvement,
                        using self-reflection as a tool for personal and
                        professional development.
                      </li>
                    </ul>
                  </details> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Personal SWOT Analysis</b>
      </h2>
      <p className="mt-4 text-gray-600">
        This activity aims to help you gain a deeper understanding of your
        strengths, weaknesses, opportunities, and threats as a leader. By
        conducting a personal SWOT analysis, you will identify areas of focus
        and leverage your strengths to maximize your leadership potential.
      </p>

      {/* <ul
  className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
  style={{ listStyle: "circle" }}
>
  <p className="mx-6 mt-4 font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    Instructions{" "}
  </p>
  <li className="m-6">
    Reflect on your experiences and characteristics: Take some time to
    think about your past experiences, both in leadership roles and in
    other aspects of your life. Consider your skills, talents, and
    personal qualities that contribute to your effectiveness as a leader.
  </li>
  <li className="m-6">
    Identify your strengths: Think about the unique strengths that set you
    apart as a leader. These could be qualities such as excellent
    communication skills, strategic thinking, problem-solving abilities,
    or a talent for inspiring others.
  </li>
  <li className="m-6">
    Recognize your weaknesses: Be honest with yourself and identify areas
    where you may need improvement. These could be skills you lack,
    personal traits that hinder your leadership effectiveness, or areas
    where you feel less confident.
  </li>
  <li className="m-6">
    Explore opportunities for growth: Consider the opportunities available
    to you for further developing your leadership abilities. These could
    include attending workshops, taking on new responsibilities, seeking
    mentorship, or pursuing additional education or training.
  </li>
  <li className="m-6">
    Evaluate potential threats: Assess the external factors or challenges
    that may pose a threat to your leadership journey. These could be
    things like limited resources, changing market conditions, or
    competition.
  </li>
</ul> */}


<ul className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm" style={{ listStyle: "circle" }}>
  <p className="mx-6 mt-4 font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    Instructions{" "}
  </p>
  <li className="m-6">
    <strong>Understand the Components:</strong> Familiarize yourself with the four elements of SWOT - Strengths, Weaknesses, Opportunities, and Threats.
    <ul className="ml-6" style={{listStyle:'disc'}}>
      <li><strong>Strengths:</strong> Personal attributes or skills that give you an advantage.</li>
      <li><strong>Weaknesses:</strong> Areas where you may have challenges or are at a disadvantage.</li>
      <li><strong>Opportunities:</strong> External factors or situations you can capitalize on or use to your advantage.</li>
      <li><strong>Threats:</strong> External factors or challenges that could hinder your progress.</li>
    </ul>
  </li>
  <li className="m-6">
    <strong>Evaluate Your Strengths, Weaknesses, Opportunities, and Threats:</strong>
    <ul className="ml-6" style={{listStyle:'disc'}}>
      <li><strong>For Strengths:</strong> Consider what you excel at and what separates you from your peers.</li>
      <li><strong>For Weaknesses:</strong> Identify areas for improvement or skills you lack.</li>
      <li><strong>For Opportunities:</strong> Identify trends, networks, and resources you can access.</li>
      <li><strong>For Threats:</strong> Identify competition, resource limitations, or external challenges.</li>
    </ul>
  </li>
  <li className="m-6">
    <strong>Write and Submit Your Analysis:</strong>
    <ul className="ml-6" style={{listStyle:'disc'}}>
      <li>Aim for clarity and conciseness in your response.</li>
      <li>You are expected to write at least 50 words for each component of the SWOT analysis.</li>
      <li>Submit your completed SWOT analysis according to the structure below.</li>
    </ul>
  </li>
</ul>

{status === "Completed" || disableBtn ? (
              <div className="my-4">
                <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">

                  <ul>
                    <li><b>Strengths:</b><br/> {swotSubmission?.strengths}<br/><br/></li>
                    <li><b>Weaknesses:</b><br/> {swotSubmission?.weaknesses}<br/><br/></li>
                    <li><b>Opportunities:</b><br/> {swotSubmission?.opportunities}<br/><br/></li>
                    <li><b>Threats:</b><br/> {swotSubmission?.threats}</li>
                  </ul>

                </p>
              </div>
            ) : (

<div>
<div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Strengths</span>
          </label>
          <textarea
            value={swotSubmission.strengths ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({ ...prev, strengths: e.target.value }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(swotSubmission?.strengths)} / 50 words</small>
        </div>
      </div>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Weaknesses</span>
          </label>
          <textarea
            value={swotSubmission.weaknesses ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({ ...prev, weaknesses: e.target.value }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(swotSubmission?.weaknesses)} / 50 words</small>
        </div>
      </div>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Opportunities</span>
          </label>
          <textarea
            value={swotSubmission.opportunities ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                opportunities: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(swotSubmission?.opportunities)} / 50 words</small>

        </div>
      </div>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Threats</span>
          </label>
          <textarea
            value={swotSubmission.threats ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({ ...prev, threats: e.target.value }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(swotSubmission?.threats )} / 50 words</small>

        </div>
      </div>
      <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={checkBox} 
  onChange={() => {
    setCheckBox(!checkBox); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Do you agree to share your response with a peer for the purpose of receiving constructive feedback</span>
      </div>




      <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={RecheckBox} 
  onChange={() => {
    setRecheckBox(!RecheckBox); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Read your submission carefully before submitting</span>
      </div>


      <button
        className="btn btn-primary mt-8 bg-neutral-50"
        onClick={handleStepSubmission}
        disabled={status === "Completed" || !RecheckBox || isAnySectionInvalid || !checkBox || disableBtn}
      >
        Submit
      </button>




      
</div>
              
            )}


     
    </section>,




<section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Self Reflection Journaling</b>
      </h2>
      <p className="mt-4 text-gray-600">
        Self-reflection is a powerful tool for personal growth, enhancing self-awareness, and deepening our
        understanding of ourselves as leaders. This activity encourages you to engage in introspection and
        introspective writing, allowing you to explore your thoughts, emotions, and experiences in a reflective
        and meaningful way.
      </p>



      <ul className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm" style={{ listStyle: "circle" }}>
  <p className="mx-6 mt-4 font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    Instructions{" "}
  </p>
  <li className="m-6">
    <strong>Choose a Theme or Focus:</strong> This journal should have a specific focus or theme related to your experiences or feelings about leadership. This could be a recent leadership challenge, a significant accomplishment, or reflections on your leadership style.
  </li>
  <li className="m-6">
    <strong>Reflect Deeply:</strong> Go beyond surface-level observations. Dive into your emotions, motivations, and the implications of your experiences. Ask yourself questions like "Why did I react that way?" or "What can I learn from this experience?"
  </li>
  <li className="m-6">
    <strong>Maintain Honesty:</strong> Be truthful in your reflections. The purpose of this journal is personal growth, which can only be achieved through honest self-assessment.
  </li>
  <li className="m-6">
    <strong>Minimum Word Count:</strong> You are expected to write at least 300 words. This length allows for enough depth to truly explore and articulate your thoughts and feelings.
  </li>
</ul>


      {/* <ul
        className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          Instructions{" "}
        </p>

        <li className="m-6">
        Find a quiet and comfortable space: Set aside dedicated time and create a calm environment
where you can focus on your self-reflection without distractions.
        </li>
        <li className="m-6">
        Reflect on the provided question: A self-reflection question has been provided below to guide
your journaling. Take a moment to contemplate the question and its relevance to your personal
leadership journey.
        </li>
        <li className="m-6">
        Dive deeper: As you reflect, consider the question from different angles. Explore how your
thoughts, emotions, and experiences have shaped your understanding of leadership and
influenced your actions and decisions.
        </li>
        <li className="m-6">
        Be self-compassionate: Remember to approach your reflections with self-compassion and
kindness. Embrace any challenges or mistakes as opportunities for growth and learning
        </li>

      </ul> */}


      

{status === "Completed" || disableBtnTwo ? (
              <div className="my-4">
                <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">

                  <ul>
                    <li><b>Response:</b><br/> {swotSubmission2.response}<br/><br/></li>
                  </ul>

                </p>
              </div>
            ) : (

<div>
<div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">How have your past experiences influenced your leadership style and approach?
Reflect on significant moments that have shaped your leadership journey.</span>
          </label>
          <textarea
            value={swotSubmission2.response ?? ""}
            onChange={(e) =>
              setSubmission2((prev) => ({ ...prev, response: e.target.value }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
           <small className="mt-6 text-end text-gray-400"> {countWords(swotSubmission2?.response)} / 300 words</small>
        </div>
      </div>



      <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={RecheckBox2} 
  onChange={() => {
    setRecheckBox2(!RecheckBox2); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Read your submission carefully before submitting</span>
      </div>


      <button
        className="btn btn-primary mt-8 bg-neutral-50"
        onClick={handleStepSubmission2}
        disabled={status === "Completed" || !RecheckBox2 || !areResponseValid || disableBtnTwo }
      >
        Submit
      </button>
</div>
              
            )}


      
    </section>,


<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">

<Confetti numberOfPieces={100} />

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8  lg:gap-16">

    <div className="">
      <div>
        <h2 className="text-xl font-medium sm:text-xl">
          <b> A quick recap </b>
        </h2>
        <p className="mt-4 text-gray-600">
        Congratulations on completing Week 1: Self-Awareness! You have taken
the first step on your journey of leadership growth. Let's take a moment to recap the key learnings from
this week: <br/><br/>
<b>Leadership and Self-Awareness:</b> We explored the significance of self-awareness  in your growth as a leader. Through Ayesha's story, we witnessed the transformative power of
self-awareness in effective leadership.<br/><br/>
<b>Elements of Self-Awareness:</b> We delved into understanding our values and beliefs, recognizing our
strengths and areas for growth, becoming aware of our emotions and their impact, and reflecting on our
leadership style. These elements form the building blocks of self-awareness.<br/><br/>
<b>Personal SWOT Analysis:</b> You engaged in a personal SWOT analysis to gain a deeper understanding of
your strengths, weaknesses, opportunities, and threats as a leader. This activity allowed you to identify
areas of focus and leverage your strengths to maximize your leadership potential.<br/><br/>
<b>Self-Reflection Journaling:</b> Through introspective writing, you explored your thoughts, emotions, and
experiences, reflecting on how your past experiences have influenced your leadership style and
approach. This activity deepened your self-awareness and facilitated personal growth.<br/><br/>

        </p>
        
        Well done on completing Week 1! Get ready for Week 2, where we will dive deeper into the realm of
emotional intelligence. Keep up the great work and continue your journey of becoming a remarkable
leader.
      </div>

      <div className="divider"></div>
      <div>

        <p className="mt-4 text-gray-600">
          Feel free to explore the following resources to help you
          understand more about yourself.
        </p>



        <ul class="flex flex-col" style={{listStyle:"disc"}}>
  <li class="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    The Power of Now by Eckhart Tolle, Chapter 3 – Moving Deeply Into The Now
  </li>
  <li class="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    Emotional Intelligence by Daniel Goleman, Chapter 1 – Emotional Intelligence
  </li>
  <li class="inline-flex items-center gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
     <a  target="_blank" href="https://www.ted.com/talks/brene_brown_the_power_of_vulnerability" className="link link-primary">The Power of Vulnerability by Brene Brown</a> 
  </li>
</ul>
             <div className="w-full flex justify-end items-center pt-6">
             <span className="text-lg">- Team Ascent</span>
             </div>


      </div>
    </div>
  </div>
</div>
</section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "This week’s objectives";

      case 2:
        return "Ayesha’s journey";

      case 3:
        return "Explore the elements of self-awareness";

      case 4:
        return "SWOT Analysis";

        case 5:
          return "Self Journal";

          case 6:
          return "A quick recap";

      default:
        return "Page";
    }
  };

  

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1 }
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              
                                disabled={!nextBtnCondtion}
                            
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Week1;