import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek5 = ({ onImageIndexChange }) => {
  // const slideImages = [
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1702658304/AHbranding/Slide_16_9_-_21_i64zk5.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1702658302/AHbranding/Slide_16_9_-_22_q3uvbv.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1702658301/AHbranding/Slide_16_9_-_24_ltscgi.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1702658303/AHbranding/Slide_16_9_-_23_ciujdn.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1702658303/AHbranding/Slide_16_9_-_25_zrubra.png',

  // ];

  // const handleImageIndexChange = (index) => {
  //   if (onImageIndexChange) {
  //     onImageIndexChange(index);
  //   }
  // };


  const storedImages = localStorage.getItem('week5Images');
  const slideImages = storedImages ? JSON.parse(storedImages) : [];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek5;
