import React from 'react';

const Waiting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" className='w-8/12'> 
  <g id="freepik--background-complete--inject-5">
    <path
      d="M319.32,252.3l-123-1.15L159,251l-10.14,0-2.64,0c-.59,0-.9,0-.9.05a5.27,5.27,0,0,0,.9.06l2.64.06,10.13.12,37.29.2,123,.08c48,0,91.51.05,123,.28,15.74.11,28.48.26,37.28.42,4.4.08,7.82.17,10.14.26a17.65,17.65,0,0,1,3.53.26,19.45,19.45,0,0,1-3.53.22c-2.32.07-5.74.12-10.14.16-8.81.07-21.55.09-37.29.05C410.82,253.16,367.34,252.79,319.32,252.3Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M318.36,262.84,197,243.11l-36.8-5.8-10-1.5-2.61-.37a5.43,5.43,0,0,0-.89-.08,5.22,5.22,0,0,0,.87.19l2.6.46,10,1.66L197,243.5l121.53,18.64C365.92,269.38,408.87,276,439.93,281c15.53,2.48,28.09,4.55,36.76,6,4.33.75,7.7,1.35,10,1.79a18.09,18.09,0,0,1,3.45.8,20.16,20.16,0,0,1-3.53-.31c-2.3-.29-5.69-.75-10-1.38-8.71-1.25-21.3-3.15-36.84-5.58C408.62,277.51,365.72,270.57,318.36,262.84Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M315.72,273.1,199.08,235l-35.4-11.36-9.65-3-2.52-.76a5.81,5.81,0,0,0-.87-.22,4.61,4.61,0,0,0,.83.33l2.49.85,9.6,3.17L199,235.41l117,37c45.67,14.4,87,27.52,116.88,37.19,14.93,4.83,27,8.8,35.32,11.6,4.16,1.4,7.38,2.51,9.56,3.29a18.12,18.12,0,0,1,3.27,1.32,19.05,19.05,0,0,1-3.43-.84c-2.23-.64-5.5-1.61-9.7-2.9-8.4-2.56-20.52-6.37-35.47-11.14C402.47,301.41,361.23,288,315.72,273.1Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M311.45,282.82l-109-55.42L169.3,210.77l-9-4.46-2.37-1.13a7,7,0,0,0-.82-.36,4.67,4.67,0,0,0,.77.45l2.32,1.22,9,4.6,33.11,16.65,109.54,54.45c42.77,21.22,81.47,40.5,109.4,54.61,14,7.06,25.24,12.82,33,16.86,3.88,2,6.88,3.61,8.9,4.71a18.06,18.06,0,0,1,3,1.8,20.17,20.17,0,0,1-3.26-1.36c-2.09-1-5.16-2.44-9.1-4.35-7.88-3.82-19.23-9.43-33.23-16.43C392.54,324,354,304.48,311.45,282.82Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M305.66,291.77l-98.77-71.3-30-21.47-8.21-5.77-2.16-1.48a4.7,4.7,0,0,0-.75-.47,5.29,5.29,0,0,0,.69.56l2.09,1.55c1.88,1.37,4.61,3.36,8.12,5.9l30,21.49c25.48,18,60.61,42.92,99.44,70.42s74,52.37,99.27,70.56c12.66,9.09,22.88,16.49,29.89,21.66,3.51,2.58,6.22,4.61,8,6a18,18,0,0,1,2.7,2.23,19.14,19.14,0,0,1-3-1.84c-1.91-1.27-4.71-3.18-8.29-5.67-7.17-5-17.48-12.24-30.18-21.28C379.12,344.79,344.19,319.62,305.66,291.77Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M298.51,299.73l-86.16-85.36-26.24-25.75-7.19-6.94L177,179.89a4.61,4.61,0,0,0-.67-.58,6.93,6.93,0,0,0,.59.65l1.82,1.86,7.08,7.05,26.22,25.77,87,84.59c34,33,64.67,62.92,86.77,84.71,11.05,10.89,19.95,19.75,26.06,25.92,3.05,3.08,5.4,5.49,7,7.14a18.86,18.86,0,0,1,2.31,2.62,18.48,18.48,0,0,1-2.67-2.27c-1.69-1.55-4.14-3.86-7.28-6.86-6.29-6-15.31-14.73-26.41-25.58C362.59,363.21,332.12,333.07,298.51,299.73Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M290.18,306.51l-71.45-97.35-21.81-29.39c-2.58-3.44-4.6-6.11-6-8l-1.59-2a4.84,4.84,0,0,0-.57-.68,6.67,6.67,0,0,0,.48.74l1.5,2.11,5.86,8,21.79,29.41,72.36,96.71C319,343.82,344.59,378,362.93,402.89c9.17,12.43,16.55,22.53,21.59,29.54,2.52,3.51,4.46,6.25,5.75,8.12a17.72,17.72,0,0,1,1.86,2.93,18.2,18.2,0,0,1-2.27-2.64c-1.42-1.79-3.47-4.44-6.1-7.88-5.25-6.87-12.76-16.87-22-29.26C343.32,378.91,318,344.53,290.18,306.51Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M280.86,312c-21.45-41.79-40.85-79.6-54.91-107-7.09-13.64-12.83-24.68-16.81-32.35l-4.63-8.76-1.24-2.27a6.25,6.25,0,0,0-.45-.75,4.49,4.49,0,0,0,.35.8l1.14,2.31c1.05,2.05,2.56,5,4.5,8.83,4,7.67,9.7,18.72,16.78,32.36L281.5,311.63c21.86,41.56,41.59,79.22,55.71,106.57,7.06,13.67,12.72,24.76,16.57,32.46,1.93,3.84,3.4,6.84,4.38,8.89a17,17,0,0,1,1.36,3.18,18.45,18.45,0,0,1-1.82-3c-1.11-2-2.72-4.91-4.76-8.7-4.07-7.59-9.89-18.61-17-32.25C321.69,391.53,302.25,353.73,280.86,312Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M270.77,315.9l-36.9-114.08-11.36-34.51c-1.36-4-2.43-7.19-3.16-9.36l-.85-2.43a4.73,4.73,0,0,0-.33-.81,5.68,5.68,0,0,0,.22.85c.18.58.43,1.39.75,2.45l3,9.41c2.69,8.18,6.56,20,11.33,34.52l38,113.74c14.86,44.38,28.25,84.59,37.76,113.76C314,444,317.78,455.84,320.34,464c1.28,4.09,2.25,7.28,2.88,9.45a17.6,17.6,0,0,1,.83,3.34,16.44,16.44,0,0,1-1.32-3.19c-.78-2.12-1.89-5.27-3.29-9.32-2.8-8.12-6.76-19.89-11.58-34.45C298.21,400.74,285.14,360.43,270.77,315.9Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M260.17,318.25c-7-46.19-13.37-88-18-118.29-2.37-15.11-4.29-27.33-5.63-35.82l-1.6-9.73c-.19-1.09-.34-1.93-.45-2.53a5.63,5.63,0,0,0-.19-.85,5.15,5.15,0,0,0,.08.87c.08.6.2,1.45.35,2.54.33,2.26.82,5.54,1.45,9.75,1.32,8.49,3.24,20.72,5.6,35.83,4.89,30.25,11.63,72,19.09,118.13s14.2,87.85,18.87,118.11c2.34,15.13,4.17,27.38,5.37,35.86.6,4.24,1.05,7.53,1.32,9.77a17,17,0,0,1,.28,3.43A17.22,17.22,0,0,1,286,482c-.43-2.21-1-5.48-1.74-9.7-1.45-8.44-3.46-20.67-5.86-35.79C273.54,406.23,267.15,364.43,260.17,318.25Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M249.32,319c.43-46.69.82-89,1.11-119.57.06-15.28.12-27.65.15-36.24,0-4.26,0-7.57,0-9.85,0-1.11,0-2,0-2.56a8.56,8.56,0,0,0-.05-.88,4.93,4.93,0,0,0-.06.87c0,.61,0,1.46-.06,2.57,0,2.28-.07,5.59-.12,9.85,0,8.59-.11,21-.18,36.24,0,30.62,0,72.88,0,119.57s0,88.94-.21,119.53c-.1,15.3-.25,27.68-.41,36.24-.08,4.27-.17,7.6-.26,9.85a18.79,18.79,0,0,1-.26,3.43,17,17,0,0,1-.24-3.43c-.06-2.26-.12-5.58-.16-9.86-.09-8.55-.11-20.94-.08-36.23C248.49,407.9,248.85,365.64,249.32,319Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M238.48,318.05c7.89-46.06,15-87.74,20.22-118,2.51-15.08,4.53-27.29,5.94-35.77.67-4.2,1.19-7.48,1.55-9.74.16-1.09.28-1.93.37-2.53s.1-.87.09-.87a4.21,4.21,0,0,0-.2.85c-.12.6-.27,1.43-.48,2.52l-1.69,9.71c-1.42,8.48-3.46,20.69-6,35.77-4.89,30.26-11.64,72-19.11,118.13S225,406,219.88,436.21c-2.55,15.09-4.67,27.3-6.2,35.73-.76,4.21-1.38,7.48-1.83,9.69A16.75,16.75,0,0,1,211,485a18.67,18.67,0,0,1,.32-3.43c.29-2.23.76-5.53,1.41-9.76,1.28-8.46,3.23-20.7,5.71-35.81C223.44,405.77,230.55,364.08,238.48,318.05Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M227.92,315.48,267,202.1c4.92-14.51,8.91-26.26,11.67-34.42l3.1-9.38c.34-1.05.59-1.87.78-2.45a6.71,6.71,0,0,0,.23-.84,4.17,4.17,0,0,0-.34.81c-.21.57-.5,1.37-.87,2.42-.76,2.16-1.85,5.3-3.26,9.33L266.62,202c-9.74,29.13-23.17,69.32-38,113.73s-28.26,84.58-38.19,113.61c-5,14.52-9,26.25-11.92,34.34-1.44,4-2.58,7.18-3.38,9.29a17.89,17.89,0,0,1-1.35,3.19,16.33,16.33,0,0,1,.86-3.34c.65-2.17,1.65-5.35,3-9.43,2.64-8.16,6.55-19.95,11.45-34.48C198.84,399.81,212.63,359.72,227.92,315.48Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M217.92,311.34c22.23-41.39,42.36-78.85,57-106,7.2-13.58,13-24.57,17.09-32.2l4.58-8.79,1.17-2.3a5.27,5.27,0,0,0,.36-.8,4.66,4.66,0,0,0-.46.75l-1.26,2.25c-1.09,2-2.68,5-4.72,8.72l-17.12,32.19-55.95,106.5c-21.81,41.58-41.62,79.21-56.12,106.36-7.25,13.58-13.17,24.54-17.33,32.09-2.07,3.77-3.7,6.69-4.84,8.66a17.94,17.94,0,0,1-1.84,2.94,17,17,0,0,1,1.4-3.17c1-2,2.49-5,4.46-8.85,3.93-7.66,9.7-18.7,16.89-32.3C175.56,390.17,195.66,352.69,217.92,311.34Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M208.71,305.72l73.29-96,22.07-29.21,5.94-8,1.52-2.09a5.17,5.17,0,0,0,.48-.74,5.89,5.89,0,0,0-.57.68l-1.6,2-6.07,7.9-22.08,29.19-72.4,96.69c-28.23,37.76-53.84,71.91-72.54,96.52-9.34,12.31-17,22.24-22.27,29.06-2.66,3.42-4.74,6-6.17,7.82a18.64,18.64,0,0,1-2.3,2.63,17.09,17.09,0,0,1,1.89-2.92c1.31-1.86,3.27-4.58,5.83-8.06,5.11-7,12.59-17,21.88-29.34C154.19,377.15,180.07,343.18,208.71,305.72Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M200.51,298.78,288.28,215l26.48-25.52,7.14-7,1.84-1.84a4.54,4.54,0,0,0,.6-.65,4.45,4.45,0,0,0-.68.58l-1.91,1.77-7.25,6.88L288,214.71l-87,84.57c-33.92,33-64.69,62.89-87.1,84.38-11.2,10.75-20.31,19.41-26.65,25.34-3.17,3-5.65,5.26-7.35,6.79A19.43,19.43,0,0,1,77.23,418a18.45,18.45,0,0,1,2.33-2.6c1.59-1.64,4-4,7-7.08,6.17-6.11,15.15-14.88,26.31-25.68C135.23,361.1,166.23,331.46,200.51,298.78Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M193.52,290.68l100.12-69.51L323.88,200l8.17-5.83,2.11-1.54a6.84,6.84,0,0,0,.7-.55,5.5,5.5,0,0,0-.76.47L331.93,194l-8.27,5.7-30.25,21.18L194,291.25c-38.8,27.5-74,52.35-99.55,70.19-12.78,8.93-23.17,16.1-30.38,21-3.61,2.45-6.42,4.34-8.35,5.59a17.83,17.83,0,0,1-3,1.81,17.8,17.8,0,0,1,2.72-2.21c1.83-1.38,4.56-3.38,8.09-5.93,7.07-5.1,17.35-12.41,30.1-21.39C119.06,342.37,154.43,317.78,193.52,290.68Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M187.92,281.62,298,228.19l33.28-16.35,9-4.51,2.33-1.2a4.67,4.67,0,0,0,.77-.45,7.29,7.29,0,0,0-.82.35l-2.38,1.11-9.08,4.37-33.29,16.33L188.25,282.25c-42.74,21.27-81.48,40.47-109.61,54.21-14.06,6.87-25.47,12.38-33.39,16.13-4,1.87-7,3.31-9.14,4.25a18.18,18.18,0,0,1-3.27,1.33,19,19,0,0,1,3-1.77c2-1.08,5-2.64,8.95-4.63,7.8-4,19.13-9.62,33.17-16.55C106.06,321.36,145,302.45,187.92,281.62Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M183.85,271.83,301.2,235.89l35.51-11.06,9.63-3.08,2.49-.83a4.63,4.63,0,0,0,.84-.32,5.81,5.81,0,0,0-.87.22l-2.53.73-9.68,2.93-35.51,11-117,37c-45.66,14.47-87,27.51-117,36.77-15,4.63-27.15,8.33-35.58,10.82-4.21,1.25-7.49,2.19-9.72,2.8a18.85,18.85,0,0,1-3.44.82,17,17,0,0,1,3.29-1.29c2.18-.76,5.41-1.84,9.58-3.2,8.35-2.73,20.46-6.58,35.44-11.28C96.58,298.55,138,285.82,183.85,271.83Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M181.42,261.52,303.13,244l36.85-5.5,10-1.56,2.6-.44a4.68,4.68,0,0,0,.88-.19,7.51,7.51,0,0,0-.9.08l-2.61.34-10,1.42-36.86,5.46L181.53,262.22C134.1,269.53,91.14,276.08,60,280.64c-15.57,2.28-28.17,4.07-36.9,5.24-4.36.59-7.75,1-10,1.29a18.28,18.28,0,0,1-3.53.27,19.41,19.41,0,0,1,3.45-.76c2.28-.42,5.65-1,10-1.7,8.69-1.41,21.27-3.37,36.82-5.71C90.91,274.57,133.92,268.34,181.42,261.52Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M180.68,251l123,1,37.29.13,10.13,0,2.64,0a6.3,6.3,0,0,0,.9,0,5.27,5.27,0,0,0-.9-.06l-2.64-.06L341,251.76l-37.29-.15-123,.07c-48,.07-91.51.06-123-.13-15.74-.09-28.48-.22-37.28-.38-4.4-.07-7.82-.16-10.14-.24a18.67,18.67,0,0,1-3.53-.26,19.74,19.74,0,0,1,3.53-.23c2.32-.06,5.74-.12,10.14-.16,8.81-.09,21.55-.13,37.29-.1C89.18,250.23,132.66,250.55,180.68,251Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M181.62,240.44,303,260l36.81,5.75,10,1.5,2.61.35a5,5,0,0,0,.9.09,6,6,0,0,0-.88-.19l-2.6-.46-10-1.64-36.81-5.79L181.51,241.14C134.06,234,91.1,227.38,60,222.44c-15.53-2.46-28.09-4.52-36.76-6-4.34-.74-7.7-1.34-10-1.78a18,18,0,0,1-3.45-.79,18.92,18.92,0,0,1,3.53.31c2.3.28,5.69.74,10,1.36,8.72,1.24,21.31,3.13,36.85,5.53C91.35,225.88,134.26,232.76,181.62,240.44Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M184.25,230.18l116.69,37.91,35.42,11.32,9.66,3,2.52.75a5.49,5.49,0,0,0,.87.22,5.57,5.57,0,0,0-.84-.33l-2.49-.84-9.6-3.16-35.41-11.34L184,230.85c-45.7-14.35-87-27.41-116.93-37-14.94-4.81-27-8.76-35.34-11.56-4.16-1.39-7.39-2.5-9.56-3.28a18.66,18.66,0,0,1-3.28-1.31,19,19,0,0,1,3.44.84c2.22.63,5.49,1.6,9.7,2.88,8.4,2.56,20.53,6.35,35.49,11.1C97.47,202,138.72,215.34,184.25,230.18Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M188.51,220.45l109.1,55.28,33.15,16.6,9,4.45,2.37,1.13a7.86,7.86,0,0,0,.82.35,4.67,4.67,0,0,0-.77-.45l-2.32-1.22-9-4.58-33.14-16.62L188.18,221.08c-42.8-21.17-81.52-40.4-109.47-54.48-14-7-25.26-12.78-33-16.81-3.88-2-6.89-3.6-8.91-4.7a18.06,18.06,0,0,1-3-1.8A20.17,20.17,0,0,1,37,144.65c2.1,1,5.17,2.43,9.11,4.33,7.88,3.81,19.24,9.42,33.25,16.4C107.37,179.34,146,198.85,188.51,220.45Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M194.29,211.5l98.86,71.17,30.07,21.43,8.22,5.77,2.16,1.47a7.23,7.23,0,0,0,.75.48,4.34,4.34,0,0,0-.69-.56l-2.09-1.56-8.13-5.89-30.05-21.44-99.53-70.3c-38.87-27.41-74-52.29-99.37-70.44C81.82,132.55,71.6,125.16,64.58,120c-3.51-2.58-6.23-4.6-8-6a19.71,19.71,0,0,1-2.7-2.23,18.44,18.44,0,0,1,3,1.83c1.92,1.27,4.71,3.18,8.3,5.66,7.17,5,17.49,12.22,30.2,21.25C120.76,158.56,155.72,183.69,194.29,211.5Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M201.43,203.53l86.27,85.25L314,314.49l7.2,6.94,1.89,1.79a7.23,7.23,0,0,0,.67.58,4.14,4.14,0,0,0-.59-.66l-1.82-1.85-7.09-7.05L288,288.51,200.91,204c-34-33-64.75-62.83-86.88-84.6C103,108.54,94.05,99.7,87.94,93.54c-3.06-3.08-5.41-5.49-7-7.14a18.36,18.36,0,0,1-2.31-2.61,18.52,18.52,0,0,1,2.67,2.26C83,87.6,85.46,89.91,88.6,92.9c6.29,6,15.33,14.72,26.44,25.55C137.26,140.12,167.78,170.23,201.43,203.53Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M209.75,196.73,281.33,294l21.84,29.37,6,7.95,1.58,2a5.89,5.89,0,0,0,.57.68,4.21,4.21,0,0,0-.48-.74q-.52-.75-1.5-2.1l-5.87-8-21.82-29.38-72.49-96.62c-28.32-37.69-53.91-71.86-72.28-96.71C127.69,88,120.3,77.94,115.25,70.93c-2.53-3.51-4.47-6.24-5.76-8.11a17.47,17.47,0,0,1-1.87-2.93,18.55,18.55,0,0,1,2.28,2.64c1.42,1.78,3.48,4.43,6.1,7.87,5.26,6.86,12.79,16.85,22,29.23C156.52,124.4,181.85,158.75,209.75,196.73Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M219.07,191.28l55.05,107L291,330.55l4.65,8.76,1.24,2.26a6,6,0,0,0,.45.76,4.71,4.71,0,0,0-.35-.81c-.28-.54-.65-1.31-1.15-2.3l-4.5-8.83-16.83-32.34c-14.35-27.27-34.16-64.88-56.05-106.45s-41.69-79.16-55.84-106.49C155.5,71.44,149.82,60.36,146,52.67c-1.93-3.85-3.41-6.84-4.38-8.89a16.75,16.75,0,0,1-1.37-3.18,19.41,19.41,0,0,1,1.82,3c1.11,2,2.72,4.91,4.77,8.7,4.09,7.58,9.91,18.58,17,32.22C178.13,111.75,197.61,149.53,219.07,191.28Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M229.15,187.32c14.46,44.53,27.56,84.82,37,114l11.4,34.5c1.37,4,2.44,7.19,3.17,9.36l.86,2.42a5.17,5.17,0,0,0,.33.82,5.06,5.06,0,0,0-.22-.85l-.76-2.46c-.7-2.18-1.72-5.34-3-9.4l-11.38-34.51c-9.76-29.12-23.24-69.3-38.13-113.69S200.1,103,190.55,73.83c-4.78-14.58-8.59-26.39-11.16-34.58-1.29-4.09-2.26-7.27-2.89-9.44a16.18,16.18,0,0,1-.84-3.35,18.1,18.1,0,0,1,1.32,3.2c.78,2.12,1.9,5.26,3.3,9.32,2.81,8.1,6.79,19.87,11.63,34.43C201.59,102.52,214.72,142.81,229.15,187.32Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M239.74,185c7.08,46.19,13.49,88,18.13,118.28,2.4,15.1,4.34,27.32,5.68,35.81.7,4.2,1.24,7.47,1.62,9.73q.29,1.62.45,2.52a5.61,5.61,0,0,0,.19.86,5.08,5.08,0,0,0-.08-.87c-.08-.61-.2-1.45-.35-2.54L263.92,339c-1.34-8.49-3.27-20.72-5.65-35.82-4.93-30.25-11.73-72-19.24-118.11S224.71,97.22,220,67c-2.36-15.12-4.21-27.37-5.42-35.85-.61-4.24-1.05-7.53-1.33-9.77a17,17,0,0,1-.28-3.43,18.21,18.21,0,0,1,.78,3.35c.43,2.22,1,5.49,1.76,9.71,1.46,8.44,3.48,20.66,5.9,35.78C226.26,97,232.7,138.79,239.74,185Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M250.59,184.22c-.37,46.7-.71,89-1,119.58,0,15.28-.08,27.65-.11,36.24,0,4.25,0,7.56,0,9.85,0,1.1,0,1.95,0,2.56a8.17,8.17,0,0,0,0,.87,4.79,4.79,0,0,0,.06-.87c0-.61,0-1.46.06-2.56,0-2.29.06-5.6.11-9.85,0-8.59.07-21,.13-36.24,0-30.63-.09-72.88-.15-119.58s-.11-88.93.06-119.53c.08-15.3.21-27.68.36-36.23.08-4.28.16-7.6.25-9.85a16.78,16.78,0,0,1,.26-3.44,19.19,19.19,0,0,1,.24,3.44c.07,2.25.13,5.57.17,9.85.1,8.56.14,20.94.12,36.24C251.3,95.29,251,137.56,250.59,184.22Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M261.44,185.13c-7.84,46.07-14.93,87.76-20.07,118-2.49,15.09-4.5,27.3-5.9,35.78-.66,4.21-1.17,7.48-1.53,9.74-.16,1.09-.28,1.94-.36,2.54a4.73,4.73,0,0,0-.09.87,6.27,6.27,0,0,0,.2-.85c.11-.6.26-1.44.46-2.53.4-2.25,1-5.51,1.69-9.71,1.4-8.48,3.43-20.69,5.93-35.78,4.85-30.26,11.55-72,19-118.15S274.81,97.15,279.88,67c2.52-15.1,4.63-27.31,6.15-35.74.76-4.21,1.37-7.48,1.82-9.69a17.9,17.9,0,0,1,.81-3.36,17,17,0,0,1-.31,3.44c-.29,2.23-.76,5.52-1.4,9.76-1.27,8.46-3.2,20.7-5.67,35.81C276.36,97.39,269.31,139.09,261.44,185.13Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M272,187.68c-15.21,44.3-29,84.38-38.94,113.44-4.9,14.51-8.86,26.27-11.62,34.43-1.33,4.05-2.37,7.21-3.09,9.38-.33,1.06-.59,1.87-.77,2.45a6.52,6.52,0,0,0-.23.85,4.17,4.17,0,0,0,.34-.81c.21-.58.49-1.38.87-2.42l3.24-9.34,11.65-34.42c9.7-29.14,23.07-69.35,37.86-113.78s28.15-84.62,38-113.66c4.94-14.53,9-26.26,11.87-34.35,1.43-4.05,2.57-7.18,3.37-9.3A18.48,18.48,0,0,1,325.93,27a16.77,16.77,0,0,1-.85,3.35c-.65,2.16-1.65,5.34-3,9.42-2.63,8.17-6.52,20-11.4,34.5C301,103.32,287.23,143.42,272,187.68Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M282,191.82c-22.19,41.41-42.26,78.9-56.81,106.06-7.19,13.59-13,24.59-17.06,32.23-2,3.8-3.51,6.75-4.57,8.79-.5,1-.88,1.76-1.16,2.3a5.58,5.58,0,0,0-.36.81,6.08,6.08,0,0,0,.46-.76c.3-.53.71-1.28,1.25-2.25l4.71-8.73,17.08-32.21L281.37,191.5c21.75-41.61,41.5-79.26,56-106.43,7.23-13.59,13.13-24.56,17.28-32.11,2.07-3.78,3.69-6.7,4.83-8.67a17.36,17.36,0,0,1,1.84-2.94,18.9,18.9,0,0,1-1.4,3.17c-1,2-2.49,5-4.45,8.85C351.53,61,345.78,72.08,338.6,85.7,324.26,112.93,304.21,150.43,282,191.82Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M291.22,197.42l-73.17,96.14c-9.29,12.32-16.8,22.3-22,29.23-2.56,3.46-4.56,6.14-5.93,8l-1.52,2.09a6.14,6.14,0,0,0-.49.74,5.23,5.23,0,0,0,.58-.68l1.6-2c1.4-1.84,3.44-4.49,6-7.9l22.05-29.23L290.64,197c28.18-37.8,53.75-72,72.41-96.62C372.38,88.07,380,78.13,385.28,71.3c2.66-3.41,4.73-6,6.17-7.82a18.29,18.29,0,0,1,2.29-2.63,18.37,18.37,0,0,1-1.88,2.92c-1.31,1.86-3.27,4.58-5.82,8.07-5.11,7-12.57,17-21.84,29.37C345.65,125.93,319.81,159.92,291.22,197.42Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M299.43,204.36l-87.67,83.9-26.43,25.55-7.14,7-1.83,1.84a5.16,5.16,0,0,0-.6.65,4.84,4.84,0,0,0,.67-.57l1.91-1.77,7.24-6.9c6.28-6.05,15.31-14.76,26.46-25.53l86.88-84.67c33.88-33.08,64.61-63,87-84.5C397.1,108.6,406.19,99.94,412.53,94c3.16-3,5.64-5.26,7.34-6.8a19.09,19.09,0,0,1,2.68-2.25,17,17,0,0,1-2.33,2.6c-1.58,1.64-4,4-7,7.09-6.15,6.12-15.13,14.91-26.27,25.71C364.63,142,333.67,171.64,299.43,204.36Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M306.43,212.44l-100,69.63-30.21,21.25L168,309.15l-2.1,1.54a5.93,5.93,0,0,0-.7.56,5.5,5.5,0,0,0,.76-.47l2.17-1.46,8.26-5.71,30.22-21.23L306,211.87c38.76-27.55,73.9-52.44,99.45-70.31,12.78-8.94,23.15-16.13,30.36-21,3.6-2.46,6.42-4.35,8.34-5.61a17.83,17.83,0,0,1,3-1.81,18,18,0,0,1-2.71,2.21c-1.84,1.38-4.56,3.39-8.09,5.94-7.06,5.11-17.34,12.44-30.07,21.43C380.82,160.66,345.49,185.3,306.43,212.44Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M312,221.49,202,275.07l-33.25,16.39-9,4.52-2.33,1.21a4.33,4.33,0,0,0-.77.44,5.78,5.78,0,0,0,.82-.35l2.38-1.11,9.08-4.39,33.26-16.37c28-14,66.74-33.24,109.49-54.55s81.43-40.57,109.54-54.34c14.06-6.88,25.46-12.41,33.37-16.17,3.95-1.88,7-3.31,9.14-4.26a20.17,20.17,0,0,1,3.26-1.34,17.66,17.66,0,0,1-3,1.78c-2,1.08-5.05,2.65-8.95,4.64-7.8,4-19.12,9.65-33.14,16.59C393.85,181.65,355,200.62,312,221.49Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M316.12,231.29l-117.3,36.08-35.49,11.11-9.63,3.09-2.49.83a4.63,4.63,0,0,0-.84.32,5.49,5.49,0,0,0,.87-.22l2.53-.73,9.67-2.94,35.5-11.08,117-37.13c45.63-14.53,87-27.62,117-36.92,15-4.65,27.15-8.37,35.57-10.87,4.21-1.25,7.49-2.2,9.72-2.81a18.64,18.64,0,0,1,3.44-.82,19.29,19.29,0,0,1-3.29,1.29c-2.18.76-5.41,1.84-9.58,3.21-8.35,2.74-20.45,6.61-35.42,11.32C403.36,204.45,361.91,217.24,316.12,231.29Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M318.56,241.59,196.88,259.28,160,264.82l-10,1.58-2.6.44a6,6,0,0,0-.88.19,5.44,5.44,0,0,0,.9-.08l2.61-.34,10-1.43,36.85-5.51,121.51-18.78c47.42-7.37,90.37-14,121.5-18.57,15.56-2.3,28.16-4.11,36.88-5.29,4.36-.59,7.75-1,10.05-1.29a19.84,19.84,0,0,1,3.54-.29,18.25,18.25,0,0,1-3.46.77c-2.28.42-5.64,1-10,1.71-8.68,1.43-21.25,3.4-36.81,5.76C409.06,228.43,366.06,234.72,318.56,241.59Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M300.69,409.55a25.31,25.31,0,0,0-3-6.15,8.5,8.5,0,0,0-5.58-3.63,5.19,5.19,0,0,0-5.52,3.11c-.32-4.87-1-9.87-3.45-14.1s-7-7.55-11.86-7.25c-4.59.28-8.53,3.69-10.71,7.74s-2.94,8.7-3.65,13.25c-1.74-2.14-5.42-1.47-7.24.6s-2.28,5-2.66,7.7h54.78Z"
      style={{ fill: "#f5f5f5" }}
    />
    <path
      d="M230.9,112c-1.44-3.84-2.91-7.73-5.33-11s-5.95-6-10-6.52S207,96.1,205.64,100c-.58-8.75-1.82-17.72-6.2-25.31s-12.54-13.56-21.29-13c-8.24.5-15.3,6.63-19.23,13.9s-5.27,15.61-6.56,23.77c-3.12-3.83-9.72-2.63-13,1.09s-4.09,8.93-4.78,13.83H233Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M398.68,96.14A31.12,31.12,0,0,0,395,88.57a10.51,10.51,0,0,0-6.88-4.48c-2.79-.34-5.88,1.17-6.79,3.83-.4-6-1.25-12.15-4.25-17.35s-8.6-9.3-14.6-8.93c-5.65.34-10.5,4.55-13.19,9.53s-3.61,10.71-4.49,16.3c-2.15-2.63-6.67-1.8-8.91.75s-2.8,6.12-3.28,9.48h67.44Z"
      style={{ fill: "#f5f5f5" }}
    />
    <path
      d="M293.62,54.46a20.25,20.25,0,0,0-2.41-5,6.87,6.87,0,0,0-4.54-3A4.23,4.23,0,0,0,282.18,49c-.26-4-.82-8-2.8-11.46s-5.69-6.14-9.65-5.9c-3.73.23-6.93,3-8.71,6.3s-2.39,7.07-3,10.77c-1.42-1.74-4.41-1.19-5.88.49s-1.86,4-2.17,6.26h44.55Z"
      style={{ fill: "#f5f5f5" }}
    />
    <path
      d="M126.57,60.92a20.79,20.79,0,0,0-2.41-5,6.89,6.89,0,0,0-4.54-3,4.21,4.21,0,0,0-4.49,2.53c-.27-4-.83-8-2.81-11.47s-5.68-6.14-9.64-5.9c-3.74.23-6.94,3-8.71,6.3S91.58,51.5,91,55.2c-1.41-1.74-4.4-1.19-5.88.49S83.26,59.74,82.94,62H127.5Z"
      style={{ fill: "#fafafa" }}
    />
    <path
      d="M304.58,132.91a20.61,20.61,0,0,0-2.41-5,6.94,6.94,0,0,0-4.54-3,4.22,4.22,0,0,0-4.49,2.53c-.26-4-.83-8-2.81-11.46s-5.68-6.14-9.64-5.9c-3.74.23-6.94,3-8.71,6.3s-2.39,7.07-3,10.76c-1.42-1.73-4.41-1.19-5.89.5s-1.85,4-2.16,6.26h44.55Z"
      style={{ fill: "#fafafa" }}
    />
    <path
      d="M467,63.27a20.61,20.61,0,0,0-2.41-5,6.94,6.94,0,0,0-4.55-3,4.22,4.22,0,0,0-4.48,2.53c-.27-4-.83-8-2.81-11.46s-5.68-6.14-9.64-5.9c-3.74.23-6.94,3-8.72,6.3s-2.38,7.07-3,10.77c-1.41-1.74-4.4-1.2-5.88.49s-1.85,4-2.17,6.26H467.9Z"
      style={{ fill: "#fafafa" }}
    />
    <path
      d="M407.23,464a29.18,29.18,0,0,0-3.47-7.2,9.92,9.92,0,0,0-6.53-4.24c-2.65-.33-5.59,1.1-6.45,3.63-.38-5.7-1.19-11.54-4-16.49s-8.18-8.83-13.88-8.49c-5.37.33-10,4.32-12.53,9.06s-3.44,10.18-4.28,15.5c-2-2.5-6.33-1.72-8.46.71s-2.67,5.81-3.12,9h64.1Z"
      style={{ fill: "#fafafa" }}
    />
    <path
      d="M78.76,405.91a25.59,25.59,0,0,0-3-6.23A8.64,8.64,0,0,0,70.11,396a5.26,5.26,0,0,0-5.59,3.15c-.33-4.93-1-10-3.49-14.27s-7.07-7.64-12-7.34c-4.65.29-8.63,3.74-10.85,7.84s-3,8.8-3.69,13.4c-1.76-2.16-5.49-1.48-7.33.62s-2.3,5-2.69,7.79H79.91Z"
      style={{ fill: "#fafafa" }}
    />
    <path
      d="M464,189.71a40.23,40.23,0,0,0-4.76-9.88c-2.17-3-5.33-5.4-9-5.84s-7.68,1.52-8.87,5c-.52-7.82-1.63-15.85-5.55-22.65s-11.23-12.13-19.06-11.65c-7.38.45-13.7,5.93-17.21,12.44s-4.72,14-5.87,21.28c-2.8-3.43-8.71-2.35-11.63,1s-3.66,8-4.28,12.37h88Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M223.39,445.88c-1.52-4.08-3.08-8.21-5.64-11.72s-6.32-6.38-10.63-6.91-9.1,1.8-10.51,5.91c-.62-9.27-1.93-18.78-6.57-26.83S176.73,392,167.46,392.52c-8.75.53-16.24,7-20.4,14.74s-5.59,16.56-7,25.21c-3.31-4.06-10.31-2.79-13.77,1.16s-4.34,9.46-5.07,14.66H225.57Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M105.17,298.29a32.13,32.13,0,0,0-3.77-7.8,10.73,10.73,0,0,0-7.08-4.61c-2.88-.35-6.06,1.2-7,3.94-.41-6.18-1.29-12.52-4.38-17.88s-8.87-9.58-15-9.2c-5.83.35-10.82,4.68-13.59,9.82s-3.73,11-4.64,16.8c-2.21-2.71-6.87-1.86-9.18.77s-2.89,6.31-3.38,9.77h69.52Z"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M427.18,384.71a39.2,39.2,0,0,0-4.64-9.62c-2.11-2.89-5.19-5.25-8.73-5.68s-7.48,1.48-8.63,4.86c-.51-7.62-1.59-15.44-5.41-22s-10.93-11.82-18.55-11.35c-7.18.44-13.34,5.78-16.76,12.11s-4.59,13.61-5.71,20.72c-2.73-3.34-8.48-2.29-11.32.95s-3.56,7.77-4.17,12H429Z"
      style={{ fill: "#ebebeb" }}
    />
  </g>
  <g id="freepik--Lines--inject-5">
    <path
      d="M407.85,102.63c.08.12-16.07,11-36.06,24.37S335.51,151.07,335.43,151s16.07-11,36.07-24.38S407.77,102.51,407.85,102.63Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M401.93,125.54c.08.12-5.91,4.3-13.38,9.33s-13.58,9-13.66,8.9,5.9-4.3,13.37-9.33S401.85,125.42,401.93,125.54Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M84.91,331.55c-.08-.11,11.59-8.08,26.06-17.78s26.28-17.47,26.36-17.35-11.59,8.08-26.07,17.78S85,331.67,84.91,331.55Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M148,314.17c.08.12-20.9,14.76-46.86,32.69S54,379.25,53.94,379.13s20.89-14.75,46.86-32.7S147.87,314.05,148,314.17Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M140.27,345c.08.12-7.71,5.7-17.41,12.47s-17.62,12.16-17.7,12,7.71-5.7,17.41-12.47S140.19,344.85,140.27,345Z"
      style={{ fill: "#263238" }}
    />
  </g>
  <g id="freepik--Clock--inject-5">
    <path
      d="M302,358.58a57.94,57.94,0,0,1-18.49-14.67,59.8,59.8,0,0,1-3.71-5c-.85-1.28-2.78-3.48-3-5-.4-3,4.53-6.24,6.92-7.09s7.37-1.44,9.23.66c1.47,1.66,1.65,5.89,2.4,8a54.2,54.2,0,0,0,9.46,16.64,10,10,0,0,1,2.18,3.38,3.07,3.07,0,0,1-1.14,3.57C304.69,359.75,303.23,359.19,302,358.58Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M490.11,228.44a204.55,204.55,0,0,1-85.79-1.52c-7.19-1.69-25.05-5.74-32.31-2.84-3.71,1.49-7.17,4.21-10.71,6.54-12.71,8.36-16.4,13.73-29.8,11.64l-23.36,78.48c6.9-8.73,13.94-17.59,22.18-23.34s18-8,25.94-3.12c9.6,6,22.25,28.22,31.18,23.34,6.53-3.3-1.17-21.95-1.74-22.88,6.54,10.64,20.11,31.51,31.14,20.07,3.05-5.06-3.19-18.13-3.19-18.13,5.42,8.53,13.13,12.72,19.92,12.05,5.77-.56,8.86-7.8,5.69-13.11a33.7,33.7,0,0,0-4.8-6.29c7,6.67,16.23,9.34,25.91,5.26,7.76-3.27,7.62-13.89,2.61-20l-8-8.87C470.81,263.5,484.91,249.27,490.11,228.44Z"
      style={{ fill: "#e0e0e0" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M345.31,241.15,338,242.68l-7.66,54.72s5.76-3.86,8.68-4.51a63.58,63.58,0,0,0,7.58-22.26C348,260.78,349.06,250.37,345.31,241.15Z" />
    </g>
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M424.35,277.32a23.89,23.89,0,0,1-13.42-7,32.5,32.5,0,0,1,.62,7.48,10.58,10.58,0,0,1-2.68,6.74c-2.65,2.66-6.51,2.25-9.75.53a25.25,25.25,0,0,1-8.32-7.48c-2.25-3.07-4.93-6.66-7.16-9.51.38,3.76,2,9.18,2.5,12.91.53,4.23.94,7.53-1.36,10.37-2.15,2.66-3.83,2.61-6.94,1.55s-8.75-6.5-11.27-8.76l-4.39-5.44a34.53,34.53,0,0,0,16.37,18.87c3.08,1.56,6.95,2.5,9.68.3,2.94-2.36,3.06-7,2.9-11,3.23,2,6.51,4,10.11,4.87s7.63.62,10.64-1.71,4.63-7,3-10.63c4.06,1.91,8.5,3.16,12.9,2.66a14.29,14.29,0,0,0,11-7.13A27.28,27.28,0,0,1,424.35,277.32Z" />
    </g>
    <path
      d="M444,271.13l-.12.12-.37.34c-.34.29-.8.76-1.49,1.28a22.94,22.94,0,0,1-6.57,3.47l-1.18.39c-.41.12-.84.19-1.27.29s-.88.21-1.34.28l-1.42.17a10.2,10.2,0,0,1-1.48.11c-.5,0-1,0-1.54,0a26.54,26.54,0,0,1-3.25-.3,27.39,27.39,0,0,1-3.4-.75,28.7,28.7,0,0,1-3.42-1.37,23.55,23.55,0,0,1-6.44-4.61l.38-.2a28.3,28.3,0,0,1,.81,8.53,11.26,11.26,0,0,1-1.37,4.37,6.78,6.78,0,0,1-3.55,3.13,8.17,8.17,0,0,1-4.87,0,16.08,16.08,0,0,1-4.55-2,34.57,34.57,0,0,1-7.38-7,62.86,62.86,0,0,1-7.12-11l.48-.17c.92,4.25,1.77,8.38,2.49,12.38a33.61,33.61,0,0,1,.69,5.94,12.69,12.69,0,0,1-1.33,5.57,6,6,0,0,1-4.21,3.33,8,8,0,0,1-5-1.08,30.3,30.3,0,0,1-7-5.62,84.75,84.75,0,0,1-8.09-10c-.87-1.24-1.51-2.21-1.94-2.87l-.48-.76q-.17-.25-.15-.27l.19.24.51.73,2,2.82a89.68,89.68,0,0,0,8.18,9.89,30.67,30.67,0,0,0,7,5.5,7.69,7.69,0,0,0,4.71,1A5.52,5.52,0,0,0,385,289.9a12.26,12.26,0,0,0,1.26-5.37,34.41,34.41,0,0,0-.7-5.85c-.73-4-1.59-8.1-2.51-12.35a.26.26,0,0,1,.19-.31.28.28,0,0,1,.29.14A63.29,63.29,0,0,0,390.55,277a34.69,34.69,0,0,0,7.26,6.93,15.61,15.61,0,0,0,4.4,2,7.66,7.66,0,0,0,4.57,0,6.29,6.29,0,0,0,3.29-2.91,10.67,10.67,0,0,0,1.33-4.17,28.34,28.34,0,0,0-.78-8.39.23.23,0,0,1,.18-.27.25.25,0,0,1,.2.07,23.49,23.49,0,0,0,6.3,4.55,25.7,25.7,0,0,0,3.36,1.36,26.21,26.21,0,0,0,3.34.76,25.78,25.78,0,0,0,3.19.33l1.53,0a11.56,11.56,0,0,0,1.46-.09l1.4-.16c.46-.06.9-.18,1.33-.26s.85-.16,1.26-.28l1.17-.36a23.82,23.82,0,0,0,6.57-3.34c.69-.5,1.17-.95,1.53-1.22l.39-.31C443.92,271.16,444,271.12,444,271.13Z"
      style={{ fill: "#263238" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M379.06,244.82c-1.7,2.24-3.68,2.74-5.9,4.62l-18.53,12.92c5.68-3.09,12.09-5.64,17.77-8.73a10.14,10.14,0,0,1,10.64-.91c9.52,3.2,19.24,6,29.19,6.52S432.42,258,441,252.49C419.92,257.92,398.32,255.7,379.06,244.82Z" />
    </g>
    <path
      d="M459.54,245.39l-.27.15-.82.42-3.18,1.55c-1.39.64-3.15,1.3-5.16,2.14-1,.36-2.14.72-3.31,1.12l-1.81.6-1.94.53A85.63,85.63,0,0,1,424,255a89.25,89.25,0,0,1-23.48-2A86.13,86.13,0,0,1,379,245l.26,0a255.62,255.62,0,0,1-21.94,15.82c-2.79,1.8-5,3.16-6.58,4.08l-1.78,1-.46.26c-.11.06-.16.08-.17.07l.15-.11.44-.29,1.74-1.09c1.52-1,3.74-2.37,6.5-4.19,5.53-3.65,13.24-9,21.8-16l.12-.1.14.08a86.77,86.77,0,0,0,21.51,7.82A89.94,89.94,0,0,0,424,254.49a86.82,86.82,0,0,0,18.93-3l1.94-.5,1.81-.59,3.31-1.08c2-.8,3.78-1.44,5.18-2.05l3.21-1.46.84-.36A1.18,1.18,0,0,1,459.54,245.39Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M161.84,169.43c-2.39-3.58-4.54-7.49-7.49-10.38-5.74-5.64-24.77-9.33-32.46-10.76a224,224,0,0,1-83.58-34c-4.11,21.11,3.29,39.89,17.61,48.43l-11.61,4.78C36.78,171,32,180.57,38.06,186.76c7.56,7.72,17.64,9.09,27.34,5.92a36.25,36.25,0,0,0-7.4,3.74c-5.38,3.52-5.58,11.39-.24,14.29C64,214.12,73.31,213.5,82.29,208c0,0-11.76,9.33-11,15.2,5.65,15,27.92,1.58,38.9-5.42-1,.61-16.57,14.42-11.72,20.13,6.49,8.12,28.46-6.89,40.37-8.38,9.81-1.22,18.26,4.91,23.7,13.56s8.36,19.61,11.21,30.42l20.19-79.62C180,190.23,170.46,182.3,161.84,169.43Z"
      style={{ fill: "#e0e0e0" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M80.44,185.91a26.85,26.85,0,0,0,16-.86,32.46,32.46,0,0,0-3.88,6.55,10,10,0,0,0-.36,7.25c1.39,3.51,5.3,4.73,9.18,4.51A28,28,0,0,0,112.75,200c3.52-1.86,7.69-4,11.1-5.7-2,3.27-6,7.52-8.08,10.72-2.36,3.64-4.21,6.48-3.24,10,.92,3.31,2.57,4,6,4.28S129.88,217,133.3,216l6.63-3.14a37.67,37.67,0,0,1-24.1,10.42c-3.66.15-7.81-.6-9.49-3.72-1.8-3.37.12-7.66,2-11.22-4,.46-8,.92-11.9.26s-7.64-2.59-9.54-6-1.4-8.28,1.75-10.92c-4.76.06-9.6-.64-13.63-2.91s-7.16-6.32-7.5-11A29,29,0,0,0,80.44,185.91Z" />
    </g>
    <path
      d="M64.19,172.17s0,.05.1.15l.24.46c.24.41.5,1,1,1.8a23.56,23.56,0,0,0,5,5.83A26.62,26.62,0,0,0,96.44,185a.22.22,0,0,1,.29.13.26.26,0,0,1,0,.21,27.31,27.31,0,0,0-4.48,7.45,9.93,9.93,0,0,0-.47,4.47,6,6,0,0,0,2.13,4,9.08,9.08,0,0,0,4.44,1.69,17.62,17.62,0,0,0,5,0,37.86,37.86,0,0,0,9.84-3.24,68.12,68.12,0,0,0,11.7-7,.25.25,0,0,1,.36.05.24.24,0,0,1,0,.31c-2.79,3.53-5.47,7-7.94,10.35a30.32,30.32,0,0,0-3.25,5.15,11,11,0,0,0-1,5.59,5.93,5.93,0,0,0,.88,2.55,5,5,0,0,0,1.94,1.72,9.64,9.64,0,0,0,5,.74,26.07,26.07,0,0,0,4.73-.85c1.49-.38,2.89-.87,4.22-1.34a99,99,0,0,0,12.06-5.56l3.15-1.71.81-.45.28-.14s-.08.07-.26.18l-.79.49c-.7.42-1.74,1-3.1,1.79a94.17,94.17,0,0,1-12,5.72c-1.33.48-2.74,1-4.25,1.38a26.41,26.41,0,0,1-4.79.88,10,10,0,0,1-5.21-.75,5.62,5.62,0,0,1-2.11-1.86,6.43,6.43,0,0,1-1-2.73,11.66,11.66,0,0,1,1-5.84,30.09,30.09,0,0,1,3.3-5.24c2.47-3.4,5.14-6.85,7.93-10.39l.35.37a68.18,68.18,0,0,1-11.79,7.1,38.05,38.05,0,0,1-10,3.28,18.37,18.37,0,0,1-5.13,0,9.66,9.66,0,0,1-4.68-1.81,6.53,6.53,0,0,1-2.3-4.3,10.48,10.48,0,0,1,.5-4.7,27.69,27.69,0,0,1,4.58-7.56l.25.35a26.47,26.47,0,0,1-15.53.7,26,26,0,0,1-10.7-5.52,23,23,0,0,1-5-6A25.08,25.08,0,0,1,64.19,172.17Z"
      style={{ fill: "#263238" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M138.45,175c.67,2.74,2.36,4,3.67,6.64l12.25,19.43c-4.14-5.17-9.21-10.13-13.35-15.3a14.23,14.23,0,0,0-4.16-4,15.46,15.46,0,0,0-5.72-1.28c-10.6-1-21.22-2.5-31.06-6.11s-19-9.51-24.87-18.05A90.88,90.88,0,0,0,138.45,175Z" />
    </g>
    <path
      d="M60.43,142.3s.09.07.24.22l.66.69c.59.64,1.42,1.54,2.49,2.68s2.53,2.47,4.14,4.05l2.74,2.35,1.5,1.28,1.66,1.26A93.36,93.36,0,0,0,90.8,165.28,98.82,98.82,0,0,0,113.9,173a97.93,97.93,0,0,0,24.54,1.83h.15l.07.14c5.27,9.9,10.36,18,14.1,23.57,1.87,2.8,3.39,5,4.44,6.48l1.2,1.71.3.44.09.16s0,0-.13-.13-.19-.25-.33-.43l-1.26-1.66c-1.08-1.47-2.64-3.63-4.55-6.41a254.66,254.66,0,0,1-14.28-23.51l.22.13a97,97,0,0,1-24.66-1.8,98.09,98.09,0,0,1-23.21-7.76,92,92,0,0,1-17-10.59L72,153.85l-1.49-1.3-2.73-2.4c-1.59-1.6-3-3-4.09-4.12l-2.42-2.75-.62-.73A1.47,1.47,0,0,1,60.43,142.3Z"
      style={{ fill: "#263238" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M157.53,236.69s14.15-33.55,26.41-46.38c0,0,8.65,3.88,12.46,4.37s-.33,1.1-1.32,1.1S164,247,164,247,160.25,239.51,157.53,236.69Z" />
    </g>
    <ellipse
      cx="334.42"
      cy="193.5"
      rx="10.48"
      ry="41.2"
      transform="matrix(0.81, -0.59, 0.59, 0.81, -49.59, 236.13)"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M357.26,177.07c13.5,18.32,14.65,37.52,5,47.41-6.66,6.85-19.33-8.6-32.83-26.92s-25.84-34.88-17.64-39.77C324.88,150,343.76,158.75,357.26,177.07Z"
      style={{ fill: "#4F0696" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <ellipse
        cx="334.42"
        cy="193.5"
        rx="10.48"
        ry="41.2"
        transform="matrix(0.81, -0.59, 0.59, 0.81, -49.59, 236.13)"
      />
    </g>
    <path
      d="M208.08,179.18s-14.34,6.33-40.3,49.5L275.19,352.39s38.3-22.54,49.43-49.14Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M318.53,278.85c0,45.61-33.86,82.14-78.27,82.14S155,319,155,273.35a84,84,0,0,1,10-39.82c13.69-25.49,40.12-42.76,70.47-42.76C279.85,190.77,318.53,233.24,318.53,278.85Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M342.83,253.49c0,46.64-35.13,84-81.21,84s-88.45-43-88.45-89.61a84.82,84.82,0,0,1,10.32-40.71,83.28,83.28,0,0,1,73.12-43.73C302.7,163.43,342.83,206.85,342.83,253.49Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M338,256.74c0,45.62-33.85,82.14-78.27,82.14s-85.25-42-85.25-87.63,36-82.59,80.42-82.59S338,211.13,338,256.74Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M338,256.74s0-.45-.06-1.34,0-2.22-.19-4a84.77,84.77,0,0,0-2.4-15c-.4-1.6-.9-3.27-1.42-5s-1.16-3.57-1.93-5.43a88.41,88.41,0,0,0-5.56-11.81,94,94,0,0,0-19.88-24.68,88.66,88.66,0,0,0-15.06-10.71,84.11,84.11,0,0,0-8.84-4.3,77.52,77.52,0,0,0-9.69-3.23c-13.37-3.61-28.81-3.13-43.55,1.92a80.75,80.75,0,0,0-38.66,28.68A83.34,83.34,0,0,0,179,224.77a82.27,82.27,0,0,0-3.15,39.51,86.45,86.45,0,0,0,3.25,12.81,91.56,91.56,0,0,0,11.69,23,93.82,93.82,0,0,0,36.89,31.28,78.92,78.92,0,0,0,21.73,6.53,74.12,74.12,0,0,0,21.4,0A75.23,75.23,0,0,0,306,323.2a79,79,0,0,0,20.8-23.81,85.66,85.66,0,0,0,8.75-22.32,89,89,0,0,0,2.21-15c.15-1.74.12-3.07.17-4s.06-1.35.06-1.35,0,.46,0,1.35,0,2.23-.11,4a87.57,87.57,0,0,1-2.12,15.07,85.56,85.56,0,0,1-8.7,22.41,78.91,78.91,0,0,1-20.83,24,76.35,76.35,0,0,1-56.89,14.84,79.63,79.63,0,0,1-21.87-6.55,94.36,94.36,0,0,1-37.09-31.42,91.69,91.69,0,0,1-11.77-23.11,86,86,0,0,1-3.27-12.89,82.64,82.64,0,0,1,3.17-39.75,83.4,83.4,0,0,1,11.84-23.05,81.19,81.19,0,0,1,38.91-28.84c14.83-5.06,30.37-5.53,43.82-1.87a77.79,77.79,0,0,1,9.74,3.25,84,84,0,0,1,8.88,4.34,88.5,88.5,0,0,1,15.1,10.78A94.26,94.26,0,0,1,326.67,214a87.4,87.4,0,0,1,5.54,11.86c.77,1.87,1.32,3.72,1.92,5.45s1,3.44,1.4,5.05a84.24,84.24,0,0,1,2.31,15c.14,1.73.09,3.07.13,4S338,256.74,338,256.74Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M276.28,188.2l-3.77,13.85-3.21-.87,3.07-11.28-2.77-.75.7-2.57Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M286,202.91l-.71,2.61-10.47-2.85.57-2.07,6.71-3.59a6.14,6.14,0,0,0,1.53-1.08,2.68,2.68,0,0,0,.61-1.08,1.57,1.57,0,0,0-.22-1.43,2.7,2.7,0,0,0-1.53-.9,4.1,4.1,0,0,0-1.73-.1,3.29,3.29,0,0,0-1.51.71L277.3,191a5.7,5.7,0,0,1,2.75-1.28,7.6,7.6,0,0,1,3.45.21,6.92,6.92,0,0,1,2.57,1.24,4.32,4.32,0,0,1,1.41,1.91,3.8,3.8,0,0,1,0,2.34,5,5,0,0,1-1,2,8.41,8.41,0,0,1-2.45,1.75l-4,2.14Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M310.12,209.16l-2.55,14.13-3.26-.59,2.07-11.5-2.83-.51.47-2.62Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M325.12,247.83l-.47,2.67L314,248.57l.38-2.12,6.38-4.16a6,6,0,0,0,1.42-1.21,2.57,2.57,0,0,0,.52-1.13,1.58,1.58,0,0,0-.34-1.4,2.76,2.76,0,0,0-1.6-.76,4,4,0,0,0-1.74.05,3.3,3.3,0,0,0-1.44.84l-2.11-2a5.63,5.63,0,0,1,2.63-1.52,7.79,7.79,0,0,1,3.45-.1,7.12,7.12,0,0,1,2.67,1,4.39,4.39,0,0,1,1.57,1.79,3.85,3.85,0,0,1,.26,2.32,5,5,0,0,1-.88,2.08,8.46,8.46,0,0,1-2.29,2l-3.8,2.48Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M322.81,275.86a3.88,3.88,0,0,1,.51,2.85,4.32,4.32,0,0,1-1.06,2.16,4.6,4.6,0,0,1-2.26,1.31,7.79,7.79,0,0,1-3.35,0,11.45,11.45,0,0,1-2.87-.92,7.82,7.82,0,0,1-2.25-1.57l1.72-2.27a6.33,6.33,0,0,0,1.7,1.28,7.22,7.22,0,0,0,2.11.72,3.39,3.39,0,0,0,2-.13,1.55,1.55,0,0,0,.95-1.21c.21-1.16-.57-1.89-2.33-2.21l-1.49-.27.38-2.16,3.5-2.76-6-1.09.47-2.62,9.89,1.78-.38,2.12L320.21,274A4.77,4.77,0,0,1,322.81,275.86Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M308.71,304.64l-2.3-.42-.54,3-3.19-.57.54-3-7.61-1.37.4-2.2,8.27-7.76,3.43.62-7.7,7.38,3.79.69.48-2.65,3.08.56-.47,2.64,2.3.41Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M282,315.82a4,4,0,0,1,.84,3.44,4.5,4.5,0,0,1-1.06,2.24,4.56,4.56,0,0,1-2.28,1.34,7.7,7.7,0,0,1-3.37.05,11.51,11.51,0,0,1-2.86-.92A7.56,7.56,0,0,1,271,320.4l1.74-2.26a6.25,6.25,0,0,0,1.69,1.27,7,7,0,0,0,2.09.72,3.42,3.42,0,0,0,2-.14,1.67,1.67,0,0,0,.46-2.73,5.3,5.3,0,0,0-2.47-.92l-3.61-.65,2.16-7.82,8.61,1.55-.47,2.63L277.36,311l-.71,2.64,1.15.21A7,7,0,0,1,282,315.82Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M248.53,315.14A4.39,4.39,0,0,1,250,317a4.27,4.27,0,0,1,.23,2.42,4.47,4.47,0,0,1-1.16,2.36,4.7,4.7,0,0,1-2.22,1.3,6.49,6.49,0,0,1-2.82.08,6.07,6.07,0,0,1-4.31-2.65A7.46,7.46,0,0,1,239,315a8.88,8.88,0,0,1,1.65-3.95,6.33,6.33,0,0,1,3-2.15,8,8,0,0,1,3.92-.21,9,9,0,0,1,2.12.65,5.91,5.91,0,0,1,1.64,1l-1.64,2.18a4.79,4.79,0,0,0-2.52-1.18,3.93,3.93,0,0,0-3,.54,4.42,4.42,0,0,0-1.68,2.84,4.81,4.81,0,0,1,3.72-.62A5.55,5.55,0,0,1,248.53,315.14Zm-2.42,5.29a2.17,2.17,0,0,0,.55-3.08,2.64,2.64,0,0,0-1.64-.89,2.69,2.69,0,0,0-1.86.28,2,2,0,0,0-1,1.4,2,2,0,0,0,.39,1.64,2.69,2.69,0,0,0,1.71.91A2.59,2.59,0,0,0,246.11,320.43Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M224.93,294.48l-.38,2.12-7.41,11.06-3.55-.64,7.13-10.54-4.68-.85-.41,2.3-2.91-.52.89-5Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M206.05,275.87a3.45,3.45,0,0,1,.2,2.05,3.65,3.65,0,0,1-1.16,2.16,4.78,4.78,0,0,1-2.37,1.13,8.51,8.51,0,0,1-3.22,0,8.62,8.62,0,0,1-3-1.1,4.86,4.86,0,0,1-1.81-1.89,3.76,3.76,0,0,1-.32-2.42,3.55,3.55,0,0,1,.91-1.86,4,4,0,0,1,1.83-1.08,3.46,3.46,0,0,1-1-1.44,3.25,3.25,0,0,1-.12-1.76,3.47,3.47,0,0,1,1.07-2,4.52,4.52,0,0,1,2.17-1.06,8.59,8.59,0,0,1,5.68,1,4.36,4.36,0,0,1,1.67,1.75,3.46,3.46,0,0,1,.3,2.26,3.35,3.35,0,0,1-.72,1.61,3.56,3.56,0,0,1-1.46,1A4,4,0,0,1,206.05,275.87ZM202,278.58a1.8,1.8,0,0,0,1-1.35,1.75,1.75,0,0,0-.45-1.6,3.2,3.2,0,0,0-1.85-.9,3.06,3.06,0,0,0-2,.2,1.94,1.94,0,0,0-.54,3,3.11,3.11,0,0,0,1.82.89A3.17,3.17,0,0,0,202,278.58ZM200,269.17a1.6,1.6,0,0,0-.83,1.19,1.54,1.54,0,0,0,.36,1.38,2.52,2.52,0,0,0,1.51.75,2.65,2.65,0,0,0,1.69-.17,1.57,1.57,0,0,0,.84-1.17,1.61,1.61,0,0,0-.37-1.41,2.65,2.65,0,0,0-1.53-.76A2.47,2.47,0,0,0,200,269.17Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M204,239.57a7.46,7.46,0,0,1,.74,5.49,8.87,8.87,0,0,1-1.64,4,6.23,6.23,0,0,1-3,2.15,7.92,7.92,0,0,1-3.93.2,8.93,8.93,0,0,1-2.11-.64,5.77,5.77,0,0,1-1.63-1l1.64-2.18a4.65,4.65,0,0,0,2.52,1.18,3.85,3.85,0,0,0,3-.55,4.52,4.52,0,0,0,1.69-2.85,4.85,4.85,0,0,1-3.74.64,5.54,5.54,0,0,1-2.37-1,4.42,4.42,0,0,1-1.47-1.86,4.13,4.13,0,0,1-.23-2.43,4.39,4.39,0,0,1,1.15-2.35,4.7,4.7,0,0,1,2.22-1.3,6.5,6.5,0,0,1,2.82-.08A6,6,0,0,1,204,239.57Zm-3.42,3.78a2,2,0,0,0,.95-1.4,1.94,1.94,0,0,0-.38-1.64,2.71,2.71,0,0,0-1.7-.91,2.59,2.59,0,0,0-1.81.26,2.17,2.17,0,0,0-.55,3.08,2.94,2.94,0,0,0,3.49.61Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M209.58,207.61l-3.45,13.93-3.22-.8,2.81-11.34-2.79-.69.64-2.59Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M211.6,222.24a6,6,0,0,1-1.51-3A10.42,10.42,0,0,1,212,211.5a6,6,0,0,1,8.9-.43,6,6,0,0,1,1.51,3,10.54,10.54,0,0,1-1.92,7.74,6,6,0,0,1-8.9.43Zm5.82-1.71a6.44,6.44,0,0,0,1.56-3.2,6.32,6.32,0,0,0,.11-3.56,2.43,2.43,0,0,0-4-1,6.48,6.48,0,0,0-1.56,3.2,6.35,6.35,0,0,0-.11,3.57,2.3,2.3,0,0,0,1.71,1.58A2.35,2.35,0,0,0,217.42,220.53Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M240.8,188l-3.46,13.93-3.22-.8,2.81-11.34-2.78-.69.64-2.59Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M248.6,189.89l-3.46,13.93-3.22-.8,2.81-11.34-2.79-.69.65-2.59Z"
      style={{ fill: "#263238" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M176.74,256.62c0-45.61,36-82.58,80.43-82.58,32.75,0,62.37,23.11,75.67,53.53-12-33-43.18-58.91-77.85-58.91-44.41,0-80.42,37-80.42,82.59a82.51,82.51,0,0,0,7.31,33.44A81.22,81.22,0,0,1,176.74,256.62Z" />
    </g>
    <path
      d="M263.29,150.51c-.3-1.61-18.35.34-40.33,4.37s-39.55,8.6-39.26,10.21,18.35-.35,40.33-4.37S263.58,152.12,263.29,150.51Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M223.48,154.77l-39.78,10.3s-4.37-26.7,34.08-34.39"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M263.29,150.51s-4-25.9-39.81-21.07c-1.06.15-5.7,1.24-5.7,1.24l4.7,24.24Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M209.34,133.52a8.65,8.65,0,0,1,17-3.12"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M350,171.53a8.65,8.65,0,1,1,10.26,13.94"
      style={{ fill: "#4F0696" }}
    />
    <g style={{ opacity: "0.2" }}>
      <path d="M268.94,264.85l.34-3.85-8.12.19c0-.07,0-.13,0-.19a5.86,5.86,0,0,0-1.24-3.61l5.34-28.57a2.77,2.77,0,0,0-.09-1.4,3.13,3.13,0,0,0-4.91-1.35l-.06,0a2.91,2.91,0,0,0-1,1.69l-4.56,22.49-14.35-39.9-.79-1.1c-1.57-2.17-5.18-.73-4.58,1.84a.57.57,0,0,0,0,.14c.66,2.65,8.71,25.8,15.51,45.21a6,6,0,0,0-2.1,4.52c0,.16,0,.32,0,.49L184.48,263l65,1.43a6.42,6.42,0,0,0,4.72,2.66c3.36,9.58,5.81,16.51,5.81,16.51l5.81-2.21-6-16.62.08-.11Z" />
    </g>
    <path
      d="M256.22,259.84l6.9-35.68a3.1,3.1,0,0,1,1-1.77l.06,0a3.07,3.07,0,0,1,4.91,1.42h0a3.05,3.05,0,0,1,.1,1.47l-6.44,36.09Z"
      style={{ fill: "#455a64" }}
    />
    <path
      d="M243.42,204.74a2.53,2.53,0,0,0-4.57,1.93l0,.15c1.16,4.87,25,75.86,25,75.86l5.81-2.32-25.51-74.47Z"
      style={{ fill: "#455a64" }}
    />
    <polygon
      points="272.89 263.02 273.23 258.98 188.44 261.07 272.89 263.02"
      style={{ fill: "#263238" }}
    />
    <circle cx="258.71" cy="258.98" r="6.42" style={{ fill: "#4F0696" }} />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M263.29,150.51c-.3-1.61-18.35.34-40.33,4.37s-39.55,8.6-39.26,10.21,18.35-.35,40.33-4.37S263.58,152.12,263.29,150.51Z" />
    </g>
    <path
      d="M230.7,128.8c0,.09-1.52.11-4,.33a73.74,73.74,0,0,0-9.43,1.47,74.71,74.71,0,0,0-9.15,2.71c-2.29.85-3.67,1.48-3.71,1.4a4,4,0,0,1,.93-.53c.6-.32,1.51-.74,2.65-1.21a57.18,57.18,0,0,1,9.17-2.88,58.07,58.07,0,0,1,9.52-1.33c1.22-.06,2.22-.07,2.91,0A4.38,4.38,0,0,1,230.7,128.8Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M218.25,155.7c.07,1.06,3.2,16.44,3.2,16.44l9.12-2.31-3.79-16.39Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M242,151.43a1.59,1.59,0,0,1-.46.12l-1.35.26-5,.87c-4.2.74-10,1.78-16.38,3s-12.14,2.48-16.31,3.38l-4.93,1.07-1.35.28a2.72,2.72,0,0,1-.48.06,1.65,1.65,0,0,1,.46-.17l1.32-.36c1.15-.31,2.83-.74,4.9-1.23,4.15-1,9.9-2.29,16.29-3.54s12.21-2.24,16.42-2.88c2.11-.32,3.82-.56,5-.71l1.36-.16A1.6,1.6,0,0,1,242,151.43Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M249.16,163.85a12.52,12.52,0,0,1-1.67.23c-1.08.1-2.64.29-4.55.59s-4.18.73-6.66,1.33-5.19,1.41-8,2.4-5.36,2.14-7.66,3.25-4.32,2.24-6,3.24-3,1.85-3.88,2.46a10.42,10.42,0,0,1-1.43.89,11.12,11.12,0,0,1,1.32-1.06c.86-.66,2.15-1.56,3.79-2.6s3.66-2.19,6-3.36a79.21,79.21,0,0,1,7.69-3.31,78.21,78.21,0,0,1,8-2.38c2.51-.57,4.8-1,6.73-1.22s3.5-.4,4.58-.44A9.48,9.48,0,0,1,249.16,163.85Z"
      style={{ fill: "#263238" }}
    />
    <polygon
      points="319.9 195.26 339.25 182.1 344.87 189.48 324.29 202.4 319.9 195.26"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M351.3,200a6.81,6.81,0,0,1-.8-1.06c-.55-.79-1.23-1.78-2.06-3-1.73-2.51-4.16-5.94-6.94-9.66s-5.41-7-7.39-9.31c-1-1.16-1.79-2.08-2.36-2.72a6.64,6.64,0,0,1-.84-1,5.8,5.8,0,0,1,1,.88c.61.6,1.47,1.48,2.49,2.62,2.05,2.26,4.73,5.52,7.53,9.24s5.17,7.2,6.83,9.76c.83,1.28,1.48,2.33,1.92,3.06A6.68,6.68,0,0,1,351.3,200Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M364.88,191c-.08,0-.69-1.5-1.85-3.88a68.62,68.62,0,0,0-5.22-8.91,67.83,67.83,0,0,0-6.53-8c-1.83-1.91-3-3-3-3.09a6.07,6.07,0,0,1,.92.7c.57.48,1.37,1.2,2.32,2.14a55.42,55.42,0,0,1,6.7,8,56.67,56.67,0,0,1,5.12,9.06c.54,1.21.94,2.21,1.19,2.92A5.36,5.36,0,0,1,364.88,191Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M325.15,200.8a7.43,7.43,0,0,1-.93-1.1c-.57-.72-1.4-1.77-2.47-3a106.06,106.06,0,0,0-8.84-9.36,108.44,108.44,0,0,0-9.9-8.25c-1.33-1-2.42-1.75-3.18-2.28a7.11,7.11,0,0,1-1.15-.86,7.75,7.75,0,0,1,1.26.69c.8.47,1.92,1.21,3.29,2.15a89.13,89.13,0,0,1,10,8.17,88.09,88.09,0,0,1,8.78,9.51c1,1.29,1.82,2.38,2.34,3.14A8.23,8.23,0,0,1,325.15,200.8Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M169,227.35s-.07.19-.22.54l-.69,1.54c-.59,1.36-1.48,3.36-2.5,6a78,78,0,0,0-.91,57.82A93.75,93.75,0,0,0,173.72,312a99.53,99.53,0,0,0,13.91,17.5,103.5,103.5,0,0,0,8.45,7.6,99.7,99.7,0,0,0,9,6.35,96,96,0,0,0,9.34,5.1,89.91,89.91,0,0,0,9.5,3.75A77.19,77.19,0,0,0,242.42,356a79.56,79.56,0,0,0,16.41-.56,77.94,77.94,0,0,0,22.76-6.81c2.55-1.2,4.46-2.27,5.75-3l1.46-.85.5-.28a5,5,0,0,1-.48.32l-1.43.9c-1.27.76-3.17,1.86-5.71,3.1a77.11,77.11,0,0,1-22.79,7,78.83,78.83,0,0,1-16.5.62,77.06,77.06,0,0,1-18.59-3.66,88.68,88.68,0,0,1-9.56-3.75,95,95,0,0,1-9.4-5.12,96.86,96.86,0,0,1-9.08-6.38,102.87,102.87,0,0,1-8.5-7.63,99.62,99.62,0,0,1-14-17.6,94.72,94.72,0,0,1-9.11-18.85,83.76,83.76,0,0,1-4.7-34.94,82.63,82.63,0,0,1,5.89-23.11C167.49,230.11,169,227.35,169,227.35Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M162.7,336.09A52.59,52.59,0,0,0,187.52,325c1.1-.9,3.4-2.31,4-3.63,1.18-2.57-2.18-6.92-4-8.38s-6-3.44-8.21-2.09c-1.74,1.06-3.08,4.8-4.34,6.49a50.28,50.28,0,0,1-12.92,12.16,9.38,9.38,0,0,0-2.84,2.39,3,3,0,0,0,0,3.53C160.05,336.35,161.48,336.28,162.7,336.09Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M161.17,330.31c0-.06,1.08-.92,2.82-2.38a85.06,85.06,0,0,0,6.51-6,86.64,86.64,0,0,0,5.84-6.69,32.74,32.74,0,0,1,2.3-2.88,3.72,3.72,0,0,1-.48.87c-.34.54-.86,1.31-1.53,2.23a62.7,62.7,0,0,1-12.41,12.79c-.9.7-1.65,1.24-2.19,1.59A3.25,3.25,0,0,1,161.17,330.31Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M189.83,322.87a13.26,13.26,0,0,1-2.15,2.15,42.83,42.83,0,0,1-5.93,4.3,48,48,0,0,1-6.54,3.29,13.79,13.79,0,0,1-2.86,1,23.53,23.53,0,0,1,2.71-1.32,70.91,70.91,0,0,0,6.42-3.4,58.5,58.5,0,0,0,6-4.14A24.72,24.72,0,0,1,189.83,322.87Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M190.71,322a18.8,18.8,0,0,0,.1-2.8,9.32,9.32,0,0,0-2.75-5.91A8.66,8.66,0,0,0,182,311a18.77,18.77,0,0,0-2.76.41,2,2,0,0,1,.69-.34,7,7,0,0,1,2.06-.44,8.57,8.57,0,0,1,6.44,2.29,9.15,9.15,0,0,1,2.75,6.27,7.3,7.3,0,0,1-.2,2.09A1.87,1.87,0,0,1,190.71,322Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M304.48,244.29a6.36,6.36,0,0,1,.17.78c.09.52.27,1.28.38,2.27a45.36,45.36,0,0,1,.32,8.48A47.38,47.38,0,0,1,285,292.6a48,48,0,0,1-11.14,5.82,44.45,44.45,0,0,1-8.19,2.19c-1,.19-1.76.24-2.28.31a4,4,0,0,1-.8.06,6.12,6.12,0,0,1,.78-.16c.52-.11,1.29-.19,2.26-.41a48.82,48.82,0,0,0,8.1-2.33,49.63,49.63,0,0,0,11-5.87A47.2,47.2,0,0,0,302.61,268a50.06,50.06,0,0,0,2.22-20.66c-.08-1-.23-1.76-.28-2.28A3.69,3.69,0,0,1,304.48,244.29Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M296.47,259.14a5.39,5.39,0,0,1,.18,1.24,10.48,10.48,0,0,1,.08,1.47,18.84,18.84,0,0,1,0,2,28.18,28.18,0,0,1-.75,5,32,32,0,0,1-2.1,5.87,31.6,31.6,0,0,1-3.28,5.29,27.69,27.69,0,0,1-3.48,3.71c-.52.5-1,.88-1.5,1.24a12.17,12.17,0,0,1-1.2.85,5.79,5.79,0,0,1-1.09.64c-.06-.09,1.46-1,3.54-3a31.44,31.44,0,0,0,3.35-3.73,31.3,31.3,0,0,0,5.3-11,31.31,31.31,0,0,0,.84-4.94C296.57,260.93,296.37,259.15,296.47,259.14Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M417,258.41a5.62,5.62,0,0,1-.08,1.31,25.36,25.36,0,0,1-.66,3.55,10.68,10.68,0,0,1-2.55,4.71,7.24,7.24,0,0,1-2.69,1.78,6.63,6.63,0,0,1-3.46.23,22.35,22.35,0,0,1-6-2.57c-1.76-1-3.28-2-4.53-2.83s-2.21-1.63-2.86-2.17a6.38,6.38,0,0,1-1-.89,7.44,7.44,0,0,1,1.09.73c.69.49,1.69,1.2,3,2s2.79,1.76,4.55,2.71a22.36,22.36,0,0,0,5.9,2.47,6.16,6.16,0,0,0,3.2-.19,6.74,6.74,0,0,0,2.51-1.63,10.68,10.68,0,0,0,2.55-4.49,32.85,32.85,0,0,0,.81-3.47A7.05,7.05,0,0,1,417,258.41Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M386.85,251.33a12.11,12.11,0,0,1,.08,1.39c.05.89.22,2.17.4,3.77a12.9,12.9,0,0,1-.42,5.67A6.33,6.33,0,0,1,385,265a5.51,5.51,0,0,1-3.48,1.19,12.79,12.79,0,0,1-3.6-.54,22.31,22.31,0,0,1-8-4.05,20.87,20.87,0,0,1-2.77-2.63c-.3-.34-.51-.62-.65-.81a1.25,1.25,0,0,1-.2-.31,39.7,39.7,0,0,0,3.85,3.46A23.14,23.14,0,0,0,375,264.1a22.24,22.24,0,0,0,3.11,1.07,12.36,12.36,0,0,0,3.45.51,5.17,5.17,0,0,0,3.17-1.06,6,6,0,0,0,1.76-2.62,12.79,12.79,0,0,0,.5-5.47c-.13-1.6-.24-2.9-.23-3.81A5.07,5.07,0,0,1,386.85,251.33Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M119.91,181a1.32,1.32,0,0,1-.34.22c-.23.13-.57.32-1,.54a41.21,41.21,0,0,1-3.88,1.66,44.85,44.85,0,0,1-6,1.75c-1.15.25-2.38.46-3.65.63a21.75,21.75,0,0,1-4,.23A7,7,0,0,1,94.73,182a11.38,11.38,0,0,1-1-6.2,25.87,25.87,0,0,1,.8-4.15,9.5,9.5,0,0,1,.46-1.48,9.14,9.14,0,0,1-.27,1.53,32.61,32.61,0,0,0-.62,4.13,11.28,11.28,0,0,0,1,6,7.32,7.32,0,0,0,2.29,2.68,7.06,7.06,0,0,0,3.6,1.13,20.87,20.87,0,0,0,3.89-.22c1.27-.15,2.48-.36,3.62-.59a49.35,49.35,0,0,0,6-1.63c1.68-.56,3-1.11,3.92-1.5A10.4,10.4,0,0,1,119.91,181Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M147,191.18a8.21,8.21,0,0,1-1.46.5c-.95.27-2.33.64-4.06,1s-3.8.76-6.12,1.07c-1.16.15-2.38.28-3.65.39a14.49,14.49,0,0,1-4-.08,5.74,5.74,0,0,1-3.47-2,7.62,7.62,0,0,1-1.51-3.43,13.59,13.59,0,0,1,.52-6.22c.48-1.7.94-3.05,1.21-4a12.31,12.31,0,0,1,.43-1.47,7,7,0,0,1-.24,1.52c-.2,1-.61,2.35-1,4.05a13.56,13.56,0,0,0-.41,6,7.32,7.32,0,0,0,1.43,3.2,5.3,5.3,0,0,0,3.18,1.84,14.21,14.21,0,0,0,3.85.08q1.89-.15,3.63-.36c2.31-.28,4.38-.62,6.11-.95s3.12-.64,4.08-.86A8.74,8.74,0,0,1,147,191.18Z"
      style={{ fill: "#263238" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path
        d="M218.11,132.08c-6.8,2.54-13.69,5.14-19.48,9.51s-10.42,10.84-10.84,18.08l26.16-6A28.72,28.72,0,0,1,218.11,132.08Z"
        style={{ fill: "#fff" }}
      />
    </g>
    <g style={{ opacity: "0.30000000000000004" }}>
      <path
        d="M315.09,158l.31.28a72.84,72.84,0,0,1,23.91,22,37.38,37.38,0,0,1,13.25-7.1A38.78,38.78,0,0,0,315.09,158Z"
        style={{ fill: "#fff" }}
      />
    </g>
    <path
      d="M203.35,139.13c0,.1-1.44.59-3.59,1.85a25.42,25.42,0,0,0-3.55,2.51,28.88,28.88,0,0,0-3.69,3.84,28.28,28.28,0,0,0-2.83,4.51,25,25,0,0,0-1.58,4.06c-.7,2.39-.82,3.94-.92,3.93a4.11,4.11,0,0,1,0-1.09,8.77,8.77,0,0,1,.17-1.27,14.78,14.78,0,0,1,.35-1.67,23.58,23.58,0,0,1,1.51-4.17,26.78,26.78,0,0,1,2.85-4.62,25.92,25.92,0,0,1,3.79-3.88,22.53,22.53,0,0,1,3.68-2.47,13.32,13.32,0,0,1,1.53-.75,7.62,7.62,0,0,1,1.19-.47A4.74,4.74,0,0,1,203.35,139.13Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M215.45,126.27c.07.14-.91.71-1.86,1.69s-1.51,2-1.64,1.91a5,5,0,0,1,3.5-3.6Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M285.84,170.5l-.24-.05-.69-.19c-.61-.16-1.49-.46-2.64-.75a91.67,91.67,0,0,0-9.87-2.13,87.81,87.81,0,0,0-63.46,14.06,91.7,91.7,0,0,0-8,6.09c-.92.76-1.59,1.4-2.07,1.8l-.55.47c-.12.1-.19.15-.2.14a1.62,1.62,0,0,1,.16-.18l.51-.5c.46-.43,1.11-1.1,2-1.88a80.1,80.1,0,0,1,8-6.25A86.24,86.24,0,0,1,272.46,167a81.48,81.48,0,0,1,9.86,2.3c1.15.33,2,.66,2.62.85l.68.24Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M351.36,172.82a12.91,12.91,0,0,1-1.05-1.33,39.37,39.37,0,0,0-3-3.48,34.74,34.74,0,0,0-27.6-10.37,37.28,37.28,0,0,0-4.58.62,14.47,14.47,0,0,1-1.68.31,1.53,1.53,0,0,1,.41-.16c.28-.08.68-.22,1.22-.35a30.66,30.66,0,0,1,4.59-.79,33.72,33.72,0,0,1,27.9,10.48,30.61,30.61,0,0,1,2.94,3.62c.32.44.53.82.69,1.06S351.38,172.81,351.36,172.82Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M361.24,174.53c-.09,0-.23-.45-.7-1a5.34,5.34,0,0,0-2.4-1.67,11.66,11.66,0,0,0-3-.55c-.78-.06-1.27-.11-1.27-.19a3.16,3.16,0,0,1,1.28-.18,8.67,8.67,0,0,1,3.13.43,4.91,4.91,0,0,1,2.52,1.94C361.28,174,361.29,174.53,361.24,174.53Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M172.42,272a11.68,11.68,0,0,1-.5-1.67c-.15-.54-.32-1.2-.48-2s-.37-1.67-.52-2.66c-.36-2-.66-4.35-.87-7a71,71,0,0,1,.85-17.23c.47-2.61,1-5,1.55-6.88.25-1,.55-1.83.79-2.59s.47-1.4.67-1.93a11.26,11.26,0,0,1,.66-1.61,12.79,12.79,0,0,1-.47,1.68c-.17.53-.38,1.18-.58,2s-.49,1.63-.71,2.6c-.51,1.93-1,4.26-1.44,6.86s-.74,5.5-.9,8.54a83.77,83.77,0,0,0,.06,8.58c.18,2.63.44,5,.75,7,.13,1,.31,1.88.45,2.66s.27,1.45.39,2A9.78,9.78,0,0,1,172.42,272Z"
      style={{ fill: "#fff" }}
    />
    <g style={{ opacity: "0.30000000000000004" }}>
      <path d="M178.27,331.37s9.74.79,12.74-1.06-1.18-7.44-1.18-7.44Z" />
    </g>
    <path
      d="M64.52,162.82a2.73,2.73,0,0,1-.45-.23l-1.25-.75a19.87,19.87,0,0,1-1.9-1.28,30.28,30.28,0,0,1-2.42-1.85l-1.36-1.14c-.47-.4-.91-.87-1.4-1.32-1-.9-1.92-2-2.92-3.07a49,49,0,0,1-9.57-16.8c-.44-1.43-.88-2.78-1.15-4.08-.14-.65-.32-1.27-.42-1.88s-.2-1.19-.29-1.75a29.84,29.84,0,0,1-.36-3,20.47,20.47,0,0,1-.13-2.29c0-.58,0-1.06,0-1.46a1.77,1.77,0,0,1,0-.5,2.5,2.5,0,0,1,.07.5c0,.39.06.87.11,1.45a22.74,22.74,0,0,0,.21,2.27c.12.88.2,1.9.43,3,.1.56.21,1.13.32,1.73s.3,1.22.45,1.86c.28,1.28.74,2.62,1.18,4A56.93,56.93,0,0,0,47.65,145a55.89,55.89,0,0,0,5.53,7.87c1,1.1,1.9,2.17,2.87,3.07.47.46.9.92,1.36,1.33l1.32,1.16c.83.76,1.66,1.34,2.36,1.9a22.68,22.68,0,0,0,1.85,1.34l1.18.83C64.39,162.69,64.53,162.8,64.52,162.82Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M48.67,186.41a2.53,2.53,0,0,1-.8-.39,13.82,13.82,0,0,1-1.94-1.45,11.71,11.71,0,0,1-2.29-2.79,9.22,9.22,0,0,1-1.27-4.25,7.43,7.43,0,0,1,1.1-4.31,6.6,6.6,0,0,1,2.76-2.37,5.43,5.43,0,0,1,2.39-.49,2.15,2.15,0,0,1,.88.16,7.1,7.1,0,0,0-3.11.67,6.27,6.27,0,0,0-2.51,2.29,7.09,7.09,0,0,0-1,4,9.21,9.21,0,0,0,1.16,4,12.43,12.43,0,0,0,2.13,2.75C47.62,185.7,48.72,186.33,48.67,186.41Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M60.94,206a3.69,3.69,0,0,1,.2-1.84,8.41,8.41,0,0,1,6.43-5.75,3.75,3.75,0,0,1,1.86,0c0,.09-.71.08-1.78.35a9.19,9.19,0,0,0-3.74,2,9.09,9.09,0,0,0-2.42,3.49C61.1,205.3,61,206,60.94,206Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M86,183.4a2.9,2.9,0,0,1-.89-.17,21.94,21.94,0,0,1-2.33-.77,30.26,30.26,0,0,1-7.08-3.93,30.58,30.58,0,0,1-5.78-5.68,20,20,0,0,1-1.39-2,3.24,3.24,0,0,1-.42-.8c.07,0,.78,1,2.1,2.58a34.77,34.77,0,0,0,5.8,5.49,35.18,35.18,0,0,0,6.91,4C84.77,182.94,86,183.32,86,183.4Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M118.21,153.06a4.15,4.15,0,0,1-.78-.11l-2.22-.41c-1-.18-2.14-.42-3.5-.74s-2.91-.63-4.61-1.08c-3.41-.84-7.43-2-11.85-3.38s-9.23-3.15-14.19-5.16-9.59-4.19-13.74-6.26-7.81-4.1-10.83-5.89c-1.52-.87-2.86-1.72-4.05-2.45s-2.19-1.38-3-1.93l-1.87-1.26a5.32,5.32,0,0,1-.64-.46,3.38,3.38,0,0,1,.69.38l1.93,1.17c.83.52,1.85,1.15,3.05,1.85s2.55,1.54,4.09,2.38c3,1.75,6.71,3.71,10.85,5.78s8.79,4.17,13.73,6.21,9.74,3.73,14.14,5.18,8.4,2.6,11.79,3.48c1.69.47,3.24.82,4.59,1.16s2.51.61,3.47.82l2.2.51A5.84,5.84,0,0,1,118.21,153.06Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M96.1,196.87a7,7,0,0,1,3.53-6.66,21.76,21.76,0,0,0-2.28,3.06A22.22,22.22,0,0,0,96.1,196.87Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M116,214.39a9.58,9.58,0,0,1,.78-2.48,31,31,0,0,1,3-5.53,30.53,30.53,0,0,1,4-4.82,9.6,9.6,0,0,1,2-1.66,18.65,18.65,0,0,1-1.74,1.91,40,40,0,0,0-6.9,10.25A20.17,20.17,0,0,1,116,214.39Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M73,222.85a5.21,5.21,0,0,1,.17-2.19,11.39,11.39,0,0,1,2.43-4.74,11.27,11.27,0,0,1,4.31-3.12,5.22,5.22,0,0,1,2.14-.5c0,.09-.79.27-2,.84a12.5,12.5,0,0,0-6.53,7.61C73.16,222,73.1,222.86,73,222.85Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M102.17,236.18a4.38,4.38,0,0,1-.1-1.83,10.57,10.57,0,0,1,1.35-4.24,10.35,10.35,0,0,1,3-3.32,4.05,4.05,0,0,1,1.63-.85,14.1,14.1,0,0,1-1.41,1.14,12.59,12.59,0,0,0-2.73,3.28,12.28,12.28,0,0,0-1.43,4A13.65,13.65,0,0,1,102.17,236.18Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M386.79,229a14.83,14.83,0,0,0-5.17-1.26,17.12,17.12,0,0,0-5.69.78,26.49,26.49,0,0,0-6.38,3.06c-2.14,1.36-4.07,2.82-5.84,4.12s-3.41,2.45-4.84,3.32a27.47,27.47,0,0,1-3.51,1.84c-.43.18-.77.31-1,.39s-.36.11-.36.09a8.77,8.77,0,0,1,1.28-.66,37.54,37.54,0,0,0,3.4-2c1.39-.9,3-2.06,4.75-3.39s3.69-2.81,5.85-4.18a26.17,26.17,0,0,1,6.52-3.08,16.33,16.33,0,0,1,5.86-.68,11.93,11.93,0,0,1,3.86.91,8.43,8.43,0,0,1,1,.49C386.69,228.86,386.8,228.93,386.79,229Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M374.84,283a66.76,66.76,0,0,1-6.37-5.46,69.74,69.74,0,0,1-6-5.84,65.77,65.77,0,0,1,6.38,5.46A67,67,0,0,1,374.84,283Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M402.53,280.46a8.66,8.66,0,0,1-2.11-1,36.87,36.87,0,0,1-4.65-3.2,37.44,37.44,0,0,1-4.11-3.88,8.71,8.71,0,0,1-1.44-1.85c.12-.11,2.46,2.58,5.87,5.32S402.61,280.32,402.53,280.46Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M433.93,274.23a8.57,8.57,0,0,1-2.35-.21,31.36,31.36,0,0,1-5.51-1.48,30.85,30.85,0,0,1-5.15-2.47,8,8,0,0,1-1.92-1.38c.09-.14,3.1,1.84,7.25,3.36S434,274.06,433.93,274.23Z"
      style={{ fill: "#fff" }}
    />
    <path
      d="M465.68,242.91a2.41,2.41,0,0,1-.56.18l-1.62.39c-1.41.35-3.46.78-6,1.27a185.26,185.26,0,0,1-20.09,2.63,183,183,0,0,1-20.25.37c-2.59-.1-4.69-.23-6.13-.36l-1.67-.16a3.61,3.61,0,0,1-.57-.08,2,2,0,0,1,.58,0l1.67.06c1.45.06,3.54.14,6.13.19,5.17.12,12.33.06,20.21-.52s15-1.6,20.06-2.48c2.55-.43,4.61-.81,6-1.09l1.64-.31A2.54,2.54,0,0,1,465.68,242.91Z"
      style={{ fill: "#fff" }}
    />
  </g>
</svg>

  );
};

export default Waiting;
