import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import DueDate from "../components/DueDate";
import { Link } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import NotAssignedList from "../guided_pathways/NotAssignedList";

function PeerReviewTable({peerSubData}) {
  


  const [peerData, setPeerData] = useState(peerSubData);

  const [sortOrder, setSortOrder] = useState(null);


  const handleSort = (field) => {
    const sortedData = [...peerData].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setPeerData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };


    // Calculate the count of week_id entries
    const weekIdCount = peerData.reduce((acc, item) => {
      const weekId = item.week_id;
      acc[weekId] = (acc[weekId] || 0) + 1;
      return acc;
    }, {});


  return (
    <>
      <>
        {/* Table Section */}
        {/* <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

    <div className="flex flex-col">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle"> */}



        <Tabs>
                        <TabList>
                          <Tab>
                            <button
                              type="button"
                              class="btn btn-sm border-0 text-gray-400 hover:bg-white"
                            >
                              Assigned Submissions
                              
                            </button>
                          </Tab>
                          <Tab>
                            <button
                              type="button"
                              class="btn btn-sm border-0 text-gray-400 hover:bg-white"
                            >
                              All Submissions
                              
                            </button>
                          </Tab>

                          <Tab>
                            <button
                              type="button"
                              class="btn btn-sm border-0 text-gray-400 hover:bg-white"
                            >
                              Not Participating
                              
                            </button>
                          </Tab>
                        </TabList>
                       

                        <TabPanel>
                          
                          
        <div className="bg-white  border-gray-200 rounded-xl  overflow-hidden darki:bg-slate-900 darki:border-gray-700">
          <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
            <thead className="bg-gray-50 darki:bg-slate-800">
              <tr>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("user_name")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Student
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("assigned_name")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Reviewer
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer" onClick={() => handleSort("flag")} >
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Flagged 
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("week_id")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Week
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("content")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Content
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("feedback")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Feedback
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("rating")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Feedback Rating
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                
                {/* <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Status
                    </span>
                  </div>
                </th> */}
                
                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Action
                    </span>
                    
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
            {peerData
              ?.slice()
              .reverse()
              .filter((data) => data.pr_enabled === true)
              .map((data) => (
                  <tr
                    // key={index}
                    className="bg-white hover:bg-gray-50 darki:bg-slate-900 darki:hover:bg-slate-800"
                  >
                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <div className="flex items-center gap-x-3">
                          <img
                            className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                            // src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                            src={'https://icons.iconarchive.com/icons/papirus-team/papirus-status/512/avatar-default-icon.png'}
                            alt="Image Description"
                          />
                          <div className="grow">
                            <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                              {data?.user_name}
                            </span>
                            <span className="block text-sm text-gray-500">
                              {data?.email}
                            </span>
                          </div>
                        </div>
                      </a>
                    </td>

                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <div className="flex items-center gap-x-3">
                          <img
                            className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                            // src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                            src={'https://icons.iconarchive.com/icons/papirus-team/papirus-status/512/avatar-default-icon.png'}
                            alt="Image Description"
                          />
                          <div className="grow">
  {data?.pr_enabled ? (
    <>
      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
        {data?.assigned_name}
      </span>
      <span className="block text-sm text-gray-500">
        {data?.assigned_email}
      </span>
    </>
  ) : (
    <span>-</span>
  )}
</div>
                        </div>
                      </a>
                    </td>
                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className={`w-6 h-6 ${data.flag ? 'text-red-500' : 'text-gray-300'}`}
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3 2.25a.75.75 0 01.75.75v.54l1.838-.46a9.75 9.75 0 016.725.738l.108.054a8.25 8.25 0 005.58.652l3.109-.732a.75.75 0 01.917.81 47.784 47.784 0 00.005 10.337.75.75 0 01-.574.812l-3.114.733a9.75 9.75 0 01-6.594-.77l-.108-.054a8.25 8.25 0 00-5.69-.625l-2.202.55V21a.75.75 0 01-1.5 0V3A.75.75 0 013 2.25z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </td>
                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <span className="text-sm text-gray-600 darki:text-gray-400">
                         Week {(data.week_id)-1}
                        </span>
                      </a>
                    </td>
                    <td className="h-px w-72 min-w-[18rem] align-top">
                      <a className="block p-6">
                        <span className="block text-sm text-gray-500">
                          {data.content && data.content.length > 10
                            ? `${data.content.slice(0, 25)}...`
                            : data.content}
                        </span>
                      </a>
                    </td>

                    <td className="h-px w-72 min-w-[18rem] align-top">
                      <a className="block p-6">
                        <span className="block text-sm text-gray-500">
                          {data.feedback && data.feedback.length > 10
                            ? `${data.feedback.slice(0, 25)}...`
                            : data.feedback}
                        </span>
                      </a>
                    </td>
                    <td className="h-px w-72 min-w-[18rem] align-top">
                    <div className="flex flex-col justify-start p-6">

<button className="btn btn-sm no-animation items-center w-max btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-amber-500">
  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
</svg>
{(data.rating)/2}
</button>

                    <span className="block text-sm text-gray-500">
                        

                        {data.per_sub_feedback && data.per_sub_feedback.length > 10
                            ? `${data.per_sub_feedback.slice(0, 25)}...`
                            : data.per_sub_feedback}
                      </span>
                    </div>
                     
                    </td>
                    
                    {/* <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full darki:bg-teal-500/10 darki:text-teal-500">
                          <svg
                            className="w-2.5 h-2.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                          {data.status}
                        </span>
                      </a>
                    </td> */}

                    

                    <td className="h-px w-px whitespace-nowrap">
                      <div className="px-6 py-1.5 flex flex-row gap-4">
                        <Link
                          to={`/edit_peer/${data?.id}`}
                          className="btn btn-sm"
                        >
                          View
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* End Table */}
          {/* Footer */}

          {/* End Footer */}
        </div>

                        </TabPanel>
                        <TabPanel>
                          
        <div className="bg-white  border-gray-200 rounded-xl  overflow-hidden darki:bg-slate-900 darki:border-gray-700">
          <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
            <thead className="bg-gray-50 darki:bg-slate-800">
              <tr>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("user_name")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Student
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("assigned_name")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Reviewer
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer" onClick={() => handleSort("flag")} >
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Flagged 
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("week_id")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Week
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("content")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Content
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("feedback")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Feedback
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start cursor-pointer"onClick={() => handleSort("rating")}>
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Feedback Rating
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </th>
                
                {/* <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Status
                    </span>
                  </div>
                </th> */}
                
                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2 flex-row">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Action
                    </span>
                    
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
              {peerData
                ?.slice()
                .reverse()
                .map((data) => (
                  <tr
                    // key={index}
                    className="bg-white hover:bg-gray-50 darki:bg-slate-900 darki:hover:bg-slate-800"
                  >
                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <div className="flex items-center gap-x-3">
                          <img
                            className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                            // src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                            src={'https://icons.iconarchive.com/icons/papirus-team/papirus-status/512/avatar-default-icon.png'}
                            alt="Image Description"
                          />
                          <div className="grow">
                            <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                              {data?.user_name}
                            </span>
                            <span className="block text-sm text-gray-500">
                              {data?.email}
                            </span>
                          </div>
                        </div>
                      </a>
                    </td>

                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <div className="flex items-center gap-x-3">
                          <img
                            className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                            // src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                            src={'https://icons.iconarchive.com/icons/papirus-team/papirus-status/512/avatar-default-icon.png'}
                            alt="Image Description"
                          />
                          <div className="grow">
  {data?.pr_enabled ? (
    <>
      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
        {data?.assigned_name}
      </span>
      <span className="block text-sm text-gray-500">
        {data?.assigned_email}
      </span>
    </>
  ) : (
    <span>-</span>
  )}
</div>
                        </div>
                      </a>
                    </td>
                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className={`w-6 h-6 ${data.flag ? 'text-red-500' : 'text-gray-300'}`}
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3 2.25a.75.75 0 01.75.75v.54l1.838-.46a9.75 9.75 0 016.725.738l.108.054a8.25 8.25 0 005.58.652l3.109-.732a.75.75 0 01.917.81 47.784 47.784 0 00.005 10.337.75.75 0 01-.574.812l-3.114.733a9.75 9.75 0 01-6.594-.77l-.108-.054a8.25 8.25 0 00-5.69-.625l-2.202.55V21a.75.75 0 01-1.5 0V3A.75.75 0 013 2.25z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </td>
                    <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <span className="text-sm text-gray-600 darki:text-gray-400">
                         Week {(data.week_id)-1}
                        </span>
                      </a>
                    </td>
                    <td className="h-px w-72 min-w-[18rem] align-top">
                      <a className="block p-6">
                        <span className="block text-sm text-gray-500">
                          {data.content && data.content.length > 10
                            ? `${data.content.slice(0, 25)}...`
                            : data.content}
                        </span>
                      </a>
                    </td>

                    <td className="h-px w-72 min-w-[18rem] align-top">
                      <a className="block p-6">
                        <span className="block text-sm text-gray-500">
                          {data.feedback && data.feedback.length > 10
                            ? `${data.feedback.slice(0, 25)}...`
                            : data.feedback}
                        </span>
                      </a>
                    </td>
                    <td className="h-px w-72 min-w-[18rem] align-top">
                    <div className="flex flex-col justify-start p-6">

<button className="btn btn-sm no-animation items-center w-max btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-amber-500">
  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
</svg>
{(data.rating)/2}
</button>

                    <span className="block text-sm text-gray-500">
                        

                        {data.per_sub_feedback && data.per_sub_feedback.length > 10
                            ? `${data.per_sub_feedback.slice(0, 25)}...`
                            : data.per_sub_feedback}
                      </span>
                    </div>
                     
                    </td>
                    
                    {/* <td className="h-px w-px whitespace-nowrap align-top">
                      <a className="block p-6">
                        <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full darki:bg-teal-500/10 darki:text-teal-500">
                          <svg
                            className="w-2.5 h-2.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                          {data.status}
                        </span>
                      </a>
                    </td> */}

                    

                    <td className="h-px w-px whitespace-nowrap">
                      <div className="px-6 py-1.5 flex flex-row gap-4">
                        <Link
                          to={`/edit_peer/${data?.id}`}
                          className="btn btn-sm"
                        >
                          View
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* End Table */}
          {/* Footer */}

          {/* End Footer */}
        </div>

                          
                        </TabPanel>


                        <TabPanel>
                          <NotAssignedList/>
                        </TabPanel>
                        
                      </Tabs>


        {/* End Table Section */}
      </>
    </>
  );
}

export default PeerReviewTable;