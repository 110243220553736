import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";
import SliderWeek10 from "./SliderWeek10";
import SliderWeek10_2 from "./SliderWeek10_2";
import QuizComponent from "./QuizComponent";
import SliderWeek11 from "./SliderWeek11";
import SliderWeek11_2 from "./SliderWeek11_2";
import Confetti from 'react-confetti';
import { axiosInstance } from "../Axios";
const Week12 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 13;
  const week_name = "week_12";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      })
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axiosInstance
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_13",
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axiosInstance.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        });
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axiosInstance.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: true,
          activity_name: "Resilience Reflection- Overcoming Challenges ",
        });
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };

  const totalSteps = 8; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };





  const [currentQuestion2, setCurrentQuestion2] = useState(0);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [showExplanation2, setShowExplanation2] = useState(false);

  const handleOptionSelect2 = (option2) => {
    setSelectedOption2(option2);
  };

  const handleNextQuestion2 = () => {
    setShowExplanation2(true);
  };

  const handleNextQuestionOrFinish2 = () => {
    if (currentQuestion2 + 1 < questionsData2.length) {
      setCurrentQuestion2(currentQuestion2 + 1);
      setSelectedOption2("");
      setShowExplanation2(false);
    }
  };



  const questionsData = [
    {
      question: "Resilience in problem-solving always leads to indecision and prolonged decision-making in the workplace.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "In dynamic workplace situations, sticking rigidly to a predetermined decision-making process is the most effective approach.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Being resilient in decision-making means frequently changing decisions in the workplace.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Cognitive biases and fixed mindsets have no significant impact on an individual’s problem-solving flexibility in professional settings.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Resilience in thinking and decision-making can hinder innovation and the development of effective solutions in the workplace.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
  ];
  
  
  const questionsData2 = [
    {
      question: "Effective coping strategies in leadership are only about managing personal stress.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Effective coping strategies in leadership encompass managing personal stress and fostering resilience within the team.",
    },
    {
      question: "Emotional resilience in leadership does not impact decision-making and team dynamics.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Emotional resilience is crucial for effective leadership as it positively impacts decision-making and team dynamics.",
    },
    {
      question: "Regular stress-management practices are unrelated to overall leadership effectiveness.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Regular stress-management practices are essential for maintaining clarity, focus, and overall leadership effectiveness.",
    },
    {
      question: "Creating a supportive environment for resilience is solely the responsibility of individual team members.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Leaders play a critical role in cultivating a team culture that supports resilience and stress management.",
    },
    {
      question: "Learning from successful leaders who manage stress effectively is not beneficial for developing resilience.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Observing and learning from successful leaders who effectively manage stress can provide valuable insights for developing resilience.",
    },
  ];
  
  

  const accordionData = [
    {
      title: 'Identifying the Elements of Resilience',
      content: (
        <div>
          <p><strong>Recognizing Resilience:</strong> Understanding the signs and behaviors of resilience in oneself and others.</p>
          <p><strong>Emotional and Mental Resilience:</strong> Developing the emotional and mental fortitude to handle stress and setbacks.</p>
          <p><strong>Physical Resilience:</strong> Recognizing the role of physical health and wellness in building overall resilience.</p>
        </div>
      ),
    },
    {
      title: 'Understanding the Role of Resilience in Leadership',
      content: (
        <div>
          <p><strong>The Impact of Resilience on Leading Effectively</strong></p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Leading Through Challenges: How resilience enables leaders to guide others through difficult times.</li>
            <li>Resilience in Decision-Making: Integrating resilience into the decision-making process for better outcomes.</li>
            <li>Inspiring Resilience in Teams: Techniques for fostering resilience within a team or organization.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Strategies for Developing Resilience',
      content: (
        <div>
          <p><strong>Practical Methods to Enhance Resilience</strong></p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Building Emotional Intelligence: Cultivating self-awareness and empathy to manage emotions effectively.</li>
            <li>Coping Mechanisms: Identifying and practicing effective coping strategies for dealing with stress and adversity.</li>
            <li>Growth Mindset: Encouraging a mindset that views challenges as opportunities for learning and growth.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Applying Resilience in Real-World Scenarios',
      content: (
        <div>
          <p><strong>Utilizing Resilience Skills in Practical Situations</strong></p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Scenario Analysis: Examining real-life situations where resilience has played a key role in overcoming obstacles.</li>
            <li>Reflective Practice: Encouraging self-reflection on past experiences to identify and strengthen resilience.</li>
            <li>Case Studies: Analyzing examples of resilient leaders and organizations and the impact of their resilience on success.</li>
          </ul>
        </div>
      ),
    },
  ];
  

  const accordionData2 = [
    {
      title: 'Understanding Coping Mechanisms in Leadership',
      content: (
        <div>
          <p><strong>Recognizing the significance of coping strategies in enhancing leadership resilience.</strong></p>
          <p><strong>Self-Reflection Points:</strong></p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I currently cope with stress and challenges in my leadership role?</li>
            <li>What coping mechanisms can I develop further to improve my resilience as a leader?</li>
            <li>Which leaders exemplify effective coping strategies, and what can I learn from them?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Developing Emotional Resilience',
      content: (
        <div>
          <p><strong>Building emotional resilience is key to maintaining effective leadership under pressure.</strong></p>
          <p><strong>Self-Reflection Points:</strong></p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I manage my emotional responses to stressful situations in leadership?</li>
            <li>What practices can I adopt to strengthen my emotional resilience?</li>
            <li>How can emotional resilience positively impact my decision-making and team dynamics?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Implementing Stress-Reduction Techniques',
      content: (
        <div>
          <p><strong>Employing stress-reduction techniques to maintain clarity and focus in leadership.</strong></p>
          <p><strong>Self-Reflection Points:</strong></p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What stress-reduction methods can I integrate into my daily routine as a leader?</li>
            <li>How do regular stress-management practices benefit my overall leadership effectiveness?</li>
            <li>Can I identify successful leaders who effectively manage stress, and what techniques do they use?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Fostering a Supportive Environment for Resilience',
      content: (
        <div>
          <p><strong>Creating a supportive environment that encourages resilience among team members.</strong></p>
          <p><strong>Self-Reflection Points:</strong></p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I cultivate a team culture that supports resilience and stress management?</li>
            <li>What steps can I take to ensure my team has access to resources and support for coping with challenges?</li>
            <li>How do I lead by example in promoting a resilient and supportive work or society environment?</li>
          </ul>
        </div>
      ),
    },
  ];
  
  
  const accordionData3 = [
    {
      title: 'Building Strength and Adaptability in Leaders',
      content: (
        <div>
          <p><strong>Developing Personal Resilience:</strong> Strategies for leaders to build their own resilience, including self-awareness, stress management, and emotional intelligence.</p>
          <p><strong>Resilience as a Leadership Competency:</strong> Understanding how resilience contributes to effective leadership and the ability to handle crises.</p>
          <p><strong>Leading by Example:</strong> Demonstrating resilience in leadership to inspire and motivate teams through challenging situations.</p>
        </div>
      ),
    },
    {
      title: 'Fostering Resilience in Teams',
      content: (
        <div>
          <p><strong>Encouraging a Resilient Organisational Culture</strong></p>
          <p><strong>Creating a Resilient Team Environment:</strong> Techniques for building resilience within teams, including open communication, support structures, and collaborative problem-solving.</p>
          <p><strong>Resilience Training for Teams:</strong> Implementing training and development programs to enhance team resilience.</p>
          <p><strong>Recognizing and Celebrating Resilience:</strong> Acknowledging and rewarding resilient behaviors and actions within the team.</p>
        </div>
      ),
    },
    {
      title: 'Integrating Resilience into Decision-Making',
      content: (
        <div>
          <p><strong>Applying Resilience Principles in Leadership Decisions</strong></p>
          <p><strong>Decision-Making under Pressure:</strong> How resilient leaders make effective decisions during high-stress and uncertain times.</p>
          <p><strong>Balancing Emotional and Rational Approaches:</strong> Combining resilience with analytical thinking for well-rounded decision-making.</p>
          <p><strong>Scenario Planning:</strong> Using resilience to anticipate and prepare for potential challenges and crises.</p>
        </div>
      ),
    },
    {
      title: 'Leveraging Resilience for Leadership Growth',
      content: (
        <div>
          <p><strong>Using Adversity as a Catalyst for Development</strong></p>
          <p><strong>Learning from Adversity:</strong> How challenges and setbacks can be valuable learning experiences for leaders.</p>
          <p><strong>Building a Legacy of Resilient Leadership:</strong> Shaping a leadership style that is adaptable, strong, and empathetic.</p>
          <p><strong>Case Studies of Resilient Leadership:</strong> Examining real-life examples where leaders demonstrated resilience and led their teams through adversity.</p>
        </div>
      ),
    },
  ];
  

  const accordionData82 = [
    {
      title: 'Cultivate a Shared Vision',
      content: '- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n' +
        'Reflect on your recent team interactions:\n' +
        '- How have I communicated and fostered a shared vision within the team?\n' +
        '- In what ways have I ensured that everyone understands and is committed to our collective goals?\n' +
        '- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?',
    },
    {
      title: 'Analyse and Set SMART Goals',
      content: '- Effective goal setting is a collaborative and strategic process. Evaluate your team\'s approach to setting goals:\n' +
        '- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n' +
        '- Where can we improve in making our goals more aligned with our strategic vision?\n' +
        '- How can I lead the team to break down our larger vision into achievable, strategically informed goals?',
    },
    {
      title: 'Encourage Participation in Vision and Goal Setting',
      content: '- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n' +
        '- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n' +
        '- How can I better ensure that all voices are heard and considered in shaping our team\'s direction?\n' +
        '- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team\'s cohesion?',
    },
  ];
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];



  const accordionData7 = [
    {
      title: 'Additional Resources for Further Learning ',
      content: (
        <div>
          <p>
            Option B: Facing Adversity, Building Resilience, and Finding Joy by Sheryl Sandberg and Adam Grant, Chapter 3
          </p>
          <p>
            The Upside of Stress by Kelly McGonigal, Chapter 4
          </p>
          <p>
            Rising Strong by Brené Brown, Chapter 3
          </p>
        </div>
      ),
    },
  ];
  
  
  
  const cardData = [
    {
      id: 1,
      frontContent: 'Embracing Resilience in Professional Problem-Solving',
      backContent: (
        <div>
          <p>Reflect on your approach to handling workplace challenges:</p>
          <ul className="px-8" style={{ listStyle: 'decimal' }}>
            <li>How do I adapt my problem-solving strategies to cope with professional setbacks or challenges?</li>
            <li>In what ways have I demonstrated resilience in resolving workplace conflicts or issues?</li>
            <li>What practices can I adopt to strengthen my resilience in professional problem-solving?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Resilient Decision-Making in Dynamic Work Environments',
      backContent: (
        <div>
          <p>Consider your decision-making style in changing work situations:</p>
          <ul className="px-8" style={{ listStyle: 'decimal' }}>
            <li>How do I modify my decision-making approach in response to evolving workplace scenarios?</li>
            <li>What strategies help me maintain a balance between decisiveness and flexibility under pressure?</li>
            <li>How can improving my resilience positively impact my ability to make effective decisions in dynamic environments?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Overcoming Rigidity in Workplace Thinking',
      backContent: (
        <div>
          <p>Reflect on cognitive flexibility in a professional context:</p>
          <ul className="px-8" style={{ listStyle: 'decimal' }}>
            <li>How do my established thought patterns influence my problem-solving and decision-making at work?</li>
            <li>In what ways might cognitive biases or a fixed mindset limit my effectiveness in the workplace?</li>
            <li>What strategies can I implement to foster a more open and adaptive mindset in professional scenarios?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'The Role of Resilience in Fostering Workplace Innovation',
      backContent: (
        <div>
          <p>Explore how resilience leads to innovative solutions in the workplace:</p>
          <ul className="px-8" style={{ listStyle: 'decimal' }}>
            <li>How has demonstrating resilience in challenging situations led to creative problem-solving or effective decisions?</li>
            <li>Can I recall instances where resilience in decision-making resulted in significant improvements or innovations at work?</li>
            <li>How can I further integrate resilience into my professional approach to encourage creativity and effective solutions?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  

  const cardData2 = [
    {
      id: 1,
      frontContent: 'Setting Specific Adaptability Goals',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Defining clear, achievable goals is the first step in your action plan.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What specific aspects of adaptability do I want to develop or enhance?</li>
            <li>How can these adaptability goals contribute to my effectiveness as a student leader or society member?</li>
            <li>What are the measurable outcomes I aim to achieve through these goals?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Identifying Strategies and Resources',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Determining the right strategies and resources is key to achieving your goals.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What strategies will I employ to improve my adaptability in various situations?</li>
            <li>Which resources (like workshops, books, mentors) can assist in developing these skills?</li>
            <li>How will I integrate these strategies and resources into my regular activities or society responsibilities?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Creating a Timeline and Milestones',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: A structured timeline with milestones helps track progress and maintain focus.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What is a realistic timeline for achieving my adaptability goals?</li>
            <li>What milestones can I set to monitor my progress over time?</li>
            <li>How will I adjust my plan if I encounter obstacles or new opportunities for growth?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Reviewing and Adjusting the Plan',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Regular review and adjustment of your action plan ensure its effectiveness and relevance.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How will I periodically review the progress of my action plan?</li>
            <li>What criteria will I use to evaluate its success or areas needing adjustment?</li>
            <li>How open am I to modifying my plan based on feedback and new experiences?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  
  

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Week 12: Resilience - Navigating Challenges with Strength</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 12 of the "Ascent" leadership pathway: Resilience - Navigating Challenges with Strength. Resilience, often seen as the ability to recover from setbacks, adapt well to change, and keep going in the face of adversity, is not merely a survival trait but a key element of successful leadership. In a dynamic world marked by constant change and unforeseen challenges, the capability to remain steadfast and agile is invaluable. This week, we will explore the multifaceted nature of resilience, understanding its crucial role in empowering leaders to not only withstand storms but also emerge stronger from them. Join us on this empowering journey as we learn to build and harness resilience, not just as a reactive quality, but as a proactive strategy for personal and professional growth.      </p>
      <div className="mt-6 flex justify-center w-full h-full">
      {/* <Quote content={"It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change."} person={"Charles Darwin"}/> */}

      {/* <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div> */}
      </div>
    </section>,





<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Resilience - Understanding Resilience </b>
            </h2>
            <p className="mt-4 text-gray-600">
      
            In leadership and life, resilience is the capacity to recover from difficulties and adapt to challenges. This section focuses on how individuals and leaders can develop and strengthen resilience, allowing them to navigate through adversity and emerge stronger. By understanding and building resilience, leaders can enhance their ability to handle stress, overcome obstacles, and lead more effectively.            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,






<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b> Resilience in the Workplace
 </b>
        </h2>
        <p className="mt-4 text-gray-600">

        Exploring the critical role of resilience in professional settings, this section highlights how resilience enhances problem-solving and decision-making in the workplace, especially under challenging and stressful conditions.

        </p>
<div className="grid grid-cols-1 ">
{cardData.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}



</div>
</section>,

<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b></b>
</h2>
<p className="mt-4 text-gray-600">
</p>



<QuizComponent questionsData={questionsData} />


</section>,



<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Resilience in setbacks</b>
            </h2>
            <p className="mt-4 text-gray-600">
      
            As we delve into the resilience aspect of leadership, it's essential to focus on the coping strategies and tools that leaders can use to enhance their resilience. Effective leaders don't just endure challenges; they employ various methods to manage stress, rebound from setbacks, and maintain a positive outlook. Whether it's handling pressure in student societies or confronting obstacles in professional arenas, mastering coping strategies is a crucial skill for resilient leadership. This section outlines steps to develop your coping abilities as a leader.            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData2}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,


<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b></b>
</h2>
<p className="mt-4 text-gray-600">
</p>



<QuizComponent questionsData={questionsData2} />


</section>,

<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Resilience and leadership: Strengthening Leadership through resilience</b>
            </h2>
            <p className="mt-4 text-gray-600">
      
            In leadership, resilience is an essential quality that enables leaders to navigate through challenges and emerge stronger. This section explores how resilience is integrated into leadership, enhancing a leader's ability to deal with adversity, inspire teams, and lead effectively through tough times. By understanding and harnessing resilience, leaders can make more impactful decisions, foster a supportive environment, and drive meaningful progress.            
            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData3}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,



























    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Resilience Reflection- Overcoming Challenges
</b>
      </h2>
      <p className="mt-4 text-gray-600">
      In the peer review segment of Activity 22, you'll delve into the realms of resilience and problem-solving in the face of adversity. This exercise invites you to review and offer constructive feedback on your peers' reflections about their experiences with resilience. Through this process, you'll deepen your understanding of resilience in various contexts, gaining insights into multiple strategies and approaches to overcoming obstacles. This peer review is an excellent opportunity to learn from a variety of perspectives, enhancing your ability to evaluate and implement effective resilience tactics in challenging situations. By engaging with your peers' experiences, you'll broaden your own understanding of resilience, equipping yourself with a richer toolkit for managing personal and professional hurdles.      
      
      
      </p>


      <p className="mt-4 text-gray-600">

<div class="">

    <div class="mb-4">
      <ul class="list-disc pl-6">
        <li>You will receive a peer's reflection on activity 22 to review. </li>
      </ul>
    </div>

    <div class="mb-4">
      <ul class="list-disc pl-6">
        <li>The identity of the author will be anonymous, and they won’t know who reviews their reflection. </li>
      </ul>
    </div>


    <div class="mb-4">
      <ul class="list-disc pl-6">
        <li>Your role is to provide thoughtful and constructive feedback based on their insights and experiences. </li>
      </ul>
    </div>


  </div>

      </p>

      <ul
        className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          Instructions{" "}
        </p>

        <li className="m-6">Approach the reflection with openness and respect for the author's perspective and experiences.</li>
<li className="m-6">Focus on providing feedback that is helpful and constructive. Highlight the strong points of the reflection and suggest areas where the author could deepen their understanding or consider alternative viewpoints.</li>
<li className="m-6">Ensure your feedback is respectful and encouraging, fostering a positive and supportive learning environment.</li>
<li className="m-6">Your feedback should specifically address the strategic thinking concepts discussed in the reflection. Offer insights or additional perspectives that the author might consider.</li>
<li className="m-6">Aim to provide impactful feedback within a concise format. Your feedback should not exceed 150 words.</li>

      </ul>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Response</span>
          </label>
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                response: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} disabled={status === 'Completed'}>
        Submit
      </button>
    </section>,

<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}
<Confetti numberOfPieces={100} />
<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Week 12 Conclusion: Embracing Resilience</b>
      </h2>
      <p class="mt-4 text-gray-600">


      Congratulations on completing Week 12: Embracing Resilience! This week has been instrumental in reinforcing your leadership capabilities, especially in areas that require resilience and the strength to overcome adversity. We've delved into the essence of resilience, a crucial attribute for leaders in our complex and often challenging world. This understanding is key to effectively managing the difficulties you may face, be it in your academic pursuits, personal growth, or while steering student-run societies. You've explored the crucial role of personal resilience, recognizing how your ability to recover from setbacks and maintain a positive outlook under pressure is fundamental in various scenarios, including leadership roles within student societies. You've learned about the importance of resilience in teamwork and how fostering a resilient culture within groups can lead to more robust and effective collaborations.
<br/><br/>Developing strategies to build and maintain resilience prepares you for diverse and challenging situations. This resilience forms a solid base for your academic, personal, and extracurricular endeavours. The "Resilience Reflection" activity provided an opportunity for introspection and application of your resilience skills, showcasing how these competencies are vital in real-world contexts, including those encountered in student-led projects.
<br/><br/>Reflecting on how to apply resilience in practical scenarios, especially within student societies, has equipped you to face obstacles with strength and adaptability. Congratulations on successfully navigating through this immersive exploration of resilience!
<br/><br/>As we move forward to Week 13 with a focus on Risk Taking, continue to apply and integrate your resilience skills. Stay engaged with the upcoming content and utilize your insights to further evolve as a leader who is not only resilient but also bold and innovative, both in academic settings and in your involvement with student societies.
<br/><br/>We are excited to see your continued progress in the next stage of your leadership journey!



</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "View Slides";

      case 3:
        return "Embarking on the Journey of Self-Awareness";

      case 4:
        return "SWOT Analysis";

      case 5:
        return "Peer Review | SWOT Analysis";

      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week12;
