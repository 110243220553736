// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek10_2 = ({ onImageIndexChange }) => {
  const slideImages = [
'https://res.cloudinary.com/ahbranding/image/upload/v1704366214/AHbranding/Slide_16_9_-_57_wknhcd.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704366215/AHbranding/Slide_16_9_-_59_euycbs.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704366215/AHbranding/Slide_16_9_-_58_kerbtj.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704366213/AHbranding/Slide_16_9_-_60_o2ulmd.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704366214/AHbranding/Slide_16_9_-_61_oei9ks.png'

  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek10_2;
