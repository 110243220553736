// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek13 = ({ onImageIndexChange }) => {
  const slideImages = [

'https://res.cloudinary.com/ahbranding/image/upload/v1704478486/AHbranding/Slide_16_9_-_77_ksfhdk.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704478485/AHbranding/Slide_16_9_-_79_vbg1eq.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704478485/AHbranding/Slide_16_9_-_76_w2f9ri.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704478489/AHbranding/Slide_16_9_-_80_aoofew.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704478485/AHbranding/Slide_16_9_-_78_fc2mpd.png'



 ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek13;
