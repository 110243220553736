import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext'
import { toast, Toaster } from 'react-hot-toast';
import SSMS_logo_white from '../media/SSMS_white.png';

export default function SignIn() {
  let {loginUser} = useContext(AuthContext)
  const handleSubmit = (event) => {
    
    /*
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      username: data.get('username'),
      password: data.get('password'),
    });
    */
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to mimic content loading
    setTimeout(() => {
      setLoading(false); // Set loading to false when content is loaded
    }, 2000); // Adjust the delay as needed
  }, []);


  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const [loader, setloader] = useState(false);

  const handleClick = () => {
    setloader(true);

    // Simulate a delay of 4 seconds before hiding the spinner
    setTimeout(() => {
      setloader(false);
    }, 4000);
  };


  return (


    <div>
    {loading ? (
      <div className="flex items-center gap-4 justify-center h-screen">
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          </div>
    ) : (
      // Your page content goes here
      


      <div>




<main class="mt-0 transition-all duration-200 ease-in-out">
<section>
  <div class="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
    <div class="container z-1">
      <div class="flex flex-wrap -mx-3">
        <div class="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
          <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
            <div class="p-6 pb-0 mb-0">
              <img src='https://res.cloudinary.com/ahbranding/image/upload/v1682359716/AHbranding/Start_Up_Early_Logo_vu73y8.png' className='w-20 mb-8' />
              <h4 class="font-bold">Sign In</h4>
              <p class="mb-0">Enter your username and password </p>
            </div>
            <div class="flex-auto p-6">
              <form role="form" onSubmit={loginUser}>
                <div class="mb-4">
                  <input type="text" placeholder="Username" name="username" class="focus:shadow-primary-outline    text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none" />
                </div>
                <div class="mb-4 relative">
<input
name="password"
type={showPassword ? 'text' : 'password'}
value={password}
onChange={(e) => setPassword(e.target.value)}
placeholder="Password"
class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none pr-10"
/>
<button
type="button"
onClick={() => setShowPassword(!showPassword)}
class="absolute top-0 right-0 h-full px-3 flex items-center text-gray-600 hover:text-gray-800 focus:outline-none"
>
{showPassword ? (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
</svg>

) : (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
<path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>


)}
</button>
</div>

                <div class="text-center">
                  <button type="submit" onClick={handleClick} disabled={loading} class="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-primary border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25">
                  {loader ? (
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        'Sign In'
      )}

                  </button>

                </div>

              </form>

              <div class="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 text-center pt-0 px-1 sm:px-6 mt-4">
              <p class="mx-auto mb-6 leading-normal text-sm">Forgot Password? <Link to="/pass_reset" class="font-semibold text-transparent bg-clip-text bg-gradient-to-tl from-blue-500 to-violet-500">Reset Now</Link></p>
            </div>
            </div>
          </div>
        </div>
        <div class="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
          <div class="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://res.cloudinary.com/ahbranding/image/upload/v1697657957/AHbranding/Group_34784350_xgb8gv.png')] rounded-xl ">
            <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
            {/* <img src='https://res.cloudinary.com/ahbranding/image/upload/v1682446626/AHbranding/Group_34784215_yphk4j.png' className='w-1/2 mb-4 mx-auto ' /> */}

            <img src={SSMS_logo_white} className='w-1/2 mb-4 mx-auto ' />
            <h4 class="z-20 mt-12 font-bold text-white">Welcome to Student Success Management System</h4>
            <p class="z-20 text-white ">Revolutionizing education with innovative tools that capture every achievement, highlighting students’ contributions beyond the classroom, to help them unleash their full potential.
</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</main>



</div>
      

    )}



    
  </div>


  );
}