import React, { useEffect, useState } from "react";
import axios from "axios";

const NotAssignedList = () => {
  const [data0, setData0] = useState([]);
  const [data1, setData1] = useState([]);
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.pathway.startupearly.com///api/stats/",config);
        setData0(response.data.week0_not_participating_list);
        setData1(response.data.week1_peer_not_assigned_data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>



<div className="collapse collapse-arrow bg-base-200">
  <input type="radio" name="my-accordion-2" defaultChecked /> 
  <div className="collapse-title text-lg font-medium">
  Wee 0 Applicants - <b>{data0?.length}</b>
  </div>
  <div className="collapse-content"> 
  <ul class="w-full flex flex-col">
        {data0.map((item, index) => (
          <li
            key={index}
            class="flex  items-end gap-x-6 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white"
          >
            <b>{item[0]}</b> <br /> {item[1]}
          </li>
        ))}
      </ul>
  </div>
</div>

<div className="collapse collapse-arrow bg-base-200">
  <input type="radio" name="my-accordion-2" defaultChecked /> 
  <div className="collapse-title text-lg font-medium">
    Wee 1 Applicants - <b>{data1?.length}</b>
  </div>
  <div className="collapse-content"> 
  <ul class="w-full flex flex-col">
        {data1.map((item, index) => (
          <li
            key={index}
            class="flex  items-end gap-x-6 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white"
          >
            <b>{item[0]}</b> <br /> {item[1]}
          </li>
        ))}
      </ul>
  </div>
</div>

      
    </div>
  );
};

export default NotAssignedList;
