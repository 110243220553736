import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function WeekProgressEdit() {
  const { id, weekid } = useParams();
  const [progresses, setProgresses] = useState([]);
  const [changeData, setChangeData] = useState({
    section: "",
    status: "",
    due_date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangeData({
      ...changeData,
      [name]: value,
    });
  };


  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };


  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/api/get_single_week/${id}/${weekid}/`,config)
      .then((res) => {
        const data = res.data;
        console.log("Week Progress Edit: ", res.data);
        setChangeData({
          section: data.section,
          status: data.status,
          due_date: data.due_date,
        });

        setProgresses(res.data);
      })
      .catch((err) => {
        console.error("Error in fetching progress data", err);
      });
  }, [id, weekid]);

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/edit_progress/${id}/${weekid}/`,
        changeData
        ,config)
      .then((res) => {
        toast
          .success("Week Progress Updated!", {
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          })
          .catch((err) => {
            toast.error("Error in updating progress!", {
              style: {
                borderRadius: "55px",
                marginBottom: "15px",
              },
            });
          });
      });
  };

  return (
    <div>
      <div className="-m-1.5 overflow-x-auto p-2 bg-white shadow-sm rounded-xl">
        <div className="p-1.5 min-w-full inline-block align-middle ">
          Section:{" "}
          <span>
            <input
              type="number"
              name="section"
              placeholder="Type here"
              className="input input-bordered w-full max-w-xs"
              defaultValue={changeData.section}
              onChange={handleChange}
            />
          </span>
        </div>

        <div className="p-1.5 min-w-full inline-block align-middle ">
          status:{" "}
          <span>
            <select
              name="status"
              id="status"
              defaultValue={changeData.status}
              onChange={handleChange}
            >
            <option value="">Select one</option>
              <option value="Active">Active</option>
              <option value="Completed">Completed</option>
              <option value="Locked">Locked</option>
            </select>
          </span>
        </div>

        <div className="p-1.5 min-w-full inline-block align-middle ">
          Due Date:{" "}
          <span>
            <input
              type="date"
              placeholder="Type here"
              name="due_date"
              className="input input-bordered w-full max-w-xs"
              //   value={studentData.assigned_name}
              defaultValue={changeData.due_date}
              onChange={handleChange}
            />
          </span>
        </div>
        <button className="btn btn-active btn-primary" onClick={handleSubmit}>
          Edit
        </button>
      </div>
    </div>
  );
}