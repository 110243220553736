import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import DueDate from "../components/DueDate";

function EnrollTable({ EnrollstudentData }) {
  const [studentData, setStudentData] = useState(EnrollstudentData);
  const [searchTerm, setSearchTerm] = useState("");
  const [originalStudentData, setOriginalStudentData] = useState(EnrollstudentData);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 500;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = studentData.slice(indexOfFirstItem, indexOfLastItem);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getStudentByIdData, setGetStudentByIdData] = useState({});
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const weekOptions = Array.from({ length: 16 }, (v, k) => ({ label: `${k}`, value: k }));

  const openModal = (item) => {
    setIsModalOpen(true);
    setGetStudentByIdData(item);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setGetStudentByIdData({});
  };

  const handleBulkApprove = async () => {
    try {
      await axios.post(process.env.REACT_APP_API_KEY + "/api/bulk_approve/", {
        userId: selectedItems,
        status: true,
      }, config);
      toast.success("Status Updated!", {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong: " + error, {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };

  const handleBulkReject = async () => {
    try {
      await axios.post(process.env.REACT_APP_API_KEY + "/api/bulk_approve/", {
        userId: selectedItems,
        status: false,
      }, config);
      toast.success("Status Updated!", {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong: " + error, {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterData(term, selectedStatus, selectedWeeks);
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
    filterData(searchTerm, status, selectedWeeks);
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      setSelectedItems(studentData.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckboxChange = (e, itemId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    }
  };

  const handleWeeksChange = (selected) => {
    setSelectedWeeks(selected);
    filterData(searchTerm, selectedStatus, selected);
  };

  const filterData = (term, status, weeks) => {
    const lowerCaseTerm = term?.toLowerCase();
    const selectedWeekValues = weeks.map((week) => week.value);

    if (term === "" && status === "" && selectedWeekValues.length === 0) {
      setStudentData(originalStudentData);
    } else {
      const filteredData = originalStudentData.filter((item) => {
        const matchesSearchTerm = term === "" || Object.values(item)?.some(
          (value) =>
            value && value.toString().toLowerCase()?.includes(lowerCaseTerm)
        );

        const matchesStatus = status === "" ||
          (status === "null"
            ? item.status === null
            : item.status && item.status.toString().toLowerCase() === status);

        const matchesWeeks = selectedWeekValues.length === 0 ||
          selectedWeekValues.includes(item.weeks_completed);

        return matchesSearchTerm && matchesStatus && matchesWeeks;
      });

      setStudentData(filteredData);
    }
  };

  const updateStatus = async (userId, status) => {
    try {
      await axios.post(process.env.REACT_APP_API_KEY + "/api/approve_status/", {
        userId: userId,
        status: status,
      }, config);

      setStudentData((prevStudentData) =>
        prevStudentData.map((student) =>
          student.id === userId ? { ...student, status: status } : student
        )
      );

      toast.success("Status Updated!", {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong: " + error, {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };

  useEffect(() => {
    console.log("Rerender");
  }, [selectedItems]);

  let pathwayId = 1;
  const [pathwayDetails, setPathwayDetails] = useState(null);

  useEffect(() => {
    fetch(`http://127.0.0.1:8000/api/pathway/${pathwayId}/`, config)
      .then((response) => response.json())
      .then((data) => {
        setPathwayDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching pathway details:", error);
      });
  }, [pathwayId]);

  const handleUpdateDueDate = () => {
    console.log("Dashboard state updated");

    toast.success("Date Updated!", {
      style: {
        borderRadius: "55px",
        marginBottom: "15px",
      },
    });
  };

  return (
    <>
      {/* Table Section */}
      {/* <div className="max-w-[85rem] py-4 lg:py-4 lg:py-14 mx-auto">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto p-2 bg-white rounded-xl">
            <div className="p-1.5 min-w-full inline-block align-middle "> */}
      <div className="bg-white w-full darki:bg-slate-900 darki:border-gray-700">
        {/* Header */}

        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-ends border-b border-gray-200 darki:border-gray-700">
          <div>
            <h2 class="text-xl font-semibold text-gray-800 darki:text-gray-200">
              Enrollments
            </h2>
            <p class="text-sm text-gray-600 darki:text-gray-400">
              List of all enrollments
            </p>
            <div className="w-full flex items-center gap-3">
              <input
                type="text"
                placeholder="Search users.."
                value={searchTerm}
                onChange={handleSearchTermChange}
                className="input input-bordered w-32"
              />

              <div>
                <select
                  className="select select-bordered w-max "
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="">All Status</option>
                  <option value="true">Active</option>
                  <option value="false">Rejected</option>
                  <option value="null">Pending</option>
                </select>
              </div>
            </div>


            <MultiSelect
          options={weekOptions}
          value={selectedWeeks}
          onChange={handleWeeksChange}
          labelledBy="Select Weeks"
        />

            {selectedItems?.length > 0 ? (
              <div className="flex items-center gap-2 justify-start mt-6">
                <button
                  onClick={handleBulkApprove}
                  className="btn btn-sm btn-success text-white"
                >
                  Approve
                </button>
                <button
                  onClick={handleBulkReject}
                  className="btn btn-sm btn-error text-white"
                >
                  Reject
                </button>
              </div>
            ) : null}
          </div>
          <div className="mb-2 flex justify-between flex-col">
            <div className="inline-flex gap-x-2">
              <div className="dropdown dropdown-end">
                <div tabIndex={0} role="button" className="btn m-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    data-slot="icon"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                  </svg>
                </div>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-max "
                >
                  <div className="p-4">
                    <strong className="text-lg">Enrollment Date</strong>
                    <p className="">
                      Update enrollment due date. form will be disabled after
                      due date
                    </p>
                    <DueDate
                      pathwayId={pathwayId}
                      value={pathwayDetails?.due_date}
                      onUpdateDueDate={handleUpdateDueDate}
                    />
                  </div>
                </ul>
              </div>
              <Link to={'/add_user_form'} className="cursor-pointer py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600">
                <svg
                  className="flex-shrink-0 w-3 h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
                Add user
              </Link>
            </div>
          </div>
        </div>
        {/* End Header */}
        {/* Table */}
        <div className=" overflow-x-auto">
        <span>Entries: {currentItems.length}</span>
          <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700 table-zebra">
            <thead className="bg-gray-50 darki:bg-slate-800">
              <tr>
                <th scope="col" className="px-6 py-3 text-start">
                  <label htmlFor="hs-at-with-checkboxes-main" className="flex">
                    <input
                      type="checkbox"
                      className="checkbox shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                      id="hs-at-with-checkboxes-main"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                    <span className="sr-only">Checkbox</span>
                  </label>
                </th>
                <th
                  scope="col"
                  className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3 text-start"
                >
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Name
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      School
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Societies
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      CGPA
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      DC Status
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Status
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Applied
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                      Action
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
              {currentItems
                ?.slice()
                .reverse()
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <label
                            htmlFor="hs-at-with-checkboxes-1"
                            className="flex"
                          >
                            <input
                              type="checkbox"
                              className=" checkbox shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                              id="hs-at-with-checkboxes-1"
                              checked={selectedItems.includes(item.id)}
                              onChange={(e) => handleCheckboxChange(e, item.id)}
                            />
                            <span className="sr-only">Checkbox</span>
                          </label>
                        </div>
                      </td>
                      <td className="h-px w-px whitespace-nowrap">
                        <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                          <div className="flex items-center gap-x-3">
                            <img
                              className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                              src={item?.avatar}
                              alt="Image Description"
                            />
                            <div className="grow">
                              <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                                {item?.firstname} {item?.lastname}
                              </span>
                              <span className="block text-sm text-gray-500">
                                {item?.email}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="h-px w-72 whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                            {item?.school}
                          </span>
                          <span className="block text-sm text-gray-500">
                            Semester {item?.semester}
                          </span>
                        </div>
                      </td>
                      <td className="h-px w-72 whitespace-nowrap">
                        {Object.keys(item.SocietyPos || {}).map(
                          (society, posIndex) => (
                            <div className="px-6 py-3" key={posIndex}>
                              <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                                <span>• </span>
                                {society} society
                              </span>
                              <span className="block text-sm text-gray-500">
                                <span className="opacity-0">• </span>
                                {item.SocietyPos[society]}
                              </span>
                            </div>
                          )
                        )}
                      </td>
                      <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500">
                            {item?.cgpa}
                          </span>
                        </div>
                      </td>
                      <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          {/* <span className="text-sm text-gray-500">{item?.dc_status}</span> */}
                          <span className="text-sm text-gray-500">
                            {item?.dc_status ? (
                              <span role="img" aria-label="check">
                                ✅
                              </span>
                            ) : (
                              <span role="img" aria-label="cancel">
                                ❌
                              </span>
                            )}
                          </span>
                        </div>
                      </td>

                      <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          {item?.status === true ? (
                            <span class="py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full darki:bg-teal-500/10 darki:text-teal-500">
                              <svg
                                class="flex-shrink-0 w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
                                <path d="m9 12 2 2 4-4" />
                              </svg>
                              Approved
                            </span>
                          ) : item?.status === false ? (
                            <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full darki:bg-red-500/10 darki:text-red-500">
                              <svg
                                class="flex-shrink-0 w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z" />
                                <path d="M12 9v4" />
                                <path d="M12 17h.01" />
                              </svg>
                              Rejected
                            </span>
                          ) : (
                            <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-amber-100 text-amber-800 rounded-full darki:bg-amber-500/10 darki:text-blue-500">
                              <svg
                                class="flex-shrink-0 w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <line x1="12" x2="12" y1="2" y2="6" />
                                <line x1="12" x2="12" y1="18" y2="22" />
                                <line x1="4.93" x2="7.76" y1="4.93" y2="7.76" />
                                <line
                                  x1="16.24"
                                  x2="19.07"
                                  y1="16.24"
                                  y2="19.07"
                                />
                                <line x1="2" x2="6" y1="12" y2="12" />
                                <line x1="18" x2="22" y1="12" y2="12" />
                                <line
                                  x1="4.93"
                                  x2="7.76"
                                  y1="19.07"
                                  y2="16.24"
                                />
                                <line
                                  x1="16.24"
                                  x2="19.07"
                                  y1="7.76"
                                  y2="4.93"
                                />
                              </svg>
                              Pending
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500">
                            {/* {item?.date_joined} */}
                            {item?.date_joined &&
                              new Date(item.date_joined).toLocaleDateString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                          </span>
                        </div>
                      </td>

                      <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-1.5 flex flex-row gap-4">
                          <button
                            onClick={() => {
                              openModal(item);
                              document.getElementById("my_modal_5").showModal();
                            }}
                            className="btn btn-sm btn-circle tooltip tooltip-left flex  btn-neutral"
                            data-tip="View Details"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              class="w-5 h-5"
                            >
                              <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                              <path
                                fill-rule="evenodd"
                                d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>

                          <a
                            onClick={() => updateStatus(item.id, true)}
                            className="btn btn-sm btn-circle tooltip tooltip-left flex  btn-success"
                            data-tip="Approve Application"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </a>

                          <a
                            onClick={() => updateStatus(item.id, false)}
                            className="btn btn-sm btn-circle tooltip tooltip-left flex  btn-error"
                            data-tip="Reject Application"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              class="w-5 h-5"
                            >
                              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* End Table */}
        {/* Footer */}
        <div className="px-4 py-2 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 darki:border-gray-700">
          <div>
            <p className="m-0 text-sm text-gray-600 darki:text-gray-400">
              <span className="font-semibold text-gray-800 darki:text-gray-200">
                {originalStudentData?.length}
              </span>{" "}
              results
            </p>
          </div>
          <div class="flex items-center gap-x-1">
            <ReactPaginate
              pageCount={Math.ceil(studentData.length / itemsPerPage)}
              pageRangeDisplayed={5} // Adjust this value based on your preference
              marginPagesDisplayed={2} // Adjust this value based on your preference
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              previousLabel="Previous"
              nextLabel="Next"
            />
          </div>
        </div>

        {/* End Footer */}
      </div>

      {/* End Table Section */}

      {isModalOpen && (
        <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle ">
          <div className="modal-box p-0">
            <div className="modal-action absolute top-0 right-0  z-[99999]">
              <form method="dialog">
                <button className="btn btn-circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </form>
            </div>

            <div className="w-full bg-white  transform   duration-200 easy-in-out rounded-t-2xl ">
              <div className=" h-32  rounded-t-2xl">
                <img
                  className="w-full"
                  src="https://t3.ftcdn.net/jpg/06/04/80/16/360_F_604801664_HSbqxVq1qWoO5C8D1ls1PwcT9GTWgFhj.jpg"
                  alt=""
                />
              </div>
              <div className="flex justify-center px-5 bg-white mt-12">
                <img
                  className="h-32 w-32 bg-white p-2 rounded-full   "
                  src={getStudentByIdData?.avatar}
                  alt=""
                />
              </div>
              <div className=" ">
                <div className=" px-14 bg-white">
                  <h2 className="text-center text-gray-800 text-3xl font-bold">
                    {getStudentByIdData?.firstname +
                      " " +
                      getStudentByIdData?.lastname} (<small>SignedIn {getStudentByIdData?.first_login}</small>)
                  </h2>
                  <p className="text-gray-400  mt-2 hover:text-blue-500 text-center">
                    {getStudentByIdData?.email === ""
                      ? "not found"
                      : getStudentByIdData?.email}
                  </p>

                  <div class="flow-root mt-8">
                    <dl class="-my-3 divide-y divide-gray-100 text-sm">
                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Serial ID</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getStudentByIdData?.special_id}
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Roll no.</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getStudentByIdData?.roll_number}
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">School</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getStudentByIdData?.school} • Semester{" "}
                          {getStudentByIdData?.semester}
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">CGPA</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getStudentByIdData?.cgpa}{" "}
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Status</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                        
                          {getStudentByIdData?.status === true ? (
                            <span class="py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full darki:bg-teal-500/10 darki:text-teal-500">
                              <svg
                                class="flex-shrink-0 w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
                                <path d="m9 12 2 2 4-4" />
                              </svg>
                              Approved
                            </span>
                          ) : getStudentByIdData?.status === false ? (
                            <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full darki:bg-red-500/10 darki:text-red-500">
                              <svg
                                class="flex-shrink-0 w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z" />
                                <path d="M12 9v4" />
                                <path d="M12 17h.01" />
                              </svg>
                              Rejected
                            </span>
                          ) : (
                            <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-amber-100 text-amber-800 rounded-full darki:bg-amber-500/10 darki:text-blue-500">
                              <svg
                                class="flex-shrink-0 w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <line x1="12" x2="12" y1="2" y2="6" />
                                <line x1="12" x2="12" y1="18" y2="22" />
                                <line x1="4.93" x2="7.76" y1="4.93" y2="7.76" />
                                <line
                                  x1="16.24"
                                  x2="19.07"
                                  y1="16.24"
                                  y2="19.07"
                                />
                                <line x1="2" x2="6" y1="12" y2="12" />
                                <line x1="18" x2="22" y1="12" y2="12" />
                                <line
                                  x1="4.93"
                                  x2="7.76"
                                  y1="19.07"
                                  y2="16.24"
                                />
                                <line
                                  x1="16.24"
                                  x2="19.07"
                                  y1="7.76"
                                  y2="4.93"
                                />
                              </svg>
                              Pending
                            </span>
                          )}
                        </dd>
                      </div>

                      <div class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
                        <dt class="font-medium text-gray-900">Applied</dt>
                        <dd class="text-gray-700 sm:col-span-2">
                          {getStudentByIdData?.date_joined &&
                            new Date(
                              getStudentByIdData.date_joined
                            ).toLocaleDateString("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <hr className="mt-6" />
                <div className="flex  bg-gray-50 ">
                  <div
                    className="text-center w-1/2 p-4 bg-green-100 hover:bg-green-400 cursor-pointer rounded-bl-2xl"
                    onClick={() => updateStatus(getStudentByIdData.id, true)}
                  >
                    <p>
                      <span className="font-semibold">Approve</span>{" "}
                    </p>
                  </div>
                  <div className="border" />
                  <div
                    className="text-center w-1/2 p-4 bg-red-100 hover:bg-red-400 cursor-pointer rounded-br-2xl"
                    onClick={() => updateStatus(getStudentByIdData.id, false)}
                  >
                    <p>
                      {" "}
                      <span className="font-semibold">Reject</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dialog>
      )}
    </>
  );
}

export default EnrollTable;
