import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function TE() {
  const [data, setData] = useState(null);
  const [specialId, setSpecialId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleValidation = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_student/${specialId}/`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Please enter a valid student ID!", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        // alert("Please enter a valid student ID");
        setIsLoading(false);
      });
  };

  const handleInputChange = (e) => {
    setSpecialId(e.target.value);
  };

  return (
    <div className="flex flex-col bg-white rounded-xl">
      <div className="p-4 text-center">
        <h2 className="text-xl font-semibold text-gray-800">
          Verify Certificate or Student
        </h2>
        <p className="mt-2 text-gray-500">
          Use 10 digit serial key to validate students or their certificate
        </p>

        <div className="flex flex-row items-center gap-4">
          <input
            className="input input-bordered w-full my-4"
            placeholder="eg: TSCG544770"
            value={specialId}
            onChange={handleInputChange}
          />
          <button className="btn btn-primary" onClick={handleValidation}>
            Validate ID
          </button>
        </div>

        {isLoading && <p>Loading...</p>}
        {data && (
  <div className="mt-4">
    <table className="min-w-full border border-gray-300">

      <tbody className="rounded-2xl">
        <tr>
          <td className="border p-2 font-bold hidden">1</td>
          <td className="border p-2">First Name</td>
          <td className="border p-2">{data.firstname}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">2</td>
          <td className="border p-2">Last Name</td>
          <td className="border p-2">{data.lastname}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">3</td>
          <td className="border p-2">Email</td>
          <td className="border p-2">{data.email}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">4</td>
          <td className="border p-2">School</td>
          <td className="border p-2">{data.school}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">5</td>
          <td className="border p-2">Semester</td>
          <td className="border p-2">{data.semester}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">6</td>
          <td className="border p-2">Weeks Completed</td>
          <td className="border p-2">{data.completed}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">7</td>
          <td className="border p-2">Weeks Locked</td>
          <td className="border p-2">{data.locked}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">8</td>
          <td className="border p-2">Weeks Active</td>
          <td className="border p-2">{data.active}</td>
        </tr>
        <tr>
          <td className="border p-2 font-bold hidden">9</td>
          <td className="border p-2">Progress</td>
          <td className="border p-2">{data.percentage}%</td>
        </tr>
      </tbody>
    </table>
  </div>
)}

      </div>
    </div>
  );
}

export default TE;
