import React, { useState } from 'react';

const DueDate = ({ pathwayId,value, onUpdateDueDate }) => {
  const [dueDate, setDueDate] = useState(value);
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const handleUpdateDueDate = async () => {
    try {
      const response = await fetch(`https://backend.pathway.startupearly.com///api/pathway/${pathwayId}/update_due_date/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ due_date: dueDate }),
      },config);

      if (response.ok) {
        console.log('Due date updated successfully');
        onUpdateDueDate(); // Call the callback to update the dashboard state or trigger a re-fetch
      } else {
        const errorData = await response.json();
        console.error('Error updating due date:', errorData.error);
        // Handle the error as needed
      }
    } catch (error) {
      console.error('An error occurred while updating due date:', error);
      // Handle the error as needed
    }
  };

  return (
    <div className="join">
      <input
        className="input input-bordered join-item"
        type="date"
        placeholder="Due Date"
        value={dueDate}
        onChange={(e) => setDueDate(e.target.value)}
      />
      <button
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-100 text-red-800 hover:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-900 dark:text-red-500 dark:hover:text-red-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 join-item"
        onClick={handleUpdateDueDate}
        disabled={!dueDate}
      >
        Update Due Date
      </button>
    </div>
  );
};

export default DueDate;
