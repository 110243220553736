import React, { useState } from 'react';

const Accordion = ({ title, content,index, openAccordionIndex, setOpenAccordionIndex }) => {
  const isOpen = index === openAccordionIndex;

  const toggleAccordion = () => {
    setOpenAccordionIndex(isOpen ? null : index);
  };


  return (
    <div className="w-full ">
      <div className="border border-gray-300 rounded-md">
        <div className="flex justify-between items-center p-4 cursor-pointer" onClick={toggleAccordion}>
          <h3 className="text-lg font-semibold">{title}</h3>
          <span className={`transition-transform transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}>▼</span>
        </div>
        {isOpen && <div className="p-4">{content}</div>}
      </div>
    </div>
  );
};

const Curriculum = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const accordions = [
    { title: 'Week 0: Introduction', content: 'Get oriented to the Ascent program and its objectives, setting the stage for your leadership journey.' },
    { title: 'Week 1: Self-awareness', content: 'Discover the importance of self-awareness' },
    { title: 'Week 2: Emotional Intelligence', content: 'Develop your emotional intelligence skills to build strong relationships and navigate emotions as a leader.' },
    { title: 'Week 3: Effective Communication', content: 'Master the art of communication to convey your message with clarity, impact, and influence.' },
    { title: 'Week 4: Empathy', content: 'Cultivate empathy to understand and connect with others, fostering a supportive and inclusive leadership approach.' },
    { title: 'Week 5: Team Building', content: 'Learn strategies for building and leading high-performing teams that collaborate and achieve collective success.' },
    { title: 'Week 6: Ethical Leadership', content: 'Explore the principles of ethical leadership and how to make morally sound decisions in challenging situations.' },
    { title: 'Week 7: Critical Thinking', content: 'Hone your critical thinking skills to analyze problems, evaluate options, and make informed decisions as a leader.' },
    { title: 'Week 8: Analytical Skills', content: 'Develop analytical skills to gather and interpret data, uncover insights, and drive evidence-based decision-making.' },
    { title: 'Week 9: Strategic Thinking', content: 'Cultivate a strategic mindset to envision the big picture, set goals, and make plans to achieve long-term success.' },
    { title: 'Week 10: Creativity', content: 'Unlock your creative potential and leverage innovative thinking to solve problems and drive innovation as a leader.' },
    { title: 'Week 11: Adaptability', content: 'Embrace adaptability and agility to navigate change, thrive in uncertainty, and lead effectively in dynamic environments.' },
    { title: 'Week 12: Resilience', content: 'Build resilience to bounce back from setbacks, stay motivated, and persevere in the face of challenges as a leader.' },
    { title: 'Week 13: Risk Taking', content: 'Explore calculated risk-taking, learn to assess and manage risks, and embrace opportunities for growth and innovation.' },
    { title: 'Week 14: Understanding and Addressing Harassment', content: 'Learn to recognize various forms of harassment, support affected individuals, and implement preventive measures to ensure a respectful and safe environment for all leaders and team members.' },
    { title: 'Week 15: Reflection and Future Planning', content: 'Reflect on your Ascent journey, celebrate growth, and create a plan' },
    // ... Add more accordion items
    // ... Add more accordion items
  ];

  return (
    <div className="container mx-auto py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {accordions.map((accordion, index) => (
          <Accordion
              key={index}
              title={accordion.title}
              index={index}
              openAccordionIndex={openAccordionIndex}
              setOpenAccordionIndex={setOpenAccordionIndex}
              content={accordion.content} />
        ))}
      </div>
    </div>
  );
};

export default Curriculum;
