import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";
import SliderWeek10 from "./SliderWeek10";
import SliderWeek10_2 from "./SliderWeek10_2";
import QuizComponent from "./QuizComponent";
import SliderWeek11 from "./SliderWeek11";
import SliderWeek11_2 from "./SliderWeek11_2";
import SliderWeek13 from "./SliderWeek13";

const Week13 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 14;
  const week_name = "week_13";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      })
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_14",
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        });
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: true,
          activity_name: "Risk Analysis Scenario",
        });
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };

  const totalSteps = 8; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };





  const [currentQuestion2, setCurrentQuestion2] = useState(0);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [showExplanation2, setShowExplanation2] = useState(false);

  const handleOptionSelect2 = (option2) => {
    setSelectedOption2(option2);
  };

  const handleNextQuestion2 = () => {
    setShowExplanation2(true);
  };

  const handleNextQuestionOrFinish2 = () => {
    if (currentQuestion2 + 1 < questionsData2.length) {
      setCurrentQuestion2(currentQuestion2 + 1);
      setSelectedOption2("");
      setShowExplanation2(false);
    }
  };



  const questionsData = [
    {
      question: "Groups always make more cautious risk decisions compared to individuals.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Effective communication within a group doesn't influence the quality of risk-taking decisions.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "In a group setting, the risk preferences of the majority always determine the final decision.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Diverse perspectives within a team can lead to more balanced and effective risk assessment.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
    {
      question: "Groupthink always leads to optimal risk-taking decisions in team environments.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
  ];

  

  
  const questionsData2 = [
    {
      question: "Effective coping strategies in leadership are only about managing personal stress.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Effective coping strategies in leadership encompass managing personal stress and fostering resilience within the team.",
    },
    {
      question: "Emotional resilience in leadership does not impact decision-making and team dynamics.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Emotional resilience is crucial for effective leadership as it positively impacts decision-making and team dynamics.",
    },
    {
      question: "Regular stress-management practices are unrelated to overall leadership effectiveness.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Regular stress-management practices are essential for maintaining clarity, focus, and overall leadership effectiveness.",
    },
    {
      question: "Creating a supportive environment for resilience is solely the responsibility of individual team members.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Leaders play a critical role in cultivating a team culture that supports resilience and stress management.",
    },
    {
      question: "Learning from successful leaders who manage stress effectively is not beneficial for developing resilience.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Observing and learning from successful leaders who effectively manage stress can provide valuable insights for developing resilience.",
    },
  ];
  
  

  const accordionData = [
    {
      title: 'Assessing the Value of Potential Rewards',
      content: (
        <div>
          <p>Evaluating the Desirability and Benefits of Outcomes</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Quantifying Benefits: How to measure the potential gains from a risk, including financial, strategic, and personal growth aspects.</li>
            <li>Long-term vs. Short-term Rewards: Understanding the difference between immediate gains and enduring benefits.</li>
            <li>Aligning with Goals: Ensuring that the potential rewards align with personal, team, and organizational objectives.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Calculating the Magnitude of Risks',
      content: (
        <div>
          <p>Analyzing the Potential Costs and Downsides</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Identifying Risks: Techniques for pinpointing potential risks in various decisions.</li>
            <li>Risk Severity Assessment: Evaluating how severe the consequences of risks could be.</li>
            <li>Probability of Occurrence: Estimating the likelihood of different risks materializing.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Making Informed Trade-offs',
      content: (
        <div>
          <p>Strategies for Balancing Risks and Rewards</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Risk-Reward Ratio: How to calculate and use the risk-reward ratio in decision-making.</li>
            <li>Mitigating Risks: Techniques for reducing the severity or likelihood of negative outcomes.</li>
            <li>Acceptable Risk Levels: Determining what level of risk is acceptable in pursuit of potential rewards.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Embracing Smart Risk-Taking',
      content: (
        <div>
          <p>Encouraging Calculated Risks for Greater Achievement</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Learning from Failures: How to use setbacks as learning opportunities to refine future risk assessments.</li>
            <li>Encouraging a Risk-Taking Culture: Fostering an environment where calculated risks are encouraged and supported.</li>
            <li>Real-World Examples: Analyzing case studies where balancing risk and reward led to significant achievements or learning experiences.</li>
          </ul>
        </div>
      ),
    },
    // Add more accordion data as needed
  ];
  


  const accordionData2 = [
    {
      title: 'Understanding the Role of Risk in Leadership',
      content: (
        <div>
          <p>(Click to Expand)</p>
          <p>Recognizing the importance of risk-taking in leadership is fundamental.</p>
          <h4>Self-Reflection Points:</h4>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I perceive risk in my role as a leader, and how does it shape my decision-making?</li>
            <li>In what ways can embracing risk contribute to my growth and the progress of my team or society?</li>
            <li>What historical or contemporary leaders can I look to as models for effective risk-taking?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Assessing Risks in Leadership Decisions',
      content: (
        <div>
          <p>(Click to Expand)</p>
          <p>Evaluating potential risks is crucial for informed and strategic leadership.</p>
          <h4>Self-Reflection Points:</h4>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I assess the potential impact and probability of risks in my decisions?</li>
            <li>What frameworks or tools can assist me in conducting thorough risk assessments?</li>
            <li>How can I use risk analysis to guide my team or society towards more effective strategies?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Balancing Risk and Caution in Decision-Making',
      content: (
        <div>
          <p>(Click to Expand)</p>
          <p>Finding the right balance between being risk-averse and risk-seeking is key to successful leadership.</p>
          <h4>Self-Reflection Points:</h4>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I strike a balance between caution and boldness in my leadership style?</li>
            <li>What are some successful examples of this balance in action within student societies or the business world?</li>
            <li>How can I cultivate a team culture that values both careful planning and the courage to take risks?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Cultivating a Risk-Tolerant Environment',
      content: (
        <div>
          <p>(Click to Expand)</p>
          <p>Creating an environment where taking calculated risks is encouraged and supported.</p>
          <h4>Self-Reflection Points:</h4>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I foster a culture within my team or society that is open to taking risks?</li>
            <li>What steps can I take to ensure that risks are taken thoughtfully and responsibly?</li>
            <li>How do I encourage and support my team members when a risk leads to failure?</li>
          </ul>
        </div>
      ),
    },
    // Add more accordion data as needed
  ];
  
  
  const accordionData3 = [
    {
      title: 'Building Strength and Adaptability in Leaders',
      content: (
        <div>
          <p><strong>Developing Personal Resilience:</strong> Strategies for leaders to build their own resilience, including self-awareness, stress management, and emotional intelligence.</p>
          <p><strong>Resilience as a Leadership Competency:</strong> Understanding how resilience contributes to effective leadership and the ability to handle crises.</p>
          <p><strong>Leading by Example:</strong> Demonstrating resilience in leadership to inspire and motivate teams through challenging situations.</p>
        </div>
      ),
    },
    {
      title: 'Fostering Resilience in Teams',
      content: (
        <div>
          <p><strong>Encouraging a Resilient Organisational Culture</strong></p>
          <p><strong>Creating a Resilient Team Environment:</strong> Techniques for building resilience within teams, including open communication, support structures, and collaborative problem-solving.</p>
          <p><strong>Resilience Training for Teams:</strong> Implementing training and development programs to enhance team resilience.</p>
          <p><strong>Recognizing and Celebrating Resilience:</strong> Acknowledging and rewarding resilient behaviors and actions within the team.</p>
        </div>
      ),
    },
    {
      title: 'Integrating Resilience into Decision-Making',
      content: (
        <div>
          <p><strong>Applying Resilience Principles in Leadership Decisions</strong></p>
          <p><strong>Decision-Making under Pressure:</strong> How resilient leaders make effective decisions during high-stress and uncertain times.</p>
          <p><strong>Balancing Emotional and Rational Approaches:</strong> Combining resilience with analytical thinking for well-rounded decision-making.</p>
          <p><strong>Scenario Planning:</strong> Using resilience to anticipate and prepare for potential challenges and crises.</p>
        </div>
      ),
    },
    {
      title: 'Leveraging Resilience for Leadership Growth',
      content: (
        <div>
          <p><strong>Using Adversity as a Catalyst for Development</strong></p>
          <p><strong>Learning from Adversity:</strong> How challenges and setbacks can be valuable learning experiences for leaders.</p>
          <p><strong>Building a Legacy of Resilient Leadership:</strong> Shaping a leadership style that is adaptable, strong, and empathetic.</p>
          <p><strong>Case Studies of Resilient Leadership:</strong> Examining real-life examples where leaders demonstrated resilience and led their teams through adversity.</p>
        </div>
      ),
    },
  ];
  

  const accordionData82 = [
    {
      title: 'Cultivate a Shared Vision',
      content: '- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n' +
        'Reflect on your recent team interactions:\n' +
        '- How have I communicated and fostered a shared vision within the team?\n' +
        '- In what ways have I ensured that everyone understands and is committed to our collective goals?\n' +
        '- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?',
    },
    {
      title: 'Analyse and Set SMART Goals',
      content: '- Effective goal setting is a collaborative and strategic process. Evaluate your team\'s approach to setting goals:\n' +
        '- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n' +
        '- Where can we improve in making our goals more aligned with our strategic vision?\n' +
        '- How can I lead the team to break down our larger vision into achievable, strategically informed goals?',
    },
    {
      title: 'Encourage Participation in Vision and Goal Setting',
      content: '- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n' +
        '- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n' +
        '- How can I better ensure that all voices are heard and considered in shaping our team\'s direction?\n' +
        '- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team\'s cohesion?',
    },
  ];
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];



  const accordionData7 = [
    {
      title: 'Additional Resources for Further Learning ',
      content: (
        <div>
          <p>
            Option B: Facing Adversity, Building Resilience, and Finding Joy by Sheryl Sandberg and Adam Grant, Chapter 3
          </p>
          <p>
            The Upside of Stress by Kelly McGonigal, Chapter 4
          </p>
          <p>
            Rising Strong by Brené Brown, Chapter 3
          </p>
        </div>
      ),
    },
  ];
  
  const cardData = [
    {
      id: 1,
      frontContent: 'Identifying Different Types of Risks',
      backContent: (
        <div>
          
          <p>Reflect on different types of risks:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What are the common types of risks encountered in university and professional settings (e.g., strategic, financial, reputational)?</li>
            <li>How do these risks differ in nature and impact?</li>
            <li>What strategies can be employed to identify these risks early in decision-making processes?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Understanding the Probability and Impact of Risks',
      backContent: (
        <div>
          
          <p>Evaluating the probability and impact of risks is crucial for effective risk-taking.</p>
          <p>Consider your decision-making:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I assess the likelihood of a risk occurring?</li>
            <li>What methods can I use to evaluate the potential impact of risks on my goals?</li>
            <li>In what ways can I improve my ability to estimate risk severity and likelihood?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Differentiating Between Perceived and Actual Risks',
      backContent: (
        <div>
          
          <p>Distinguishing perceived risks from actual risks is key to balanced risk-taking.</p>
          <p>Reflect on your risk assessments:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do my perceptions of risk compare to their actual probability and impact?</li>
            <li>In what ways might cognitive biases affect my perception of risks?</li>
            <li>How can I develop a more objective approach to evaluating and responding to risks?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'The Role of Risk in Innovation and Growth',
      backContent: (
        <div>
          
          <p>Risk is a driving force in innovation and growth.</p>
          <p>Think about growth opportunities:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How has taking calculated risks led to innovation and growth in various contexts?</li>
            <li>What examples from real life or case studies illustrate the positive outcomes of risk-taking?</li>
            <li>How can I integrate risk-taking into my approach to foster innovation and personal development?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  

  const cardData2 = [
    {
      id: 1,
      frontContent: 'Setting Specific Adaptability Goals',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Defining clear, achievable goals is the first step in your action plan.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What specific aspects of adaptability do I want to develop or enhance?</li>
            <li>How can these adaptability goals contribute to my effectiveness as a student leader or society member?</li>
            <li>What are the measurable outcomes I aim to achieve through these goals?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Identifying Strategies and Resources',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Determining the right strategies and resources is key to achieving your goals.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What strategies will I employ to improve my adaptability in various situations?</li>
            <li>Which resources (like workshops, books, mentors) can assist in developing these skills?</li>
            <li>How will I integrate these strategies and resources into my regular activities or society responsibilities?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Creating a Timeline and Milestones',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: A structured timeline with milestones helps track progress and maintain focus.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What is a realistic timeline for achieving my adaptability goals?</li>
            <li>What milestones can I set to monitor my progress over time?</li>
            <li>How will I adjust my plan if I encounter obstacles or new opportunities for growth?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Reviewing and Adjusting the Plan',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Regular review and adjustment of your action plan ensure its effectiveness and relevance.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How will I periodically review the progress of my action plan?</li>
            <li>What criteria will I use to evaluate its success or areas needing adjustment?</li>
            <li>How open am I to modifying my plan based on feedback and new experiences?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  
  

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Week 13: Risk Taking</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 13! This week, we dive into the invigorating world of "Risk Taking." Essential for your growth both within the university and in your future endeavors, understanding how to intelligently and confidently take risks is a key skill. As you navigate through various responsibilities at LUMS, from leadership roles in student societies to innovative academic projects, the ability to assess and embrace risk is invaluable. We'll uncover the nuances of risk versus reward, explore the role of risk-taking in leadership, examine its dynamics in group settings, and prepare you for handling failures with resilience. This week is a blend of theoretical knowledge and practical experiences, designed to equip you with the courage and insight to take calculated risks. Get ready to step out of your comfort zone, challenge your conventional thinking, and embrace the exciting opportunities that risk-taking brings!      
      
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
      <Quote content={"Only those who will risk going too far can possibly find out how far one can go."} person={"T.S. Eliot"}/>

      {/* <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div> */}
      </div>
    </section>,

<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b> Understanding Risk: Introducing the Fundamental Concepts and Types of Risk
 </b>
        </h2>
        <p className="mt-4 text-gray-600">

        Having delved into the essence of risk taking, it's vital to ground our understanding in the fundamental concepts and types of risk. This section explores the different dimensions of risk, ensuring that our approach to risk-taking is not just intuitive but also well-informed and strategic.
        </p>
<div className="grid grid-cols-1 ">
{cardData.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}



</div>
</section>,



<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Risk vs. Reward: Exploring the Trade-offs Between Potential Risks and Rewards in Leadership</b>
            </h2>
            <p className="mt-4 text-gray-600">
      
            In the realm of decision-making, especially in leadership roles, the interplay between risk and reward is a fundamental concept. This section delves into how leaders can effectively balance these two critical elements, ensuring that the risks taken are aligned with potential rewards. By understanding this balance, leaders can make more informed decisions, drive innovation, and achieve significant breakthroughs.            
            
            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,





<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Risk-Taking in Leadership: Navigating Challenges and Seizing Opportunities</b>
            </h2>
            <p className="mt-4 text-gray-600">
      
            As we move forward from understanding basic risk concepts, we turn our attention to a critical aspect of leadership: Risk-Taking. Effective leaders don't just manage risks; they strategically embrace them to drive innovation, growth, and positive change. Whether it's making bold decisions in student societies or navigating complex challenges in professional settings, mastering the art of risk-taking is a vital skill for any leader. This section outlines steps to develop your risk-taking abilities in leadership.            
            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData2}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,



<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
<div className="mt-6 flex justify-start w-full">
  <SliderWeek13 className='w-full' onImageIndexChange={handleImageIndexChange} />

</div>
</section>,


<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b></b>
</h2>
<p className="mt-4 text-gray-600"> 
</p>



<QuizComponent questionsData={questionsData} />


</section>,



    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Risk Analysis Scenario - Starting a New Student Society
</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Hello Future Leaders!

Imagine you're at LUMS, inspired to start a new student society named "Green Innovators of LUMS" Your vision is to create a community focused on sustainability and environmental innovation. This society will plan green initiatives, host educational workshops, and partner with environmental organizations.
      
      
      </p>


      <p className="mt-4 text-gray-600">
      But, as with any ambitious endeavour, there are risks. Let's walk through them:
<div class="">
<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Financial Risk: You have limited funds to get started. How will you manage the budget? What if you can't secure enough sponsorships or funding?
    </li>
  </ul>
</div>

<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Reputation Risk: As a new society, building credibility and attracting members is crucial. What if students don’t show interest? How will you establish your society’s presence on campus?
    </li>
  </ul>
</div>

<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Operational Risk: Balancing the society's activities with your academic workload can be challenging. How will you ensure that both your studies and the society thrive?
    </li>
  </ul>
</div>

<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Collaboration Risk: You aim to collaborate with external organizations. What if these collaborations don’t align with your society's goals or values?
    </li>
  </ul>
</div>



  </div>

      </p>





      <p className="mt-4 text-gray-600">
      Now, it’s your turn to step into the shoes of a leader. Analyze these risks and write a response discussing the following:

<div class="">


<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Risk Assessment: For each risk mentioned, provide a detailed analysis. Consider the potential impact and likelihood of these risks.
    </li>
  </ul>
</div>

<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Rewards and Opportunities: Discuss the potential rewards and opportunities that starting "Green Innovators" could bring. Think about personal growth, societal impact, and the fulfillment of your environmental passion.
    </li>
  </ul>
</div>

<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Decision Making: Based on your risk assessment, make a decision. Would you go ahead with starting the society? Why or why not?
    </li>
  </ul>
</div>

<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Risk Mitigation Strategies: If you decide to proceed, outline strategies to mitigate the identified risks. How will you tackle financial constraints? What steps will you take to build reputation and manage operational challenges?
    </li>
  </ul>
</div>

<div class="mb-4">
  <ul class="list-disc pl-6">
    <li>
      Reflection on Leadership: Reflect on how this scenario relates to your understanding of risk-taking in leadership. What skills and qualities do you think are essential for managing such risks?
    </li>
  </ul>
</div>




  </div>

      </p>



      <ul
        className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          Instructions{" "}
        </p>

        <li className="m-6">Be concise yet thorough in your analysis.</li>
<li className="m-6">Use critical thinking to weigh risks against potential benefits.</li>
<li className="m-6">Ensure your response is structured logically, with clear arguments and justifications.</li>
<li className="m-6">Reflect on your personal leadership style and how it influences your decision-making process.</li>
<li className="m-6">Remember, there’s no right or wrong decision here. It’s about how you analyze, justify, and plan to manage risks. This exercise will enhance your leadership skills, preparing you for real-world challenges and opportunities.</li>

      </ul>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Response</span>
          </label>
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                response: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} disabled={status === 'Completed'}>
        Submit
      </button>
    </section>,

<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Week 13 Conclusion: Embracing Resilience</b>
      </h2>
      <p class="mt-4 text-gray-600">


      Congratulations on completingWeek 13 Conclusion: Embracing Resilience! This week has been instrumental in reinforcing your leadership capabilities, especially in areas that require resilience and the strength to overcome adversity. We've delved into the essence of resilience, a crucial attribute for leaders in our complex and often challenging world. This understanding is key to effectively managing the difficulties you may face, be it in your academic pursuits, personal growth, or while steering student-run societies. You've explored the crucial role of personal resilience, recognizing how your ability to recover from setbacks and maintain a positive outlook under pressure is fundamental in various scenarios, including leadership roles within student societies. You've learned about the importance of resilience in teamwork and how fostering a resilient culture within groups can lead to more robust and effective collaborations.
<br/><br/>Developing strategies to build and maintain resilience prepares you for diverse and challenging situations. This resilience forms a solid base for your academic, personal, and extracurricular endeavours. The "Resilience Reflection" activity provided an opportunity for introspection and application of your resilience skills, showcasing how these competencies are vital in real-world contexts, including those encountered in student-led projects.
<br/><br/>Reflecting on how to apply resilience in practical scenarios, especially within student societies, has equipped you to face obstacles with strength and adaptability. Congratulations on successfully navigating through this immersive exploration of resilience!
<br/><br/>As we move forward to Week 13 with a focus on Risk Taking, continue to apply and integrate your resilience skills. Stay engaged with the upcoming content and utilize your insights to further evolve as a leader who is not only resilient but also bold and innovative, both in academic settings and in your involvement with student societies.
<br/><br/>We are excited to see your continued progress in the next stage of your leadership journey!



</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "View Slides";

      case 3:
        return "Embarking on the Journey of Self-Awareness";

      case 4:
        return "SWOT Analysis";

      case 5:
        return "Peer Review | SWOT Analysis";

      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week13;
