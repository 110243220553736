// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek1 = ({ onImageIndexChange }) => {
  // const slideImages = [
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700214915/AHbranding/Slide_16_9_-_1_yyzdiq.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700214751/AHbranding/Slide_16_9_-_2_xsrzjy.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700214764/AHbranding/Slide_16_9_-_3_y6wqcd.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700214778/AHbranding/Slide_16_9_-_4_kjkxar.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700214886/AHbranding/Slide_16_9_-_6_pq0mer.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700214873/AHbranding/Slide_16_9_-_5_v1z44x.png',
  //   'https://res.cloudinary.com/ahbranding/image/upload/v1700214900/AHbranding/Slide_16_9_-_7_zqoj7f.png',
  // ];

  // const handleImageIndexChange = (index) => {
  //   if (onImageIndexChange) {
  //     onImageIndexChange(index);
  //   }
  // };


  const storedImages = localStorage.getItem('week1Images');
  const slideImages = storedImages ? JSON.parse(storedImages) : [];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek1;
