import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import "react-calendar/dist/Calendar.css";

import { Link } from "react-router-dom";
import axios from "axios";
import { HiMiniBell, HiOutlineBell, HiBell } from "react-icons/hi2";
import MobileNav from "./MobileNav";
import CryptoJS from 'crypto-js';

const Navbar = ({ toggleSidebar }) => {
  const [events, setEvents] = useState();
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [profile, setProfile] = useState();
  const userID = user["user_id"];

  let url = window.location.href;
  let ep = ''
  if (user["role"] === "Student") {
    ep = "profile";
  } else if (user["role"] === "Network Admin") {
    ep = "network_profile";
  }

  const [page, setPage] = useState("");

  // function extractAfter3000(url) {
  //   const match = url.match(/https?:\/\/pathways.startupearly\.com\/([^/0-9]+)/);
  //   if (match && match[1]) {
  //     return match[1];
  //   }
  //   return null; // Return null if the pattern is not found
  // }

  // function extractAfter3000(url) {
  //   const match = url.match(/https?:\/\/pathways.startupearly\.com\/([^/0-9]*)/);
  //   if (match && match[1]) {
  //     return match[1];
  //   }
  //   return null;
  // }


  function extractAfter3000(url) {
    const match = url.match(/https?:\/\/(?:pathways\.startupearly\.com|localhost:3000)\/([^/0-9]*)/);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }
  


  
  console.log("URL: " + url);
  console.log("Location: " + extractAfter3000(url));
  


  useEffect(() => {
    setPage(extractAfter3000(window.location.href));
  }, []);

  let contentTitle = "";
  let contentSubtitle = "";

  if (page === "student" || page === "network") {
    contentTitle = "Dashboard";
    contentSubtitle = "Overview of activities";
  } else if (page === "challenges") {
    contentTitle = "Challenges";
    contentSubtitle = "Details of current event";
  } else if (page === "weeks") {
    contentTitle = "List of Weeks";
    contentSubtitle = "Details of current event";
  } else if (page === "pathway") {
    contentTitle = "Guided Pathway";
    contentSubtitle = "Details of current event";
  } 
  
  else if (page === "week_0") {
    contentTitle = "Week 0: Curriculum";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "week_0") {
    contentTitle = "Week 0: Curriculum";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "week_1") {
    contentTitle = "Week 1: Introduction to Leadership and Self-Awareness";
    contentSubtitle = "Details of current event";
  } else if (page === "week_2") {
    contentTitle = "Week 2: Understanding and Harnessing Emotional Intelligence";
    contentSubtitle = "Details of current event";
  } else if (page === "week_3") {
    contentTitle = "Week 3: Effective Communication";
    contentSubtitle = "Details of current event";
  } else if (page === "week_4") {
    contentTitle = "Week 4: Empathy - The Heart of Leadership";
    contentSubtitle = "Details of current event";
  } else if (page === "week_5") {
    contentTitle = "Week 5: Team Building";
    contentSubtitle = "Details of current event";
  } else if (page === "week_6") {
    contentTitle = "Week 6: Ethical Leadership";
    contentSubtitle = "Details of current event";
  } else if (page === "week_7") {
    contentTitle = "Week 7: Critical Thinking";
    contentSubtitle = "Details of current event";
  } else if (page === "week_8") {
    contentTitle = "Week 8: Analytical Skills";
    contentSubtitle = "Details of current event";
  } else if (page === "week_9") {
    contentTitle = "Week 9: Strategic Thinking";
    contentSubtitle = "Details of current event";
  } else if (page === "week_10") {
    contentTitle = "Week 10: Creativity - Igniting Innovative Leadership";
    contentSubtitle = "Details of current event";
  } else if (page === "week_11") {
    contentTitle = "Week 11: Adaptability- The Keystone of modern leadership";
    contentSubtitle = "Details of current event";
  } else if (page === "week_12") {
    contentTitle = "Week 12: Resilience - Navigating Challenges with Strength";
    contentSubtitle = "Details of current event";
  } else if (page === "week_13") {
    contentTitle = "Week 13: Risk Taking";
    contentSubtitle = "Details of current event";
  } else if (page === "week_14") {
    contentTitle = "Week 14: Understanding and Addressing Harassment";
    contentSubtitle = "Details of current event";
  } else if (page === "week_15") {
    contentTitle = "Week 15: Reflection and Future Planning";
    contentSubtitle = "Details of current event";
  }

  else {
    contentTitle = "Weekly Curriculum";
    contentSubtitle = "Other Name Overview";
  }

  let fullname = user["first_name"] + " " + user["last_name"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  const [decryptedData, setDecryptedData] = useState({});

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_KEY}/api/${ep}/${userID}`)
  //     .then((response) => {
  //       const data = response.data;
  //       setProfileData(data);
  //       setProfile(data);
  //       setProfilePhotoUrl(data.profile_photo);
  //       //console.log("Profile Photo URL: ", data.profile_photo);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [userID]);

  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${ep}/${userID}`, config)
      .then((response) => {
        const resp_data = response.data;
        const key = 'c908ca7a8fbe5de8afcfd2bc6129c805'; // Same key used for encryption\\
        // const decryptedBytes = CryptoJS.AES.decrypt(encrypted_data, key);
        // const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8); // Assuming decrypted data is plain text

        setProfileData(resp_data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID, ep]);



  return (
    <div>
      <nav
        className="relative flex flex-wrap items-center justify-between px-0 md:px-8 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start "
        navbar-main=""
        navbar-scroll="false"
      >
        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
          <nav className="text-black">
            {/* breadcrumb */}

            {(() => {
        if (contentTitle==="Guided Pathway") {
          return (
            <h6 className="mb-0 font-bold text-black capitalize">
              <img className="w-16" src="https://res.cloudinary.com/ahbranding/image/upload/v1690535517/AHbranding/Start_Up_Early_Logo_vbp2fh.png" />
            </h6>
          )
        }else {
          return (
            <h6 className="mb-0 font-bold text-black ">
              {contentTitle}
            </h6>
          )
        }
      })()}
            
          </nav>
          <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
            <div className="flex items-center md:ml-auto md:pr-4 opacity-0">
              <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease">
                <span className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                  <i className="fas fa-search" />
                </span>
                <input
                  type="text"
                  className="hidden pl-9 text-sm focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300   bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                  placeholder="Type here..."
                />
              </div>
            </div>
            <ul
              className="flex flex-row items-center justify-end gap-2 pl-0 mb-0 list-none md-max:w-full"
              style={{ zIndex: "9999999999999999999999 !import" }}
            >
              {/* online builder   */}
              <li class="flex items-center">
                <div className="dropdown dropdown-end hidden">
                  <label
                    tabIndex={0}
                    className=" -circle bg-none border-0 text-black hvr:text-black"
                  >
                    <HiBell size={25} />
                  </label>

                  <ul
                    tabIndex={0}
                    className="dropdown-content hidden z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <a>Item 1</a>
                    </li>
                    <li>
                      <a>Item 2</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="flex items-center dropdown"
                style={{ zIndex: "9999999999999999999999 !important" }}
              >
                <a
                  tabIndex={0}
                  className=" border-0  m-1 flex flex-row items-center gap-2 p-0 text-sm font-semibold text-black transition-all ease-nav-brand"
                >
                  <img className="w-10 h-10 rounded-full" src={`https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png`} />

                  

                  <span className=" sm:inline">
                    {(() => {
                      if (user["role"] === "Network Admin") {
                        return (
                          <div className="flex flex-row gap-2 items-center dropdown dropdown-end">
                            {profileData?.network_name}{" "}
                            <small className="badge text-xs">
                              {" "}
                              {user.role}{" "}
                            </small>
                          </div>
                        );
                      } else if (user["role"] === "Student") {
                        return (
                          <div className="flex flex-row gap-2 text-black items-center dropdown dropdown-end">
                            {profileData?.firstname} {profileData?.lastname}{" "}
                            <small className="badge text-xs">
                              {" "}
                              {user.role}{" "}
                            </small>
                          </div>
                        );
                      }
                    })()}

                    <ul
                      tabIndex={0}
                      className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                      style={{ zIndex: "9999999999999999999999 !important" }}
                    >
                      {(() => {
                        if (user["role"] === "Student") {
                          return (
                            <li className="text-black hidden">
                              <Link
                                className="p-2"
                                to={
                                  user["role"] === "Network Admin" ||
                                  user["role"] === "School Admin"
                                    ? `/admin_profile`
                                    : `/student_profile`
                                }
                              >
                                Profile
                              </Link>
                            </li>
                          );
                        }
                      })()}

                      <li
                        onClick={logoutUser}
                        to="/login"
                        className="text-red-400 p-2"
                        style={{ zIndex: "9999999999999999999999 !important" }}
                      >
                        <a>Logout</a>
                      </li>
                    </ul>
                  </span>
                </a>
              </li>
              {/* <li
                className="flex items-center pl-4 xl:hidden   border-0"
                onClick={toggleSidebar}
              >
                <a
                  href="javascript:;"
                  className="block p-0 text-sm text-black transition-all ease-nav-brand"
                >
                  <div className="w-4.5 overflow-hidden">
                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all" />
                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all" />
                    <i className="ease relative block h-0.5 rounded-sm bg-white transition-all" />
                  </div>
                </a>
              </li> */}
              <li className="flex items-center px-4 hidden">
                <a
                  href="javascript:;"
                  className="p-0 text-sm text-black transition-all ease-nav-brand"
                >
                  <i
                    fixed-plugin-button-nav=""
                    className="cursor-pointer fa fa-cog"
                  />
                  {/* fixed-plugin-button-nav  */}
                </a>
              </li>
              {/* notifications */}
              <li className="relative flex items-center pr-2 hidden">
                <p className="hidden transform-dropdown-show" />
                <a
                  href="javascript:;"
                  className="block p-0 text-sm text-black transition-all ease-nav-brand"
                  dropdown-trigger=""
                  aria-expanded="false"
                >
                  <i className="cursor-pointer fa fa-bell" />
                </a>
                <ul
                  dropdown-menu=""
                  className="text-sm transform-dropdown before:font-awesome before:leading-default before:duration-350 before:ease lg:shadow-3xl duration-250 min-w-44 before:sm:right-8 before:text-5.5 pointer-events-none absolute right-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent   bg-white bg-clip-padding px-2 py-4 text-left text-slate-500 opacity-0 transition-all before:absolute before:right-2 before:left-auto before:top-0 before:z-50 before:inline-block before:font-normal before:text-black before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 lg:absolute lg:right-0 lg:left-auto lg:mt-2 lg:block lg:cursor-pointer"
                >
                  {/* add show class on dropdown open js */}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* <MobileNav source={contentTitle} img={profile?.avatar} /> */}
    </div>
  );
};
export default Navbar;
