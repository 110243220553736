import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { axiosInstance } from "../Axios";
import PhoneInput from "react-phone-number-input";
import toast from "react-hot-toast";

function PassWarn(props) {
  const [modalOpen, setModalOpen] = useState(true);
  useEffect(() => {
    const modal = document.getElementById("my_modal_5");
    if (modal) {
      modal.showModal();

      // Prevent closing on ESC
      window.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          event.preventDefault();
          // Optionally, inform the user that ESC won't close the dialog
          console.log("Pressing ESC will not close the dialog.");
        }
      });

      // Cleanup listener when component unmounts or modal gets closed
      return () => window.removeEventListener("keydown", this);
    }
  }, []);
  const [firstName, setFirstName] = useState(props.profile?.firstname);
  const [lastName, setLastName] = useState(props.profile?.lastname);
  const [email, setEmail] = useState(props.profile?.email);
  const [roll_number, setroll_number] = useState(props.profile?.roll_number);
  const [school, setschool] = useState(props.profile?.school);
  const [semester, setSemester] = useState(props.profile?.semester);
  const [contact, setContact] = useState(props.profile?.contact);

  const handleContactChange = (value) => {
    if (value.trim() !== '') {
      // Check if the entered value already starts with "+92"
      if (!value.startsWith('+92')) {
        // If not, prepend "+92" to the value
        setContact('+92' + value.trim());
      } else {
        // If it already starts with "+92", set the contact to the value as it is
        setContact(value.trim());
      }
    } else {
      // If empty value, set the contact to an empty string or whatever your default value should be
      setContact('');
    }
  };

  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const checkPasswordsMatch = () => {
    return password === confirmPassword;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordsMatch(
      e.target.value === confirmPassword && e.target.value !== ""
    );
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(e.target.value === password && e.target.value !== "");
  };
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      try {
        const response = await axiosInstance.post(`update_popup_info/`, {
          id: props.profile.id,
          new_password: password,
          firstname: firstName,
          lastname: lastName,
          email: email,
          contact: contact,
          roll_number:roll_number,
          school:school
        },config);

        if (response.status === 200) {
          setModalOpen(false);
          toast.success("Information Updated");
          console.warn(response);
        } else {
          toast.error("Error updating Information. Please reload the page.", {
            icon: "😰",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        }
      } catch (error) {
        toast.error("Error updating Information." + error, {
          icon: "😰",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        console.error(error);
      }
    } else {
      setPasswordsMatch(false);
    }
  };




  const validateEmail = (email) => {
    const emailRegex = /^[0-9]{8}@lums\.edu\.pk$/;
    return emailRegex.test(email);
  };
  
  // Function to validate roll number
  const validateRollNumber = (rollNumber, email) => {
    const rollNumberRegex = /^[0-9]{8}$/;
    return rollNumberRegex.test(rollNumber) && rollNumber === email.substring(0, 8);
  };
  
  // const validatePhone = (phone) => {
  //   const phoneRegex = /^[0-9]{11,15}$/;
  //   return phoneRegex.test(phone);
  // };

  const validatePhone = (phone) => {
    // Allow any combination of digits, spaces, and optional leading '+'
    // Ensure there are at least 6 digits
    const phoneRegex = /^[+\d\s]{6,}$/;
    return phoneRegex.test(phone);
  };

  const isEmailValid = validateEmail(email);
  const isRollNumberValid = validateRollNumber(roll_number, email);
  const isPhoneNumberValid = validatePhone(contact);

  console.warn('EMAIL: '+isEmailValid)
  console.warn('Roll: '+isRollNumberValid)
  console.warn('PHone: '+isPhoneNumberValid)

  return (
    <div>
      {/* <button className="btn" onClick={() => document.getElementById('my_modal_5').showModal()}>Open Modal</button> */}
      {modalOpen && (
        <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">

            <h3 className="font-bold text-lg flex flex-row gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="text-red-600 w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>
              Update your information to continue
            </h3>
            <p className="py-4">
              You're using a temporary password. To continue using your account,
              set a new password
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6 my-4">
              <div>
                <label
                  htmlFor="hs-firstname-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  First Name
                </label>
                <input
                  required
                  type="text"
                  name="hs-firstname-hire-us-1"
                  id="hs-firstname-hire-us-1"
                  className="input input-bordered w-full"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                       readOnly            />
              </div>
              <div>
                <label
                  htmlFor="hs-lastname-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  Last Name
                </label>
                <input
                  required
                  type="text"
                  name="hs-lastname-hire-us-1"
                  id="hs-lastname-hire-us-1"
                  className="input input-bordered w-full"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                       readOnly            />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6 my-4">
              <div>
                <label
                  htmlFor="hs-work-email-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  LUMS Email
                </label>

                <input
                  required
                  type="email"
                  name="hs-work-email-hire-us-1"
                  id="hs-work-email-hire-us-1"
                  autoComplete="email"
                  placeholder="12345678@lums.edu.pk"
                  className={`input input-bordered w-full`}
                  defaultValue={email}
                       readOnly            />
              </div>

              <div>
                <label
                  htmlFor="hs-work-email-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  LUMS Roll number
                </label>
                <input
                  required
                  type="email"
                  name="hs-work-email-hire-us-1"
                  id="hs-work-email-hire-us-1"
                  autoComplete="email"
                  placeholder="12345678"
                  className={`input input-bordered w-full`}
                  defaultValue={roll_number}
                       readOnly            />
              </div>
            </div>





            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6 my-4">
              <div>
                <label
                  htmlFor="hs-work-email-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  School
                </label>

                {/* <input
                  required
                  type="email"
                  name="hs-work-email-hire-us-1"
                  id="hs-work-email-hire-us-1"
                  autoComplete="email"
                  placeholder="eg: SDSB" 
                  className={`input input-bordered w-full`}
                  defaultValue={school}
                /> */}


<select
    required
    name="hs-work-email-hire-us-1"
    id="hs-work-email-hire-us-1"
    autoComplete="email"
    className={`input input-bordered w-full`}
    defaultValue={school}
>
    <option value="">Select School</option>
    <option value="MGSHSS">MGSHSS</option>
    <option value="SDSB">SDSB</option>
    <option value="SSE">SSE</option>
    <option value="SAHSOL">SAHSOL</option>
    <option value="SOE">SOE</option>
</select>           
              </div>

              <div>
                <label
                  htmlFor="hs-work-email-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  Contact
                </label>
                {/* <PhoneInput
                  placeholder="eg: +92300123456"
                  value={contact}
                  onChange={setContact}
                  defaultCountry="PK"
                  className="input input-bordered"
                  countries={["PK"]}
                  required
                /> */}

                <PhoneInput
      placeholder="eg: +92300123456"
      value={contact}
      onChange={handleContactChange}
      defaultCountry="PK"
      inputStyle={{ width: '100%' }}
      countries={['PK']}
      required
      className="input input-bordered"
    />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6 my-4">
              <div>
                <label
                  htmlFor="hs-firstname-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                 New Password
                </label>
                <input
                  required
                  type="text"
                  name="hs-firstname-hire-us-1"
                  id="hs-firstname-hire-us-1"
                  className="input input-bordered w-full"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div>
                <label
                  htmlFor="hs-lastname-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  Confirm New Password
                </label>
                <input
                  required
                  type="text"
                  name="hs-lastname-hire-us-1"
                  id="hs-lastname-hire-us-1"
                  className="input input-bordered w-full"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </div>
            </div>
            <div className="modal-action">
              <button
                className="btn btn-primary bg-primary text-white"
                //disabled={!passwordsMatch || !isEmailValid || !isRollNumberValid || !isPhoneNumberValid}
                onClick={handleSubmit}
              >
                Confirm & Save Changes
              </button>
            </div>
          </div>
        </dialog>
      )}
    </div>
  );
}

export default PassWarn;
