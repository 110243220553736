// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek6_2 = ({ onImageIndexChange }) => {
  const slideImages = [
    'https://res.cloudinary.com/ahbranding/image/upload/v1716210271/AHbranding/Slide_16_9_-_14_jniscl.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702387230/AHbranding/Slide_16_9_-_15_sv0krw.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1706245315/Slide_16_9_-_19_umab05.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702387231/AHbranding/Slide_16_9_-_16_n0nl1l.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1706245313/Slide_16_9_-_20_vtpjxj.png',
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek6_2;
