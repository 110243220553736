import React, { useState } from 'react';

const Collapse = ({ accordionData }) => {
  const [selectedAccordion, setSelectedAccordion] = useState(null);

  const handleAccordionChange = (index) => {
    setSelectedAccordion(index);
  };

  return (
    <div className="join join-vertical w-full">
      {accordionData.map((item, index) => (
        <div
          key={index}
          className={`collapse collapse-arrow join-item border border-base-300 ${
            selectedAccordion === index ? '' : ''
          }`}
        >
          <input
            type="radio"
            name="my-accordion-4"
            checked={selectedAccordion === index}
            onChange={() => handleAccordionChange(index)}
          />
          <div className="collapse-title text-primary text-base font-bold font-medium">{item.title}</div>
          <div className="collapse-content" style={{ whiteSpace: 'pre-line' }}>
            {item.sub && <h5 className='my-4'>{item.sub}</h5>}
            <p>{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Collapse;
