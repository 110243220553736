// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek8_2 = ({ onImageIndexChange }) => {
  const slideImages = [
    'https://res.cloudinary.com/ahbranding/image/upload/v1718171058/AHbranding/Slide_16_9_-_86_uupbbv.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1718171059/AHbranding/Slide_16_9_-_87_njppbu.png',

    'https://res.cloudinary.com/ahbranding/image/upload/v1718171056/AHbranding/Slide_16_9_-_88_nujkrc.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1718171057/AHbranding/Slide_16_9_-_89_g2ssps.png'

  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek8_2;
