import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
// import Lottie from "react-lottie";
import Curriculum from "./Curriculum";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import AboardSVG from '../guided_pathways/AboardSVG'
import ReactPlayer from 'react-player'
import { toast, Toaster } from 'react-hot-toast';
// import Vimeo from '@vimeo/player';
import Vimeo from '@u-wave/react-vimeo';
// import PasteDisabled from "./PasteDisabled";
// import { usePasteDisabled } from './PasteDisabled';
import Collapse from "../components/Collapse";

const Week0 = () => {
  // const { showAlert, handlePaste } = usePasteDisabled();

  const week_id = 1;
  const week_name = "week_0";
  const pathway_id = 1;
  const navigate = useNavigate();
  
  const videoId = '894027638';

  let { user } = useContext(AuthContext);
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress?.status;

  console.log(status,"===================?>>>")
  let section = server_progress?.section;
  // extract profile id
  let params = useParams();
  let profileID = user["user_id"];
  const [textareaContent, setTextareaContent] = useState("");

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const [RecheckBox,setRecheckBox] = useState(false)
  function countWords(text) {
    const trimmedText = text.trim();
    const words = trimmedText.split(/\s+/);
    return words.length;
  }
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [week_info, setweek_info] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);

  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });
        if (response.status === 200) {
          let cleanData = response.data.content.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
          setTextareaContent(cleanData);
        }
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,config);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };
    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 2, pathway_id);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/week/${week_id}`,config);
        let data = await response.json();
        setweek_info(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };
    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 2, pathway_id);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const videoId = "HBtDaHv8GC0"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  console.log(server_progress);

  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  // const [flag,setFlag] = useState(false)
  const [flag, setFlag] = useState(status === "Completed");

  const handleNext = () => {
    // if (status === "active" && currentStep === 2) {
    //   axios.post(`${process.env.REACT_APP_API_KEY}/api/text_response/`, {
    //     user_id: user.user_id,
    //     letter: textareaContent,
    //     week: "week_0",
    //     reviewable: false,
    //   });
    // }
    // if steps from server is greater than current step, then dont send put request
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id: 1,
                next_week_name: "week_1",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  

  const handleStepSubmission = () => {
    if (status === "Active") {
      axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
        user_id: user.user_id,
        week_name: week_name,
        week_id: week_id,
        section: currentStep,
        content: textareaContent,
        pathway: 1,
        status: "Reviewing",
        rating: 0,
        pr_enabled: false,
        activity_name: "Letter to yourself",
      },config)
      .then(response => {
          setFlag(true);
        // Handle success
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        // Additional success handling if needed
      })
      .catch(error => {
        // Handle error
        console.error('Error submitting activity:', error);
        toast.error('Oops! Something went wrong. Please try again later.', {
          icon: '❌',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        // Additional error handling if needed
      });
    }
  };

  const totalSteps = 4; // Total number of steps

  const handleTextareaChange = (event) => {
    setTextareaContent(event.target.value);
  };

  const accordionData = [
    { title: 'Explore the Curriculum', content: 'Familiarize yourself with the different modules and themes that will be covered in the journey. Each week will focus on a specific area of leadership, guiding you through a progressive learning journey.' },
    { title: 'Engage in Activities', content: 'Participate actively in the activities provided. These hands-on exercises are designed to challenge you, encourage critical thinking, and promote self-reflection. Completing each activity will contribute to your overall progress in the program.' },
    { title: 'Embrace Peer Reviews', content: 'Emphasizing a collaborative learning environment, peer reviews play a crucial role in Ascent. After completing an activity, you will have the opportunity to provide feedback to your peers and receive feedback in return. This exchange of insights and perspectives will enhance your understanding and foster growth.' },
    { title: 'Explore Additional Resources', content: 'In addition to the core curriculum, we offer a selection of suggested readings and talks to further expand your knowledge and broaden your leadership perspective. Feel free to explore these resources at your own pace and consider how they align with your personal and professional aspirations.' },
  ];

  // const isNextButtonDisabled =
  //   currentStep === 2 && textareaContent.trim() === "";

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to the Ascent Leadership Program {profile?.firstname} 🎊
      </h2>
      <p className="mt-4 text-gray-600">
        Congratulations on taking this brave step towards developing your
        leadership skills. We are thrilled to have you on board and excited to
        embark on this transformative journey together. Ascent is designed to
        equip you with the necessary skills and knowledge to become an
        exceptional leader.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >

        <div className="video-container">
          {/* <YouTube videoId={videoId} opts={opts} /> */}

          {/* <div ref={playerRef}></div> */}
          <Vimeo
      video={videoId}
      autoplay
    />
        </div>
          
        </div>
      </div>
    </section>,
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        A message from the <b>Dean of Student Affairs, LUMS</b>
      </h2>

      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
        <div className="video-container">
        <Vimeo
      video={900346104}
      autoplay
    />
        </div>
        </div>
      </div>
    </section>,
    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Letter to your future self.</b>
      </h2>
      <p className="mt-4 text-gray-600">

      Your task is to write a letter to your future self. In this letter, you can share your dreams, goals, and aspirations as a leader. Describe the kind of impact you wish to make and the person you want to become. This activity will help you set the tone for your leadership development and serve as a reference point for your growth throughout the program. The length of this letter should be at least 250 words.

      </p>

      <div className="mt-6 flex justify-start w-full">
        


        {status === "Completed" || flag ? (
              <div>
                <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">{textareaContent}</p>
              </div>
            ) : (

              <div className="w-full">

              <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Write Letter</span>
          </label>
          <textarea
                value={textareaContent}
                onChange={handleTextareaChange}
                className="textarea textarea-lg textarea-bordered h-32 w-full"
                placeholder="Type here..."
              ></textarea>
          <small className="mt-6 text-end text-gray-400"> {countWords(textareaContent)} / 250 words</small>
        </div>
        <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={RecheckBox} 
  onChange={() => {
    setRecheckBox(!RecheckBox); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Read your submission carefully before submitting</span>
      </div>
              </div>
            )}


      </div>



      {status !== "Completed" || !flag || countWords(textareaContent) < 250 ? (
  <button
    className="btn btn-primary my-8 bg-neutral-50"
    onClick={handleStepSubmission}
    disabled={status === 'Completed' || !RecheckBox || flag || countWords(textareaContent) < 250}
  >
    Submit
  </button>
) : null}

    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <AboardSVG/>
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>
                Envision your journey ahead!
              </b>
            </h2>
            <p className="mt-4 text-gray-600">
              We are thrilled to have you on board and excited to embark on this
              transformative journey together. To help you prepare for the
              program, we have provided a brief overview of the program. Ascent
              is designed to lay a strong groundwork for your leadership
              development. Throughout this journey, you will dive into key
              skills and concepts that form the building blocks of effective
              leadership. To make the most of your Ascent experience, we
              recommend following these steps:
            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center px-0">
                <div className="space-y-4">

                <Collapse accordionData={accordionData}/>
                  {/* <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Explore the Curriculum
                    </summary>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Familiarize yourself with the different modules and themes
                      that will be covered in the journey. Each week will focus
                      on a specific area of leadership, guiding you through a
                      progressive learning journey.{" "}
                    </p>
                  </details>
                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Engage in Activities
                    </summary>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Participate actively in the activities provided. These
                      hands-on exercises are designed to challenge you,
                      encourage critical thinking, and promote self-reflection.
                      Completing each activity will contribute to your overall
                      progress in the program.{" "}
                    </p>
                  </details>
                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Embrace Peer Reviews
                    </summary>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Emphasizing a collaborative learning environment, peer
                      reviews play a crucial role in Ascent. After completing an
                      activity, you will have the opportunity to provide
                      feedback to your peers and receive feedback in return.
                      This exchange of insights and perspectives will enhance
                      your understanding and foster growth.
                    </p>
                  </details>
                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Explore Additional Resources
                    </summary>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      In addition to the core curriculum, we offer a selection
                      of suggested readings and talks to further expand your
                      knowledge and broaden your leadership perspective. Feel
                      free to explore these resources at your own pace and
                      consider how they align with your personal and
                      professional aspirations.
                    </p>
                  </details> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
          <b>Note: </b>Remember, you are not alone in this journey. Connect with your fellow
          participants through discussions and collaborative activities and
          support each other's growth as you progress together. We are thrilled
          to have you as part of the Ascent community. Get ready to unlock your
          leadership potential and embark on an inspiring and transformative
          experience.
        </p>
      </div>
    </section>,

    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        {" "}
        <b>
        Curriculum Overview: Unleash Your Leadership Potential with Ascent

        </b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to the comprehensive and transformative curriculum of Ascent: Your Path to Leadership. This
thoughtfully designed program will empower you to embark on a remarkable journey of personal and
professional growth, equipping you with the skills and mindset necessary to thrive as a leader.
Throughout the Ascent curriculum, you will delve into key areas of leadership, immersing yourself in a
dynamic learning experience that combines theory, practical application, and collaborative exploration.
Each week brings a new theme, focusing on essential leadership skills and concepts, guiding you towards
becoming a well-rounded and impactful leader.
      </p>
      <div className="mt-6 ">
        <Curriculum />
      </div>
    </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction Video";

      case 1:
        return "Message from the Dean";

      case 2:
        return "Write a letter to yourself";

      case 3:
        return "Envision your journey ahead!";

      case 4:
        return "Curriculum Overview";

      default:
        return "Page";
    }
  };

  return (
    <div>
      <div>
        <>
          <div className="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl ">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}

                      // disabled={
                      //   currentStep === 2 && (countWords(textareaContent) < 250) ||
                      //   currentStep === 3 && (!flag)

                      //   }

                      disabled={
  (currentStep === 2 && countWords(textareaContent) < 250) ||
  (currentStep === 2 && !flag)
}

                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}

                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>
  );
};

export default Week0;
