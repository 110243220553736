import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import PeerReviewCount from "./PeerReviewCount";
import { axiosInstance } from "../Axios";

function AdminStats() {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem('authTokens'));
    const accessToken = authTokens.access;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  
    axios.get(process.env.REACT_APP_API_KEY + "/api/stats/", config)
      .then((res) => {
        const data = res.data;
        setStats(data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // code to run after modal opens
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [isShownMore, setisShownMore] = useState(false);

  const toggleVisibility = () => {
    setisShownMore(!isShownMore);
  };


  return (
    <>
      {/* Card Section */}
      <div className="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-4 lg:py-4 mx-auto">
        {/* Grid */}
       <>

       <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          <div className="flex flex-col bg-white shadow-sm rounded-xl rand:bg-slate-900 rand:border-gray-800">
            <div className="p-4 md:p-5">
              <div className="flex items-center gap-x-2">
                <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                  Total Students
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2">
                <h3 className="text-xl sm:text-2xl font-medium text-gray-800 rand:text-gray-200">
                  {stats.profiles} Attendees
                </h3>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow-sm rounded-xl rand:bg-slate-900 rand:border-gray-800">
            <div className="p-4 md:p-5">
              <div className="flex items-center gap-x-2">
                <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                  Total Pending
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2">
                <h3 className="text-xl sm:text-2xl font-medium text-gray-800 rand:text-gray-200">
                  {stats.pending} Requests
                </h3>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow-sm rounded-xl rand:bg-slate-900 rand:border-gray-800">
            <div className="p-4 md:p-5">
              <div className="flex items-center gap-x-2">
                <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                  Total Weeks
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2">
                <h3 className="text-xl sm:text-2xl font-medium text-gray-800 rand:text-gray-200">
                  {stats.weeks} Weeks
                </h3>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow-sm rounded-xl rand:bg-slate-900 rand:border-gray-800 " onClick={openModal}>
            <div className="p-4 md:p-5">
              <div className="flex items-center gap-x-2">
                <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                  Total Peers Submission
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2">
                <h3 className="text-xl sm:text-2xl font-medium text-gray-800 rand:text-gray-200">
                  {stats.peers}
                </h3>
              </div>
            </div>
          </div>


          <div className="flex flex-col bg-white shadow-sm rounded-xl rand:bg-slate-900 rand:border-gray-800">
            <div className="p-4 md:p-5">
              <div className="flex items-center gap-x-2">
                <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                  Logged in students
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2">
                <h3 className="text-xl sm:text-2xl font-medium text-gray-800 rand:text-gray-200">
                  {stats.first_login_false_count}
                </h3>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-white shadow-sm rounded-xl rand:bg-slate-900 rand:border-gray-800">
            <div className="p-4 md:p-5">
              <div className="flex items-center gap-x-2">
                <p className="text-xs m-0 uppercase tracking-wide text-gray-500">
                  Dead accounts
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2">
                <h3 className="text-xl sm:text-2xl font-medium text-gray-800 rand:text-gray-200">
                  {stats.first_login_true_count}
                </h3>
              </div>
            </div>
          </div>

        </div>


       </>
        {/* End Grid */}





{(() => {
        if (isShownMore) {
          return (
            <>
              
        <ul className="grid grid-cols-4 mt-6">
  <li className="inline-flex justify-center items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-red-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">

    Week 0 Locked <b>{stats.week0_locked}</b>
  </li>
  <li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-blue-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

  Week 0 Active <b>{stats.week0_active}</b>
</li>


<li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-green-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

Week 0 Completed <b>{stats.week0_completed}</b>
</li>


<li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-green-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

Week 0 Completion rate <b> {Math.round(stats.completion_rate_w0)}%</b>
</li>
</ul>




<ul className="grid grid-cols-4 mt-6">
  <li className="inline-flex justify-center items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-red-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">

    Week 1 Locked <b>{stats.week1_locked}</b>
  </li>
  <li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-blue-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

  Week 1 Active <b>{stats.week1_peer_not_feedback_recieved}</b>
</li>


<li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-green-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

Week 1 Completed <b>{stats.week1_peer_feedback_recieved}</b>
</li>

<li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-green-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

Week 1 Completion rate <b>{Math.round(stats.completion_rate_w1_2)} %</b>
</li>

</ul>



<ul className="grid grid-cols-4 mt-6">
  <li className="inline-flex justify-center items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-red-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">

    Week 1 peer not assigned <b>{stats.week1_peer_not_assigned}</b>
  </li>

  <li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-green-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

Week 1 peer assigned <b>{stats.week1_peer_assigned}</b>
</li>


  <li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-blue-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

  Week 1 pending peer feedback <b>{stats.week1_peer_not_feedback_recieved}</b>
</li>




<li className="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-green-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white justify-center">

Week 1 peer feedback received <b>{stats.week1_peer_feedback_recieved}</b>
</li>

</ul>



<div className="flex justify-center mt-6">
<button className="btn btn-ghost text-primary" onClick={toggleVisibility}>Hide more <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
</svg>

</button>
</div>

            </>
          )
        } else {
          return (
            <div className="flex justify-center mt-6">
<button className="btn btn-ghost text-primary" onClick={toggleVisibility}>Show more <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
</button>
</div>
          )
        }
      })()}


      </div>
      {/* End Card Section */}


      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 className="text-xl text-black">Submissions Breakdown</h2>

        <div className="mt2">
            <PeerReviewCount/>
        </div>

        <button className="btn btn-ghost" onClick={closeModal}> Close </button>
        
      </Modal>
    </>
  );
}

export default AdminStats;
