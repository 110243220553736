import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";
import SliderWeek10 from "./SliderWeek10";
import SliderWeek10_2 from "./SliderWeek10_2";
import QuizComponent from "./QuizComponent";

const Week10 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 11;
  const week_name = "week_10";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,config
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_11",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        },config);
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: true,
          activity_name: "Innovation Challenge",
        },config);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };

  const totalSteps = 9; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };





  const [currentQuestion2, setCurrentQuestion2] = useState(0);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [showExplanation2, setShowExplanation2] = useState(false);

  const handleOptionSelect2 = (option2) => {
    setSelectedOption2(option2);
  };

  const handleNextQuestion2 = () => {
    setShowExplanation2(true);
  };

  const handleNextQuestionOrFinish2 = () => {
    if (currentQuestion2 + 1 < questionsData2.length) {
      setCurrentQuestion2(currentQuestion2 + 1);
      setSelectedOption2("");
      setShowExplanation2(false);
    }
  };



  const questionsData = [
    {
      question: "Always aiming for perfection in the first attempt at idea generation is crucial for creativity.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Combining unrelated ideas or concepts can lead to innovative solutions.",
      options: ["Truth ", "Myth"],
      correctAnswer: "Truth",
    },
    {
      question: "Creativity is only about generating completely new ideas, never about modifying existing ones.",
      options: ["Truth", "Myth "],
      correctAnswer: "Myth",
    },
    {
      question: "Constraints and limitations hinder the creative process and should always be avoided.",
      options: ["Truth", "Myth "],
      correctAnswer: "Myth",
    },
    {
      question: "Using structured techniques for creative thinking stifles spontaneity and genuine innovation.",
      options: ["Truth", "Myth "],
      correctAnswer: "Myth",
    },
  ];
  
  
  const questionsData2 = [
    {
      question: "All creative ideas should be pursued regardless of their feasibility and alignment with goals.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Providing feedback on creative ideas should focus on the personal traits of the idea's originator.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "The most innovative ideas are always the most practical ones to implement.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Assessing the potential impact and risks is unnecessary when evaluating creative ideas.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Analyzing past creative successes and failures can provide valuable lessons for future idea assessments.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
  ];
  


  const accordionData = [
    {
      title: 'Stage 1: Ideation and Conceptualization',
      content: (
        <div>
          <p>The starting point of the creative process is ideation, where you generate and conceptualize ideas.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I capture and develop the initial ideas that come to me?</li>
            <li>What methods do I use to expand and explore these ideas more deeply?</li>
            <li>How can brainstorming sessions or creative workshops enhance this stage?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Stage 2: Experimentation and Exploration',
      content: (
        <div>
          <p>Experimentation is about testing and exploring the feasibility and potential of your ideas.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>In what ways do I experiment with different aspects of my ideas?</li>
            <li>How do I evaluate the effectiveness of these experiments?</li>
            <li>What role does feedback play in my process of experimentation and how do I integrate it?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Stage 3: Refinement and Revision',
      content: (
        <div>
          <p>Refinement involves critically assessing and improving your ideas based on feedback and insights.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How do I refine my ideas to enhance their quality and impact?</li>
            <li>What criteria do I use to decide what changes to make during the revision process?</li>
            <li>How do I balance being open to changes with staying true to the original vision?</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Stage 4: Execution and Implementation',
      content: (
        <div>
          <p>The final stage is bringing your idea to life through careful planning and execution.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What steps do I take to turn my refined idea into reality?</li>
            <li>How do I plan and organize the resources needed for implementation?</li>
            <li>What strategies do I use to overcome challenges during the execution phase?</li>
          </ul>
        </div>
      ),
    },
  ];
  
  


  const accordionData2 = [
    {
      title: 'Focus on the Principles',
      content: 'Consider the broader impact of your actions by framing your decisions around ethical principles rather than short-term gains. Research indicates that leaders who prioritize ethical standards over immediate results cultivate a more loyal, trusting, and high-performing team.'
    },
    {
      title: 'Distinguishing Ethics from Morals',
      content: 'Ground your leadership approach in a clear understanding of both ethics and morals. While morals often stem from individual beliefs and cultural backgrounds, ethics typically refer to the accepted standards of behavior in professional and public domains. Appreciating this distinction can enhance your ability to navigate complex situations with clarity and confidence, creating an environment where ethical considerations guide the way to sustainable success.'
    },
  ];
  

  const accordionData3 = [
    {
      title: 'Vision for the Future',
      content: 'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.'
    },
    {
      title: 'Ethical Foundations',
      content: 'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.'
    },
    {
      title: 'Environmental Stewardship',
      content: 'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.'
    },
    {
      title: 'Community Enrichment',
      content: 'Sustainable leadership involves a commitment to the well-being of the community. Leaders invest in social initiatives, support educational programs, and engage in partnerships that address social issues. Their legacy is marked by the positive impact on the community’s health, wealth, and social fabric.'
    },
  ];


  const accordionData82 = [
    {
      title: 'Cultivate a Shared Vision',
      content: '- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n' +
        'Reflect on your recent team interactions:\n' +
        '- How have I communicated and fostered a shared vision within the team?\n' +
        '- In what ways have I ensured that everyone understands and is committed to our collective goals?\n' +
        '- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?',
    },
    {
      title: 'Analyse and Set SMART Goals',
      content: '- Effective goal setting is a collaborative and strategic process. Evaluate your team\'s approach to setting goals:\n' +
        '- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n' +
        '- Where can we improve in making our goals more aligned with our strategic vision?\n' +
        '- How can I lead the team to break down our larger vision into achievable, strategically informed goals?',
    },
    {
      title: 'Encourage Participation in Vision and Goal Setting',
      content: '- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n' +
        '- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n' +
        '- How can I better ensure that all voices are heard and considered in shaping our team\'s direction?\n' +
        '- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team\'s cohesion?',
    },
  ];
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];



  const accordionData7 = [

    {
      title: 'Additional Resources for Further Learning ',
      content: (
        <div>
          <p>
            Originals: How Non-Conformists Move the World" by Adam Grant, Chapter 3 – Out on a Limb
          </p>
          <p>
            Change by Design" by Tim Brown, Chapter 4 – Defining the Problem
          </p>
          <p>
            Creativity, Inc." by Ed Catmull, Chapter 7 – Practising Creativity
          </p>
          <p>
            Ted Talk: "Your Elusive Creative Genius" by Elizabeth Gilbert
          </p>
          <p>
            Writing Down the Bones" by Natalie Goldberg, Chapter 6 – Writing and the Creative Life
          </p>
        </div>
      ),
    },
  ];
  
  
  
  
  const cardData = [
    {
      id: 1,
      frontContent: 'Seek Diverse Sources of Inspiration',
      backContent: (
        <div>
          <p>
            Broadening your horizons is key to finding new ideas. Reflect on your recent experiences:
          </p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What varied sources of inspiration have I explored recently (books, art, nature, conversations)?</li>
            <li>How can I incorporate diverse stimuli into my daily life to fuel my creative thinking?</li>
            <li>What strategies can I use to regularly step out of my comfort zone and experience new things?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Connect the Dots in Unusual Ways',
      backContent: (
        <div>
          <p>
            Creativity often comes from making unexpected connections. Think about a recent project or challenge:
          </p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How can I link different ideas or concepts to create something new?</li>
            <li>What techniques, like mind mapping or analogy, can I use to see these connections more clearly?</li>
            <li>How can I encourage my team or society members to think about connections between diverse ideas or disciplines?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Create a Safe Space for Idea Sharing and Development',
      backContent: (
        <div>
          <p>
            A supportive environment is crucial for idea generation to thrive. Reflect on the group dynamics within your team or society:
          </p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How am I fostering a safe space for sharing and developing ideas?</li>
            <li>What steps can I take to ensure that all voices are heard and valued, especially those that are often quieter or less confident?</li>
            <li>How can I model and encourage constructive feedback and collaborative idea refinement?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  


  const cardData2 = [
    {
      id: 1,
      frontContent: 'Explore how to integrate creativity into team dynamics.',
      backContent: (
        <div>

          <strong>Habits to Challenge:</strong>
          <p>Are you effectively facilitating creative teamwork?</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Homogeneous Thinking: Encourage a variety of viewpoints and ideas within your team.</li>
            <li>Hierarchical Restriction: Foster an environment where every team member feels empowered to contribute creatively.</li>
            <li>Sticking to the Status Quo: Promote a culture of experimentation and embrace change.</li>
            <li>Ignoring Non-Conventional Ideas: Value and explore unconventional ideas that arise during brainstorming.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Practical Application of Team Creativity',
      backContent: (
        <div>
          
          <strong>Habits to Challenge:</strong>
          <p>Are the creative ideas being effectively realized in your projects?</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Ineffective Idea Integration: Develop strategies to effectively incorporate diverse creative inputs into tangible projects.</li>
            <li>Overlooking Implementation Challenges: Anticipate and plan for potential obstacles in bringing creative ideas to life.</li>
            <li>Resistance to Adaptation: Be flexible and open to modifying ideas as projects evolve.</li>
            <li>Siloed Creativity: Ensure that creativity is not isolated within certain subgroups but is a collective effort.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Refining Collaborative Creativity',
      backContent: (
        <div>
          
          <strong>Habits to Challenge:</strong>
          <p>Are you continuously improving your team's creative process?</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Complacency in Success: Encourage continuous improvement, even in successful projects.</li>
            <li>Not Learning from Failures: View setbacks as valuable learning opportunities for creative growth.</li>
            <li>Limited Feedback Mechanisms: Establish robust channels for feedback and constructive criticism.</li>
            <li>Avoiding Creative Risk-Taking: Encourage calculated risks and bold ideas without fear of failure.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Cultivating a Creative Team Environment',
      backContent: (
        <div>
          
          <strong>Habits to Challenge:</strong>
          <p>Are you providing a healthy environment for the team?</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Regular Creative Workshops: Organize sessions dedicated to nurturing and practicing creative thinking as a team.</li>
            <li>Diverse Team Composition: Actively seek and include members from various backgrounds to enrich the pool of ideas.</li>
            <li>Open Communication Channels: Maintain transparent and open communication to encourage the free flow of creative ideas.</li>
            <li>Celebrating Creative Contributions: Recognize and celebrate the creative inputs of team members to motivate continued innovation.</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b> Week 10: Creativity</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 10 of the "Ascent" leadership pathway: Creativity - Igniting Innovative Leadership. Creativity is often seen as the spark that ignites new ideas, drives innovation, and leads to ground-breaking solutions in leadership. It goes beyond artistic expression; it's about thinking differently, challenging norms, and envisioning what could be. This week, we will explore the vast landscape of creativity in leadership, learning how to harness it not just as a skill but as a mindset. We'll delve into practical techniques for creative thinking, discover how to foster idea generation, navigate the creative process, and enhance teamwork through collaborative creativity. Prepare to expand your horizons and transform your approach to challenges as we journey into the realm of inventive leadership. Let’s not just think outside the box, but reshape it entirely.      
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
      {/* <Quote content={"Ethics is knowing the difference between what you have a right to do and what id right to do."} person={"Potter Stewart"}/> */}

      {/* <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div> */}
      </div>
    </section>,
    <section
    className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    style={{ height: "100%" }}
    >
    <div className="mt-6 flex justify-start w-full">
      <SliderWeek10 className='w-full' onImageIndexChange={handleImageIndexChange} />
    
    </div>
    </section>,




<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b></b>
</h2>
<p className="mt-4 text-gray-600">
</p>



<QuizComponent questionsData={questionsData} />


</section>,



<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b> Encouraging Inspiration and Ideas: Nurturing Creative Thoughts
 </b>
        </h2>
        <p className="mt-4 text-gray-600">

        In this journey of creativity, the role of a leader is akin to a conductor orchestrating a symphony of ideas. The process of inspiration and idea generation is akin to gathering diverse musical notes to compose a harmonious melody. It’s about exploring different perspectives, connecting unique insights, and constructing a tapestry of ideas that, together, form innovative solutions. This creative endeavour requires not just an open mind but also a keen eye for detail and the agility to connect ideas that at first glance, might seem unrelated.



        </p>
<div className="grid grid-cols-1 ">
{cardData.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}

</div>
</section>,




<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b> Explore how to integrate creativity into team dynamics.
 </b>
        </h2>
        <p className="mt-4 text-gray-600">

        In student-run societies and leadership roles, harnessing the collective creative potential of your team is essential. It's about creating an environment where diverse ideas flourish and innovation is the norm. Are you leveraging the unique perspectives of your team members? Do your team dynamics foster collaborative creativity? Incorporating creativity into team settings not only leads to more innovative outcomes but also builds a culture of shared ownership and dynamic thinking.


        </p>
<div className="grid grid-cols-1 ">
{cardData2.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}

</div>
</section>,


<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
<div className="mt-6 flex justify-start w-full">
  <SliderWeek10_2 className='w-full' onImageIndexChange={handleImageIndexChange} />

</div>
</section>,




<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
<b></b>
</h2>
<p className="mt-4 text-gray-600">
</p>


<QuizComponent questionsData={questionsData2} />


</section>,



    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">


          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Creativity - Mastering the Creative Process</b>
            </h2>
            <p className="mt-4 text-gray-600">

            Moving from the realms of inspiration and idea generation, we now enter the critical stage of the Creative Process. This phase is crucial in the journey of creativity, as it involves transforming initial ideas, those fleeting sparks of inspiration, into tangible, innovative outcomes. The Creative Process is a multifaceted journey that guides student leaders and society members from the birth of an idea to its successful realisation. Understanding and effectively navigating this process is essential for transforming creative concepts into impactful and successful initiatives.
            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData}/>
                </div>
              </div>
            </div>
          </div>
        </div>

  
      </div>
    </section>,
  







    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Innovation Challenge - Tackling Real-World Problems
</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to the exciting Innovation Challenge, a key part of Week 10 in the Ascent Leadership Program. This challenge is your opportunity to showcase creative thinking and strategic problem-solving in addressing real-life issues. This activity invites you to identify a pressing problem within the LUMS community and propose a creative solution utilizing the knowledge and skills you've acquired in Week 10 of the Ascent Leadership Program.      
      </p>



      <p className="mt-4 text-gray-600">
<strong>The Task:</strong><br/>
<br/>
<div class="">

    <div class="mb-4">
      <h5 class="text-base font-semibold mb-2">Identifying the Problem:</h5>
      <ul class="list-disc pl-6">
        <li>Reflect on the LUMS environment to identify a specific problem that affects students, faculty, or the broader community.</li>
      </ul>
    </div>

    <div class="mb-4">
      <h5 class="text-base font-semibold mb-2">Problem's Significance:</h5>
      <ul class="list-disc pl-6">
        <li>Consider the problem's significance: Why is it important? How does it impact the LUMS community?</li>
      </ul>
    </div>

    <div class="mb-4">
      <h5 class="text-base font-semibold mb-2">Brainstorming Solutions:</h5>
      <ul class="list-disc pl-6">
        <li>Brainstorm innovative solutions, drawing on the creative thinking techniques covered this week.</li>
      </ul>
    </div>

    <div>
      <h5 class="text-base font-semibold mb-2">Detailed Proposal:</h5>
      <ul class="list-disc pl-6">
        <li>A clear description of the problem and its significance.</li>
        <li>A detailed explanation of your proposed solution, including how it will work in the LUMS context.</li>
        <li>An argument for why your solution is innovative and how it differs from conventional approaches.</li>
      </ul>
    </div>

  </div>

      </p>

      <ul
        className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          Instructions{" "}
        </p>

        <li className="m-6">Your submission should be a maximum of 500 words.</li>
      <li  className="m-6">Focus on creativity and practicality. The solution should be innovative yet feasible within the LUMS environment.</li>
      <li className="m-6">Provide clear and logical reasoning for why your solution would effectively address the identified problem.</li>
      <li className="m-6">Think outside the box. Consider solutions that go beyond the obvious and demonstrate your ability to think creatively.</li>
      <li  className="m-6">Ensure your proposal is well-structured, with a clear introduction, body, and conclusion.</li>
    

      </ul>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Response</span>
          </label>
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                response: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} disabled={status === 'Completed'}>
        Submit
      </button>
    </section>,

<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Congratulations on completing Week 10: Embracing Creativity! </b>
      </h2>
      <p class="mt-4 text-gray-600">
      This week has been an essential step in your journey towards becoming a leader who harnesses the power of innovative thinking and imagination. We've dived deep into what creativity means, a critical attribute for leaders in a world that values original and innovative problem-solving. Your new understanding of creativity is a powerful tool in your academic, personal growth, and leadership within student societies. You've learned and practised various creative thinking techniques, essential for generating and developing ideas in unique ways. This skill is vital in cultivating a mindset that consistently seeks out original solutions and perspectives. We've navigated the stages of the creative process, from the birth of an idea to its realisation. This journey is crucial for transforming abstract concepts into concrete, impactful outcomes, especially in leading student-run initiatives. You've explored the importance of fostering creativity within team settings. This knowledge is key to building an environment where diverse ideas flourish and innovation thrives. Developing a creative mindset prepares you to approach challenges with an open and imaginative perspective, crucial in both personal and professional spheres. Through activities and discussions, we've seen practical applications of creative thinking in real-life scenarios, especially relevant in the context of student societies and leadership roles. Well done on successfully mastering the art of creativity! As we advance to Week 11, focusing on Mastering Adaptability, continue to apply your creative insights. Engage with the new content actively, using your creativity to adapt and grow as a leader who is imaginative, innovative, and ready for any challenge, whether in academic environments or in your contributions to student societies. Excited to see your continued growth in the next phase of your leadership journey!
</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";
      default:
        return "Week 10";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week10;
