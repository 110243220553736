import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";

const Week9 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 10;
  const week_name = "week_9";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,config
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_10",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        },config);
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: true,
          activity_name: " Entering the E-Commerce Market in Pakistan",
        },config);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };

  const totalSteps = 7; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };


  const questionsData = [
    {
      question: "Scenario planning in student societies is only about predicting and preparing for risks.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Engaging members in scenario planning can limit the society's ability to forecast effectively.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Flexibility in strategy is unnecessary if the initial plan is well-thought-out and robust.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Understanding and applying forecasting tools enhances the ability to anticipate future trends and challenges.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
    {
      question: "Scenario planning should only focus on the most likely future scenario, ignoring less probable outcomes.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
  ];
  



  const accordionData = [
    {
      title: 'Cultivating a Vision for LES',
      content: 'Problem:\nLES faces stagnation, needing fresh vision and direction.\n\nShameer’s Approach:\nHe initiates a vision-setting exercise, encouraging members to envision future possibilities for LES. This fosters a shared vision and aligns the team\'s efforts towards common long-term objectives.\n\n',
    },
    {
      title: 'Adapting to Market Trends',
      content: 'Problem:\nRapid changes in the entrepreneurial landscape present new challenges and opportunities for LES.\n\nShameer’s Approach:\nHe emphasizes the importance of staying informed and adaptable. Together with his team, he analyzes market trends and adjusts LES\'s events\' marketing accordingly, showcasing strategic thinking in a dynamic environment.\n\n',
    },
    {
      title: 'Leveraging Team Diversity',
      content: 'Problem:\nLES comprises members with diverse skills and entrepreneurial interests.\n\nShameer’s Approach:\nRecognizing the power of diverse perspectives, Shameer strategically aligns team members with initiatives that suit their strengths and interests, thereby maximizing the society\'s potential and fostering innovation.\n\n',
    },
    {
      title: 'Balancing Growth and Sustainability',
      content: 'Problem:\nLES seeks to expand its reach while ensuring its initiatives are sustainable.\n\nShameer’s Approach:\nHe adopts a balanced approach, carefully planning growth initiatives while considering their long-term sustainability and impact.\n',
    },
  ];
  


  const accordionData2 = [
    {
      title: 'Focus on the Principles',
      content: 'Consider the broader impact of your actions by framing your decisions around ethical principles rather than short-term gains. Research indicates that leaders who prioritize ethical standards over immediate results cultivate a more loyal, trusting, and high-performing team.'
    },
    {
      title: 'Distinguishing Ethics from Morals',
      content: 'Ground your leadership approach in a clear understanding of both ethics and morals. While morals often stem from individual beliefs and cultural backgrounds, ethics typically refer to the accepted standards of behavior in professional and public domains. Appreciating this distinction can enhance your ability to navigate complex situations with clarity and confidence, creating an environment where ethical considerations guide the way to sustainable success.'
    },
  ];
  

  const accordionData3 = [
    {
      title: 'Vision for the Future',
      content: 'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.'
    },
    {
      title: 'Ethical Foundations',
      content: 'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.'
    },
    {
      title: 'Environmental Stewardship',
      content: 'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.'
    },
    {
      title: 'Community Enrichment',
      content: 'Sustainable leadership involves a commitment to the well-being of the community. Leaders invest in social initiatives, support educational programs, and engage in partnerships that address social issues. Their legacy is marked by the positive impact on the community’s health, wealth, and social fabric.'
    },
  ];


  const accordionData82 = [
    {
      title: 'Cultivate a Shared Vision',
      content: '- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n' +
        'Reflect on your recent team interactions:\n' +
        '- How have I communicated and fostered a shared vision within the team?\n' +
        '- In what ways have I ensured that everyone understands and is committed to our collective goals?\n' +
        '- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?',
    },
    {
      title: 'Analyse and Set SMART Goals',
      content: '- Effective goal setting is a collaborative and strategic process. Evaluate your team\'s approach to setting goals:\n' +
        '- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n' +
        '- Where can we improve in making our goals more aligned with our strategic vision?\n' +
        '- How can I lead the team to break down our larger vision into achievable, strategically informed goals?',
    },
    {
      title: 'Encourage Participation in Vision and Goal Setting',
      content: '- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n' +
        '- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n' +
        '- How can I better ensure that all voices are heard and considered in shaping our team\'s direction?\n' +
        '- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team\'s cohesion?',
    },
  ];
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];



  const accordionData7 = [
    {
      title: 'Additional Resources for Further Learning',
      content:
        '- Playing to Win: How Strategy Really Works by A.G. Lafley and Roger L. Martin, Chapter 4 – Where to Play\n' +
        '- Think Smarter by Michael Kallet, Chapter 3 – Strategic Thinking\n' +
        '- Blue Ocean Strategy by W. Chan Kim and Renée Mauborgne, Chapter 3 - Reconstruct Market Boundaries\n' +
        '- Good Strategy/Bad Strategy by Richard Rumelt, Chapter 1 – What is Strategy\n',
    },
  ];
  
  
  
  const cardData = [
    {
      id: 1,
      frontContent: 'SWOT Analysis',
      backContent: (
        <div>
          <p>
            SWOT Analysis involves assessing an organization's internal strengths and weaknesses, as well as external opportunities and threats. Shameer uses this framework to understand LES's position, identify areas for improvement, and uncover opportunities for growth.
          </p>
          <ul style={{ listStyle: 'disc' }}>
            <li>Reflect on how you can apply SWOT Analysis to evaluate your student society’s current standing and future potential.</li>
            <li>Analyze your society’s internal capabilities and external environment. How do these insights inform your strategic planning?</li>
            <li>Consider how SWOT Analysis can guide your decision-making and strategy development in student-led initiatives.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'PESTLE Analysis',
      backContent: (
        <div>
          <p>
            PESTLE Analysis helps in understanding the macro-environmental factors that can impact an organization. Shameer employs this framework to comprehend the broader context in which LES operates, ensuring that his strategies are aligned with external realities.
          </p>
          <ul style={{ listStyle: 'disc' }}>
            <li>Think about how PESTLE Analysis can help you grasp the larger societal and economic factors affecting LUMS and its societies.</li>
            <li>Evaluate how these external factors influence your society’s strategies and operations.</li>
            <li>Reflect on the application of PESTLE Analysis in enhancing your strategic thinking and societal leadership.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: "Porter's Five Forces",
      backContent: (
        <div>
          <p>
            Porter's Five Forces is a tool for analyzing an industry's competitive forces and structure. In the context of LES, Shameer utilizes this framework to understand the competitive landscape of entrepreneurial ventures and student initiatives.
          </p>
          <ul style={{ listStyle: 'disc' }}>
            <li>Consider how Porter’s Five Forces can be adapted to assess the competitive environment of student societies at LUMS.</li>
            <li>Analyze the dynamics of competition, the threat of new entrants, bargaining power of suppliers and buyers, and the threat of substitute products or services.</li>
            <li>Explore how this understanding can inform your strategic choices and help LES, or other societies, carve a niche in the university landscape.</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  
  


  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Week 9: Strategic Thinking</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 9 of your leadership journey! This week, we delve deep into the vital skill of "Strategic Thinking." Brace yourself to expand your horizon and enhance your strategic acumen as we navigate the world of forward-looking vision and long-term planning. Discover how strategic thinking is pivotal for leaders to identify opportunities, foresee challenges, and chart successful paths for their organizations and societies. This week is dedicated to refining your ability to think strategically, equipping you with the insights and tools needed to lead with vision and wisdom in an ever-evolving landscape. Get ready to transform your approach to leadership, embracing a mindset that prioritizes long-term success and thoughtful planning.      </p>
      <div className="mt-6 flex justify-center w-full h-full">
      {/* <Quote content={"Ethics is knowing the difference between what you have a right to do and what id right to do."} person={"Potter Stewart"}/> */}

      {/* <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div> */}
      </div>
    </section>,
    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">


          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Introduction: Strategic Thinking</b>
            </h2>
            <p className="mt-4 text-gray-600">
            As emerging leaders at LUMS, gearing up to navigate complex challenges and forge impactful paths, mastering "Strategic Thinking" is essential. This week, in "Strategic Thinking," we focus on the journey of Shameer, a fellow LUMS student who recently stepped into the role of President of LES (LUMS Entrepreneurial Society).
<br/><br/>
Shameer's story is a vibrant illustration of the role of strategic thinking in steering a dynamic and ambitious team. His experiences shed light on how strategic thinking is pivotal in charting long-term goals, seizing opportunities, and enhancing organizational growth within the vibrant arena of student-led initiatives.
<br/>
Throughout this week, ponder:
- How does Shameer employ Strategic Thinking in his leadership role?
- What lessons can we draw from Shameer's strategic approach to bolster our own leadership abilities?
<br/><br/>
Shameer's narrative is set to unravel the essence of Strategic Thinking in leadership. It underscores how envisioning the future, anticipating challenges, and crafting innovative strategies can lead to sustained success and impactful solutions.

<br/><br/>
<strong>
Embarking on Shameer's Journey:
</strong>

<p>As the President of LES, Shameer encounters diverse challenges. He quickly understands that his role is more than just spearheading ventures – it's about creating a vision and guiding the society towards long-term success.</p>

            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
          Through his experience, Shameer develops a deep appreciation for strategic thinking. He navigates through intricate scenarios, lays the groundwork for innovative initiatives, and leads his team towards a successful and sustainable future. His story demonstrates that strategic thinking is about more than just planning; it's about envisioning a better future and methodically working towards it. Shameer's journey with LES serves as a compelling model for all LUMS students, highlighting strategic thinking as a crucial element in their leadership arsenal.
        </p>
      </div>
    </section>,
  



<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b> Starting Your Journey of Strategic Frameworks
 </b>
        </h2>
        <p className="mt-4 text-gray-600">

        As we forge ahead in our leadership development at LUMS, this week brings us to the critical component of strategic frameworks. Like Shameer, the President of LES (LUMS Entrepreneurial Society), we are poised to enhance our strategic acumen by mastering key strategic frameworks such as SWOT, PESTLE, and Porter's Five Forces. These tools are instrumental in crafting well-informed strategies for student-led societies and beyond.
<br/><br/>
Strategic frameworks are the scaffolding of effective strategic thinking. They provide structured methods to analyze situations, identify opportunities, and formulate plans. By becoming proficient with these frameworks, we can approach organizational challenges and opportunities with a more systematic and comprehensive mindset, leading to well-rounded and impactful decisions.
<br/><br/>
Shameer's journey in LES exemplifies the practical application of these frameworks in a university society context. From assessing the society's internal strengths and weaknesses to understanding the external factors that impact its operations, these frameworks have been pivotal in his strategic planning process. As we delve into these frameworks, we too can learn to apply them effectively in our roles as student leaders at LUMS.
<br/><br/>
In our pursuit of strategic excellence, familiarizing ourselves with and applying these strategic frameworks is crucial. This knowledge empowers us to navigate the complexities of leadership with a toolkit ready for strategic analysis and decision-making.


        </p>
<div className="grid grid-cols-1 ">
{cardData.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}

</div>
</section>,


<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8  lg:gap-16">
    {/* <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
      <img
        className="w-full"
        src="https://res.cloudinary.com/ahbranding/image/upload/v1700070674/AHbranding/Group_34784441_br87wo.png"
      />
    </div> */}

    <div className="">
      <div>
        <h2 className="text-xl font-medium sm:text-xl">
          <b> Fostering Strategic Vision and Goal Setting in Team Dynamics </b>
        </h2>
        <p className="mt-4 text-gray-600">
        Navigating Team Dynamics with a Strategic Lens: Your Visionary Leadership Toolkit

Just as a navigator charts a course for a ship's journey, effective team leadership involves charting a strategic vision and setting collective goals. Strategic vision in team settings is about guiding the group towards a shared future, aligning individual efforts with overarching objectives. It's a crucial part of your leadership toolkit, ensuring that the team's direction is not just a result of spontaneous decisions but of well-thought-out planning and foresight.
        </p>

        <div className="mt-6">
          <div className="container flex flex-col justify-center mx-auto px-0 ">
            <div className="">
              

            <Collapse accordionData={accordionData82}/>

            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div>




      


      </div>
    </div>
  </div>
</div>
</section>,





<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
<div className="mt-6 flex justify-start w-full">
  <SliderWeek9 className='w-full' onImageIndexChange={handleImageIndexChange} />

</div>
</section>,




<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b></b>
</h2>
<p className="mt-4 text-gray-600">
</p>



<div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
{currentQuestion < questionsData.length ? (
  <div>
    <h2 className="text-xl text-center font-semibold mb-8">{questionsData[currentQuestion].question}</h2>
    <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
      {questionsData[currentQuestion].options.map((option, index) => (
        <div
          key={index}
          className={`${
            (showExplanation && option === questionsData[currentQuestion].correctAnswer) ? 'bg-green-500 text-white' :
            (selectedOption === option ? 'bg-purple-200 ' : 'bg-white')
          } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer `}
          onClick={() => handleOptionSelect(option)}
        >
          {option}
        </div>
      ))}
    </div>
    {selectedOption && (
      <div className="flex justify-center">
        <button
          className="btn btn-outline-primary mt-4"
          onClick={handleNextQuestion}
        >
          Show Answer
        </button>
      </div>
    )}
    {showExplanation && (
      <div className="mt-4">
        <p className="font-semibold">Correct Answer:</p>
        <p className="text-green-500">{questionsData[currentQuestion].correctAnswer}</p>
        {/* <p className="font-semibold mt-2">Explanation:</p>
        <p>{questionsData[currentQuestion].explanation}</p> */}
        <div className="flex justify-center mt-4">
          <button
            className="btn btn-outline-primary"
            onClick={handleNextQuestionOrFinish}
          >
            {/* {currentQuestion + 1 < questionsData.length ? 'Next Question' : 'Finish'} */}

            {currentQuestion + 1 < questionsData.length ? 'Next Question' : ''}
          </button>
        </div>
      </div>
    )}
  </div>
) : (
  <div>
    <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
  </div>
)}
</div>


</section>,




    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Case Study: Entering the E-Commerce Market in Pakistan
</b>
      </h2>
      <p className="mt-4 text-gray-600">
      You are a strategic advisor for a company, "FuturTech," which is considering entering the e-commerce market in Pakistan. FuturTech has a strong background in retail but is new to the digital marketplace. The company aims to establish itself as a significant player in Pakistan’s growing e-commerce sector.      </p>

      <h5>The Challenge</h5>

      <p className="mt-4 text-gray-600">
<strong>The Task:</strong><br/>
<br/>
-	Market Analysis: What are the key characteristics of Pakistan's e-commerce market? Who are the main competitors, and what are the prevailing consumer behaviors and preferences?<br/>
-	Strategic Positioning: Based on your market analysis, how should FuturTech position itself in the market? What should be its USP?<br/>
-	Product and Pricing Strategy: What products should FuturTech initially offer, and at what price points? How does this align with your market analysis and positioning strategy?<br/>
-	Marketing and Partnerships: What marketing channels and partnership strategies should FuturTech adopt to establish its presence in the market?<br/>
-	Risk Mitigation: What are the potential risks and challenges FuturTech might face, and how can these be mitigated?<br/><br/>


Your strategic plan will be peer-reviewed, where you will receive feedback and also give your insights on your classmates’ plans. This exercise is designed to develop your strategic thinking, planning, and problem-solving skills in a real-world business context. 
      </p>

      <ul
        className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
        style={{ listStyle: "circle" }}
      >
        <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          Instructions{" "}
        </p>

        <li className="m-6">
        Carefully review the provided information about BrightTech Electronics, including the sales data, market context, and customer feedback.
        </li>
        <li className="m-6">
        Your response should not exceed 15 lines.
        </li>
        <li className="m-6">
        Ensure your response is clear, concise, and directly addresses the case study’s challenges.
        </li>

      </ul>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Response</span>
          </label>
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                response: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} disabled={status === 'Completed'}>
        Submit
      </button>
    </section>,

<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Congratulations on completing Week 9: Embracing Strategic Thinking</b>
      </h2>
      <p class="mt-4 text-gray-600">
      This week has been pivotal in advancing your leadership journey, equipping you with the foresight and planning skills crucial for success in various contexts, including student societies and academic projects.
<br/><br/>
Exploring Strategic Thinking: We delved into the critical aspects of strategic thinking, learning how to anticipate future trends, set long-term goals, and create visionary plans. These skills are indispensable in navigating the complexities of both academic and extracurricular activities.
<br/><br/>
Frameworks for Strategy Development: You have now familiarized yourself with key strategic frameworks like SWOT, PESTLE, and Porter’s Five Forces. This knowledge is instrumental in analyzing scenarios and formulating effective strategies in diverse environments.
<br/><br/>
Vision Setting and Goal Alignment: Your ability to set clear visions and align goals is now enhanced, a vital skill for leading teams and projects with a strategic focus.
<br/><br/>
Scenario Planning and Future Forecasting: You've gained insights into preparing for various potential futures, a crucial skill for adapting to and leading through change.
<br/><br/>
Applying Strategic Thought in Case Studies: The case study provided a practical application of your strategic thinking skills, demonstrating their relevance in real-world challenges you might encounter. 
<br/><br/>
Brilliant work navigating this insightful week! As we move into Week 10, focusing on Creativity, carry forward the strategic thinking skills you’ve honed. Continue to engage deeply with the upcoming material and apply these insights to further develop your leadership prowess in both your academic and extracurricular pursuits. Excited to see you thrive in the next chapter of your leadership growth!
</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "View Slides";

      case 3:
        return "Embarking on the Journey of Self-Awareness";

      case 4:
        return "SWOT Analysis";

      case 5:
        return "Peer Review | SWOT Analysis";

      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week9;
