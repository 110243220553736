import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { axiosInstance } from "../Axios";
import PhoneInput from "react-phone-number-input";
import toast from "react-hot-toast";
import Select from "react-select";
import axios from "axios";

function SoceityAdd(props) {
  const [modalOpen, setModalOpen] = useState(true);
  useEffect(() => {
    const modal = document.getElementById("my_modal_5");
    if (modal) {
      modal.showModal();

      // Prevent closing on ESC
      window.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          event.preventDefault();
          // Optionally, inform the user that ESC won't close the dialog
          console.log("Pressing ESC will not close the dialog.");
        }
      });

      // Cleanup listener when component unmounts or modal gets closed
      return () => window.removeEventListener("keydown", this);
    }
  }, []);
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    axiosInstance
      .post(`post_add_society/`, {
        user: props.profile.user,
        societies: selectedSocieties.map((society) => society.value),
      },config)
      .then((res) => {
        console.log(res.data);
        console.warn(props.profile.user);
        toast.success("Society Added Successfully");
        setModalOpen(false);
        // props.history.push("/societies");
      })
      .catch((err) => {
        toast.error("Error in adding society");
        console.log(err);
      });
  };

  // const validatePhone = (phone) => {
  //   const phoneRegex = /^[0-9]{11,15}$/;
  //   return phoneRegex.test(phone);
  // };

  const allSocietiesList = [
    "Aiesec Society",
    "Amnesty International",
    "Dramaline Society",
    "Debating and Recitation Society",
    "IEEE LUMS Student Chapter",
    "LUMS Adventure Society",
    "Law and Politics Society",
    "LUMS Entrepreneurship Society",
    "LUMS Literary Society",
    "LUMS Media Arts Society",
    "LUMS Art Club",
    "LUMS Culture Society",
    "LUMS Religious Society",
    "LUMS Model United Nations",
    "LUMS Environmental Action Forum",
    "LUMS Community Service Society",
    "LUMS Daily Student Society",
    "Lums Photographic Society",
    "Lums Students Maths Society",
    "Lums Music Society",
    "Publication at LUMS",
    "The Economics Society",
    "Sharing Analysis on Regional Ec",
    "SPADES",
    "FINTRA - Finance",
    "Feministy Society",
    "Lums Consultancy Group",
    "Psychology Society at LUMS",
    "LUMS Student Professional Accountancy",
    "Index the Design Society",
    "LUMS Culinary Society",
    "Hum Aahang Society",
    "LUMS Society of Chemical Sciences & Engineering",
    "Animals and Welfare Society",
    "Rizq",
    "Better Educational Engagement (BEE)",
    "LUMS Data Science Society (LDSS)",
    "Riayat LUMS Chapter (Riayat)",
    "LUMS Dance Society",
    "Radio at LUMS",
    "LUMS Women in Computing",
    "Anime and Manga at LUMS",
    "LUMS Historical Research Society",
    "SCOLAR Lahore HUB",
    "LUMS Policy Research Initiative",
    "Toastmaster",
    "Escapade (Graduate Society)",
    "E Gaming (Graduate Society)",
    "Education and Professional Excellence (Graduate Society)",
    "Entertainment (Graduate Society)",
    "Women in Business (Graduate Society)",
  ];

  const [societiesList, setSocietiesList] = useState([""]);
  const sortedOptions = allSocietiesList.sort((a, b) =>
    a.localeCompare(b, "en", { sensitivity: "base" })
  );
  const [selectedSocieties, setSelectedSocieties] = useState([]);

  const handleSocietiesChange = (selectedOptions) => {
    setSelectedSocieties(selectedOptions);
  };

  return (
    <div>
      {/* <button className="btn" onClick={() => document.getElementById('my_modal_5').showModal()}>Open Modal</button> */}
      {modalOpen && (
        <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <h3 className="font-bold text-lg flex flex-row gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="text-red-600 w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>
              Your Student Society Involvements
            </h3>
            <p className="py-4">
            Help us tailor your experience by selecting the student societies that you are currently a part of.
            </p>

            <div className="grid grid-cols-1 sm:grid-cols gap-4 lg:gap-6 my-4">
              <div>
                <label
                  htmlFor="hs-firstname-hire-us-1"
                  className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                >
                  Select Societies
                </label>
                <div className="flex flex-col gap-4">
                  {societiesList.map((society, index) => (
                    <div
                      key={index}
                      className="w-full flex items-center justify-between p-6 border rounded-xl"
                    >
                      <div className="w-full">
                        <label
                          htmlFor={`society-${index}`}
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          Societies
                        </label>

                        <Select
                          isMulti
                          options={sortedOptions.map((society) => ({
                            value: society,
                            label: society,
                          }))}
                          value={selectedSocieties}
                          onChange={handleSocietiesChange}
                          className=""
                          placeholder="Select societies..."
                          isSearchable
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-action">
              <button
                className="btn btn-primary bg-primary text-white"
                onClick={handleSubmit}
              >
                Confirm & Save Changes
              </button>
            </div>
          </div>
        </dialog>
      )}
    </div>
  );
}

export default SoceityAdd;
