import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import axios from "axios";



const MobileNav = ({source, img}) => {

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  return (

<div className="navbar bg-base-100 flex md:hidden pt-8">
  <div className="flex-1">
    <a className="btn btn-ghost normal-case text-xl">{source}</a>
  </div>
  <div className="flex-none">
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
        <div className="w-10 rounded-full">
          <img src={img} />
        </div>
      </label>
      <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
        <li className="p-2"><Link to="/student_profile" className="p-2">Profile</Link></li>
        <li className="p-2"><Link onClick={logoutUser} to="/login" className="p-2 text-red">Logout</Link></li>
      </ul>
    </div>
  </div>
</div>
    
  );
};

export default MobileNav;
