import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

function WeekProgress() {
  const [studentData, setStudentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [originalStudentData, setOriginalStudentData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 500;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentItems = studentData.slice(indexOfFirstItem, indexOfLastItem);
  const [progress, setProgress] = useState([]);
  const { id, weekid } = useParams();
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const fetchStudent = async () => {
    await axios
      .get((process.env.REACT_APP_API_KEY + "/api/profile/" + id),config)
      .then((res) => {
        setOriginalStudentData(res?.data);
        setStudentData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivities = async () => {
    await axios
      .get(process.env.REACT_APP_API_KEY + "/api/get_progress/" + id + "/",config)
      .then((res) => {
        // setOriginalStudentData(res?.data);
        setProgress(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStudent();
    fetchActivities();
  }, []);
  return (
    <div class="max-w-[85rem]  py-10  py-4 lg:py-4 lg:py-14 mx-auto">
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto p-2 bg-white rounded-xl">
          <div className="p-1.5 min-w-full inline-block align-middle ">
            <div className="bg-white    overflow-hidden darki:bg-slate-900 darki:border-gray-700">
              <div className="flex flex-row items-center px-4">
                <div className="flex flex-col w-full">
                  <h2 class="text-xl font-semibold text-gray-800 darki:text-gray-200">
                    Name:{" "}
                    <span>
                      <p>{studentData.name}</p><br/>
                      <small>{studentData.roll_number}</small>
                    </span>
                  </h2>
                  <h2>
                    Email:{" "}
                    <span>
                      <p>{studentData.email}</p>
                    </span>
                  </h2>
                </div>
              </div>
              <table class="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                <thead class="bg-gray-50 darki:bg-slate-800">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Week #
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap min-w-[16rem]"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Section
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Status
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Due Date
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Action
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200 darki:divide-gray-700">
                  {progress?.map((item, index) => {
                    return (
                      <tr>
                        <td class="h-px w-px whitespace-nowrap px-6 py-3">
                          <button
                            type="button"
                            class="flex items-center gap-x-2"
                          >
                            <span class="text-sm text-gray-800 darki:text-gray-200">
                              {index}
                            </span>
                          </button>
                        </td>
                        <td class="h-px w-px whitespace-nowrap px-6 py-3">
                          <div class="flex items-center gap-x-3">
                            <p>{item?.section}</p>
                            {/* <div className="flex flex-col">
                              <span class="font-semibold text-sm text-gray-800 darki:text-white">
                                {item?.name}
                              </span>
                            </div> */}
                          </div>
                        </td>

                        <td class="h-px w-px whitespace-nowrap px-6 py-3">
                          <div class="flex items-center gap-x-3">
                            <p>{item?.status}</p>
                          </div>
                        </td>

                        <td class="h-px w-px whitespace-nowrap px-2  py-3">
                          <div class="flex items-center gap-x-3">
                            <p>{item?.due_date}</p>
                          </div>
                        </td>

                        <td class="h-px w-px whitespace-nowrap px-2  py-3">
                          <div class="flex items-center gap-x-3 ">
                            <button className="btn btn-xs ">
                              <Link to={`/get_single_week/${id}/${index + 1}`}>
                                Edit
                              </Link>{" "}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <ReactPaginate
                pageCount={Math.ceil(studentData?.length / itemsPerPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                // onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                previousLabel="Previous"
                nextLabel="Next"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeekProgress;