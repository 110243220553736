import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/login');
  }, [navigate]);

  // The component's rendering logic will go here, if needed

  return (
    <>
      <div className="flex items-center justify-center space-x-2">
	<div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
	<div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
	<div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
</div>
    </>
  );
}

export default LandingPage;
