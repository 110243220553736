import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link } from "react-router-dom";

function ProgressTable({StudentProgData}) {
  const [studentData, setStudentData] = useState(StudentProgData);
  const [searchTerm, setSearchTerm] = useState("");
  // const [originalStudentData, setOriginalStudentData] = useState([]);
  const [originalStudentData, setOriginalStudentData] = useState(StudentProgData);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 500;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = studentData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterData(term, selectedStatus);
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
    filterData(searchTerm, status);
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      setSelectedItems(studentData.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckboxChange = (e, itemId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    }
  };
  // const filterData = (term, status) => {
  //   const lowerCaseTerm = term?.toLowerCase();
  //   if (term === "" && status === "") {
  //     setStudentData(originalStudentData);
  //   } else {
  //     const filteredData = originalStudentData.filter((item) => {
  //       return (
  //         (term === "" ||
  //           Object.values(item)?.some(
  //             (value) =>
  //               value && value.toString().toLowerCase()?.includes(lowerCaseTerm)
  //           )) &&
  //         (status === "" || item.status.toString().toLowerCase() === status)
  //       );
  //     });
  //     setStudentData(filteredData);
  //   }
  // };

  const filterData = (term, status) => {
    const lowerCaseTerm = term?.toLowerCase();
    if (term === "" && status === "") {
      setStudentData(originalStudentData);
    } else {
      const filteredData = originalStudentData.filter((item) => {
        return (
          (term === "" ||
            Object.values(item)?.some(
              (value) =>
                value && value.toString().toLowerCase()?.includes(lowerCaseTerm)
            )) &&
          (status === "" || (item.status && item.status.toString().toLowerCase() === status))
        );
      });
      setStudentData(filteredData);
    }
  };
  

  // const fetchStudent = async () => {
  //   await axios
  //     .get(
  //       process.env.REACT_APP_API_KEY + "/api/get_all_students_week_progress/1/"
  //     )
  //     .then((res) => {
  //       setOriginalStudentData(res?.data);
  //       setStudentData(res?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   fetchStudent();
  // }, []);

  console.warn(`data`,studentData)

  return (
    <div class="max-w-[85rem]  py-10  py-4 lg:py-4 lg:py-14 mx-auto">
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto p-2 bg-white rounded-xl">
          <div className="p-1.5 min-w-full inline-block align-middle ">
            <div className="bg-white    overflow-hidden darki:bg-slate-900 darki:border-gray-700">
              <div className="flex flex-row items-center px-4">
                <div className="flex flex-col w-full">
                  <h2 class="text-xl font-semibold text-gray-800 darki:text-gray-200">
                    Pathway Progress
                  </h2>
                  <p class="text-sm text-gray-600 darki:text-gray-400">
                    Detailed overview of student's weekly progress
                  </p>
                </div>
                <div className="w-full flex justify-end items-center gap-3">
                  <input
                    type="text"
                    placeholder="Search users.."
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="input input-bordered w-full max-w-xs"
                  />
                </div>
              </div>
              <table class="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                <thead class="bg-gray-50 darki:bg-slate-800">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        #
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap min-w-[16rem]"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Student
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Weekly Status
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Progress
                      </span>
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-start whitespace-nowrap"
                    >
                      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Action
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200 darki:divide-gray-700">
                  {studentData?.map((item, index) => {
                    return (
                      <tr>
                        <td class="h-px w-px whitespace-nowrap px-6 py-3">
                          <button
                            type="button"
                            class="flex items-center gap-x-2"
                          >
                            <span class="text-sm text-gray-800 darki:text-gray-200">
                              {index + 1}
                            </span>
                          </button>
                        </td>
                        <td class="h-px w-px whitespace-nowrap px-6 py-3">
                          <div class="flex items-center gap-x-3">
                            <img
                              class="inline-block h-8 w-8 rounded-full"
                              src={item?.avatar}
                              alt="Image Description"
                            />
                            <div className="flex flex-col">
                              <span class="font-semibold text-sm text-gray-800 darki:text-white">
                                {item?.name}
                              </span>

                              <span className="text-sm text-gray-400">{item?.email}</span>
                            </div>
                          </div>
                        </td>

                        <td class="h-px w-px whitespace-nowrap px-6 py-3">
                          <div class="flex items-center gap-3">
                            <span class="py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full darki:bg-teal-500/10 darki:text-teal-500">
                              <svg
                                class="flex-shrink-0 w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
                                <path d="m9 12 2 2 4-4" />
                              </svg>
                              {item?.completed + " Completed"}
                            </span>

                            <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full darki:bg-blue-500/10 darki:text-blue-500">
                              <svg
                                class="flex-shrink-0 w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <line x1="12" x2="12" y1="2" y2="6" />
                                <line x1="12" x2="12" y1="18" y2="22" />
                                <line x1="4.93" x2="7.76" y1="4.93" y2="7.76" />
                                <line
                                  x1="16.24"
                                  x2="19.07"
                                  y1="16.24"
                                  y2="19.07"
                                />
                                <line x1="2" x2="6" y1="12" y2="12" />
                                <line x1="18" x2="22" y1="12" y2="12" />
                                <line
                                  x1="4.93"
                                  x2="7.76"
                                  y1="19.07"
                                  y2="16.24"
                                />
                                <line
                                  x1="16.24"
                                  x2="19.07"
                                  y1="7.76"
                                  y2="4.93"
                                />
                              </svg>
                              {item?.active + " Active"}
                            </span>

                            <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full darki:bg-red-500/10 darki:text-red-500">
                              <svg
                                class="flex-shrink-0 w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z" />
                                <path d="M12 9v4" />
                                <path d="M12 17h.01" />
                              </svg>
                              {item?.locked + " Locked"}
                            </span>
                          </div>
                        </td>

                        <td class="h-px w-px whitespace-nowrap px-2  py-3">
                          <div className="px-6 py-3">
                            <div className="flex items-center gap-x-3">
                              <span className="text-xs text-gray-500">
                                {Math.floor(item?.percentage)} %
                              </span>
                              <div className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden darki:bg-gray-700">
                                <div
                                  className="flex flex-col justify-center overflow-hidden bg-gray-800 darki:bg-gray-200"
                                  role="progressbar"
                                  style={{
                                    width: `${Math.floor(item?.percentage)}%`,
                                  }}
                                  aria-valuenow={Math.floor(item?.percentage)}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="h-px w-px whitespace-nowrap px-2  py-3">
                          <div class="flex items-center gap-x-3 ">
                            <button className="btn btn-xs ">
                              <Link to={`/all_progress/${item?.id}`}>
                                View Details{" "}
                              </Link>{" "}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <ReactPaginate
                pageCount={Math.ceil(studentData?.length / itemsPerPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                previousLabel="Previous"
                nextLabel="Next"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressTable;