import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";
import SliderWeek10 from "./SliderWeek10";
import SliderWeek10_2 from "./SliderWeek10_2";
import QuizComponent from "./QuizComponent";
import SliderWeek11 from "./SliderWeek11";
import SliderWeek11_2 from "./SliderWeek11_2";
import SliderWeek13 from "./SliderWeek13";

const Week13 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 16;
  const week_name = "week_14";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      })
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_15",
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        });
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: true,
          activity_name: "Risk Analysis Scenario",
        });
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };

  const totalSteps = 6; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };





  const [currentQuestion2, setCurrentQuestion2] = useState(0);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [showExplanation2, setShowExplanation2] = useState(false);

  const handleOptionSelect2 = (option2) => {
    setSelectedOption2(option2);
  };

  const handleNextQuestion2 = () => {
    setShowExplanation2(true);
  };

  const handleNextQuestionOrFinish2 = () => {
    if (currentQuestion2 + 1 < questionsData2.length) {
      setCurrentQuestion2(currentQuestion2 + 1);
      setSelectedOption2("");
      setShowExplanation2(false);
    }
  };



  const questionsData = [
    {
      question: "Groups always make more cautious risk decisions compared to individuals.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Effective communication within a group doesn't influence the quality of risk-taking decisions.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "In a group setting, the risk preferences of the majority always determine the final decision.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Diverse perspectives within a team can lead to more balanced and effective risk assessment.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
    {
      question: "Groupthink always leads to optimal risk-taking decisions in team environments.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
  ];

  

  
  const questionsData2 = [
    {
      question: "Effective coping strategies in leadership are only about managing personal stress.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Effective coping strategies in leadership encompass managing personal stress and fostering resilience within the team.",
    },
    {
      question: "Emotional resilience in leadership does not impact decision-making and team dynamics.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Emotional resilience is crucial for effective leadership as it positively impacts decision-making and team dynamics.",
    },
    {
      question: "Regular stress-management practices are unrelated to overall leadership effectiveness.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Regular stress-management practices are essential for maintaining clarity, focus, and overall leadership effectiveness.",
    },
    {
      question: "Creating a supportive environment for resilience is solely the responsibility of individual team members.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Leaders play a critical role in cultivating a team culture that supports resilience and stress management.",
    },
    {
      question: "Learning from successful leaders who manage stress effectively is not beneficial for developing resilience.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Observing and learning from successful leaders who effectively manage stress can provide valuable insights for developing resilience.",
    },
  ];
  
  
  const accordionData = [
    {
      title: 'Sexual Harassment',
      content: (
        <div>
          <p>Description: This involves unwelcome sexual advances, requests for sexual favours, and other verbal or physical conduct of a sexual nature. It can range from inappropriate jokes to outright assault.</p>
          <p>Impact: Sexual harassment can create a hostile environment, leading to psychological trauma, anxiety, and a decrease in work or academic performance.</p>
        </div>
      ),
    },
    {
      title: 'Workplace Bullying',
      content: (
        <div>
          <p>Description: This form of harassment occurs when an individual repeatedly and deliberately aims to hurt or humiliate someone at work. This can include verbal abuse, spreading rumours, or intentional exclusion.</p>
          <p>Impact: It leads to a toxic work environment, diminished self-esteem of the victim, and decreased productivity.</p>
        </div>
      ),
    },
    {
      title: 'Cyberbullying',
      content: (
        <div>
          <p>Description: Involving the use of digital platforms to intimidate, harass, or demean individuals, cyberbullying includes sending threatening emails, posting harmful content, or online shaming.</p>
          <p>Impact: The anonymity of the internet can intensify the effects of cyberbullying, leading to emotional distress, social isolation, and in severe cases, self-harm.</p>
        </div>
      ),
    },
    {
      title: 'Racial Harassment',
      content: (
        <div>
          <p>Description: This involves harassment based on race, ethnicity, or nationality. It includes racial slurs, derogatory remarks, or discriminatory actions.</p>
          <p>Impact: Racial harassment can create an atmosphere of fear and hostility, impacting the victim’s sense of belonging and identity.</p>
        </div>
      ),
    },
    {
      title: 'Discriminatory Harassment',
      content: (
        <div>
          <p>Description: This refers to unfair treatment or derogatory behaviour based on gender, religion, age, disability, or sexual orientation.</p>
          <p>Impact: It can lead to exclusion, reduced opportunities, and a sense of injustice, significantly impacting mental health and well-being.</p>
        </div>
      ),
    },
    // Add more accordion data as needed
  ];
  

  const accordionData2 = [
    {
      title: 'Emotional and Psychological Impact on Individuals',
      content: (
        <div>
          
          <p>Emotional Trauma: Victims of harassment often experience intense emotional reactions, including fear, anger, humiliation, and a sense of helplessness. These reactions can be immediate and intense, affecting an individual’s mental health and well-being.</p>
          <p>Psychological Effects: Long-term psychological effects can include anxiety, depression, and post-traumatic stress disorder (PTSD). The trauma of harassment can lead to lasting psychological scars, affecting an individual's ability to trust others and feel safe in social or professional settings.</p>
        </div>
      ),
    },
    {
      title: 'Professional Consequences for Individuals',
      content: (
        <div>
          
          <p>Impact on Performance and Productivity: Harassment can severely impact a victim's work performance. Stress and anxiety can lead to decreased concentration, absenteeism, and a decline in overall productivity.</p>
          <p>Career Progression: Victims of harassment may avoid certain situations or people, potentially limiting their professional opportunities and career advancement. In severe cases, individuals might even leave their job or societal positions to escape the harassment.</p>
        </div>
      ),
    },
    {
      title: 'Effects on Teams and Group Dynamics',
      content: (
        <div>
          
          <p>Erosion of Trust and Morale: Harassment can damage the trust and morale within a team. It creates an environment of fear and discomfort, hindering open communication and collaboration.</p>
          <p>Reduced Team Cohesion: Teams affected by harassment may experience divisions and reduced cohesion. This can lead to a breakdown in teamwork and cooperation, negatively impacting group projects and goals.</p>
        </div>
      ),
    },
    // Add more accordion data as needed
  ];
  
  
  const accordionData3 = [
    {
      title: 'Recognize and Document the Incident: Identifying and Recording Harassment',
      content: (
        <div>
          <p>If you experience or witness harassment, recognize it and document the details. This can include the date, time, location, nature of the harassment, and names of any witnesses.</p>
        </div>
      ),
    },
    {
      title: 'Contact the Concerned Department: Reaching Out for Help',
      content: (
        <div>
          <p>LUMS has designated departments and individuals to handle harassment cases:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Email the Office of Student Affairs: <a href="mailto:studentaffairs@lums.edu.pk">studentaffairs@lums.edu.pk</a> for guidance on next steps.</li>
            <li>Contact the Harassment Committee: Specific email addresses and contact details of committee members can be found on the LUMS website or through the student portal.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Formal Reporting Procedures: Official Reporting Mechanism',
      content: (
        <div>
          <p>To formally report harassment:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Fill out the harassment complaint form available online on the LUMS portal.</li>
            <li>Submit the form to the Harassment Committee via their official email <a href="mailto:harassment@lums.edu.pk">harassment@lums.edu.pk</a>.</li>
            <li>You can also request a meeting with a committee member or member of the student council for a more personal approach.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Seeking Immediate Support: Accessing Immediate Assistance',
      content: (
        <div>
          <p>If you need immediate assistance or counselling:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Reach out to the LUMS Counselling and Psychological Services at <a href="mailto:counseling@lums.edu.pk">counseling@lums.edu.pk</a>.</li>
            <li>Contact your department head or a trusted faculty member for immediate advice and support.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Bystander Intervention and Informal Support: The Role of Bystanders',
      content: (
        <div>
          <p>As a bystander, you can support victims by:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Offering immediate assistance or escorting them to a safe place.</li>
            <li>Encouraging them to document the incident and seek help.</li>
            <li>Accompanying them to report the incident if they feel comfortable.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Follow-up and Continual Support: Post-Reporting Follow-up',
      content: (
        <div>
          <p>After reporting, stay in touch with the concerned department for updates. Continue to access support services as needed. LUMS ensures confidentiality and continued support for all individuals involved in harassment cases.</p>
        </div>
      ),
    },
  ];
  
  

  const accordionData82 = [
    {
      title: 'Cultivate a Shared Vision',
      content: '- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n' +
        'Reflect on your recent team interactions:\n' +
        '- How have I communicated and fostered a shared vision within the team?\n' +
        '- In what ways have I ensured that everyone understands and is committed to our collective goals?\n' +
        '- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?',
    },
    {
      title: 'Analyse and Set SMART Goals',
      content: '- Effective goal setting is a collaborative and strategic process. Evaluate your team\'s approach to setting goals:\n' +
        '- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n' +
        '- Where can we improve in making our goals more aligned with our strategic vision?\n' +
        '- How can I lead the team to break down our larger vision into achievable, strategically informed goals?',
    },
    {
      title: 'Encourage Participation in Vision and Goal Setting',
      content: '- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n' +
        '- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n' +
        '- How can I better ensure that all voices are heard and considered in shaping our team\'s direction?\n' +
        '- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team\'s cohesion?',
    },
  ];
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];


  const accordionData7 = [
    {
      title: 'Understanding Harassment',
      content: (
        <div>
          <p>
            You’ve explored the different types of harassment, learning to recognize and differentiate them from everyday conflicts. This knowledge is vital in identifying harassment early and taking appropriate action.
          </p>
        </div>
      ),
    },
    {
      title: 'Recognizing Harassment',
      content: (
        <div>
          <p>
            We delved into the subtle nuances of identifying harassment, emphasizing the importance of not normalizing problematic behaviors under the guise of less severe conflicts.
          </p>
        </div>
      ),
    },
    {
      title: 'Impact of Harassment',
      content: (
        <div>
          <p>
            You’ve gained insights into the wide-ranging emotional, psychological, and professional impacts of harassment on individuals, teams, and organizations, understanding that these effects vary greatly depending on the circumstances and individuals involved.
          </p>
        </div>
      ),
    },
    {
      title: 'Preventing Harassment',
      content: (
        <div>
          <p>
            We discussed individual strategies that you, as members of student societies, can implement to prevent harassment, emphasizing the role of personal action and responsibility in fostering a safe and respectful environment.
          </p>
        </div>
      ),
    },
    {
      title: 'Responding to Harassment',
      content: (
        <div>
          <p>
            The week concluded with guidance on how to respond to harassment, providing a step-by-step approach, including formal procedures and informal strategies to address and report such incidents at LUMS.
          </p>
        </div>
      ),
    },
  ];
  
  
  const cardData = [
    {
      id: 1,
      frontContent: 'Understanding and Upholding Policies: Personal Responsibility in Policy Adherence',
      backContent: (
        <div>
          <p>Content: Familiarize yourself with your society's anti-harassment policies. Understand what constitutes harassment and commit to upholding these standards in your interactions. Personal adherence to policies sets a standard for behaviour and helps create a safe environment for everyone.</p>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Promoting Inclusivity and Respect: Being an Advocate for Diversity and Respect',
      backContent: (
        <div>
          <p>Content: Actively promote an inclusive atmosphere by respecting diverse viewpoints and backgrounds. Challenge discriminatory jokes or comments when you encounter them. Your actions can influence the overall culture of the society, encouraging others to follow suit.</p>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Effective Communication: Open and Respectful Communication',
      backContent: (
        <div>
          <p>Content: Practice open, honest, and respectful communication. Address conflicts constructively and seek to understand others' perspectives. Clear communication can prevent misunderstandings that may lead to perceived harassment.</p>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Bystander Intervention: Taking Action as a Bystander',
      backContent: (
        <div>
          <p>Content: If you witness harassment, consider safe ways to intervene or support the victim. This could be through direct intervention, distraction, or seeking help from society leaders or authorities. Bystander intervention can stop harassment and show that such behaviour is unacceptable.</p>
        </div>
      ),
    },
    {
      id: 5,
      frontContent: 'Personal Reflection and Growth: Reflecting on Personal Biases and Behaviours',
      backContent: (
        <div>
          <p>Content: Regularly reflect on your own biases and behaviours. Consider how your actions and words might be perceived by others. Being self-aware and willing to grow personally is key to preventing harassment.</p>
        </div>
      ),
    },
    {
      id: 6,
      frontContent: 'Seeking and Offering Support: Building a Supportive Network',
      backContent: (
        <div>
          <p>Content: Be approachable and supportive to peers who might be experiencing harassment. Offer help, whether it’s listening, providing advice, or assisting in reporting the incident. Having a supportive network can make it easier for victims to come forward.</p>
        </div>
      ),
    },
    {
      id: 7,
      frontContent: 'Leading by Example: Role Modelling Positive Behaviour',
      backContent: (
        <div>
          <p>Content: Lead by example in all your interactions within the society. Demonstrate respect, kindness, and inclusivity. Your behaviour can set a positive example for others and contribute to a respectful society culture.</p>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  

  const cardData2 = [
    {
      id: 1,
      frontContent: 'Setting Specific Adaptability Goals',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Defining clear, achievable goals is the first step in your action plan.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What specific aspects of adaptability do I want to develop or enhance?</li>
            <li>How can these adaptability goals contribute to my effectiveness as a student leader or society member?</li>
            <li>What are the measurable outcomes I aim to achieve through these goals?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Identifying Strategies and Resources',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Determining the right strategies and resources is key to achieving your goals.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What strategies will I employ to improve my adaptability in various situations?</li>
            <li>Which resources (like workshops, books, mentors) can assist in developing these skills?</li>
            <li>How will I integrate these strategies and resources into my regular activities or society responsibilities?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Creating a Timeline and Milestones',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: A structured timeline with milestones helps track progress and maintain focus.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What is a realistic timeline for achieving my adaptability goals?</li>
            <li>What milestones can I set to monitor my progress over time?</li>
            <li>How will I adjust my plan if I encounter obstacles or new opportunities for growth?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Reviewing and Adjusting the Plan',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Regular review and adjustment of your action plan ensure its effectiveness and relevance.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How will I periodically review the progress of my action plan?</li>
            <li>What criteria will I use to evaluate its success or areas needing adjustment?</li>
            <li>How open am I to modifying my plan based on feedback and new experiences?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  
  

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Understanding and Addressing Harassment</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 14 of your transformative journey in the Ascent Leadership Program. This week, we address a critically important and sensitive topic: "Harassment." In a world where respect, dignity, and safety are paramount, understanding harassment in all its forms is crucial for every emerging leader. This week is dedicated to developing awareness, empathy, and proactive strategies to recognize, prevent, and respond to harassment.      
      
      <br/> <br/>As future leaders, you are not only responsible for your own conduct but also for fostering an environment that is safe and respectful for everyone. By including 'Harassment' as a focused topic in our program, we aim to empower you with the knowledge and skills to create inclusive and harmonious spaces, both professionally and personally. We believe that awareness and understanding are key to preventing harassment and building a culture of respect and integrity.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
      {/* <Quote content={"Only those who will risk going too far can possibly find out how far one can go."} person={"T.S. Eliot"}/> */}

      {/* <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div> */}
      </div>
    </section>,








<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>

              Understanding Harassment: Types and Manifestations

              </b>
            </h2>

            {/* <div class="mt-8 bg-gray-100 border border-gray-200 text-sm text-gray-800 rounded-lg p-4 darki:bg-white/10 darki:border-white/20 darki:text-white" role="alert">
  <span class="font-bold">Disclaimer</span> 
  Please note that the following content covers only some common types of harassment and their typical impacts. Harassment can take various other forms, and the impact on individuals may vary greatly depending on personal circumstances and the context in which the harassment occurs. 
</div> */}

<div class="mt-8 bg-blue-100 border border-blue-200 text-sm text-blue-800 rounded-lg p-4 darki:bg-blue-800/10 darki:border-blue-900 darki:text-blue-500" role="alert">
  <span class="font-bold">Disclaimer</span> <br/>
  Please note that the following content covers only some common types of harassment and their typical impacts. Harassment can take various other forms, and the impact on individuals may vary greatly depending on personal circumstances and the context in which the harassment occurs. 
</div>

            <p className="mt-4 text-gray-600">
      
            In today's complex social and professional landscapes, harassment takes on various forms, each with its unique characteristics and impacts. Understanding these types is crucial in identifying and addressing harassment effectively. In this module, we will highlight and explain some of the most common and significant types of harassment. By recognizing these different forms, you can be better equipped to prevent and respond to such incidents, fostering a safer and more inclusive environment.            
            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,









<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>

              Impact of Harassment: Understanding Its Reach and Consequences
              </b>
            </h2>

            {/* <div class="mt-8 bg-gray-100 border border-gray-200 text-sm text-gray-800 rounded-lg p-4 darki:bg-white/10 darki:border-white/20 darki:text-white" role="alert">
  <span class="font-bold">Disclaimer</span> 
  Please note that the following content covers only some common types of harassment and their typical impacts. Harassment can take various other forms, and the impact on individuals may vary greatly depending on personal circumstances and the context in which the harassment occurs. 
</div> */}

<div class="mt-8 bg-blue-100 border border-blue-200 text-sm text-blue-800 rounded-lg p-4 darki:bg-blue-800/10 darki:border-blue-900 darki:text-blue-500" role="alert">
  <span class="font-bold">Disclaimer</span> <br/>

  Please note that the impacts of harassment discussed here represent some common consequences. However, harassment can have a wide range of effects, varying significantly based on individual experiences, the severity of the harassment, the context in which it occurs, and other situational factors. This section is not exhaustive but aims to highlight key areas of impact. 

</div>

            <p className="mt-4 text-gray-600">

This section explores the profound impact of harassment on individuals, teams, and organizations. Harassment, in its various forms, can have far-reaching consequences that extend beyond immediate harm. Understanding these impacts is crucial for fostering a culture of respect and taking proactive measures against such behaviours.      

            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData2}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,



<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b> Preventing Harassment</b>
        </h2>
        <p className="mt-4 text-gray-600">

        Creating a harassment-free environment requires proactive individual efforts. In a university, where diverse individuals collaborate, it's essential to proactively foster an environment free from harassment. Each member has a role to play in fostering a culture of respect and safety. This section provides strategies that students can adopt individually to prevent harassment and contribute to a positive and inclusive society culture.
</p>
<div className="grid grid-cols-1 ">
{cardData.map((card) => (
    
        <CardFlip
          key={card.id}
          frontContent={card.frontContent}
          backContent={card.backContent}
        />
        
      ))}



</div>
</section>,







<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Responding to Harassment at LUMS: A Step-by-Step Guide</b>
            </h2>
            <p className="mt-4 text-gray-600">
            LUMS is committed to ensuring a safe and respectful environment for all. Understanding how to respond effectively to harassment is crucial. This guide outlines the steps to report harassment and take action, including specific contacts and departments to reach out to.
            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData3}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,




<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>LUMS Policy on Conduct and Harassment Prevention in Student Societies</b>
            </h2>
            <p className="mt-4 text-gray-600">
            LUMS is dedicated to fostering a safe, respectful, and inclusive environment. As part of our commitment, we have established a specific policy to guide conduct and harassment prevention within student societies. This policy aligns with LUMS' broader ethical framework and ensures a nurturing space for all members.
<br/> <br/>
This document is an extension of the "LUMS Sexual Harassment Policy" and the "Code of Conduct," tailored specifically for student societies. It provides detailed guidelines on handling various interactions and scenarios that may arise within these groups.
            </p>



            <div className="mt-8">
      <h5 className="text-lg font-bold mb-4">Key Areas Covered:</h5>

      <ul className="list-disc list-inside">
        <li className="mb-2">Adherence to Main Policies</li>
        <li className="mb-2">Reporting Mechanisms within Societies</li>
        <li className="mb-2">The Role of Co-Curricular Activities Office</li>
        <li className="mb-2">Direct Reporting to the Office of Accessibility and Inclusion (OAI)</li>
        <li className="mb-2">Training and Awareness</li>
        <li className="mb-2">Event Management and Conduct</li>
        <li className="mb-2">Confidentiality and Data Handling</li>
        <li className="mb-2">Review and Feedback Mechanisms</li>
        <li className="mb-2">Consequences of Non-Compliance</li>
      </ul>
    </div>


    <p className="mt-8">All students are encouraged to read this document thoroughly to understand their responsibilities and the resources available to them. It's crucial for maintaining a respectful and harassment-free environment within our student societies.</p>
      
            <div className="mt-6">



            <>
  {/* File Uploading Progress Form */}
  <div>
    {/* Uploading File Content */}
    <div className="mb-2 flex justify-between items-center">
      <div className="flex items-center gap-x-3">
        <span className="w-8 h-8 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg darki:border-neutral-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>

        </span>
        <div>
          <Link target="blank" to={'https://drive.google.com/file/d/1dk2sVmLHijIV2MI0X7PR3bXN69uBimBO/view?usp=sharing'} className="link link-primary text-sm m-0 font-medium darki:text-white">
          Policy on Conduct and Harassment Prevention
          </Link>
          <p className="m-0 text-xs text-gray-500 darki:text-gray-500">.DOCSX</p>
        </div>

      </div>
      
    </div>
    {/* End Uploading File Content */}
    {/* Progress Bar */}

    {/* End Progress Bar */}
  </div>
  {/* End File Uploading Progress Form */}
</>



              
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,





<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>
        Week 14 Conclusion: Addressing and Understanding Harassment        
        </b>
      </h2>
      <p class="mt-4 text-gray-600">


      Congratulations on completing Week 14 of the Ascent Leadership Program at LUMS. This week has been a profound journey into understanding and addressing harassment, a critical and sensitive issue in our society and within student communities.


</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "View Slides";

      case 3:
        return "Embarking on the Journey of Self-Awareness";

      case 4:
        return "SWOT Analysis";

      case 5:
        return "Peer Review | SWOT Analysis";

      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week13;
