import React, { useState, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

// Create a context
const PasteDisabledContext = createContext();

// Create a provider component
const PasteDisabledProvider = ({ children }) => {
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);

  const handlePaste = (event) => {
    event.preventDefault();
    setShowAlert(true);
  };

  const handleClickAlertHidden = () => {
    setShowAlert(false); // Set showAlert to false to hide the alert
  };

  // Define the routes where you want to disable paste
  const disabledRoutes = [
    "/week_0",
    "/week_1",
    "/week_2",
    "/week_3",
    "/week_4",
    "/week_5",
    "/week_6",
    "/week_7",
    "/week_8",
    "/week_9",
    "/week_10",
    "/week_11",
    "/week_12",
    "/week_13",
    "/week_14",
    "/week_15",
  ];

  // Check if the current route is in the disabledRoutes array
  const isDisabledRoute = disabledRoutes.includes(location.pathname);


  return (
    <PasteDisabledContext.Provider
      value={{ showAlert: isDisabledRoute && showAlert, handlePaste }}
    >
      <div onPaste={isDisabledRoute ? handlePaste : undefined}>
        {isDisabledRoute && showAlert && (
          <div>
            <div className="fixed bottom-0 end-0 z-[60] sm:max-w-xl w-full mx-auto p-6">
              {/* Card */}
              <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm darki:bg-gray-800 darki:border-gray-700">
                <div className="flex gap-x-5">
                  <img
                    className="h-16"
                    src="https://res.cloudinary.com/ahbranding/image/upload/v1677524706/AHbranding/favicon_uavysk.png"
                  />
                  <div className="grow">
                    <h2 className="text-lg font-semibold text-gray-800 darki:text-white">
                      Protecting Your Brilliance 🌟
                    </h2>
                    <p className="mt-2 text-sm text-gray-600 darki:text-gray-400">
                      Hey there! To keep your genius ideas safe from plagiarism
                      and AI mischief, we've temporarily disabled paste. We're
                      all about fostering originality here! 🚀💡
                    </p>

                    <button
                      onClick={handleClickAlertHidden}
                      type="button"
                      className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                    >
                      Got it, Let's Roll!
                    </button>
                  </div>
                </div>
              </div>
              {/* End Card */}
            </div>
          </div>
        )}
        {children}
      </div>
    </PasteDisabledContext.Provider>
  );
};

// Create a hook to use the context
const usePasteDisabled = () => {
  return useContext(PasteDisabledContext);
};

export { PasteDisabledProvider, usePasteDisabled };
