import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import ReactPlayer from "react-player";
import Vimeo from '@u-wave/react-vimeo';
import Collapse from "../components/Collapse";
import Confetti from 'react-confetti';
import "./new-certi.css";
const Week3 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 4;
  const week_name = "week_3";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [checkBox,setCheckBox] = useState(false)
  const [disableBtn,setDisableBtn] = useState(false)

  const [publicspeakingExercise, setpublicspeakingExercise] = useState({
    reflection: "",
  });

  const arePublicspeakingValid = countWords(publicspeakingExercise?.reflection) >= 50;

  const isAnySectionInvalid =
      !arePublicspeakingValid
      const [RecheckBox,setRecheckBox] = useState(false)
      const [RecheckBox2,setRecheckBox2] = useState(false)

  function countWords(text) {
    const trimmedText = text?.trim();
    const words = trimmedText?.split(/\s+/);
    return words?.length;
  }

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
  });
  const [assignedReview, setAssigned] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log(response);
        // toast.success("Good Job! Activity Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            strengths: data.strengths,
            weaknesses: data.weaknesses,
            opportunities: data.opportunities,
            threats: data.threats,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          // toast.success("Good Job! Activity Submitted", {
          //   icon: "👏",
          //   style: {
          //     borderRadius: "55px",
          //     marginBottom: "15px",
          //   },
          // });
          setpublicspeakingExercise({
            reflection: data.reflection,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,config);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 7, pathway_id);
      // fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "898992480"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_4",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active") {
      axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
        user_id: user.user_id,
        week_name: week_name,
        week_id: week_id,
        section: currentStep,
        content: publicspeakingExercise,
        pathway: 1,
        status: "Reviewing",
        rating: 0,
        pr_enabled: true,
        activity_name: "Reflecting on Communication in Leadership",
      },config)
      .then(response => {
        // Handle success
        setDisableBtn(true)
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch(error => {
        // Handle error
        console.error("Error submitting activity:", error);
        toast.error("Error submitting activity. Please try again later.", {
          icon: "❌",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
    }

  };


  const accordionData = [
    {
      title: 'Clarity',
      content: 'Your message needs to be clear and precise. Avoid using jargon or complex language that could lead to misunderstandings.'
    },
    {
      title: 'Listening',
      content: 'Effective communication is a two-way street. Listening to your team members fosters a sense of respect and openness, creating an environment where everyone feels heard and valued.'
    },
    {
      title: 'Empathy',
      content: 'Understanding and acknowledging the feelings and perspectives of others strengthens relationships and promotes effective collaboration. Cultivating empathy in your communication fosters a supportive and inclusive team culture.'
    },
    {
      title: 'Non-Verbal Communication',
      content: 'Your body language, facial expressions, and tone of voice can convey more than words. Being aware of your non-verbal cues can significantly enhance your communication effectiveness, ensuring that your intended message aligns with your non-verbal signals.'
    },
    {
      title: 'Feedback',
      content: 'Offering constructive feedback helps your team members grow and improve. Similarly, being open to feedback allows you to reflect and improve as a leader. Creating a feedback-rich environment promotes continuous learning and development.'
    },
    {
      title: 'Adaptability',
      content: 'Different situations and people require different communication styles. Being adaptable in your communication approach is key to effective leadership.'
    },
  ];


  const accordionData2 = [
    {
      title: 'Deeper Than Words',
      content: "Communication is not just about relaying information; it's about making a connection. This is achieved when your message resonates with your audience's experiences, emotions, and values. Remember, as a leader, your goal is not just to be heard, but to be understood and to evoke a response."
    },
    {
      title: 'Setting Intentions',
      content: "Just like Karim, set clear intentions before communicating. In this case, Karim's intention was to ensure his team's arguments not only made sense logically but also emotionally connected with the audience. When you communicate with a clear purpose, your message becomes more impactful."
    },
    {
      title: 'The Power of Why',
      content: "Karim encouraged his team to focus on the 'why' of their arguments. The 'why' is the emotional core of your message and the key to meaningful communication. As Simon Sinek famously puts it, \"People don't buy what you do, they buy why you do it.\""
    },
    {
      title: 'Personalizing the Message',
      content: "By connecting their arguments to broader themes, Karim's team was able to create a narrative that resonated with their audience. As leaders, it's important to understand what matters to your audience and to tailor your message to those values and experiences."
    },{
      title: 'Emotion is Key',
      content: "Emotion is a powerful tool in communication. It's what transforms a good argument into a compelling narrative. As a leader, your ability to infuse passion and conviction into your message will set you apart."
    },
  ];
  
  const accordionData3 = [
    {
      title: 'Effective Communication',
      content: "This week, we delved into the essence of effective communication and its pivotal role in impactful leadership. We studied how clarity, empathy, active listening, and non-verbal cues combine to create powerful communication channels, and we learned this through Karim's continued leadership journey."
    },
    {
      title: 'Drivers of Effective Communication',
      content: "We explored the elements of effective communication, including the importance of giving and receiving meaningful messages, the power of non-verbal communication, and the need for adaptable communication styles. Recognizing and understanding these elements are vital for building strong communication skills."
    },
    {
      title: 'Importance of Meaning in Communication',
      content: "We delved into why it's crucial to infuse our communications with meaning and purpose. We explored this through a narrative that showed how Karim learned to craft meaningful messages that resonated with his audience and made him a more effective leader."
    },
    // {
    //   title: 'Peer Review',
    //   content: "You provided valuable feedback to your peers on their speeches and discussion contributions. Your thoughtful insights and constructive criticism contributed to a supportive learning environment and helped your peers in their journey of improving their communication skills."
    // },
  ];
  
  
  const totalSteps = 8; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  
const questionsData = [
    {
      question: "What is the role of effective communication in leadership?",
      options: [
        "To inform the team about tasks",
        "To convey expectations and give instructions",
        "To connect, inspire, and foster a shared understanding",
        "To command and control the team"
      ],
      correctAnswer: "To connect, inspire, and foster a shared understanding",
      explanation: "While A, B, and D may be parts of communication, the broader role of effective communication in leadership is to connect with the team, inspire them towards shared goals, and foster a mutual understanding (Option C)."
    },
    {
      question: "Which of the following is NOT a common challenge in effective communication?",
      options: [
        "Overlooking non-verbal communication",
        "Listening more than talking",
        "Misunderstanding communication as just talking",
        "Not adapting communication style to suit different situations"
      ],
      correctAnswer: " Listening more than talking",
      explanation: "Active listening is a crucial aspect of effective communication. Therefore, listening more than talking is not a challenge but rather a positive practice in effective communication (Option B)."
    },
    {
      question: "How does setting clear intentions before communicating help a leader?",
      options: [
        "It ensures that the leader dominates the conversation",
        " It ensures that the message is impactful and purposeful",
        "It ensures that the leader talks more than others",
        "It ensures that the leader does not have to listen to others"
      ],
      correctAnswer: " It ensures that the message is impactful and purposeful",
      explanation: "Clear intentions help the leader guide the communication in a purposeful and meaningful way, ensuring their message carries the necessary impact (Option B)."
    },
    {
      question: "What role does 'why' play in effective communication?",
      options: [
        "It complicates the message",
        " It adds unnecessary details",
        "It provides the emotional core of the message",
        "It diverts attention from the main message"
      ],
      correctAnswer: "It provides the emotional core of the message",
      explanation: "The 'why' in communication refers to the purpose, motivation, or reason behind a message or action. It provides the emotional core that resonates with people and makes the message more meaningful (Option C)."
    },
    {
      question: "According to Karim's story, what transformed the debate team's arguments into compelling narratives?",
      options: [
        "They used complicated jargon",
        " They spoke louder than their opponents",
        "They focused on personal connections and broader themes",
        "They rehearsed more times than their opponents"
      ],
      correctAnswer: "They focused on personal connections and broader themes",
      explanation: "In Karim's story, the debate team's arguments became compelling narratives when they connected their arguments to broader themes and personalized them. This made the arguments emotionally resonate with the audience (Option C)."
    }
  ];
  
  const books = [
    "Crucial Conversations: Tools for Talking when Stakes Are High by Kerry Patterson, Chapter 7 – How to Transform Anger and Hurt Feelings into Powerful Dialogue",
    "Business Communication: Developing Leaders for a Networked World by Peter Cardon, Chapter 4 – Crafting Messages for Electronic Media",
    "Just Listen by Mark Goulston, Chapter 4 – The Power of Listening",
    "Interpersonal Communication by Sarah Trenholm and Arthur Jensen, Chapter 7 – The Skills of Listening",
    "10 Ways to Have a Better Conversation by Celeste Headlee"
  ];

//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [selectedOption, setSelectedOption] = useState('');
//   const [score, setScore] = useState(0);

//   const handleOptionSelect = (option) => {
//     setSelectedOption(option);
//   };

//   const handleNextQuestion = () => {
//     if (selectedOption === questionsData[currentQuestion].correctAnswer) {
//       setScore(score + 1);
//     }
//     setSelectedOption('');
//     setCurrentQuestion(currentQuestion + 1);
//   };


const [currentQuestion, setCurrentQuestion] = useState(0);
const [selectedOption, setSelectedOption] = useState('');
const [showExplanation, setShowExplanation] = useState(false);

const handleOptionSelect = (option) => {
  setSelectedOption(option);
};

const handleNextQuestion = () => {
  setShowExplanation(true);
};

// const handleNextQuestionOrFinish = () => {
//   if (currentQuestion + 1 < questionsData.length) {
//     setCurrentQuestion(currentQuestion + 4);
//     setSelectedOption('');
//     setShowExplanation(false);
//   }
// };


const handleNextQuestionOrFinish = () => {
  if (currentQuestion + 1 < questionsData.length) {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedOption('');
    setShowExplanation(false);
    console.log("Current Question Index:", currentQuestion + 1); // Debugging log
  }
};




const [nextBtnCondtion, setNextBtnCondtion] = useState(false)


useEffect(() => {
  const wordCounts = Object.values(publicspeakingExercise).map(value => value.split(/\s+/).filter(word => word !== '').length);
  // alert("WORDS: "+wordCounts);
  if (currentStep === 0 || currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6) {
    setNextBtnCondtion(true); // Enable button at step 0
  } else if (currentStep === 2 && currentImageIndex < 6) {
    setNextBtnCondtion(false);
  } else if (currentStep === 2 && currentImageIndex > 5) {
    setNextBtnCondtion(true);
  } 

  else if (currentStep === 7 && status==='Completed') {
    setNextBtnCondtion(true);
  } 

  // else if (currentStep === 7 && status==='Active' && Object.values(publicspeakingExercise).every(value => value !== '')){
  //   setNextBtnCondtion(true);
  // }

  

  else if (currentStep === 7 && status === 'Active' && wordCounts > 50 && disableBtn) {
    setNextBtnCondtion(true);
  }
  
  
  // else if (currentStep === 5 && ((status==='Completed' && Object.values(publicspeakingExercise).some(value => value !== '')))) {
  //   setNextBtnCondtion(true);
  // } 
  
  
  else {
    setNextBtnCondtion(false);
  }
}, [currentStep, currentImageIndex, status, publicspeakingExercise, isAnySectionInvalid,disableBtn]);




  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1692603965/AHbranding/Group_34784410_q9qk6r.png"
            />
          </div>

          <div className="">
            {/* <h2 className="text-xl font-medium sm:text-xl">
              Welcome back <b>{profile?.firstname} 👋</b>
            </h2> */}

            <h2 className="text-xl font-medium sm:text-xl">
              Welcome to: <b>Week 3: Effective Communication</b>
            </h2>
            <p className="mt-4 text-gray-600">
              As we venture into Week 3 of our leadership journey, we turn our
              focus towards one of the most vital skills in leadership -
              effective communication. In our diverse and rapidly changing
              student communities, effective communication is a pivotal tool in
              a leader's repertoire. It goes beyond mere verbal exchanges; it
              entails active listening, efficient conveying of ideas,
              understanding, and interpreting both verbal and non-verbal cues.{" "}
              <br /> <br />
              Effective communication fosters strong connections, inspires
              action, and constructs resilient teams. It's through this potent
              skill that leaders can shape their environment, promoting an
              atmosphere of respect, inclusivity, and active participation.{" "}
              <br /> <br />
              Throughout this week, we aim to enhance your understanding and
              proficiency in effective communication. We invite you to engage,
              learn, and experiment with various facets of communication,
              enhancing your overall leadership capabilities. <br /> <br />
              In our exploration of effective communication, let us set our
              learning intentions and delve into the following questions:
            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center p-0">
                <div className="space-y-4">
                  <button className="p-4 rounded-2xl btn-block no-animation justify-start text-start border-2 border-purple-200">
                    {" "}
                    What constitutes effective communication in a leadership
                    role?{" "}
                  </button>
                  <button className="p-4 rounded-2xl  btn-block no-animation justify-start text-start border-2 border-purple-200">
                    {" "}
                    How can you set intention to improve your communication
                    skills?{" "}
                  </button>
                  <button className="p-4 rounded-2xl  btn-block no-animation justify-start text-start border-2 border-purple-200">
                    How communication methods impact your effectiveness as a leader?
                  </button>
                  <button className="p-4 rounded-2xl  btn-block no-animation justify-start text-start border-2 border-purple-200">
                    {" "}
                    How can effective communication improve your leadership
                    journey?{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
         <b>Note: </b> As you answer these questions, we hope you'll gain insights into your
          unique communication style and how it shapes your leadership
          capabilities. Our goal for this week is to provide you with a
          versatile set of communication skills that will enhance your
          leadership potential and equip you for future leadership roles.
        </p>
      </div>
    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1701345655/AHbranding/Group_34784444_wfqznd.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Why Does Effective Communication Matter?</b>
            </h2>
            <p className="mt-4 text-gray-600">
              Effective communication forms the bedrock of any leadership role.
              In our increasingly interconnected world, the ability to convey
              ideas, stimulate discussion, and inspire action is a fundamental
              aspect of being a leader. It is through effective communication
              that a leader can connect, motivate, and build strong teams.
              <br /> <br />
              When approached with intent, effective communication can truly be
              transformative. It helps to create a constructive environment
              where ideas flourish, conflicts are resolved efficiently, and
              everyone feels heard and valued. It not only amplifies the
              leader's influence but also strengthens the bond within the team,
              creating a sense of belonging and mutual respect.
              <br /> <br />
              As we navigate through this week's theme, let's acknowledge the
              power of effective communication and its implications in
              leadership. Reflect upon these questions:
            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center p-0">
                <div className="space-y-4">
                  <button className="p-4 rounded-2xl  btn-block no-animation justify-start text-start border-2 border-purple-200">
                    {" "}
                    When was the last time you consciously made an effort to communicate effectively? What was the impact of this approach?{" "}
                  </button>
                  <button className="p-4 rounded-2xl  btn-block no-animation justify-start text-start border-2 border-purple-200">
                    {" "}
                    What communication challenges have you faced in your
                    leadership journey? How did you tackle them?{" "}
                  </button>
                  <button className="p-4 rounded-2xl  btn-block no-animation justify-start text-start border-2 border-purple-200">
                    {" "}
                    What new strategies are you excited to learn and implement
                    in your communication style? What intentions do you have for
                    improving your communication skills?{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
          Your Power Lies in Effective Communication
      </h2>

      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}
            <Vimeo
      video={videoId}
      autoplay
    />

          </div>
        </div>
      </div>
    </section>,
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <div className="mt-6 flex justify-start w-full">
    //     <SliderWeek1
    //       className="w-full"
    //       onImageIndexChange={handleImageIndexChange}
    //     />
    //   </div>
    // </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1701346129/AHbranding/Group_34784445_tmghum.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Framing Our Learning Intentions to Improve Communication</b>
            </h2>
            <p className="mt-4 text-gray-600">
              Communication isn't merely about exchanging words or sharing
              information. It's a process of understanding, connecting, and
              influencing. As we embark on this learning journey, it's essential
              to frame our intentions around becoming better communicators.
              <br /> <br />
              When setting your learning intentions for communication, think
              about the 'what', 'why', and 'how'. What aspects of communication
              do you want to improve? Why is improving these aspects important
              to you as a leader? How will you go about developing these skills?
              <br /> <br />
              For instance, your learning intention might be: "I want to improve
              my active listening skills to ensure my team feels heard and
              valued. I will achieve this by practicing mindful listening in
              every interaction I have this week." Remember, setting a clear and
              personal learning intention paves the way for purposeful learning
              and growth.
            </p>
          </div>
        </div>
      </div>
    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1701346262/AHbranding/Group_34784446_eekvad.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Drivers of Effective Communication</b>
            </h2>
            <p className="mt-4 text-gray-600">
              Effective communication in leadership hinges on several key
              drivers:
            </p>

            <div className="mt-6 grid grid-cols-1 gap-6">
      <div className="space-y-4">
      <Collapse accordionData={accordionData}/>

      <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
         <b>Note: </b> As we progress through this week, keep these drivers in mind. Reflect on how they currently feature in your communication and how you might enhance these aspects to be an even more effective leader.
        </p>
      </div>
    </div>


          </div>
        </div>
      </div>
    </section>,




<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
    <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
      <img
        className="w-full"
        src="https://res.cloudinary.com/ahbranding/image/upload/v1701351266/AHbranding/Group_34784447_ufawy3.png"
      />
    </div>

    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Karim’s Story</b>
      </h2>
      <div className="h-[35rem] mt-2 p-4 bg-gray-50 overflow-scroll rounded-xl">
      <p className="mt-4 text-gray-600">
  Let's consider the story of Karim, a senior student who recently took over as the captain of his school's debate team. Having always been a strong debater himself, Karim was passionate about leading his team to victory in the upcoming national debate competition.<br/><br/>
  In one of their early practice sessions, Karim noticed something troubling. Despite the clarity of the arguments and the eloquence of the team members, there seemed to be a missing link in their performance. The arguments, while logically sound, didn't seem to carry the intended weight and failed to engage the listeners.<br/><br/>
  Reflecting on this, Karim realized the importance of meaning in communication. It wasn't enough to craft logically sound arguments; the team needed to imbue their communication with a sense of meaning that resonated with their audience and judges. They had to make their arguments not just heard but felt.<br/><br/>
  Setting a learning intention for himself and the team, Karim decided: "<b>We will learn to communicate with meaning, making our arguments not just logically sound but also emotionally impactful.</b>"<br/><br/>
  During the following practice sessions, Karim began to integrate this learning intention. He encouraged team members to dig deeper into their arguments, exploring not just the 'what' and 'how', but the 'why'. He urged them to connect their arguments to broader themes that resonated on an emotional level - justice, morality, compassion, human rights. He coached them on how to infuse their delivery with passion and conviction, transforming their debates from logical analyses into meaningful narratives.<br/>
  This change was palpable in their performance. The debates were no longer just exchanges of arguments; they became engaging dialogues that drew in the audience and the judges. By focusing on the meaning behind their words, the team was able to connect with their audience on a deeper level, leading them to triumph in the national debate competition.<br/><br/>
  Karim's journey underscores the power of meaning in effective communication. As a leader, he recognized the need to go beyond the surface and tap into the deeper, emotional layers of communication. He showed his team that when they communicated with meaning, they were not just debaters presenting arguments, but storytellers painting a compelling narrative.
</p>


      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">
  <p>Karim's story provides us with a valuable teaching moment about the importance of meaning in effective communication. Here are a few key takeaways:</p>
<Collapse accordionData={accordionData2}/>
  <p>This story should inspire you to examine your own communication habits. Do you communicate with intent? Do you focus on the 'why'? Do you consider the emotions of your audience? Reflect on these questions as you continue your journey of becoming an effective leader. Remember, effective communication is not just about speaking; it's about connecting.</p>
</div>
</div>

</div>
    </div>
  </div>
</div>
</section>,



    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        <b></b>
      </h2>
      <p className="mt-4 text-gray-600">
      </p>


{/* 
      <div className="">
      {currentQuestion < questionsData.length ? (
        <div>
          <h2 className="text-xl text-center font-semibold mb-8">{questionsData[currentQuestion].question}</h2>
          <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
            {questionsData[currentQuestion].options.map((option, index) => (
              <div
                key={index}
                className={`${
                  selectedOption === option ? 'bg-locked text-white' : 'bg-white'
                } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer `}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
          {selectedOption && (
            <div className="flex justify-center">
            <button
              className="btn btn-outline-primary mt-4"
              onClick={handleNextQuestion}
            >
              Next Question
            </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
          <p>Your score: {score}/{questionsData.length}</p>
        </div>
      )}
    </div>
 */}



 <div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
      {currentQuestion < questionsData.length ? (
        <div>
          <h2 className="text-xl text-center font-semibold mb-8">{questionsData[currentQuestion].question}</h2>
          <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
            {questionsData[currentQuestion].options.map((option, index) => (
              <div
                key={index}
                className={`${
                  (showExplanation && option === questionsData[currentQuestion].correctAnswer) ? 'bg-green-500 text-white' :
                  (selectedOption === option ? 'bg-purple-200 ' : 'bg-white')
                } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer `}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
          {selectedOption && (
            <div className="flex justify-center">
              <button
                className="btn btn-outline-primary mt-4"
                onClick={handleNextQuestion}
              >
                Show Answer
              </button>
            </div>
          )}
          {showExplanation && (
            <div className="mt-4">
              <p className="font-semibold">Correct Answer:</p>
              <p className="text-green-500">{questionsData[currentQuestion].correctAnswer}</p>
              <p className="font-semibold mt-2">Explanation:</p>
              <p>{questionsData[currentQuestion].explanation}</p>
              <div className="flex justify-center mt-4">
               {/* */}
               {currentQuestion + 1 < questionsData.length ? (
                <button
    className="btn btn-outline-primary"
    onClick={handleNextQuestionOrFinish}
  >
  Next Question
    
  </button>

               ) : null}
               
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
        </div>
      )}
    </div>


    </section>,
    
    

    <section
    className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    style={{ height: "100%" }}
  >
    {/* Step 1 content */}
    <h2 className="text-xl font-medium sm:text-xl">
      <b>Reflecting on Communication in Leadership</b>
    </h2>
    {/* <h4>Activity Description:</h4> */}
    <p className="mt-4 text-gray-600">
    This activity is designed to enhance your understanding of the role of effective communication in leadership. You will reflect on your personal leadership experiences, focusing on how effective communication either improved or lack of effective communication hindered your leadership effectiveness.
    </p>
{/* 
    <ul
      className="mt-4 bg-amber-50 px-3 pt-4 pb-8 rounded-xl border-2 border-amber-100 text-sm "
      style={{ listStyle: "circle" }}
    >
      <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        Instructions{" "}
      </p>
      <strong>Analyze Communication's Role:</strong>

      <h5 className='m-2 text-lg'>Emotional Response:</h5>
      <li className="mx-6" >
      If the experience was positive, describe how effective communication contributed to your success.
      </li>
        <li className="mx-6" >If the experience was challenging, discuss how lack of effective communication played a part.</li>

      <h5 className='m-2 text-lg'>Reflect on Lessons Learned:</h5>
      <li className="mx-6" >For a positive experience, identify key communication strategies that were effective.</li>
      <li className="mx-6" >For a challenging experience, reflect on what communication approaches could have improved the situation.</li>

      <h5 className='m-2 text-lg'>Write Your Reflection:</h5>
      <li className="mx-6" >Compose a reflection of 300-400 words detailing your experience and the role of communication.</li>
      <li className="mx-6" >Ensure your reflection includes specific examples and insights into how communication impacted your leadership.</li>
      <li className="mx-6" >Submit for Review: Share your reflection within the app for peer review. This will allow for constructive feedback and shared learning among your peers.</li>

    </ul> */}

    <ul className="mt-4 bg-amber-50 px-3 pt-4 pb-8 rounded-xl border-2 border-amber-100 text-sm" style={{ listStyle: "circle" }}>
  <p className="mx-6 mt-4 font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
    </svg>
    Instructions{" "}
  </p>
  <li className="mx-6 mt-2">
    <strong>Choose a Leadership Experience:</strong> Select a specific instance from your past where you were in a leadership role. This could be within a student society, a group project, or any other scenario where you had to guide or influence others.
  </li>
  <li className="mx-6 mt-2">
    <strong>Analyze Communication's Role:</strong>
    <ul>
      <li>If the experience was positive, describe how effective communication contributed to your success.</li>
      <li>If the experience was challenging, discuss how lack of effective communication played a part.</li>
    </ul>
  </li>
  <li className="mx-6 mt-2">
    <strong>Reflect on Lessons Learned:</strong>
    <ul>
      <li>For a positive experience, identify key communication strategies that were effective.</li>
      <li>For a challenging experience, reflect on what communication approaches could have improved the situation.</li>
    </ul>
  </li>
  <li className="mx-6 mt-2">
    <strong>Write Your Reflection:</strong>
    <ul>
      <li>Compose a reflection of 300-400 words detailing your experience and the role of communication.</li>
      <li>Ensure your reflection includes specific examples and insights into how communication impacted your leadership.</li>
    </ul>
  </li>

  <li className="mx-6 mt-2">
    <strong>Submit for Review:</strong>
    <ul>
      <li>Share your reflection within the app for peer review. This will allow for constructive feedback and shared learning among your peers.</li>
    </ul>
  </li>
</ul>


    
{status === "Completed" || disableBtn ? (
              <div className="my-4">
                <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">

                  <ul>
                    <li><b>Reflection:</b><br/> {publicspeakingExercise?.reflection}<br/><br/></li>
                  </ul>

                </p>
              </div>
            ) : (

<div>
<div className="mt-6 flex justify-start w-full">
      <div className="form-control w-full ">
        <label className="label">
          <span className="label-text font-bold">Write Your Reflection</span>
        </label>
        <textarea
          value={publicspeakingExercise.reflection }
          onChange={(e) =>
            setpublicspeakingExercise((prev) => ({
              ...prev,
              reflection: e.target.value,
            }))
          }
          className="textarea textarea-sm textarea-bordered h-20"
          placeholder="Type here..."
        ></textarea>
        <small className="mt-6 text-end text-gray-400"> {countWords(publicspeakingExercise?.reflection)} / 50 words</small>

      </div>
    </div>


    <div className="flex items-center gap-2">
      <input
          type="checkbox"
          checked={checkBox}
          className="checkbox checkbox-warning"
          onChange={() => {
            setCheckBox(!checkBox); // Toggle the checkbox state
          }}
      />
      <span className="text-black-400 ">Do you agree to share your response with a peer for the purpose of receiving constructive feedback. </span>
    </div>


    <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={RecheckBox} 
  onChange={() => {
    setRecheckBox(!RecheckBox); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Read your submission carefully before submitting</span>
      </div>

    <button
      className="btn btn-primary mt-8 bg-neutral-50"
      onClick={handleStepSubmission}
      disabled={status === "Completed" || !RecheckBox || isAnySectionInvalid || !checkBox || disableBtn }
    >
      Submit
    </button>
</div>   
            )}


    
  </section>
    
    ,



    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    {/* Step 1 content */}
    
    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
      <div className="grid grid-cols-1 gap-8 lg:gap-16">

      <Confetti numberOfPieces={100} />
        <div className="">
          <h2 className="text-xl font-medium sm:text-xl">
            <b> A quick recap</b>
          </h2>
          <p className="mt-4 text-gray-600">
          Congratulations on completing Week 3: Effective Communication in Leadership! You've made significant strides on your leadership development journey. Here's a brief summary of this week's key points:          </p>
    
    
          <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">
<Collapse accordionData={accordionData3}/>

</div>





</div>
    
    <p className="mt-4 text-gray-600">
    Great job on completing Week 3! Prepare yourself for Week 4, where we will delve into Empathy and its role in leadership. Keep up the outstanding work and continue your journey towards
becoming an extraordinary leader.
Feel free to explore the following resources to help you understand more about yourself.
          
                    </p>



                    <ul  className="block p-6 flex flex-col gap-2" style={{listStyle:'circle'}}>
                    {books.map((book, index) => (
  index === books.length - 1 ? (
    <li><a className="underline text-primary" href="https://www.ted.com/talks/celeste_headlee_10_ways_to_have_a_better_conversation" target="_blank" key={index}>{book}</a></li>
  ) : (
    <li key={index}>{book}</li>
  )
))}
      </ul>
      <div className="w-full flex justify-end items-center pt-6">
             <span className="text-lg">- Team Ascent</span>
             </div>
        </div>
      </div>
    </div>
    </section>
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "Effective communication video";

      case 3:
        return "Improve Communication";

      case 4:
        return "Drivers of Effective Communication";

      case 5:
        return "Karim’s Story ";

        case 6:
        return "Knowledge Check";

        case 7:
        return "Reflecting on Communication in Leadership";

        
      default:
        return "Recap";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              disabled={!nextBtnCondtion}
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Week3;
