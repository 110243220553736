// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek6 = ({ onImageIndexChange }) => {
  const slideImages = [
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382642/AHbranding/slideshow_2_revised_page-0001_tmkoxw.jpg',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382644/AHbranding/slideshow_2_revised_page-0002_fsr6qc.jpg',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382646/AHbranding/slideshow_2_revised_page-0003_pfnwys.jpg',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382641/AHbranding/slideshow_2_revised_page-0004_fcukj7.jpg',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382637/AHbranding/slideshow_2_revised_page-0005_xh9uhd.jpg',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382638/AHbranding/slideshow_2_revised_page-0006_tzycwc.jpg',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382637/AHbranding/slideshow_2_revised_page-0007_mikvk4.jpg',

    'https://res.cloudinary.com/ahbranding/image/upload/v1702382640/AHbranding/slideshow_2_revised_page-0008_xlvkri.jpg',
    'https://res.cloudinary.com/ahbranding/image/upload/v1702382643/AHbranding/slideshow_2_revised_page-0009_egiyn8.jpg',
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek6;
