import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import ReactPlayer from "react-player";
import SliderWeek4 from "./SliderWeek4";
import Vimeo from '@u-wave/react-vimeo';
import Collapse from "../components/Collapse";
import Confetti from 'react-confetti';
import "./new-certi.css";


const Week4 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 5;
  const week_name = "week_4";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const [peerFeedback, setpeerFeedback] = useState("");
  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [disableBtn,setDisableBtn] = useState(false)
  const [checkBox,setCheckBox] = useState(false)
  const [RecheckBox,setRecheckBox] = useState(false)
  const [loading, setLoading] = useState(true);
  const [empathyMapping, setempathyMapping] = useState({
    think: "",
    feel: "",
    say: "",
    hear: "",
    see: "",
  });
  const [assignedReview, setAssigned] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
    saying: "",
  });
  const [subFeedback, setSubFeedback] = useState("");
  const handleSubFeedback = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`, {
        peerid: peerid,
        subFeedback: subFeedback,
        flag: true,
      },config)
      .then((res) => {
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  function countWords(text) {
    const trimmedText = text?.trim();
    const words = trimmedText?.split(/\s+/);
    return words?.length;
  }

  const areStrengthsValid = countWords(empathyMapping?.think) >= 50;
  const areWeaknessesValid = countWords(empathyMapping?.feel) >= 50;
  const areOpportunitiesValid = countWords(empathyMapping?.hear) >= 50;
  const areThreatsValid = countWords(empathyMapping?.see) >= 50;
  const areSayingValid = countWords(empathyMapping?.say) >= 50;

  const isAnySectionInvalid =
      !areStrengthsValid ||
      !areWeaknessesValid ||
      !areOpportunitiesValid|| !areThreatsValid || areSayingValid


  const isEveryFieldValid = Object.values(empathyMapping).every(value => {
        // Check if the value exists and its length is less than 50 characters
        return value && value.length >= 50;
      });

  const [VideoSubmission, setVideoSubmission] = useState({
    video: "",
  });
  const [assignedVideoSubmission, setassignedVideoSubmission] = useState({
    video: "",
  });
  const [swotFeedback, setFeedback] = useState("");
  const [videoFeedback, setvideoFeedback] = useState("");
  const [videoFeedbackUser, setvideoFeedbackUser] = useState("");
  
  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log("data getted",response);
        // toast.success("Good Job! Activity Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            // saying: data.saying,
            // video:data.video,

            think:data.think,
            feel:data.feel,
            say:data.say,
            hear:data.hear,
            see:data.see,
          });
          setFeedback(response.data.feedback);
          setvideoFeedback(response.data.feedback);
        }
      });
  };


  const fetchAssignedVideo = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      },config)
      .then((response) => {
        console.log("VIDEO: ",response);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setassignedVideoSubmission({
            video: data.video,
          });
          setvideoFeedback(response.data.feedback);
        }
      });
  };
  const [peerid, setPeerId] = useState(0);
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          
          setempathyMapping({
            think:data.think,
            feel:data.feel,
            say:data.say,
            hear:data.hear,
            see:data.see,
          });
        }
      });
  };


  const fetchVideoStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      },config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);
          setVideoSubmission({
            video: data.video,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchVideoStepData(profileID, week_id, week_name, 5, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
      fetchAssignedVideo(profileID, week_id, week_name, 5);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "898993100"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };
  const [rating, setRating] = useState(0);
  const handleRating = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/peer_rating/`, {
        peerid: peerid,
        rating: rating,
      },config)
      .then((res) => {
        toast.success("Good Job! Rating Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };
  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_5",
              },config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(empathyMapping);

  console.log(empathyMapping);
  console.log(swotFeedback);

  const accordionData = [
    {
      title: 'The Dimensions of Empathy',
      content: (
        <ul className="px-6" style={{listStyle:"disc"}}>
          <li>
            <strong>Cognitive Empathy:</strong> This refers to our ability to understand or comprehend another person's perspective or thoughts. It's like reading someone's mind, discerning their feelings, and understanding their reactions.
          </li>
          <li>
            <strong>Emotional Empathy:</strong> This is about feeling alongside another person. When they rejoice, you rejoice. When they grieve, you feel their sorrow. It's an emotional resonance, a shared feeling.
          </li>
          <li>
            <strong>Compassionate Empathy:</strong> This dimension propels us into action. It's not just understanding or feeling what another person is going through but being moved to help if needed.
          </li>
        </ul>
      ),
    },
    {
      title: 'Why is Empathy Crucial for Leaders?',
      content: (
        <ul className="px-6" style={{listStyle:"disc"}}>
          <li>
            <strong>Builds Trust:</strong> When team members feel understood and valued, it fosters an environment of trust. They are more likely to open up, share concerns, and contribute actively.
          </li>
          <li>
            <strong>Enhances Collaboration:</strong> Understanding diverse perspectives leads to richer brainstorming sessions and more holistic solutions.
          </li>
          <li>
            <strong>Promotes Inclusivity:</strong> An empathetic leader ensures that every voice is heard, fostering a culture where everyone feels they belong.
          </li>
          <li>
            <strong>Drives Engagement:</strong> Employees are more committed and motivated when they feel their leaders understand and care about them.
          </li>
          <li>
            <strong>Facilitates Conflict Resolution:</strong> Empathy allows leaders to see all sides of an issue, mediating disputes with fairness and understanding.
          </li>
        </ul>
      ),
    },
  ];
  
  
  
  const accordionData2 = [
    {
      title: "Understanding Others' Perspectives",
      sub: "Walking in Another's Shoes",
      contentTwo:["Empathy begins with the willingness to step into another's world. It's about setting aside our judgments and truly understanding another's perspective.","Challenge yourself to listen actively, without formulating responses in your mind.","Engage in conversations with an open heart, seeking to understand before being understood.","Reflect on moments when you felt deeply understood by someone. What made those interactions special?"],
      content:
        "Empathy begins with the willingness to step into another's world. It's about setting aside our judgments and truly understanding another's perspective.\n\n" +
        "• Challenge yourself to listen actively, without formulating responses in your mind.\n" +
        "• Engage in conversations with an open heart, seeking to understand before being understood.\n" +
        "• Reflect on moments when you felt deeply understood by someone. What made those interactions special?",
    },
    {
      title: "Empathetic Communication",
      sub: "Building Bridges of Understanding",
      contentTwo:["Empathy plays a crucial role in effective communication, ensuring that messages are not just heard but deeply understood.",'Use empathetic responses, such as "I can imagine how that must feel" or "That sounds challenging."',"Reflect on your communication style. How can you infuse more empathy into your interactions?"],
      content:
        "Empathy plays a crucial role in effective communication, ensuring that messages are not just heard but deeply understood.\n\n" +
        '• Practice active listening, giving your full attention to the speaker.\n' +
        '• Use empathetic responses, such as "I can imagine how that must feel" or "That sounds challenging."\n' +
        "• Reflect on your communication style. How can you infuse more empathy into your interactions?",
    },
    {
      title: "Empathy in Action",
      sub: "Moving Beyond Feeling to Helping",
      contentTwo:["Empathy doesn't stop at understanding and feeling; it propels us to act, to support, and to help.","Identify opportunities where you can offer support or assistance based on your empathetic understanding","Engage in acts of kindness, big or small, driven by genuine empathy.","Reflect on times when someone's empathetic actions made a difference in your life. How can you pay it forward?"],
      content:
        "Empathy doesn't stop at understanding and feeling; it propels us to act, to support, and to help.\n\n" +
        "• Identify opportunities where you can offer support or assistance based on your empathetic understanding.\n" +
        "• Engage in acts of kindness, big or small, driven by genuine empathy.\n" +
        "• Reflect on times when someone's empathetic actions made a difference in your life. How can you pay it forward?",
    },
  ];
  
  const accordionData3 = [
    {
      title: 'Understanding Empathy',
      content:
        'We differentiated empathy from sympathy and compassion, emphasizing its role as a bridge to genuine human connection.\n\n',
    },
    {
      title: 'Empathy Mapping Activity',
      content:
        'By stepping into Ahmed\'s world, we practiced the art of empathy, gaining insights into the myriad emotions, thoughts, and perceptions one might experience in a new environment.\n\n',
    },
    {
      title: 'Peer Reviews',
      content:
        'Your thoughtful feedback and reflections will showcase a deep understanding of empathy\'s role in leadership, fostering a collaborative and supportive learning environment.\n\n',
    },
  ];
  



  // const handleStepSubmission = () => {
  //   console.log("this step", currentStep);
  
  //   if (status === "Active" && currentStep === 6) {
  //     axios
  //       .post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
  //         user_id: user.user_id,
  //         week_name: week_name,
  //         week_id: week_id,
  //         section: 4,
  //         content: videoFeedback,
  //         pathway: 1,
  //         feedback: videoFeedback,
  //       })
  //       .then(() => {
  //         toast.success("Good Job! Feedback Submitted", {
  //           icon: "👏",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error submitting feedback:", error);
  
  //         toast.error("Oops! Something went wrong. Please try again later.", {
  //           icon: "❌",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       });
  
  //     return;
  //   } else if (status === "Active" && (currentStep === 4 || currentStep === 5)) {
  //     const submissionType = currentStep === 4 ? "Activity" : "Video";
  //     axios
  //       .post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
  //         user_id: user.user_id,
  //         week_name: week_name,
  //         week_id: week_id,
  //         section: currentStep,
  //         content: currentStep === 4 ? empathyMapping : VideoSubmission,
  //         pathway: 1,
  //         status: "Reviewing",
  //       })
  //       .then(() => {
  //         toast.success('Good Job! ${submissionType} Submitted', {
  //           icon: "👏",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error submitting ${submissionType.toLowerCase()}:', error);
  
  //         toast.error("Oops! Something went wrong. Please try again later.", {
  //           icon: "❌",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       });
  //   }
  // };

  const handleStepSubmission = () => {
    console.log("this steo", currentStep);

  if(status==='Active'){
    
    axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
      user_id: user.user_id,
      week_name: week_name,
      week_id: week_id,
      section: currentStep,
      content: empathyMapping,
      pathway: 1,
      status: "Reviewing",
      rating: 0,
      pr_enabled: true,
      activity_name: "Empathy Mapping",
  },config)
  .then(response => {
        setDisableBtn(true);
      toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
              borderRadius: "55px",
              marginBottom: "15px",
          },
      });
  })
  .catch(error => {
      console.error('Error submitting activity:', error);
      setDisableBtn(false);
      // Handle error here, maybe show a toast with an error message
  });
  }

  };

  const totalSteps = 5; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;
  

  
  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const questionsData = [
    {
      question: "What is the role of effective communication in leadership?",
      options: [
        "To inform the team about tasks",
        "To convey expectations and give instructions",
        "To connect, inspire, and foster a shared understanding",
        "To command and control the team",
      ],
      correctAnswer: "To connect, inspire, and foster a shared understanding",
      explanation:
        "While A, B, and D may be parts of communication, the broader role of effective communication in leadership is to connect with the team, inspire them towards shared goals, and foster a mutual understanding (Option C).",
    },
    {
      question:
        "Which of the following is NOT a common challenge in effective communication?",
      options: [
        "Overlooking non-verbal communication",
        "Listening more than talking",
        "Misunderstanding communication as just talking",
        "Not adapting communication style to suit different situations",
      ],
      correctAnswer: " Listening more than talking",
      explanation:
        "Active listening is a crucial aspect of effective communication. Therefore, listening more than talking is not a challenge but rather a positive practice in effective communication (Option B).",
    },
    {
      question:
        "How does setting clear intentions before communicating help a leader?",
      options: [
        "It ensures that the leader dominates the conversation",
        " It ensures that the message is impactful and purposeful",
        "It ensures that the leader talks more than others",
        "It ensures that the leader does not have to listen to others",
      ],
      correctAnswer: " It ensures that the message is impactful and purposeful",
      explanation:
        "Clear intentions help the leader guide the communication in a purposeful and meaningful way, ensuring their message carries the necessary impact (Option B).",
    },
    {
      question: "What role does 'why' play in effective communication?",
      options: [
        "It complicates the message",
        " It adds unnecessary details",
        "It provides the emotional core of the message",
        "It diverts attention from the main message",
      ],
      correctAnswer: "It provides the emotional core of the message",
      explanation:
        "The 'why' in communication refers to the purpose, motivation, or reason behind a message or action. It provides the emotional core that resonates with people and makes the message more meaningful (Option C).",
    },
    {
      question:
        "According to Karim's story, what transformed the debate team's arguments into compelling narratives?",
      options: [
        "They used complicated jargon",
        " They spoke louder than their opponents",
        "They focused on personal connections and broader themes",
        "They rehearsed more times than their opponents",
      ],
      correctAnswer: "They focused on personal connections and broader themes",
      explanation:
        "In Karim's story, the debate team's arguments became compelling narratives when they connected their arguments to broader themes and personalized them. This made the arguments emotionally resonate with the audience (Option C).",
    },
  ];

  const books = [
    "Crucial Conversations: Tools for Talking when Stakes Are High by Kerry Patterson, Chapter 7 – How to Transform Anger and Hurt Feelings into Powerful Dialogue",
    "Business Communication: Developing Leaders for a Networked World by Peter Cardon, Chapter 4 – Crafting Messages for Electronic Media",
    "Just Listen by Mark Goulston, Chapter 4 – The Power of Listening",
    "Interpersonal Communication by Sarah Trenholm and Arthur Jensen, Chapter 7 – The Skills of Listening",
    "10 Ways to Have a Better Conversation by Celeste Headlee",
  ];
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
  };
  //   const [currentQuestion, setCurrentQuestion] = useState(0);
  //   const [selectedOption, setSelectedOption] = useState('');
  //   const [score, setScore] = useState(0);

  //   const handleOptionSelect = (option) => {
  //     setSelectedOption(option);
  //   };

  //   const handleNextQuestion = () => {
  //     if (selectedOption === questionsData[currentQuestion].correctAnswer) {
  //       setScore(score + 1);
  //     }
  //     setSelectedOption('');
  //     setCurrentQuestion(currentQuestion + 1);
  //   };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1701424508/AHbranding/Group_34784450_pzno6c.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              Welcome to: <b>Week 4: Empathy</b>
            </h2>
            <p className="mt-4 text-gray-600">
              {/* Welcome to Week 4 of the "Ascent" leadership pathway: Empathy - */}
              Empathy, often described as the ability
              to understand and share the feelings of another, is not just a
              soft skill but a fundamental component of effective leadership. In
              a world where technological advancements and data-driven decisions
              are at the forefront, the human touch remains irreplaceable. This
              week, we delve deep into the essence of empathy, understanding its
              pivotal role in shaping leaders who are not just efficient but
              also profoundly connected to their teams, stakeholders, and the
              communities they serve. Join us on this enlightening journey as we
              uncover the heart of leadership. Let's embrace empathy, not just
              as a skill, but as a way of being.
            </p>
          </div>
        </div>
      </div>
    </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <div className="mt-6 flex justify-start w-full">
    //     <SliderWeek4
    //       className="w-full"
    //       onImageIndexChange={handleImageIndexChange}
    //     />

        
    //   </div>
    // </section>,

    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
    {/* Step 1 content */}
    <h2 className="text-xl font-medium sm:text-xl">
      Empathy & Leadership
    </h2>

    <div className="mt-6 flex justify-center w-full h-full">
      <div
        style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
      >
        <div className="video-container">
          {/* <YouTube videoId={videoId} opts={opts} /> */}
          <Vimeo
    video={videoId}
    autoplay
  />


        </div>
      </div>
    </div>
  </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1701426281/AHbranding/Group_34784451_tpcji6.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Understanding Empathy: The Soul of Connection</b>
            </h2>
            <p className="mt-4 text-gray-600">
              Empathy, often misconstrued as mere sympathy or compassion, stands
              distinct in its depth and application. At its core, empathy is the
              ability to truly understand and share the feelings of another, not
              merely as an observer but as if you were in their shoes. It's the
              bridge that connects individuals, transcending barriers of
              language, culture, and experience.
            </p>

            <div className="mt-6 gap-6">
              <div className="space-y-4">
                

              <Collapse contentTwo="contentTwo" accordionData={accordionData}/>

                {/* <p className="p-2">
                  
                </p> */}




              </div>
            </div>

            <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
          {" "}
          <strong>Note: </strong>In the realm of leadership, empathy is not just a
                  'nice-to-have' but a 'must-have.' It's the soul of connection,
                  the essence of understanding, and the foundation of genuine
                  collaboration. In a world that's increasingly interconnected
                  yet divided, empathy stands out as a beacon of hope, reminding
                  us of our shared humanity and the ties that bind us all.
        </p>
          </div>
        </div>
      </div>
    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              className="w-full"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1701427247/AHbranding/Group_34784452_hduqki.png"
            />
          </div>

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Core Aspects of Empathy</b>
            </h2>
            <p className="mt-4 text-gray-600">
              
              In our pursuit of leadership excellence, empathy emerges as a
              pivotal quality. By nurturing empathy, we foster trust, enhance
              collaboration, and build teams that are united in purpose and
              spirit. Dive into the Core Aspects of Empathy:
            </p>

            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">

                <Collapse contentTwo="contentTwo"  accordionData={accordionData2}/>
                  {/* <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                      Understanding Others' Perspectives
                    </summary>
                    <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
                      Walking in Another's Shoes
                    </h3>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                      Empathy begins with the willingness to step into another's
                      world. It's about setting aside our judgments and truly
                      understanding another's perspective.
                    </p>
                    <ul
                      className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
                      style={{ listStyle: "circle" }}
                    >
                      <li>
                        Challenge yourself to listen actively, without
                        formulating responses in your mind.
                      </li>
                      <li>
                        Engage in conversations with an open heart, seeking to
                        understand before being understood.
                      </li>
                      <li>
                        Reflect on moments when you felt deeply understood by
                        someone. What made those interactions special?
                      </li>
                    </ul>
                  </details>

                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                    Empathetic Communication                    </summary>
                    <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
                    Building Bridges of Understanding
                    </h3>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                    Empathy plays a crucial role in effective communication, ensuring that messages are not just heard but deeply understood.
                    </p>
                    <ul
                      className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
                      style={{ listStyle: "circle" }}
                    >
                      <li>
                      Practice active listening, giving your full attention to the speaker.
                      </li>
                      <li>
                      Use empathetic responses, such as "I can imagine how that must feel" or "That sounds challenging."
                      </li>
                      <li>
                      Reflect on your communication style. How can you infuse more empathy into your interactions?
                      </li>
                    </ul>
                  </details>

                  <details className="w-full border rounded-lg">
                    <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
                    Empathy in Action
                    </summary>
                    <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
                    Moving Beyond Feeling to Helping
                    </h3>
                    <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
                    Empathy doesn't stop at understanding and feeling; it propels us to act, to support, and to help.
                    </p>
                    <ul
                      className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
                      style={{ listStyle: "circle" }}
                    >
                      <li>
                      Identify opportunities where you can offer support or assistance based on your empathetic understanding.
                      </li>
                      <li>
                      Engage in acts of kindness, big or small, driven by genuine empathy.
                      </li>
                      <li>
                      Reflect on times when someone's empathetic actions made a difference in your life. How can you pay it forward?
                      </li>
                    </ul>
                  </details> */}

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

   
<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b>Empathy Mapping</b>
</h2>
<p className="mt-4 text-gray-600">
This activity aims to cultivate a deeper understanding of empathy by placing students in the shoes of a given character or scenario. Through the process of empathy mapping, students will explore the different dimensions of a character's experience, fostering a holistic understanding of their emotions, thoughts, and perceptions.
</p>

<ul
  className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
  style={{ listStyle: "circle" }}
>
  <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    Instructions{" "}
  </p>
  <li className="m-6">
  Begin by reading the provided scenario about Ahmed. Immerse yourself in his story and consider the challenges and emotions he might be experiencing as a transfer student in a new university.
  </li>
  <li className="m-6">
Based on the scenario, determine the emotions Ahmed might be feeling. Is he anxious, excited, overwhelmed, hopeful, or perhaps a mix of several emotions?
  </li>
  <li className="m-6">
Identify the potential obstacles or challenges Ahmed might face. These could be feelings of isolation, navigating a new campus, or adjusting to a different academic environment.
  </li>
  <li className="m-6">
Consider the positive opportunities that this new environment might present for Ahmed. These could include making new friends, joining student organizations, or benefiting from diverse academic programs.
  </li>
  <li className="m-6">
Assess the external factors that might impact Ahmed's experience. This could involve the university's culture, the attitudes of other students, or the support systems available to him.
  </li>
</ul>




<ul
  className="mt-4 bg-purple-50 p-2 rounded-xl border-2 border-purple-100 text-sm"
  style={{ listStyle: "circle" }}
>
  <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-purple-400 text-white w-max py-1 px-3 rounded-full">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
</svg>

Scenario: "The New Dawn at LUMS"{" "}
  </p>
  <li className="m-6">
<b>Background</b> <br/><br/> 

Ahmed, a 20-year-old computer science enthusiast from Multan, steps into the world of LUMS. Known for his coding skills but introverted by nature, Ahmed experiences a mix of excitement and nervousness as he navigates this new chapter of his life.
  
  </li>
  


  <li className="m-6">
<b>Setting</b> <br/><br/> 
The LUMS campus is a vibrant blend of modern architecture and natural beauty. Students from diverse backgrounds contribute to the dynamic atmosphere, engaging in lively discussions and campus activities.  </li>


  <li className="m-6">
  {/* <img src="https://res.cloudinary.com/ahbranding/image/upload/v1707553830/AHbranding/Group_34784445_sblhcw.png" className="w-4/12 mx-auto" /> */}
<b>Characters Involved</b> <br/><br/> 
Ahmed: Feeling like an outsider, Ahmed is both awed and overwhelmed by the lively campus life. His passion for coding is his comfort zone.<br/> <br/>
Fatima: As president of the Computer Science Society, Fatima is a familiar face on campus, known for her approachable nature and enthusiasm for tech.

<br/> <br/> 
Bilal and his Group: A tight-knit group of tech enthusiasts, always on the lookout for fresh talent for their upcoming hackathon.  </li>

  <li className="m-6">
<b>Situation</b> <br/><br/>


Ahmed finds himself lost near the SSE building, unsure where to turn. The bustling campus around him feels intimidating as he hesitates to ask for directions.
  </li>

  <li className="m-6">
<b>Interaction</b> <br/><br/>



Fatima, noticing Ahmed's confusion and recalling her own initial struggles, approaches him with a friendly smile, introducing herself and offering to show him around. As they walk, she shares anecdotes about her time at LUMS, making Ahmed feel more at ease. She strikes up a conversation, intuitively sensing his passion for coding.
<br/><br/>
Nearby, Bilal and his group are brainstorming for their upcoming hackathon. Noticing Ahmed with Fatima, Bilal recalls hearing about a prodigious coder from Multan. Intrigued, he considers inviting Ahmed to their brainstorming session.  </li>

  <li className="m-6">
<b>Ahmed's Perspective</b> <br/><br/>


Ahmed feels a mix of relief and anxiety as he talks to Fatima and Bilal. He hears the lively chatter of students around him, sees groups engaged in animated discussions, and wonders if he'll ever fit in.

  </li>
  <li className="m-6">
<b>Connection and Outcome</b> <br/><br/>
Fatimah's kindness and Bilal's subsequent interest in Ahmed's coding skills gradually pull him into the LUMS community.  Impressed by Ahmed's coding skills, Bilal invites him to join the hackathon committee. Ahmed, feeling more integrated into the LUMS community, eagerly accepts. As Ahmed becomes involved in the hackathon planning, he starts feeling more at home, his initial fears replaced by a sense of belonging and purpose.

  </li>


</ul>


{status === "Completed" || disableBtn ? (
              <div className="my-4">
                <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">

                  <ul>
                    <li><b>Thinking:</b><br/> {empathyMapping?.think}<br/><br/></li>
                    <li><b>Feeling:</b><br/> {empathyMapping?.feel}<br/><br/></li>
                    <li><b>Hearing:</b><br/> {empathyMapping?.hear}<br/><br/></li>
                    <li><b>Seeing:</b><br/> {empathyMapping?.see}<br/><br/></li>
                    <li><b>Saying:</b><br/> {empathyMapping?.say}<br/><br/></li>
                  </ul>

                </p>
              </div>
            ) : (

<div>


<div className="mt-6 flex justify-start w-full">
  <div className="form-control w-full ">
    <label className="label">
      <span className="label-text font-medium"><b>Thinking: <br/><br/></b>What is on Ahmed's mind, and what are his thoughts, priorities, and concerns in the current situation?</span>
    </label>
    <textarea
      value={empathyMapping.think ?? ""}
      onChange={(e) =>
        setempathyMapping((prev) => ({ ...prev, think: e.target.value }))
      }
      className="textarea textarea-sm textarea-bordered h-20"
      placeholder="Type here.."
    ></textarea>
     <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping.think)} / 50 words</small>
  </div>
</div>

<div className="mt-6 flex justify-start w-full">
  <div className="form-control w-full ">
  <label className="label">
      <span className="label-text font-medium"><b>Feeling: <br/><br/></b>What emotions is Ahmed currently experiencing, and how does he feel about the situation?</span>
    </label>
    <textarea
      value={empathyMapping?.feel ?? ""}
      onChange={(e) =>
        setempathyMapping((prev) => ({ ...prev, feel: e.target.value }))
      }
      className="textarea textarea-sm textarea-bordered h-20"
      placeholder="Type here..."
    ></textarea>
     <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.feel)} / 50 words</small>
  </div>
</div>

<div className="mt-6 flex justify-start w-full">
  <div className="form-control w-full ">
  <label className="label">
      <span className="label-text font-medium"><b>Seeing: <br/><br/></b>What does Ahmed observe in his surroundings, and what aspects of this new environment stand out to him?</span>
    </label>
    <textarea
      value={empathyMapping?.see ?? ""}
      onChange={(e) =>
        setempathyMapping((prev) => ({
          ...prev,
          see: e.target.value,
        }))
      }
      className="textarea textarea-sm textarea-bordered h-20"
      placeholder="Type here..."
    ></textarea>
     <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.see)} / 50 words</small>
  </div>
</div>

<div className="mt-6 flex justify-start w-full">
  <div className="form-control w-full ">
  <label className="label">
      <span className="label-text font-medium"><b>Hearing: <br/><br/></b>What is Ahmed hearing, both directly and indirectly?</span>
    </label>
    <textarea
      value={empathyMapping.hear ?? ""}
      onChange={(e) =>
        setempathyMapping((prev) => ({ ...prev, hear: e.target.value }))
      }
      className="textarea textarea-sm textarea-bordered h-20"
      placeholder="Type here..."
    ></textarea>
     <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.hear)} / 50 words</small>
  </div>
</div>


<div className="mt-6 flex justify-start w-full">
  <div className="form-control w-full ">
  <label className="label">
      <span className="label-text font-medium"><b>Saying: <br/><br/></b>What verbal expressions or statements might Ahmed be making? What could he be communicating to others verbally?</span>
    </label>
    <textarea
      value={empathyMapping.say ?? ""}
      onChange={(e) =>
        setempathyMapping((prev) => ({ ...prev, say: e.target.value }))
      }
      className="textarea textarea-sm textarea-bordered h-20"
      placeholder="Type here..."
    ></textarea>
     <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.say)} / 50 words</small>
  </div>
</div>

<div className="flex items-center gap-2">
        <input
            type="checkbox"
            checked={checkBox}
            className="checkbox checkbox-warning"
            onChange={() => {
              setCheckBox(!checkBox); // Toggle the checkbox state
            }}
        />
        <span className="text-black-400 ">Do you agree to share your response with a peer for the purpose of receiving constructive feedback. </span>
      </div>


      <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
      <input 
  type="checkbox" 
  className="checkbox checkbox-warning "
  checked={RecheckBox} 
  onChange={() => {
    setRecheckBox(!RecheckBox); // Toggle the checkbox state
  }}
/>
        <span className="text-black-400 ">Read your submission carefully before submitting</span>
      </div>


<button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} 
disabled={status === 'Completed' || !RecheckBox ||  !isEveryFieldValid || !checkBox || disableBtn }
>
  Submit
</button>
</div>   
            )}


    

</section>,








    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      {/* Step 1 content */}
      <Confetti numberOfPieces={100} />
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">

          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Congratulations on Completing Week 4: Empathy - The Heart of Leadership!</b>
            </h2>
            <p className="mt-4 text-gray-600">
            As we conclude our exploration into the essence of empathy, we recognize the transformative power it holds in leadership. This week, we've delved deep into understanding, feeling, and acting with empathy, using it as a tool to forge genuine connections and lead with authenticity.
            </p>

            <div className="mt-6 ">
              <div className="space-y-4">
              <Collapse accordionData={accordionData3}/>
              </div>

            </div>


            <p className="mt-4 text-gray-600">
            Thank you for your dedication, engagement, and commitment to growth. Let's continue our ascent towards leadership excellence! 
<br/><br/>
Feel free to explore the following resources to help you understand more about yourself.            </p>


            <ul class="flex flex-col" style={{listStyle:"disc"}}>
  <li class="flex flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
  <strong>How to Win Friends and Influence People</strong> by Dale Carnegie, Chapter 8 – A Formula That Will Work Wonders for You
  </li>
  <li class="flex  flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
  <strong>The Art of Empathy</strong> by Karla McLaren, Chapter 2 – Six Habits of Highly Empathic People
  </li>


  <li class="flex  flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
  <strong>Mindwise</strong> by Nicholas Epley, Chapter 5 – Understanding Other People’s Minds  </li>


  <li class="flex  flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
  <strong>The Power of Empathy</strong> by Helen Riess
  </li>
</ul>

    


          </div>
        </div>
      </div>
    </section>,

   
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Empathy and leadership";

      case 2:
        return "Understanding Empathy";

      case 3:
        return "Core Aspects of Empathy";

      case 4:
        return "Empathy Mapping";

      case 5:
        return "Recap";

      default:
        return "Peer";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              disabled={
                                status === "Completed" ? false : ((currentStep === 4 && !isEveryFieldValid) || (currentStep === 4 && !disableBtn))
                              }
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Week4;
