import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Form from './Form';

function EnrolLand() {


  const currentYear = new Date().getFullYear();
  const ref = useRef(null);
  const inputRef = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });

    // Use requestAnimationFrame for smoother animation
    window.requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  };
  let pathwayId = 1
  const [pathwayDetails, setPathwayDetails] = useState(null);
  const authTokens = JSON.parse(localStorage.getItem('authTokens'));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  useEffect(() => {
    // Fetch pathway details when the component mounts
    fetch(`https://backend.pathway.startupearly.com///api/pathway/${pathwayId}/`,config)
      .then((response) => response.json())
      .then((data) => {
        setPathwayDetails(data);
      })
      .catch((error) => {
        console.error('Error fetching pathway details:', error);
      });
  }, [pathwayId]);



  const currentDate = new Date();

  // Parse due date from pathwayDetails
  const dueDate = pathwayDetails?.due_date ? new Date(pathwayDetails.due_date) : null;

  // Check if due date has passed
  const isDueDatePassed = dueDate && currentDate > dueDate;


  return (

<div>







{/* 
<>

  <div className="h-screen relative overflow-hidden before:absolute before:top-0 before:start-1/2 before:bg-[url('https://preline.co/assets/svg/component/squared-bg-element.svg')] before:bg-no-repeat before:bg-top before:w-full before:h-full before:-z-[1] before:transform before:-translate-x-1/2 darki:before:bg-[url('https://preline.co/assets/svg/component/squared-bg-element-dark.svg')]">
    <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-10">

      <div className="flex justify-center">

        <img src="https://res.cloudinary.com/ahbranding/image/upload/v1702136471/AHbranding/Group_34784375_qgbtst.png" className="w-6/12 mb-8" />

      </div>

      <div className="mt-5 max-w-xl text-center mx-auto">
        <h1 className="block text-primary font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl darki:text-gray-200">
        Charting Success Together 🚀
        </h1>
      </div>

      <div className="mt-5 max-w-3xl text-center mx-auto">
        <p className="text-lg text-gray-600 darki:text-gray-400">
        Embark on a transformative journey with GuidedPathway, your tailored companion for academic achievement and future success. 
        </p>
      </div>

      <div className="mt-8 gap-3 flex justify-center">
        <button className='btn btn-primary btn-lg' onClick={handleClick}> Get Started
        
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
  <path fill-rule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clip-rule="evenodd" />
</svg>
 </button>
      </div>
   
    </div>
  </div>
 
</>


 */}




 <>
  {/* Hero */}
  <div className="relative overflow-hidden">
    {/* Gradients */}
    <div
      aria-hidden="true"
      className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
    >
      <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem] darki:from-violet-900/50 darki:to-purple-900" />
      <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem] darki:from-indigo-900/70 darki:via-indigo-900/70 darki:to-blue-900/70" />
    
    

    </div>
    {/* End Gradients */}



    <div className="relative z-10">

        <div className='block mx-auto w-10/12'>
        <div className="navbar ">
  <div className="flex-1">
    <div className="btn btn-ghost text-xl"><img className='w-16' src='https://res.cloudinary.com/ahbranding/image/upload/v1690535517/AHbranding/Start_Up_Early_Logo_vbp2fh.png'/></div>
  </div>
  <div className="flex-none">
  <Link to="/login" className="btn btn-ghost">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" data-slot="icon" class="w-5 h-5">
  <path d="M10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.465 14.493a1.23 1.23 0 0 0 .41 1.412A9.957 9.957 0 0 0 10 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 0 0-13.074.003Z" />
</svg>
  Login
</Link>
  </div>
</div>
        </div>

      <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
        <div className="max-w-2xl text-center mx-auto">
          <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent darki:from-blue-400 darki:to-violet-400">
          <img src="https://res.cloudinary.com/ahbranding/image/upload/v1702136471/AHbranding/Group_34784375_qgbtst.png" className="block mx-auto w-8/12 mb-8" />

          </p>
          {/* Title */}
          <div className="mt-5 max-w-2xl">
            <h1 className="block text-primary font-semibold text-gray-800 text-5xl md:text-5xl lg:text-6xl darki:text-gray-200">
            Charting Success Together
            </h1>
          </div>
          {/* End Title */}
          <div className="mt-5 max-w-3xl">
            <p className="text-lg text-gray-600 darki:text-gray-400">
            Embark on a transformative journey with GuidedPathway, your tailored companion for academic achievement and future success. 

            </p>
          </div>
          {/* Buttons */}
          <div className="mt-8 gap-3 flex justify-center">
            <a
              className="py-3 cursor-pointer px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent btn-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
              onClick={handleClick}
            >
              Get Started
              <svg
                className="flex-shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
            
          </div>
          {/* End Buttons */}
        </div>
      </div>
    </div>
  </div>
  {/* End Hero */}
</>




{isDueDatePassed ? (
  <div className='p-6 form w-8/12 block mx-auto' ref={ref}>
  <div className="flex py-20 flex-col h-max bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-700 darki:shadow-slate-700/[.7]">
  <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
   <h1 className='text-3xl'> We're Fully Booked 😥 </h1>
    <p className="mt-5 text-base text-center text-gray-800 darki:text-gray-300 ">
    We're currently fully booked for this cohort. Fear not – our team is hard at work planning the next exciting session.

Stay tuned for updates and announcements! We can't wait to have you on board for the next adventure. Your journey with us is just a chapter away.
    </p>


  </div>
</div>
</div>
      ) : (
        <div className='p-6 form' ref={ref}>
    <Form inputRef={inputRef}/>
</div>
      )}














      <footer className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
  {/* Grid */}
  <div className="text-center">
    <div>
      <a
        className="flex-none text-xl font-semibold text-black dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#"
        aria-label="Brand"
      >
        <img className='block w-16 mx-auto' src='https://res.cloudinary.com/ahbranding/image/upload/v1690535517/AHbranding/Start_Up_Early_Logo_vbp2fh.png' />
      </a>
    </div>
    {/* End Col */}
    <div className="mt-3">

      <p className="text-gray-500">
        © Start Up Early {currentYear}. All rights reserved.
      </p>
    </div>
    {/* Social Brands */}
  
    {/* End Social Brands */}
  </div>
  {/* End Grid */}
</footer>



</div>

  );
}

export default EnrolLand;
