import React from 'react';

const WaitingSub = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" className='w-8/12'> 
  <g id="freepik--background-complete--inject-48">
    <polygon
      points="275.39 388.75 166.27 351.31 27.63 378.28 141.46 418.5 275.39 388.75"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M275.39,388.75,166.24,351.41c.29,0-138.64,27.12-138.57,27.12l0-.48,113.8,40.32c-.35.1,134-29.67,133.88-29.62Zm0,0L141.45,418.63S27.57,378.51,27.54,378.52l-.86-.31.9-.17s138.69-26.84,138.7-26.85,109.07,37.56,109.11,37.56Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M145.8,408.06c-6.71-2.88-13.63-5.56-20.58-8.21s-14-5.2-21.07-7.73-14.25-5-21.51-7.32l-10.91-3.51C68,380.19,64.38,379,60.62,378c3.33,1.46,6.79,2.8,10.19,4.2l10.38,4c6.93,2.67,14,5.21,21.08,7.73s14.24,5,21.51,7.32S138.31,405.92,145.8,408.06Z"
      style={{ fill: "#c7c7c7" }}
    />
    <path
      d="M127.77,392.84A259.1,259.1,0,0,0,103.35,383c-8.48-3-17.1-5.79-26.29-8.06a257.82,257.82,0,0,0,24.41,9.85C110,387.74,118.58,390.56,127.77,392.84Z"
      style={{ fill: "#c7c7c7" }}
    />
    <path
      d="M178.67,402c-6.71-2.88-13.62-5.56-20.57-8.21s-14-5.2-21.08-7.73-14.24-5-21.51-7.32L104.6,375.2c-3.7-1.1-7.34-2.27-11.1-3.32,3.33,1.46,6.79,2.8,10.18,4.2l10.39,4c6.93,2.67,14,5.2,21.08,7.72s14.24,5,21.51,7.32S171.19,399.83,178.67,402Z"
      style={{ fill: "#c7c7c7" }}
    />
    <path
      d="M182.88,394.6c-11.42-5-23.41-9.45-35.54-13.78s-24.51-8.4-37.41-12c11.42,5,23.41,9.46,35.54,13.78S170,391,182.88,394.6Z"
      style={{ fill: "#c7c7c7" }}
    />
    <path
      d="M168.61,380.72a175.06,175.06,0,0,0-20.18-8.36,203.85,203.85,0,0,0-22.06-6.56,176.83,176.83,0,0,0,20.18,8.35A206.81,206.81,0,0,0,168.61,380.72Z"
      style={{ fill: "#c7c7c7" }}
    />
    <polygon
      points="476.39 405.93 324.98 365.92 178.87 425.52 329.52 466.95 476.39 405.93"
      style={{ fill: "#ebebeb" }}
    />
    <path
      d="M476.39,405.93S324.69,366,325,366L179,425.75l0-.47s150.89,41.59,150.54,41.56l146.91-60.91Zm0,0s-146.84,61.14-146.86,61.16-150.7-41.35-150.72-41.33l-.71-.2.68-.27S325,365.8,325,365.79s151.37,40.15,151.42,40.14Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M331.65,454.81c-9.86-3.19-19.86-6.16-29.87-9.1s-20.1-5.77-30.19-8.58-20.25-5.51-30.47-8.11l-15.33-3.89c-5.15-1.23-10.27-2.52-15.46-3.68,4.92,1.61,9.92,3.1,14.88,4.65l15,4.45c10,3,20.1,5.77,30.19,8.57s20.26,5.52,30.47,8.11S321.3,452.44,331.65,454.81Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M292.84,433.59c-10.79-3.88-21.95-7.16-33.19-10.29s-22.65-6-34.39-8.29c10.79,3.88,22,7.16,33.19,10.28S281.11,431.28,292.84,433.59Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M346.58,437.83c-8.62-2.85-17.37-5.48-26.14-8.08s-17.61-5.08-26.46-7.55-17.76-4.83-26.74-7.08l-13.46-3.38c-4.54-1.06-9-2.18-13.6-3.18,4.3,1.45,8.68,2.77,13,4.15l13.13,3.94c8.76,2.62,17.61,5.08,26.46,7.54s17.76,4.83,26.74,7.08S337.46,435.8,346.58,437.83Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M353.62,429.22c-8-2.67-16.05-5.12-24.17-7.53s-16.29-4.73-24.49-7-16.44-4.47-24.76-6.55L267.72,405c-4.21-1-8.37-2-12.61-2.9,4,1.36,8,2.59,12,3.88l12.15,3.66c8.1,2.44,16.29,4.73,24.49,7s16.45,4.46,24.77,6.54S345.17,427.37,353.62,429.22Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M391.35,429.05c-9.86-3.19-19.86-6.16-29.87-9.1s-20.1-5.77-30.19-8.58-20.25-5.51-30.47-8.11l-15.33-3.89c-5.15-1.23-10.27-2.52-15.46-3.69,4.92,1.62,9.92,3.11,14.87,4.66l15,4.45c10,3,20.1,5.77,30.19,8.57s20.25,5.51,30.47,8.11S381,426.68,391.35,429.05Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M392.6,418.85c-8.72-2.88-17.58-5.54-26.45-8.16s-17.82-5.14-26.77-7.64-18-4.88-27.05-7.17l-13.62-3.42c-4.59-1.07-9.13-2.21-13.75-3.22,4.35,1.46,8.77,2.79,13.16,4.19l13.29,4c8.86,2.65,17.81,5.14,26.77,7.63s18,4.89,27.05,7.17S383.38,416.8,392.6,418.85Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M421.2,416.17c-9.87-3.19-19.86-6.16-29.87-9.1s-20.1-5.77-30.19-8.58-20.25-5.51-30.47-8.11l-15.33-3.89c-5.16-1.23-10.27-2.52-15.46-3.69,4.92,1.62,9.92,3.11,14.87,4.66l15,4.45c10,3,20.1,5.77,30.19,8.57s20.25,5.51,30.47,8.11S410.84,413.8,421.2,416.17Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M386.51,396.09c-11.48-4.07-23.32-7.54-35.26-10.86s-24-6.37-36.44-8.87c11.47,4.08,23.32,7.55,35.25,10.86S374.09,393.58,386.51,396.09Z"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="45.73"
      y="257.7"
      width="292.06"
      height="38.25"
      style={{ fill: "#c7c7c7" }}
    />
    <rect
      x="76.42"
      y="257.7"
      width="27.26"
      height="38.25"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="138.62"
      y="257.7"
      width="10.12"
      height="38.25"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="277.98"
      y="257.7"
      width="27.26"
      height="38.25"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="225.98"
      y="166.96"
      width="294.81"
      height="38.25"
      transform="translate(380.05 -231.05) rotate(64.42)"
      style={{ fill: "#c7c7c7" }}
    />
    <rect
      x="315.31"
      y="74.36"
      width="27.52"
      height="38.25"
      transform="translate(271.33 -243.7) rotate(64.42)"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="347.33"
      y="123.18"
      width="10.21"
      height="38.25"
      transform="translate(328.65 -237.03) rotate(64.42)"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="403.14"
      y="257.88"
      width="27.52"
      height="38.25"
      transform="translate(486.78 -218.63) rotate(64.42)"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="65.08"
      y="232.97"
      width="249.31"
      height="24.73"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="88.52"
      y="238.02"
      width="95.89"
      height="14.84"
      style={{ fill: "#ebebeb" }}
    />
    <rect
      x="89.97"
      y="138.34"
      width="223.85"
      height="51.82"
      style={{ fill: "#c7c7c7" }}
    />
    <rect
      x="269.23"
      y="138.34"
      width="20.86"
      height="51.82"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="247.44"
      y="138.34"
      width="8.09"
      height="51.82"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="55.79"
      y="295.94"
      width="289.01"
      height="31.36"
      style={{ fill: "#a6a6a6" }}
    />
    <rect
      x="287.22"
      y="295.94"
      width="26.94"
      height="31.36"
      style={{ fill: "#c7c7c7" }}
    />
    <rect
      x="115.36"
      y="295.94"
      width="26.94"
      height="31.36"
      style={{ fill: "#c7c7c7" }}
    />
    <rect
      x="160.75"
      y="295.94"
      width="10.44"
      height="31.36"
      style={{ fill: "#c7c7c7" }}
    />
    <rect
      x="46.12"
      y="190.16"
      width="160.01"
      height="42.81"
      style={{ fill: "#a6a6a6" }}
    />
    <rect
      x="206.13"
      y="190.16"
      width="147.91"
      height="42.81"
      style={{ fill: "#a6a6a6" }}
    />
    <rect
      x="46.12"
      y="194.6"
      width="160.01"
      height="33.92"
      style={{ fill: "#fff" }}
    />
    <path
      d="M206.13,228.53c-.22-.22-160.47.64-160.23,0l0-33.93v-.25h.25s160.06.17,160.09.16-.1,33.93-.08,34Zm0,0L206,194.6c.49.25-160,.17-159.92.25l.25-.25,0,33.93c-1.44-.59,160.13.18,159.79,0Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M60,201.58q25.14-.35,50.3-.49l22.74-.15,50.3.15c16.76.08,6,.26,22.75.49-16.77.24-6,.41-22.75.5l-50.3.15-22.74-.15Q85.18,201.95,60,201.58Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M60,208.24q25.14-.36,50.3-.5l22.74-.15,50.3.15c16.76.09,6,.26,22.75.5-16.77.24-6,.41-22.75.5l-50.3.14-22.74-.15Q85.18,208.61,60,208.24Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M60,214.89q25.14-.34,50.3-.49l22.74-.15,50.3.15c16.76.08,6,.26,22.75.49-16.77.24-6,.41-22.75.5l-50.3.15-22.74-.15Q85.18,215.26,60,214.89Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M60,221.55q25.14-.36,50.3-.49l22.74-.16,50.3.15c16.76.09,6,.26,22.75.5-16.77.24-6,.41-22.75.5l-50.3.14L110.34,222Q85.18,221.92,60,221.55Z"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="238.9"
      y="190.16"
      width="20.82"
      height="42.81"
      style={{ fill: "#ebebeb" }}
    />
    <rect
      x="332.39"
      y="190.16"
      width={14}
      height="42.81"
      style={{ fill: "#ebebeb" }}
    />
    <rect
      x="157.8"
      y="92.05"
      width="141.9"
      height="46.33"
      transform="translate(457.5 230.43) rotate(180)"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="50.06"
      y="92.05"
      width="107.74"
      height="46.33"
      transform="translate(207.86 230.43) rotate(180)"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="157.8"
      y="96.86"
      width="141.9"
      height="36.71"
      transform="translate(457.5 230.43) rotate(180)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M157.8,96.86l142.11-.21c0,.15,0,36.74,0,36.92v.25h-.25s-142-.16-142-.15.13-36.71.1-36.81Zm0,0,.1,36.71c-.61-.28,141.93-.15,141.8-.25l-.25.25c.1.16-.28-37.71.25-36.49l-141.9-.22Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M290.52,126q-16.59.28-33.18.39l-33.18.12L191,126.41q-16.59-.1-33.18-.39,16.59-.28,33.18-.39l33.18-.12,33.18.12Q273.93,125.74,290.52,126Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M290.52,118.82q-16.59.27-33.18.39l-33.18.12L191,119.21q-16.59-.1-33.18-.39,16.59-.28,33.18-.39l33.18-.12,33.18.12Q273.93,118.54,290.52,118.82Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M290.52,111.62q-16.59.27-33.18.38l-33.18.12L191,112q-16.59-.11-33.18-.39,16.59-.28,33.18-.4l33.18-.11,33.18.12Q273.93,111.32,290.52,111.62Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M290.52,104.41q-16.59.28-33.18.39l-33.18.12L191,104.8q-16.59-.09-33.18-.39,16.59-.27,33.18-.39l33.18-.11,33.18.11Q273.93,104.13,290.52,104.41Z"
      style={{ fill: "#dbdbdb" }}
    />
    <rect
      x="67.22"
      y="92.05"
      width="20.17"
      height="46.33"
      style={{ fill: "#c7c7c7" }}
    />
  </g>
  <g id="freepik--Table--inject-48">
    <path
      d="M45.31,327.3c131.14-.61,278.24-.62,409.38,0-131.14.62-278.24.61-409.38,0Z"
      style={{ fill: "#263238" }}
    />
  </g>
  <g id="freepik--Sheet--inject-48">
    <polygon
      points="477.48 385.04 251.13 342.99 32.73 405.63 257.94 449.18 477.48 385.04"
      style={{ fill: "#fff" }}
    />
    <path
      d="M477.48,385S250.92,343.1,251.17,343.11L32.8,405.87l0-.48s225.37,43.69,225.12,43.67l219.58-64Zm0,0L257.94,449.31S32.7,405.87,32.69,405.87l-1-.19,1-.29,218.47-62.53S477.44,385.05,477.48,385Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M261.12,436.42c-14.75-3.35-29.69-6.48-44.66-9.57s-30-6.06-45.13-9-30.27-5.79-45.54-8.53l-22.92-4.09c-7.7-1.28-15.35-2.64-23.1-3.87,7.35,1.7,14.82,3.26,22.23,4.9l22.41,4.67c15,3.12,30,6.07,45.14,9s30.27,5.79,45.54,8.53S245.63,433.93,261.12,436.42Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M203.1,414.12C187,410,170.29,406.6,153.48,403.31s-33.86-6.3-51.41-8.73c16.14,4.09,32.81,7.53,49.62,10.82S185.56,411.69,203.1,414.12Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M283.43,418.57c-12.89-3-26-5.76-39.08-8.49s-26.32-5.34-39.55-7.93-26.55-5.08-40-7.45l-20.13-3.55c-6.77-1.11-13.49-2.29-20.32-3.34,6.43,1.52,13,2.91,19.45,4.36l19.63,4.14c13.09,2.75,26.32,5.34,39.55,7.93s26.55,5.07,40,7.44S269.8,416.44,283.43,418.57Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M294,409.52c-11.9-2.8-24-5.38-36.13-7.92s-24.36-5-36.61-7.36-24.59-4.7-37-6.88l-18.66-3.27c-6.28-1-12.51-2.1-18.85-3,5.94,1.42,12,2.71,18,4.07L182.82,389c12.11,2.56,24.36,5,36.61,7.36s24.59,4.69,37,6.88S281.32,407.58,294,409.52Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M350.35,409.35c-14.74-3.36-29.68-6.48-44.65-9.57s-30-6.06-45.13-9S230.3,385,215,382.24l-22.92-4.09c-7.7-1.29-15.35-2.65-23.11-3.87,7.36,1.7,14.83,3.26,22.23,4.89l22.42,4.68c14.95,3.11,30,6.06,45.14,9s30.27,5.79,45.54,8.52S334.87,406.86,350.35,409.35Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M352.22,398.63c-13-3-26.27-5.82-39.54-8.58s-26.63-5.41-40-8-26.86-5.13-40.43-7.54l-20.36-3.6c-6.86-1.12-13.65-2.31-20.56-3.37,6.51,1.53,13.12,2.93,19.68,4.4l19.86,4.18c13.25,2.79,26.64,5.41,40,8s26.86,5.14,40.43,7.54S338.45,396.46,352.22,398.63Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M395,395.81c-14.74-3.35-29.68-6.48-44.65-9.57s-30-6.06-45.13-9-30.27-5.79-45.54-8.53l-22.92-4.09c-7.7-1.29-15.35-2.65-23.11-3.87,7.36,1.7,14.83,3.26,22.23,4.9l22.42,4.67c15,3.11,30,6.06,45.14,9s30.27,5.79,45.54,8.52S379.49,393.32,395,395.81Z"
      style={{ fill: "#dbdbdb" }}
    />
    <path
      d="M343.12,374.7c-17.16-4.28-34.87-7.92-52.7-11.41s-35.91-6.69-54.49-9.32c17.16,4.28,34.86,7.93,52.7,11.41S324.55,372.07,343.12,374.7Z"
      style={{ fill: "#dbdbdb" }}
    />
  </g>
  <g id="freepik--character-hand--inject-48">
    <path
      d="M220,373.69c17.23,0,52.78,2,82-1.61,30.81-3.81,69.49-22.64,83.79-26.74,19.32-5.54,1.43-150.4-18.39-156.09-61.68-17.71-146.12-5.08-174.35,17.47S183.35,373.65,220,373.69Z"
      style={{ fill: "rgb(235, 148, 129)" }}
    />
    <path
      d="M163.28,318c-10.17,9-14.12,30.21-11.53,35.9,4.44,9.77,21.66,20.82,38.63,24.58,9.55,2.12,62.09-13.17,71.73-18.44,26.49-14.49,15.64-33.48,3.68-35-9.27-1.17-23.11,1.32-44.23,5-14.52,2.54-27.83.07-31-.92-7.7-2.43-1.82,9.47-1.82,9.47l19.62-14.25-3.57-20.33S175,307.63,163.28,318Z"
      style={{ fill: "rgb(235, 148, 129)" }}
    />
    <path
      d="M220,373.69c13.14-4,26.37-8,38.82-13.87,11.43-5.35,23.8-16.55,13.69-29.16-7.29-9.93-46.4,2.2-58.84,1.29A150.39,150.39,0,0,1,193,329.79c10.32.34,20.6,1.06,30.75-1.21,11.91-2.1,42.86-11.11,51.5-.94,5.27,6.24,6.77,15,1.94,22-8.41,12-23.64,15.7-36.88,19.6-6.72,1.74-13.47,3.29-20.3,4.43Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M156.82,280.61c-11.93,7.89-22.27,24.08-20.28,35.21s22.79,24.62,39.61,31.86c5,2.16,69-2.29,79.86-6.36,29.77-11.2,22.72-31.75,12.85-39.73-5.85-4.73-30.48-.52-53.05.22-15.52.51-29-4-32.15-5.53-7.67-3.67-3.29,9.6-3.29,9.6l22.56-11.95-.72-21.73S170.6,271.5,156.82,280.61Z"
      style={{ fill: "rgb(235, 148, 129)" }}
    />
    <path
      d="M153.36,335.07c7.62,4.26,15.28,9.12,23.58,11.75,7.65.91,15.46-.07,23.14-.46,16.54-1.35,33.29-2.79,49.61-5.56a39.64,39.64,0,0,0,21-10.52,11.88,11.88,0,0,0,2.73-4.72,26.45,26.45,0,0,0,1.48-5.67c1.26-7.56-2.58-16-10-18.7-3.81-1.13-8.06-1.25-12.08-1.32a249.23,249.23,0,0,0-25,1.29,179.26,179.26,0,0,1,25-3.26c4.84-.11,9.84-.41,14.51,1.35,7.92,3.51,12.27,12.69,11,21.08a32.83,32.83,0,0,1-2.77,9.55c-1.72,3.22-4.48,5.25-7.24,7.3a45.37,45.37,0,0,1-11.62,6c-6.17,2-12.55,2.51-18.88,3.36q-18.75,2.22-37.63,3c-7.91.14-16,.76-23.8-.66-8.48-3.21-15.75-8.6-23.09-13.74Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M149.05,227.29c-12.93,6.13-21.86,35.32-21.47,46.61s29.68,35.22,56.16,37.45c11.32.95,49.51.68,60.6-2.62,31.28-9.31,30.06-31,21.41-40.32-5.12-5.5-32.56-3.49-55-6-15.43-1.69-28.16-8.1-31-10-7.08-4.71-4.62,9-4.62,9l24-8.65,2.36-21.61S164,220.22,149.05,227.29Z"
      style={{ fill: "rgb(235, 148, 129)" }}
    />
    <path
      d="M143.8,294.49c11.68,7,24.3,13.4,37.95,15,21.73,2.18,66.07,1.89,82.17-14,4.25-4.5,7.26-10.84,5.76-17.05-1.65-7.33-9-12.65-16.38-13.28-8.15-.57-16.52-.49-24.74-1-16.47-1-33.85-2.42-48.53-10.7a146.33,146.33,0,0,1-14.35-8.33c4.81,2.72,9.81,5.07,14.82,7.38A66.23,66.23,0,0,0,196,257.83c16.16,3.46,32.78,3.19,49.25,3.43,5.66.09,11.8-.12,17,2.64,8.26,3.82,13.68,13.69,11.17,22.78-3.81,15-20,21.15-33.68,23.91-16.45,3.26-33.33,3.6-50,2.17-5.55-.56-11.18-1.13-16.53-2.83a107.14,107.14,0,0,1-29.41-15.44Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M357,179.88c6.95,2.54,143-17.1,143-17.1v199.7l-144.49-5.71Z"
      style={{ fill: "rgb(235, 148, 129)" }}
    />
    <path
      d="M252.59,107.52S263.05,74.3,239.82,69s-28.19,29.18-28.19,29.18Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M200.22,327.27v.06h0l-22.11,25.94L167.91,351h0l-8.69-33v0h0L211.92,96.82l41,9.35Z"
      style={{ fill: "#4F0696" }}
    />
    <rect
      x="207.09"
      y="100.1"
      width="49.06"
      height="9.74"
      transform="translate(434.12 258.8) rotate(-167.16)"
      style={{ fill: "#4F0696" }}
    />
    <rect
      x="207.09"
      y="100.1"
      width="49.06"
      height="9.74"
      transform="translate(434.12 258.8) rotate(-167.16)"
      style={{ opacity: "0.2" }}
    />
    <rect
      x="204.7"
      y="110.59"
      width="49.06"
      height="9.74"
      transform="translate(427.06 278.99) rotate(-167.16)"
      style={{ fill: "#4F0696" }}
    />
    <rect
      x="204.7"
      y="110.59"
      width="49.06"
      height="9.74"
      transform="translate(427.06 278.99) rotate(-167.16)"
      style={{ opacity: "0.2" }}
    />
    <polygon
      points="252.07 125.66 236.63 122.14 230.4 189.23 236.16 190.2 252.07 125.66"
      style={{ fill: "#4F0696" }}
    />
    <polygon
      points="252.07 125.66 236.63 122.14 230.4 189.23 236.16 190.2 252.07 125.66"
      style={{ opacity: "0.2" }}
    />
    <path
      d="M169.3,351.24l1.06,4.06a1.78,1.78,0,0,0,3.08.72l2.76-3.21Z"
      style={{ fill: "#4F0696" }}
    />
    <path
      d="M169.3,351.24l1.06,4.06a1.78,1.78,0,0,0,3.08.72l2.76-3.21Z"
      style={{ opacity: "0.5" }}
    />
    <path
      d="M161.28,318.36a172.24,172.24,0,0,1,36.6,8.52,171.5,171.5,0,0,1-36.6-8.52Z"
      style={{ opacity: "0.2" }}
    />
    <path
      d="M194.93,168.92s-16.15.59-27,3.68c-10.63,3-36.19,11.72-40.6,19.51s-10.3,31.28-4.57,38.25c4.67,5.69,42,31.72,60.83,31.62,19.22-.11,25.45-12.65,20.89-28.38C201.05,221.77,173,209.87,173,209.87l12.84,10.22L184.08,215Z"
      style={{ fill: "rgb(235, 148, 129)" }}
    />
    <path
      d="M163.64,203.76c15,6.45,36.61,16.64,42.76,32.84,2.41,6,2,13.31-1.89,18.64-6.68,9.44-15.81,9.47-26,6.77-8.88-2.36-17.25-6.32-25.26-10.68a164.41,164.41,0,0,1-15.45-9.59c5.32,2.88,10.7,5.64,16.16,8.19,10.46,4.68,31.15,14.4,41.93,9.17,4.76-2.78,8.62-7.62,9.06-13.26,1.32-19.87-26.33-33.83-41.33-42.08Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M171.22,203.76c5.67,1.83,11.34,5.84,13.59,11.51-4.38-3.86-9.4-7.36-13.59-11.51Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M369.59,190.44s-25.8-23.77-53.92-36.69c-38.15-17.52-58.37-25.53-74.46-7.2-27.05,30.83-63.35,40.95-59.12,53.1,3.45,9.91,12.35,19,26.87,20.28,20.85,1.82,41-14.32,54.36-26.62,0,0,42.41,46.75,73.36,27.87C356.1,209.34,369.59,190.44,369.59,190.44Z"
      style={{ fill: "rgb(235, 148, 129)" }}
    />
    <path
      d="M200.26,179.88c-5.66,4.3-12,7.93-16,13.73A6.85,6.85,0,0,0,183,197a12.48,12.48,0,0,0,1,3.6,29,29,0,0,0,14.05,14.07,39.84,39.84,0,0,0,3.76,1.51c1.29.4,2.62.67,3.93,1l4,.51,4.1,0a55.18,55.18,0,0,0,6.16-.64l4.09-.79a44.36,44.36,0,0,0,5.82-2c15.6-6.9,29-17.91,42.05-29C259.94,199,244.66,213,227.29,219.42c-10,3-21.29,3.56-30.87-1.37a31,31,0,0,1-15.07-16.42c-2.27-5.41.07-9.11,4.13-12.6,4.54-3.82,9.7-6.45,14.78-9.15Z"
      style={{ fill: "#263238" }}
    />
    <path
      d="M263.32,193.31c1.36,17.32,5.8,35.44,17.85,48.4a135.6,135.6,0,0,0,15,12.66,242.57,242.57,0,0,0,28,17.82,98.51,98.51,0,0,1-24.6-11,105.79,105.79,0,0,1-21.24-16.92c-12.17-13.75-16.2-33.08-15.08-51Z"
      style={{ fill: "#263238" }}
    />
  </g>
</svg>


  );
};

export default WaitingSub;
