import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";
import SliderWeek10 from "./SliderWeek10";
import SliderWeek10_2 from "./SliderWeek10_2";
import QuizComponent from "./QuizComponent";
import SliderWeek11 from "./SliderWeek11";
import SliderWeek11_2 from "./SliderWeek11_2";
import SliderWeek13 from "./SliderWeek13";

const Week15 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 17;
  const week_name = "week_15";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });

  const [swotSubmission2, setSubmission2] = useState({
    response: "",
  });

  const [swotSubmission3, setSubmission3] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      })
      .then((response) => {
        console.log(response);
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('Good Job! Activity Submitted', {
            icon: '👏',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_15",
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        });
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: false,
          activity_name: "Reflective Letter to Self - Revisited",
        });
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };





  const handleStepSubmission2 = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        });
        toast.success('Good Job! Feedback Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission2,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: false,
          activity_name: "Personal Statement",
        });
        toast.success('Good Job! Activity Submitted', {
          icon: '👏',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };


  const totalSteps = 3; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };





  const [currentQuestion2, setCurrentQuestion2] = useState(0);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [showExplanation2, setShowExplanation2] = useState(false);

  const handleOptionSelect2 = (option2) => {
    setSelectedOption2(option2);
  };

  const handleNextQuestion2 = () => {
    setShowExplanation2(true);
  };

  const handleNextQuestionOrFinish2 = () => {
    if (currentQuestion2 + 1 < questionsData2.length) {
      setCurrentQuestion2(currentQuestion2 + 1);
      setSelectedOption2("");
      setShowExplanation2(false);
    }
  };



  const questionsData = [
    {
      question: "Groups always make more cautious risk decisions compared to individuals.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Effective communication within a group doesn't influence the quality of risk-taking decisions.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "In a group setting, the risk preferences of the majority always determine the final decision.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question: "Diverse perspectives within a team can lead to more balanced and effective risk assessment.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
    {
      question: "Groupthink always leads to optimal risk-taking decisions in team environments.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
  ];

  

  
  const questionsData2 = [
    {
      question: "Effective coping strategies in leadership are only about managing personal stress.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Effective coping strategies in leadership encompass managing personal stress and fostering resilience within the team.",
    },
    {
      question: "Emotional resilience in leadership does not impact decision-making and team dynamics.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Emotional resilience is crucial for effective leadership as it positively impacts decision-making and team dynamics.",
    },
    {
      question: "Regular stress-management practices are unrelated to overall leadership effectiveness.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Regular stress-management practices are essential for maintaining clarity, focus, and overall leadership effectiveness.",
    },
    {
      question: "Creating a supportive environment for resilience is solely the responsibility of individual team members.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Leaders play a critical role in cultivating a team culture that supports resilience and stress management.",
    },
    {
      question: "Learning from successful leaders who manage stress effectively is not beneficial for developing resilience.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Observing and learning from successful leaders who effectively manage stress can provide valuable insights for developing resilience.",
    },
  ];
  
  
  const accordionData = [
    {
      title: 'Sexual Harassment',
      content: (
        <div>
          <p>Description: This involves unwelcome sexual advances, requests for sexual favours, and other verbal or physical conduct of a sexual nature. It can range from inappropriate jokes to outright assault.</p>
          <p>Impact: Sexual harassment can create a hostile environment, leading to psychological trauma, anxiety, and a decrease in work or academic performance.</p>
        </div>
      ),
    },
    {
      title: 'Workplace Bullying',
      content: (
        <div>
          <p>Description: This form of harassment occurs when an individual repeatedly and deliberately aims to hurt or humiliate someone at work. This can include verbal abuse, spreading rumours, or intentional exclusion.</p>
          <p>Impact: It leads to a toxic work environment, diminished self-esteem of the victim, and decreased productivity.</p>
        </div>
      ),
    },
    {
      title: 'Cyberbullying',
      content: (
        <div>
          <p>Description: Involving the use of digital platforms to intimidate, harass, or demean individuals, cyberbullying includes sending threatening emails, posting harmful content, or online shaming.</p>
          <p>Impact: The anonymity of the internet can intensify the effects of cyberbullying, leading to emotional distress, social isolation, and in severe cases, self-harm.</p>
        </div>
      ),
    },
    {
      title: 'Racial Harassment',
      content: (
        <div>
          <p>Description: This involves harassment based on race, ethnicity, or nationality. It includes racial slurs, derogatory remarks, or discriminatory actions.</p>
          <p>Impact: Racial harassment can create an atmosphere of fear and hostility, impacting the victim’s sense of belonging and identity.</p>
        </div>
      ),
    },
    {
      title: 'Discriminatory Harassment',
      content: (
        <div>
          <p>Description: This refers to unfair treatment or derogatory behaviour based on gender, religion, age, disability, or sexual orientation.</p>
          <p>Impact: It can lead to exclusion, reduced opportunities, and a sense of injustice, significantly impacting mental health and well-being.</p>
        </div>
      ),
    },
    // Add more accordion data as needed
  ];
  

  const accordionData2 = [
    {
      title: 'Emotional and Psychological Impact on Individuals',
      content: (
        <div>
          
          <p>Emotional Trauma: Victims of harassment often experience intense emotional reactions, including fear, anger, humiliation, and a sense of helplessness. These reactions can be immediate and intense, affecting an individual’s mental health and well-being.</p>
          <p>Psychological Effects: Long-term psychological effects can include anxiety, depression, and post-traumatic stress disorder (PTSD). The trauma of harassment can lead to lasting psychological scars, affecting an individual's ability to trust others and feel safe in social or professional settings.</p>
        </div>
      ),
    },
    {
      title: 'Professional Consequences for Individuals',
      content: (
        <div>
          
          <p>Impact on Performance and Productivity: Harassment can severely impact a victim's work performance. Stress and anxiety can lead to decreased concentration, absenteeism, and a decline in overall productivity.</p>
          <p>Career Progression: Victims of harassment may avoid certain situations or people, potentially limiting their professional opportunities and career advancement. In severe cases, individuals might even leave their job or societal positions to escape the harassment.</p>
        </div>
      ),
    },
    {
      title: 'Effects on Teams and Group Dynamics',
      content: (
        <div>
          
          <p>Erosion of Trust and Morale: Harassment can damage the trust and morale within a team. It creates an environment of fear and discomfort, hindering open communication and collaboration.</p>
          <p>Reduced Team Cohesion: Teams affected by harassment may experience divisions and reduced cohesion. This can lead to a breakdown in teamwork and cooperation, negatively impacting group projects and goals.</p>
        </div>
      ),
    },
    // Add more accordion data as needed
  ];
  
  
  const accordionData3 = [
    {
      title: 'Recognize and Document the Incident: Identifying and Recording Harassment',
      content: (
        <div>
          <p>If you experience or witness harassment, recognize it and document the details. This can include the date, time, location, nature of the harassment, and names of any witnesses.</p>
        </div>
      ),
    },
    {
      title: 'Contact the Concerned Department: Reaching Out for Help',
      content: (
        <div>
          <p>LUMS has designated departments and individuals to handle harassment cases:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Email the Office of Student Affairs: <a href="mailto:studentaffairs@lums.edu.pk">studentaffairs@lums.edu.pk</a> for guidance on next steps.</li>
            <li>Contact the Harassment Committee: Specific email addresses and contact details of committee members can be found on the LUMS website or through the student portal.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Formal Reporting Procedures: Official Reporting Mechanism',
      content: (
        <div>
          <p>To formally report harassment:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Fill out the harassment complaint form available online on the LUMS portal.</li>
            <li>Submit the form to the Harassment Committee via their official email <a href="mailto:harassment@lums.edu.pk">harassment@lums.edu.pk</a>.</li>
            <li>You can also request a meeting with a committee member or member of the student council for a more personal approach.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Seeking Immediate Support: Accessing Immediate Assistance',
      content: (
        <div>
          <p>If you need immediate assistance or counselling:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Reach out to the LUMS Counselling and Psychological Services at <a href="mailto:counseling@lums.edu.pk">counseling@lums.edu.pk</a>.</li>
            <li>Contact your department head or a trusted faculty member for immediate advice and support.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Bystander Intervention and Informal Support: The Role of Bystanders',
      content: (
        <div>
          <p>As a bystander, you can support victims by:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Offering immediate assistance or escorting them to a safe place.</li>
            <li>Encouraging them to document the incident and seek help.</li>
            <li>Accompanying them to report the incident if they feel comfortable.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Follow-up and Continual Support: Post-Reporting Follow-up',
      content: (
        <div>
          <p>After reporting, stay in touch with the concerned department for updates. Continue to access support services as needed. LUMS ensures confidentiality and continued support for all individuals involved in harassment cases.</p>
        </div>
      ),
    },
  ];
  
  

  const accordionData82 = [
    {
      title: 'Cultivate a Shared Vision',
      content: '- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n' +
        'Reflect on your recent team interactions:\n' +
        '- How have I communicated and fostered a shared vision within the team?\n' +
        '- In what ways have I ensured that everyone understands and is committed to our collective goals?\n' +
        '- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?',
    },
    {
      title: 'Analyse and Set SMART Goals',
      content: '- Effective goal setting is a collaborative and strategic process. Evaluate your team\'s approach to setting goals:\n' +
        '- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n' +
        '- Where can we improve in making our goals more aligned with our strategic vision?\n' +
        '- How can I lead the team to break down our larger vision into achievable, strategically informed goals?',
    },
    {
      title: 'Encourage Participation in Vision and Goal Setting',
      content: '- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n' +
        '- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n' +
        '- How can I better ensure that all voices are heard and considered in shaping our team\'s direction?\n' +
        '- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team\'s cohesion?',
    },
  ];
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];

  const accordionData7 = [
    {
      title: 'Consolidating Your Learnings',
      content: (
        <div>
          <p>
            This week, you've engaged in deep reflection, revisiting the rich tapestry of skills, insights, and experiences you've gathered over the past 15 weeks. From emotional intelligence to strategic thinking, each week has contributed significantly to your leadership toolkit.
          </p>
        </div>
      ),
    },
    {
      title: 'Crafting Your Future Vision',
      content: (
        <div>
          <p>
            You've articulated your aspirations and values in your 'note to your future self' and 'personal vision statement', setting a clear direction for your future. This note and vision will be your guiding light as you navigate the complexities and opportunities of the world within and beyond LUMS.
          </p>
        </div>
      ),
    },
    {
      title: 'Looking Ahead with Confidence',
      content: (
        <div>
          <p>
            As you step forward from the Ascent Leadership Program, remember that leadership is a continuous journey of growth and adaptation. Carry forward the lessons learned, the connections made, and the confidence gained. You are now better equipped to lead with integrity, creativity, and empathy.
          </p>
        </div>
      ),
    },
    {
      title: 'Final Reflections',
      content: (
        <div>
          <p>
            Reflect on how far you've come and the leader you are becoming. Be proud of your accomplishments and excited for the journey ahead.
          </p>
          <p>
            Thank you for your dedication and enthusiasm throughout the Ascent Leadership Program. We are excited to see the impact you will make as an empowered and insightful leader. Remember, this is not the end, but a new beginning in your lifelong leadership journey.
          </p>
        </div>
      ),
    },
  ];
  
  
  const cardData = [
    {
      id: 1,
      frontContent: 'Understanding and Upholding Policies: Personal Responsibility in Policy Adherence',
      backContent: (
        <div>
          <p>Content: Familiarize yourself with your society's anti-harassment policies. Understand what constitutes harassment and commit to upholding these standards in your interactions. Personal adherence to policies sets a standard for behaviour and helps create a safe environment for everyone.</p>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Promoting Inclusivity and Respect: Being an Advocate for Diversity and Respect',
      backContent: (
        <div>
          <p>Content: Actively promote an inclusive atmosphere by respecting diverse viewpoints and backgrounds. Challenge discriminatory jokes or comments when you encounter them. Your actions can influence the overall culture of the society, encouraging others to follow suit.</p>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Effective Communication: Open and Respectful Communication',
      backContent: (
        <div>
          <p>Content: Practice open, honest, and respectful communication. Address conflicts constructively and seek to understand others' perspectives. Clear communication can prevent misunderstandings that may lead to perceived harassment.</p>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Bystander Intervention: Taking Action as a Bystander',
      backContent: (
        <div>
          <p>Content: If you witness harassment, consider safe ways to intervene or support the victim. This could be through direct intervention, distraction, or seeking help from society leaders or authorities. Bystander intervention can stop harassment and show that such behaviour is unacceptable.</p>
        </div>
      ),
    },
    {
      id: 5,
      frontContent: 'Personal Reflection and Growth: Reflecting on Personal Biases and Behaviours',
      backContent: (
        <div>
          <p>Content: Regularly reflect on your own biases and behaviours. Consider how your actions and words might be perceived by others. Being self-aware and willing to grow personally is key to preventing harassment.</p>
        </div>
      ),
    },
    {
      id: 6,
      frontContent: 'Seeking and Offering Support: Building a Supportive Network',
      backContent: (
        <div>
          <p>Content: Be approachable and supportive to peers who might be experiencing harassment. Offer help, whether it’s listening, providing advice, or assisting in reporting the incident. Having a supportive network can make it easier for victims to come forward.</p>
        </div>
      ),
    },
    {
      id: 7,
      frontContent: 'Leading by Example: Role Modelling Positive Behaviour',
      backContent: (
        <div>
          <p>Content: Lead by example in all your interactions within the society. Demonstrate respect, kindness, and inclusivity. Your behaviour can set a positive example for others and contribute to a respectful society culture.</p>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  

  const cardData2 = [
    {
      id: 1,
      frontContent: 'Setting Specific Adaptability Goals',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Defining clear, achievable goals is the first step in your action plan.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What specific aspects of adaptability do I want to develop or enhance?</li>
            <li>How can these adaptability goals contribute to my effectiveness as a student leader or society member?</li>
            <li>What are the measurable outcomes I aim to achieve through these goals?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Identifying Strategies and Resources',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Determining the right strategies and resources is key to achieving your goals.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What strategies will I employ to improve my adaptability in various situations?</li>
            <li>Which resources (like workshops, books, mentors) can assist in developing these skills?</li>
            <li>How will I integrate these strategies and resources into my regular activities or society responsibilities?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Creating a Timeline and Milestones',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: A structured timeline with milestones helps track progress and maintain focus.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What is a realistic timeline for achieving my adaptability goals?</li>
            <li>What milestones can I set to monitor my progress over time?</li>
            <li>How will I adjust my plan if I encounter obstacles or new opportunities for growth?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Reviewing and Adjusting the Plan',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Regular review and adjustment of your action plan ensure its effectiveness and relevance.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How will I periodically review the progress of my action plan?</li>
            <li>What criteria will I use to evaluate its success or areas needing adjustment?</li>
            <li>How open am I to modifying my plan based on feedback and new experiences?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  

  const [Userletter, setUserletter] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get( `${process.env.REACT_APP_API_KEY}/api/get_letter/${profileID}/`);
        setUserletter(response.data);
        // alert("Letter"+response.data)

      } catch (error) {
        // console.error('Error fetching data:', error);
        // alert("Letter"+error)
      }
    };

    fetchData();
  }, [profileID]);

  

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Week 15: Reflection and Future Planning</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to the final week of the Ascent Leadership Program at LUMS. This week marks a significant milestone in your leadership journey. It's a time for reflection, consolidation, and forward-thinking. Over the past 14 weeks, you've embarked on a comprehensive exploration of leadership competencies, delving into areas like emotional intelligence, strategic thinking, effective communication, and more. Each week has presented new challenges and learnings, contributing to your personal and professional growth.

      
      <br/> <br/>Now, in Week 15, "Reflection and Future Planning," we turn our focus inward and forward. This week is dedicated to introspection and envisioning your future path as a leader. You will revisit the pivotal moments and key learnings from the program, assess your growth, and set actionable goals for the future. This is not just a conclusion of your current journey but a gateway to your continual development as a leader.
   </p>
      <div className="mt-6 flex justify-center w-full h-full">
      {/* <Quote content={"Only those who will risk going too far can possibly find out how far one can go."} person={"T.S. Eliot"}/> */}

      {/* <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div> */}
      </div>
    </section>,







<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b>Reflect and Craft Your Personal Vision Statement
</b>
</h2>
<p className="mt-4 text-gray-600">
Welcome to a pivotal activity in Week 15 of the Ascent Leadership Program. This week, as you reflect on your journey of growth and learning, we present you with a unique opportunity to reconnect with your past self and chart your future course.
<br/> <br/>
At the start of this Ascent program in Week 0, you wrote a letter to your future self, outlining your dreams, goals, and aspirations as a leader. Now, after immersing yourself in 14 weeks of intensive learning and personal development, it's time to revisit that letter:

</p>


{/* <p>{Userletter?.content}</p> */}


<div className="mt-4 text-gray-600">
  <div className="">
    <div className="mb-4">
      <ul className="list-disc pl-6">
        <li>
        Begin by reading the letter you wrote in Week 0. Reflect on the aspirations, hopes, and expectations you had at the beginning of this program.        </li>
      </ul>
    </div>




    <>
  {/* Toast */}
  <div
    className="mb-8 relative bg-white border border-gray-200 rounded-xl shadow-lg darki:bg-gray-800 darki:border-gray-700"
    role="alert"
  >
    <div className="flex p-4">
      <div className="flex-shrink-0">
        {/* <img
          className="inline-block h-8 w-8 rounded-full"
          src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
          alt="Image Description"
        /> */}

      </div>
      <div className="ms-4 me-5">
        <h3 className="text-gray-800 font-medium text-sm darki:text-white">
          Your letter from Week-0
        </h3>
        <div className="mt-1 text-sm text-gray-600 darki:text-gray-400">
         {Userletter?.content}
        </div>

      </div>
    </div>
  </div>
  {/* End Toast */}
</>



    

    <div className="mb-4">
      <ul className="list-disc pl-6">
        <li>
        Now, write a new letter to your future self In at least 250 words, articulate how your perspective on leadership has changed. Reflect on the competencies you've developed, the challenges you've overcome, and the insights you've gained.        </li>
      </ul>
    </div>

    <div className="mb-4">
      <ul className="list-disc pl-6">
        <li>
        This reflective exercise is designed to help you measure your growth and to acknowledge the transformation in your leadership journey.        
        </li>
      </ul>
    </div>


  </div>
</div>






<p className="mt-4 text-gray-600">
Now, it’s your turn to step into the shoes of a leader. Analyze these risks and write a response discussing the following:

</p>



<ul
  className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
  style={{ listStyle: "circle" }}
>
  <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    Instructions{" "}
  </p>

  <li className="m-6">Be honest and introspective in your reflections.</li>
<li className="m-6">Think critically about the skills and knowledge you've gained.</li>
<li className="m-6">Consider this letter as a blueprint for your future leadership journey.</li>

</ul>

<div className="mt-6 flex justify-start w-full">
  <div className="form-control w-full ">
    <label className="label">
      <span className="label-text">Write Letter</span>
    </label>
    <textarea
      value={swotSubmission.response ?? ""}
      onChange={(e) =>
        setSubmission((prev) => ({
          ...prev,
          response: e.target.value,
        }))
      }
      className="textarea textarea-sm textarea-bordered h-20"
      placeholder="Type here..."
    ></textarea>
  </div>
</div>

<button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission} disabled={status === 'Completed'}>
  Submit
</button>
</section>,







<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>
{/* Step 1 content */}
<h2 className="text-xl font-medium sm:text-xl">
  <b>Reflect and Craft Your Personal Vision Statement
</b>
</h2>
<p className="mt-4 text-gray-600">
As you reach the culmination of the Ascent Leadership Program, it's time to channel your learnings and experiences from the past 14 weeks into crafting a personal vision statement. This statement will serve as a guiding beacon for your future, encapsulating your aspirations, values, and the impact you aim to create as a leader.
</p>


{/* <p>{Userletter?.content}</p> */}



<ul
  className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
  style={{ listStyle: "circle" }}
>
  <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    Instructions{" "}
  </p>

  <ul>
  <li className="m-6">Reflect on Your Journey: Take a moment to reflect on your key learnings from each week of the program. Consider the skills and insights you have gained and how they have shaped your understanding of leadership.</li>
  <li className="m-6">Envision Your Future: Think about the kind of leader you want to be and the impact you wish to make in your personal and professional life. What are the values you want to uphold? How do you want to be perceived by others?</li>
  <li className="m-6">Write Your Personal Vision Statement:
    <ul>
      <li>Begin by summarizing your core values and strengths that you have identified and honed during the program.</li>
      <li>Articulate your long-term goals and aspirations. Be specific about the kind of leader you aspire to be and the contributions you want to make.</li>
      <li>Use clear, affirmative, and passionate language. Write in the present tense, as if you are already embodying this vision.</li>
      <li>Keep your statement brief (about one paragraph) but powerful and inspirational.</li>
    </ul>
  </li>
  <li className="m-6">Review and Refine: Once you have written your vision statement, review it. Does it accurately reflect your aspirations and the leader you want to become? Feel free to revise it until it resonates deeply with you.</li>
</ul>

</ul>

<div className="mt-6 flex justify-start w-full">
  <div className="form-control w-full ">
    <label className="label">
      <span className="label-text">Write Your Personal Vision Statement</span>
    </label>
    <textarea
      value={swotSubmission2.response ?? ""}
      onChange={(e) =>
        setSubmission2((prev) => ({
          ...prev,
          response: e.target.value,
        }))
      }
      className="textarea textarea-sm textarea-bordered h-20"
      placeholder="Type here..."
    ></textarea>
  </div>
</div>

<button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission2} disabled={status === 'Completed'}>
  Submit
</button>
</section>,




<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}

<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>
        Week 15 Conclusion: Embracing Your Leadership Journey Ahead        </b>
      </h2>
      <p class="mt-4 text-gray-600">


      Congratulations on completing the final week of the Ascent Leadership Program! Week 15, "Reflection and Future Planning," marks not just the end of this program but the beginning of a new chapter in your leadership journey.

</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>


    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "View Slides";

      case 3:
        return "Embarking on the Journey of Self-Awareness";

      case 4:
        return "SWOT Analysis";

      case 5:
        return "Peer Review | SWOT Analysis";

      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{getTitle(currentStep)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week15;