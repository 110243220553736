// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek11_2 = ({ onImageIndexChange }) => {
  const slideImages = [

'https://res.cloudinary.com/ahbranding/image/upload/v1704373408/AHbranding/Slide_16_9_-_69_zqpseo.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704373406/AHbranding/Slide_16_9_-_70_yhwzi3.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704373406/AHbranding/Slide_16_9_-_73_byceju.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704373407/AHbranding/Slide_16_9_-_74_zwkc5v.png',
'https://res.cloudinary.com/ahbranding/image/upload/v1704373478/AHbranding/Slide_16_9_-_75_wui2iy.png'
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek11_2;
