import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function VerificationPopUp({text, desc, success}) {


  return (
<div>

<div className="fixed top-0 inset-x-0 z-[60]">
  {/* Card */}
  <div className="p-4 bg-white border border-gray-200 shadow-sm darki:bg-gray-800 darki:border-gray-700">
    <div className="max-w-sm w-full mx-auto">
      <h2 className="block text-xl font-bold text-gray-800 darki:text-white">
        {text}
      </h2>
      <p className="mt-2 text-sm text-gray-600 darki:text-gray-400">
        {desc}
      </p>
      {!success && (
  <Link className='btn btn-primary' to={'/enroll'}>
    Apply for Ascent program →
  </Link>
)}
      
    </div>
  </div>
  {/* End Card */}
</div>

</div>
  )
}

export default VerificationPopUp;